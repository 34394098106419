import React from "react";
import { observer, inject } from "mobx-react";
import { Button,Modal } from "antd-mobile";
import { FaCheckCircle,FaPlusCircle } from "react-icons/fa";
import configParam from "../libs/params";
const axios = require('axios');
const alert = Modal.alert;

@inject("cartStore")
@observer
class Recomendations extends React.Component {

   constructor(props) {
    super(props);

    this.state = {
	olderItem:0,	
	recomendations:[],	
     isLoading:false
    };
    
  }


     renderRecomendationBlock =()=>{
	 // request.recomendationPost();
	 let cartItemName=this.props.cartStore.getCartItemsName();
	 if(cartItemName.length===0 && this.props.page==='cart')
	 {
		 this.setState({
              recomendations:[],
			  olderItem:0,
			  isLoading:false
            });
		 return;
	 }
	 else{
		 this.setState({
	  isLoading:true,
	  recomendations:[],
	  olderItem:0
	   }); 
	 }
	let itemsRec=[];
    let itemsNames=[];
    let extraItem=[];
    axios.post(configParam.recomendCacheUrl,{
  "controller": this.props.cartStore.menuStore.store.controller,
  "items": cartItemName
     }).then(res=>{
		 
		const dataValues=Object.values(res.data); 
		//console.log(dataValues);
		const itemNames = dataValues.map(element => {
		return element.trim().toLowerCase();
		});
		
		const cartItemName1 = cartItemName.map(element => {
		return element.trim().toLowerCase();
		});

		
		//let menuToDisplay=this.props.cartStore.menuStore.searchResult;
		let listData = this.props.cartStore.menuStore.menuList;
		  Object.keys(listData).map((key, index) => {
        
			  let items=listData[key];        
				items.map(item => {
					let iName=item.name.trim().toLowerCase();
					  
					if(itemsNames.indexOf(iName)<0 && itemNames.indexOf(iName)>-1 && cartItemName1.indexOf(iName)<0 && !item.hasAddons)
					{
						//console.log(iName);
                        itemsNames.push(iName);
						 let avail = this.props.cartStore.menuStore.isAvailable(item);
						 if(avail){
							 itemsRec.push(item);
						 }
						
					}
					if(extraItem.length<3 && cartItemName1.indexOf(iName)<0 && !item.hasAddons)
					{
						 let avail1 = this.props.cartStore.menuStore.isAvailable(item);
						 if(avail1){
							 extraItem.push(item);
						 }					
					}
				    return item;
				});
				return key;
		  });  
      if(itemsRec.length===0)
      {
        itemsRec=extraItem;
      }
          this.setState({
			  isLoading:false,
              recomendations:itemsRec,
			        olderItem:this.props.cartStore.size
            }); 		  
	 
	}).catch(err => {
		 /* this.setState({
			  isLoading:false,
              recomendations:[],
			  olderItem:this.props.cartStore.size
        }); 
		*/
		const cartItemName1 = cartItemName.map(element => {
		return element?element.trim().toLowerCase():'';
		});
		let listData = this.props.cartStore.menuStore.menuList;
		  Object.keys(listData).map((key, index) => {        
			  let items=listData[key];
        
				items.map(item => {
					let iName=item.name?item.name.trim().toLowerCase():'';
					if(extraItem.length<3 && cartItemName1.indexOf(iName)<0)
					{
					extraItem.push(item);
					}
				    return item;
				});
				return key;
		  });
		  this.setState({
			  isLoading:false,
              recomendations:extraItem,
			  olderItem:this.props.cartStore.size
            }); 	
		  
	});
  };
render = () => {
       console.log(this.props.cartStore.menuStore.isRecommended);
		// if(!this.props.cartStore.menuStore.isRecommended)
		// {
			// return ('');
		// }
		if(this.state.olderItem!==this.props.cartStore.size && !this.state.isLoading)
		{
		this.renderRecomendationBlock();   
		}
		else if(!this.state.isLoading && this.props.page==='iteminfo' && this.state.recomendations.length===0)
		{
			this.renderRecomendationBlock();   
		}
		let items=this.state.recomendations;  
        return (
            
           
			<div className="recomSection">
			   <h3>{this.props.cartStore.menuStore.translation.recommended}</h3>
			{this.state.isLoading?(
			<div
			style={{
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center"      
			}}
			className="loadingIcon"
			>
			<img src={configParam.fzLoadingImage} alt="loading..." />
			</div>):('')}
			{items.length>0 && !this.state.isLoading && (
			<div className="recomItems">
			{items.map((item, index) => (
			<RecommendEntry key={index} item={item} cartStore={this.props.cartStore} />
			))}
			</div>)}
			{items.length===0 && !this.state.isLoading?(
			<div>{this.props.cartStore.menuStore.translation.no_recommendation}</div>
			):('')}
			</div>
        );
       
    };
}
const RecommendEntry = observer(({ item, cartStore }) => {
  let avail = cartStore.menuStore.isAvailable(item);
  let priceTx = parseFloat(item.price) === 0 ? cartStore.menuStore.translation.price_zero : item.displayPrice;
  let infoItem = item.name.includes('#INFO#');
  let name = item.name.replace('#INFO#', '');
  let isDisplayPrice=cartStore.menuStore.checkPriceDisplay(item);

  const chef1 = item.chef1 || 0;
  let qty = cartStore.quantity_map.get(item.id);
  name = _appendQty(qty, name);
  return (
    <div
      style={{
        display: "flex",
        paddingBottom: 15,
        position: "relative",
        width: "100%"
      }}
      className={"recoItem itemslist"}
      key={item.id}
      id={item.id}
    >
      <div
        style={{
          paddingRight: 6,
          position: "relative"
        }}
        onClick={() => {
          if (item.isInStock && avail) {
			manageItemInfo(item,cartStore);
          }
        }}
        className="customList"
      >
        {
          chef1 === 1 ? (
            <span className="chefRecommended"></span>
          ): null
        }
        <div className="thumbnailPlaceholder" />
        <img
          className="listEntryImage imageLoaded"
          src={item.thumbnail}
          alt=""
        />
      </div>

      <div className="li-text" style={{ paddingLeft: "15px" }}>
        <h4 className="li-head max-lines" dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(name) }} />
        <div className="pricewrapper">
		<p className="li-sub" style={{ paddingTop: 3 }}>
		{isDisplayPrice && (
		<span className="itemPrice" dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(priceTx) }} />
		)}

          {!item.isInStock && (
            <span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
            >
              {cartStore.menuStore.translation.out_of_stock}
            </span>
          )}
          {!avail && item.isInStock && (
            <span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
            >
              {cartStore.menuStore.translation.not_available_now}
            </span>
          )}
        </p>
        {item.isInStock && avail && !infoItem ? (
        <Button
          size={cartStore.menuStore.layout === 2 ? "large" : "small"}
          icon={
            qty > 0 ? (
              <FaCheckCircle color={"green"} />
            ) : (
                <FaPlusCircle />
              )
          }
          className={"break-new-theme-btn"}
          onClick={() => {
					let obj = {};
				obj.item = item;
				obj.addons = null;
				obj.notes = [""];
				cartStore.addItem(obj);
					alert(
						<div className="addedItem"></div>,
						"Added to your cart",
						[
						  {
							text: cartStore.menuStore.translation.ok
						  },						  
						]
					  ); 
          }}
        >          
        </Button>
      ) : null}
	   </div>
      </div>
    </div>
  );
});
function _appendQty(qty, name) {
  if (qty > 0) {
    return '<span class="qtyc">' + qty + 'x ' + name + '</span>';
  }
  return name;
}
function manageItemInfo(item,cartStore)
{
	   if(item.isCustomize)
	   {
		let enty=cartStore.getEntry(item);
		if(enty)
		{
			cartStore.menuStore.itemModal.showUpdateModal(
              enty.item,
              enty.addons,
              enty.notes
            );          
          return false;
		}  
	   }
	 cartStore.menuStore.itemModal.setItem(item);
}
export default Recomendations;
