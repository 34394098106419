import React, { Fragment } from "react";
import { Button, Modal, List, Checkbox } from "antd-mobile";
import _ from "underscore";
import { FiShoppingCart } from "react-icons/fi";
import { observer } from "mobx-react";
import { FaPaperclip } from "react-icons/fa";
import PromptPopup from "./Popup";
import CircularProgressbar from "react-circular-progressbar";
import API from "../libs/api";

const TOP_HEIGHT = 40;
const BOTTOM_HEIGHT = 40;
const Item = List.Item;
const Brief = Item.Brief;
const CheckboxItem = Checkbox.CheckboxItem;
const timeInterval = [2, 3, 4, 5, 10, 15, 20, 25, 30];
const COUNTDOWN_LIMIT = 3;
let countdownTimer;
let cancelCheckout;
let pollingTimer;


class CheckOrderInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      excludedItesm: [],
      htmlMsg: false
    };
  }
  componentDidMount() {
    if (this.props.onMounted) {
      this.props.onMounted({
        callChangeTableFunction: res => this.callChangeTableFunction(res)
      })
    }

    //this._refreshImages();

  }
  callChangeTableFunction(num) {
    if (num === 1) {
      this.setState({
        promptPopup: true

      });
      this._prepareChangeTableCountdown();
    }

  }
  callGetStatusApi(url) {
    let { cartStore } = this.props;
    return new Promise((resolve, reject) => {
      API.checkOrderStatus(url)
        .then(res => {

          if (res.toString().indexOf("PHP Error") !== -1 || res.length === 0) {
            reject({
              title: cartStore.menuStore.transation.error,
              content: cartStore.menuStore.transation.error_network
            });
          } else {
            resolve(res);
          }
        })
        .catch(err => {
          reject({
            title: cartStore.menuStore.translation.error,
            content: cartStore.menuStore.translation.error_network
          });
        });
    });
  }
  getPollStatus(ind, url) {
    //console.log(ind,'hari');
    let { cartStore } = this.props;
    let timeArr = timeInterval;
    let timePoll = parseInt(timeArr[ind]);
    ind++;
    this.callGetStatusApi(url)
      .then(res => {
        if (res.status !== "1" && res.status !== "-1" && timePoll) {
          //  console.log('again');
          clearTimeout(pollingTimer);
          pollingTimer = setTimeout(() => {
            return this.getPollStatus(ind, url);
          }, timePoll * 1000);
          //return ;
        }
        else if (res.success || res.success === false) {
          //this._setDefaultMessage(res);
          //console.log('status',res.success);
          this.setState({
            //excludedItesm: [],
            promptTitle: cartStore.menuStore.translation.status_no + this.props.order.id,
            promptContent: res.lastMessage,
            promptLoading: false,
            htmlMsg: true,
            closePopup: this._defaultClose()
          });
        }
        else {
          console.log('ele errr', res.success);
          this.setState({
            promptLoading: false,
            // promptTitle: cartStore.menuStore.translation.order_queue_no+ ' '+ this.props.order.id,
            promptContent: cartStore.menuStore.translation.order_check_again,
            closePopup: this._defaultClose()
          });
        }

        return res;
      });
    //}
    //return checkOrderResponse;
  }
  _checkOrderTableApi() {
    let { cartStore } = this.props;

    if (!navigator.onLine) {
      return Promise.reject({
        title: cartStore.menuStore.translation.no_internet,
        content: cartStore.menuStore.transation.error_network
      });
    }
    //let lastO= cartStore.menuStore.getLastOrder();
    let order = this.props.order;
    let payload = cartStore.prepareOrderParams('checkTable', order);

    return new Promise((resolve, reject) => {
      API.changeTableCallApi(payload)
        .then(res => {
          if (res.toString().indexOf("PHP Error") !== -1) {
            reject({
              title: cartStore.menuStore.transation.error,
              content: cartStore.menuStore.transation.error_network
            });
          } else {
            //console.log(res);		
            resolve(res);
          }
        })
        .catch(err => {
          console.log(err);
          reject({
            title: cartStore.menuStore.translation.error,
            content: cartStore.menuStore.translation.error_network
          });
        });
    });
  };

  _checkOrderApi(payload) {
    let { cartStore } = this.props;


    const cachedCode = localStorage.getItem(`${cartStore.menuStore.controller}_code`);
    let verificationCode = "";
    if (cachedCode) {
      verificationCode = JSON.parse(cachedCode).code;
    }
    return new Promise((resolve, reject) => {
      API.checkOrder(payload, verificationCode)
        .then(res => {
          if (res.toString().indexOf("PHP Error") !== -1) {
            reject({
              title: cartStore.menuStore.transation.error,
              content: cartStore.menuStore.transation.error_network
            });
          } else {
            //console.log(res);		
            resolve(res);
          }
        })
        .catch(err => {
          console.log(err);
          reject({
            title: cartStore.menuStore.translation.error,
            content: cartStore.menuStore.translation.error_network
          });
        });
    });
  };
  _checkOrder = () => {
    this._prepareCountdown()

  }
  _setDefaultMessage = (res) => {
    this.setState({
      promptTitle: res.title,
      promptContent: res.content,
      promptLoading: false,
      closePopup: this._defaultClose()
    });
  }
  _defaultClose = closeText => {
    const { cartStore } = this.props;
    return [
      {
        text: closeText
          ? closeText
          : cartStore.menuStore.translation.close,
        onPress: () => {
          clearInterval(countdownTimer);
          clearTimeout(cancelCheckout);
          this.setState({
            promptContent: "",
            promptPopup: false,
            htmlMsg: false
          });
        }
      }
    ];
  };
  _renderCircleProgress = (countdown, total_limit, closeText) => {
    let total_time = total_limit ? total_limit : COUNTDOWN_LIMIT
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <div style={{ position: "absolute", width: "100%" }}>
          {closeText ? closeText : this.props.cartStore.menuStore.translation.cancel}
        </div>
        <div
          style={{
            width: "35px",
            float: "right",
            paddingRight: 12,
            paddingTop: 4,
            position: "relative"
          }}
        >
          <CircularProgressbar
            percentage={
              (Math.abs(countdown - total_time) / total_time) * 100
            }
            strokeWidth={50}
            textForPercentage={null}
            styles={{
              width: 20,
              path: { strokeLinecap: "butt" },
              text: { fill: "#000" }
            }}
          />
        </div>
      </div>
    );
  };
  _prepareChangeTableCountdown = () => {
    const { cartStore } = this.props;
    if (!navigator.onLine) {
      this.setState({
        promptPopup: true,
        promptTitle: cartStore.menuStore.translation.no_internet,
        promptContent: cartStore.menuStore.translation.error_network,
        promptLoading: false,
        closePopup: this._defaultClose()
      });

      return;
    }
    this.setState(
      {
        timeCountdown: COUNTDOWN_LIMIT
      },
      () => {
        this.setState({
          promptPopup: true,
          promptTitle: cartStore.menuStore.translation.checking + ' (' + this.props.order.id + ')',
          promptContent: cartStore.menuStore.translation.waiting_respond_outlet,
          promptLoading: false,
          closePopup: this._defaultClose(
            this._renderCircleProgress(this.state.timeCountdown)
          )
        });
      }
    );
    countdownTimer = setInterval(() => {
      this.setState(
        {
          timeCountdown: this.state.timeCountdown - 1
        },
        () => {
          this.setState({
            closePopup: this._defaultClose(
              this._renderCircleProgress(this.state.timeCountdown)
            )
          });
        }
      );
    }, 1000);

    cancelCheckout = setTimeout(() => {
      clearInterval(countdownTimer);

      // Handle failed checkout
      countdownTimer = setTimeout(() => {
        this.setState({
          promptTitle: cartStore.menuStore.translation.error,
          promptContent: cartStore.menuStore.translation.error_network,
          promptLoading: false,
          closePopup: this._defaultClose()
        });
      }, 20000);

      this.setState({
        promptLoading: true,
        promptTitle: cartStore.menuStore.translation.order_queue_no + ' ' + this.props.order.id,
        promptContent: cartStore.menuStore.translation.waiting_respond_outlet,
        closePopup: this._defaultClose()
      });

      this._checkOrderTableApi()
        .then(res => {
          clearTimeout(cancelCheckout);
          clearTimeout(countdownTimer);
          if (res.pushMsg) {

            let pumsg = JSON.parse(res.pushMsg);
            //  console.log(pumsg,pumsg.status);
            if (parseInt(pumsg.status) === 1 || pumsg.status === "1") {
              //console.log("I am in",res.checkUrl,res);
              let url = res.checkUrl + "?accessToken=hkfwuifuw";
              this.getPollStatus(0, url);
            }
            else {
              // console.log('ellelel');
              this._setDefaultMessage({ title: cartStore.menuStore.translation.error, content: cartStore.menuStore.translation.error_network });
            }
          }
          else {
            this._setDefaultMessage({ title: cartStore.menuStore.translation.error, content: cartStore.menuStore.translation.error_network });
          }
        })
        .catch(err => {
          clearTimeout(cancelCheckout);
          clearTimeout(countdownTimer);
          this.setState({
            promptTitle: err.title,
            promptContent: err.content,
            promptLoading: false,
            closePopup: this._defaultClose()
          });
        });
    }, COUNTDOWN_LIMIT * 1000);
  };

  _prepareCountdown = () => {
    const { cartStore } = this.props;

    let itemids = this.state.excludedItesm;
    let order = this.props.order;

    let payload = cartStore.prepareOrderParams(itemids, order);
    if (payload === "") {
      this.setState({
        promptPopup: true,
        promptTitle: cartStore.menuStore.translation.error,
        promptContent: cartStore.menuStore.translation.check_item_error,
        promptLoading: false,
        closePopup: this._defaultClose()
      });
      return;
    }
    if (!navigator.onLine) {
      this.setState({
        promptPopup: true,
        promptTitle: cartStore.menuStore.translation.no_internet,
        promptContent: cartStore.menuStore.translation.error_network,
        promptLoading: false,
        closePopup: this._defaultClose()
      });

      return;
    }
    this.setState(
      {
        timeCountdown: COUNTDOWN_LIMIT
      },
      () => {
        this.setState({
          promptPopup: true,
          promptTitle: cartStore.menuStore.translation.checking + ' (' + this.props.order.id + ')',
          promptContent: cartStore.menuStore.translation.waiting_respond_outlet,
          promptLoading: false,
          closePopup: this._defaultClose(
            this._renderCircleProgress(this.state.timeCountdown)
          )
        });
      }
    );
    countdownTimer = setInterval(() => {
      this.setState(
        {
          timeCountdown: this.state.timeCountdown - 1
        },
        () => {
          this.setState({
            closePopup: this._defaultClose(
              this._renderCircleProgress(this.state.timeCountdown)
            )
          });
        }
      );
    }, 1000);

    cancelCheckout = setTimeout(() => {
      clearInterval(countdownTimer);

      // Handle failed checkout
      countdownTimer = setTimeout(() => {
        this.setState({
          promptTitle: cartStore.menuStore.translation.error,
          promptContent: cartStore.menuStore.translation.error_network,
          promptLoading: false,
          closePopup: this._defaultClose()
        });
      }, 20000);

      this.setState({
        promptLoading: true,
        promptTitle: cartStore.menuStore.translation.order_queue_no + ' ' + this.props.order.id,
        promptContent: cartStore.menuStore.translation.waiting_respond_outlet,
        //promptContent:"",
        closePopup: this._defaultClose()
      });

      this._checkOrderApi(payload)
        .then(res => {
          clearTimeout(cancelCheckout);
          clearTimeout(countdownTimer);
          if (res.pushMsg) {

            let pumsg = JSON.parse(res.pushMsg);
            //  console.log(pumsg,pumsg.status);
            if (parseInt(pumsg.status) === 1 || pumsg.status === "1") {
              //console.log("I am in",res.checkUrl,res);
              let url = res.checkUrl + "?accessToken=hkfwuifuw";
              this.getPollStatus(0, url);
            }
            else {
              // console.log('ellelel');
              this._setDefaultMessage({ title: cartStore.menuStore.translation.error, content: cartStore.menuStore.translation.error_network });
            }
          }
          else {
            this._setDefaultMessage(res);
          }
        })
        .catch(err => {
          clearTimeout(cancelCheckout);
          clearTimeout(countdownTimer);
          this.setState({
            promptTitle: err.title,
            promptContent: err.content,
            promptLoading: false,
            closePopup: this._defaultClose()
          });
        });
    }, COUNTDOWN_LIMIT * 1000);
  };

  _setItems(id) {
    let itemIds = this.state.excludedItesm;
    // console.log(itemIds,itemIds.indexOf(id));
    if (itemIds.indexOf(id) > -1) {
      let ind = itemIds.indexOf(id);
        delete itemIds[ind];
        let itemIds1 = itemIds.filter(function (el) {
          return el != null;
        });
         itemIds=itemIds1;
    }
    else {
      itemIds.push(id);
    }
    this.setState({
      excludedItesm: itemIds
    },()=>{
      let chb= document.querySelector("#checkOrder"+id+" .am-checkbox");
          chb.className = chb.className.replace(" am-checkbox-checked", "");   
      if (itemIds.indexOf(id) > -1) {
        chb.classList.add("am-checkbox-checked");
      }  
    });
    console.log(itemIds);
  };
  _renderOrderEntry() {
    const order = this.props.order;
    const cartStore = this.props.cartStore;
    if (!order.cartEntries || order.cartEntries.length === 0) {
      return (
        <div style={{ textAlign: "center", paddingTop: "25%" }}>
          <FiShoppingCart size={35} />
          <br />
          <br />
          {cartStore.menuStore.translation.cart_empty}
        </div>
      );
    } else {
      return _.map(order.cartEntries, entry => {
        return (
          <Fragment key={entry.item.id}>
            <div className="cartHeader" />
            <OrderItem entry={entry} cartStore={cartStore} checkInfoObj={this} />
          </Fragment>
        );
      });
    }
  };
  _renderPromptPopup = () => {
    return (
      <PromptPopup
        visibility={this.state.promptPopup}
        title={this.state.promptTitle}
        closePopup={this.state.closePopup}
        content={this.state.promptContent}
        isLoading={this.state.promptLoading}
        htmlMsg={this.state.htmlMsg}
		 cartStore={this.props.cartStore}
      />
    );
  };

  render() {
    // console.log(this.props.order);
    let carEntL=(this.props.order.cartEntries?this.props.order.cartEntries.length:0);
    let widper = "50%";
    return (
      <div>
        <Modal
          visible={this.props.visible}
          style={{ overflow: "hidden", height: "100%", textAlign: "left" }}
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              height: `${TOP_HEIGHT}px`,
              width: "100%"
            }}
          >
            <div className="info_header">
            [{this.props.order.id}] {this.props.cartStore.menuStore.translation.cart_check_order}
            <span
                className="info_close"
                onClick={() => {
                 
                  this.setState({
                    excludedItesm: []
                  },()=>{
                    this.props.closeModal();
                  }                  
                  );  
                }}
              >
                {this.props.translation.x}
              </span>
            </div>
            <div
              style={{
                position: "fixed",
                overflow: "scroll",
                height: "calc(100% - 52px - 40px)",
                width: "100%"
              }}
            >
              <div style={{ paddingTop: 15, margin: "0 auto" }}>

                {this._renderOrderEntry()}
              </div>
            </div>
            <div
              className="bottom"
              style={{
                position: "fixed",
                height: `${BOTTOM_HEIGHT}px`,
                bottom: 0,
                paddingTop: 6,
                width: "100%"
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  padding: 3,
                  width: "100%"
                }}
              >
                <Button
                  className="bottomBtn"
                  style={{ width: widper, float: "left" }}
                  onClick={() => {
                    this.setState({
                      excludedItesm: []
                    },()=>{
                      this.props.closeModal();
                    }                  
                    );  
                  }}
                >
                  {this.props.translation.close}
                </Button>
                <Button
                   className="bottomBtn"
                  style={{ width: widper, float: "left" }}
                  onClick={() => {
                    this._checkOrder();
                  }}
                >
                  {this.props.cartStore.menuStore.translation.last_order_btn}({this.state.excludedItesm.length}/{carEntL})
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        {this._renderPromptPopup()}
      </div>
    );
  }
}
const OrderItem = observer(({ entry, cartStore, checkInfoObj }) => {
  let item = entry.item;
  let priceTx = parseFloat(item.price) === 0 ? cartStore.menuStore.translation.price_zero : item.priceName;
  let itemNotes = [];
  if (entry.notes.length > 0) {
    //console.log(entry.notes.length);
    _.each(entry.notes, (note, index) => {
      if (note !== '') { itemNotes.push(note); }
    });
  }
  itemNotes = itemNotes.join(', ');

  let extratag = <span
    className="qtytextfield"
  >{entry.quantity}</span>

  return (
    <List>
      <Item
        wrap
        className="cart_item"
        thumb={<ItemImg entry={entry} cartStore={cartStore} checkInfoObj={checkInfoObj} />}
        extra={extratag}
        onClick={() => { checkInfoObj._setItems(entry.item.id);  }}
      >
        <div className="cartItemName"  >
          <div className="innerItemName">
            <p>{item.name}</p>
            <Brief>
              <div dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(priceTx) }} />
              {entry.hasNote && <FaPaperclip />}{itemNotes}
            </Brief>

          </div>
        </div>
      </Item>
    </List>
  );
});


const ItemImg = observer(({ entry, cartStore, checkInfoObj }) => {
  //console.log(entry);
  let layoutType = cartStore.menuStore.layout;
  let defaultImage = false;
  if (layoutType === 3) {
    defaultImage = entry.item.thumbnail.includes('/dish');
  }

  return (
    <div style={{
      display: "flex"
    }}  
    >
      <span
        className={defaultImage ? "" : "marginRight10"}
        style={{
          display: "flex",alignItems:"center"
        }}
      >
        <CheckboxItem 
        defaultChecked={false} 
        key={entry.item.id} 
        id={"checkOrder"+entry.item.id}
         onChange={(val) => {
        
         }} 
        />
      </span>
      {!defaultImage ? (
        <img
          src={entry.item.thumbnail}
          alt=""
        />) : ('')}
    </div>
  );
});
export default CheckOrderInfo;
