import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import { List,Button,Modal } from "antd-mobile";
import "../cart.css";
import PromptPopup from "./Popup";
import SuccessInfo from "./SuccessInfo";
import CheckOrderInfo from "./CheckOrderInfo";
import CheckBill from "./CheckBill";
import { FiLayers } from "react-icons/fi";
import { FaCheckCircle, FaClock, FaTimesCircle,FaTrashAlt } from "react-icons/fa";
import PowerBy from "./PowerBy";
import moment from "moment";
//import _ from "underscore";
const alert = Modal.alert;
const Item = List.Item;
//const Brief = Item.Brief;

@inject("orderStore")
@inject("cartStore")
@observer
class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promptPopup: false,
      promptTitle: this.props.cartStore.menuStore.translation
        .order_check_detail,
      promptContent: "",
      promptLoading: true,
      successModal: false,
      qrCode: "",
      receipt: "",
      order: ""
    };
  }

 componentDidMount ()
  {	     
     let lastorderPoup=this.props.cartStore.menuStore.showlastOrderPupup;
    // console.log(lastorderPoup,'66666');
      if (lastorderPoup===1) {          
        const ORDER_SAVE_KEY = `${this.props.cartStore.menuStore.controller}_order`; 
        const orders = localStorage.getItem(ORDER_SAVE_KEY);
        if (orders !== "undefined") {        
          const jsonOrders = JSON.parse(orders);
           let lastOrd=jsonOrders[jsonOrders.length-1];
           this._checkorderStatus(lastOrd);                  
        }
      }
      if (lastorderPoup===2) { 
        let lastO= this.props.cartStore.menuStore.getLastOrder();
        this.setState({        
          order:lastO
        });
        this.checkOrdInfo.callChangeTableFunction(1);
      }

  }
  changeTableMounted (callChangeTable) {
    this.checkOrdInfo = callChangeTable
   }
  checkInfoHandler=() => {
    this.setState({        
      checkInfoModal: true,
      promptPopup: false,
      successModal:false
    });
  }
  successHandler=(order) => {
    this.setState({
      order:order,
      qrCode: order.id,
      receipt:  order.info,
      successModal: true,
      promptPopup: false,
	  isNew:order.isNew,
    });
  }
 _checkorderStatus=(order)=>
 {
  const { cartStore, orderStore } = this.props;
  this._openPopup();
  
  if(order.isSocketAPi===true)
  {
	  if (order.status>0) {
      this.successHandler(order);
	  return ;
      } 
      
  }
  else if(order.status>=2)
  {
      if (order.status === true || order.status === 2 || order.status === 200) {
      this.successHandler(order);
      } else {
        
      this._updatePopup(
      `${cartStore.menuStore.translation.order_queue_no} ${order.id}`,
      <div>
      <div>
      {order.info === undefined
      ? cartStore.menuStore.translation.order_expired
      : decodeURIComponent(order.info)}
      </div>
      </div>
      );
      }
      return;
  }
  if(!navigator.onLine)
  {	
            this._updatePopup(					
      <div>
      <div>
      {cartStore.menuStore.translation.no_internet}
      </div>
      </div>
      );		
    return;
  }
  
  
  orderStore.checkOrder(order)
        .then(res => {
          if (res.status === true || res.status === 2 || res.status === 200) {
            this.successHandler(res);				
          } 
		  else {
			  let oInf=order.info
			  if(res.status === 1 || (order.isSocketAPi===true && res.status === 0))
			  {
				  oInf=cartStore.menuStore.translation.order_check_again;
			  }
            this._updatePopup(
              `${cartStore.menuStore.translation.order_queue_no} ${res.id}`,
              <div>
                <div>
                  {order.info === undefined
                    ? cartStore.menuStore.translation.order_expired
                    : decodeURIComponent(oInf)}
                </div>
              </div>
            );
          }
        })
        .catch(err => {
          this._updatePopup(
            cartStore.menuStore.translation.error,
            <div>
              <div>{err.info}</div>
            </div>
          );
        });
 }
  _openPopup = () => {
    this.setState({
      promptPopup: true
    });
  };

  _updatePopup = (title, content) => {
    this.setState({
      promptTitle: title,
      promptContent: content,
      promptLoading: false
    });
  };

  _closePopup = () => {
    this.setState(
      {
        promptPopup: false
      },
      () => {
        this.setState({
          promptLoading: true,
          promptTitle: this.props.cartStore.menuStore.translation
            .order_check_detail,
          promptContent: ""
        });
      }
    );
  };
 
  _renderPromptPopup = () => {
    return (
      <PromptPopup
        visibility={this.state.promptPopup}
        title={this.state.promptTitle}
        closePopup={this._closePopup}
        content={this.state.promptContent}
        isLoading={this.state.promptLoading}
		 cartStore={this.props.cartStore}
      />
    );
  };
 repeatOrderCall=()=>{
   //console.log(this.state.order,this.state.order.cartEntries);  
	 this.props.cartStore.repeatOrder(this.state.order);
	 this.props.cartStore.tabHandler("cart");
 };
  _renderOrderList() {
    const { cartStore, orderStore } = this.props;
    if (orderStore.isEmpty) {
      return (
        <div style={{ textAlign: "center", paddingTop: "20%" }}>
          <FiLayers size={35} />
          <br />
          <br />
          {this.props.cartStore.menuStore.translation.order_empty}
        </div>
      );
    } else {
      return (
        <Fragment>         
          {orderStore.reversedList.map((order,i) => (
            <OrderList
              key={"orderlist"+i}
              order={order}
              orderStore={orderStore}
              cartStore={cartStore}
              checkorderStatus={this._checkorderStatus}
            />
          ))}
        </Fragment>
      );
    }
  }
  _renderClearConfimPopup() {
	   	
		//console.log(this.props.cartStore.entries.length,88);
     if (!this.props.cartStore.orderStore.isEmpty) {
	  return (             
			 <div className="withoutLog clearCartBtn" >              
			 <Button 
               className="bypassbtn greytext"             
              onClick={() => {                  
				        
			     alert(
            this.props.cartStore.menuStore.translation.option_reset_order,
            this.props.cartStore.menuStore.translation
              .option_reset_order_confirmation,
            [
              {
                text: this.props.cartStore.menuStore.translation.cancel
              },
              {
                text: this.props.cartStore.menuStore.translation.confirm,
                onPress: () => {
                  this.props.cartStore.orderStore.clearOrders();
				  //  window.gtag('event','Clear Order', {  'event_category': 'Click',  'event_label': this.props.cartStore.menuStore.controller,  'value': 1});
            this.props.cartStore.menuStore.sendGoogleAnalytics('Click', 'Clear Order', this.props.cartStore.menuStore.controller, 1);     
            alert(
                    this.props.cartStore.menuStore.translation
                      .option_reset_complete,
                    null,
                    [
                      {
                        text: this.props.cartStore.menuStore.translation.close
                      }
                    ]
                  );
                }
              }
            ]
          );
						
						
              }}             
              >
			  <FaTrashAlt color={"#666"} size={16} className="trashIcon" />
               <span style={{ marginLeft:"5px" }}>{this.props.cartStore.menuStore.translation.menu_option_clear_past_order}</span>			  
              </Button>
              </div>
         );
	 } else {
		 return ("");
	 }
  };
  render() {
    return (
      <div className="viewPortOrder">
	  {this.props.cartStore.menuStore.layout===4 && (<div className="pastOrderHeading">{this.props.cartStore.menuStore.translation.order_past_title}</div>)}
        <div key="order-all">{this._renderOrderList()}</div>
		 <div key="order-option">{this._renderPromptPopup()}</div>
       {this.props.cartStore.menuStore.layout!==4 && (
	   <Fragment>
	  
        <CheckBill  cartStore={this.props.cartStore} />
	     	{this._renderClearConfimPopup()}      

	   <PowerBy/></Fragment>)}

        <SuccessInfo
          qrCode={this.state.qrCode}
          receipt={this.state.receipt}
          visible={this.state.successModal}
          closeModal={() => this.setState({ successModal: false },()=>{
			  if(this.props.cartStore.menuStore.layout===4)
			  {
				  let order=this.state.order;
				  if(order.isNew)
				  {
					 this.props.cartStore.orderStore.updateOrderNewStatus(order); 
				  }
				  this.props.showTabsPage('menu');
			  }
			  
		  })}
          translation={this.props.cartStore.menuStore.translation}
          order={this.state.order}
          repeatOrderFun={this.repeatOrderCall}
          checkOrderModal={this.checkInfoHandler}
          cartStore={this.props.cartStore}
        />
        <CheckOrderInfo         
          visible={this.state.checkInfoModal}
          closeModal={() => this.setState({ checkInfoModal: false })}
          translation={this.props.cartStore.menuStore.translation}
          order={this.state.order}
          cartStore={this.props.cartStore}
          onMounted={callChangeTable => this.changeTableMounted(callChangeTable)}
        />
        
      </div>
    );
  }
}

const OrderList = observer(
  ({
    order,
    orderStore,    
    cartStore,
    checkorderStatus
  }) => {	  
    let status = order.status;
	if(order.isSocketAPi===true)
	{
		status = cartStore.menuStore.translation.order_waiting;
		if (order.status ===1)
		status = cartStore.menuStore.translation.waiting_cooking;
		else if (order.status===2)
		status = cartStore.menuStore.translation.order_cooking;
		else if (order.status === 3)
		status = cartStore.menuStore.translation.waiting_delivery;
		else if (order.status === 4)
		status = cartStore.menuStore.translation.order_delivery;
		else if (order.status === 5)
		status = cartStore.menuStore.translation.order_delivered;
		else if (order.status===6)
		status = cartStore.menuStore.translation.order_billed;
	    else if (order.status===7)
		status = cartStore.menuStore.translation.order_paid;
		else if (order.status===8)
		status = cartStore.menuStore.translation.order_cancelled;
		else if (order.status===9)
		status = cartStore.menuStore.translation.order_refund;
		
	}
    else if (order.status ===null || order.status==='' || order.status===0)
      status = cartStore.menuStore.translation.order_pending;
    else if (order.status ===1)
      status = cartStore.menuStore.translation.order_waiting_confirm;
    else if (order.status === true || order.status===2)
      status = cartStore.menuStore.translation.order_accepted;
    else if (order.status === 100)
		status = cartStore.menuStore.translation.order_rejected;
	else if (order.status === 300)
		status = cartStore.menuStore.translation.order_error;
	else if (order.status === 90)
		status = cartStore.menuStore.translation.order_expired;
	else if (order.status===200)
      status = cartStore.menuStore.translation.order_success;

     let table="";
     if(order.table && order.table!=="")
	 {
		table=' ('+order.table+')'; 
	 }
	 let thumb='';
		if(cartStore.menuStore.layout===4)
		{
		thumb=<div className="orderItemImage">
		<img
		src={order.cartEntries[0].item.thumbnail}
		alt=""
		/></div>;		
		}
		 else if(order.status === true || order.status===2 || order.status===200 || (order.isSocketAPi===true && order.status>0))
		 {
			  thumb=<FaCheckCircle color="green" />;
		 }
	     else if(order.status < 2)
		 {
			thumb=<FaClock />;
		 }
		 else
		 {
			 thumb=<FaTimesCircle color="red" />;
		 }
        
     		 
    return (
      <Item
	    key={"order_item"+(order.uuid && order.uuid!=="" ?order.uuid:order.id)}
        className={`order_item`}
        id={"order_id"+order.id}
        thumb={thumb}
        extra={status}
        onClick={() => {
          checkorderStatus(order);
        }}
      >
        {order.id} {table} <p className="dateFormat">{moment(order.time,'DD/MM/YYYY HH:mm:ss').format('DD MMM, HH:mm')}</p>
      </Item>
    );
  }
);

export default Order;
