import { observable, action, computed, toJS } from "mobx";
import API from "../libs/api";
import moment from "moment";
import _ from "underscore";
import translation from "../libs/translation";
import configParams from "../libs/params";

const _selectLang = (text, language) => {
  if (text !== undefined) {
    if (text.indexOf("|") !== -1) {
      return text.split("|")[language];
    }
  }
  return text;
};
//console.log(translation['en']);
class AddonItem {
  id;
  name;
  description;
  groupName;
  groupOrder;
  min;
  max;
  isInStock;
  price;
  priceName;
  opt1;
  modifiers;
  @observable quantity;

  constructor({
    id,
    name,
    description,
    groupName,
    groupOrder,
    min,
    max,
    isInStock,
    price,
    priceName,
    quantity,
	opt1,
	modifiers
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.groupName = groupName;
    this.groupOrder = groupOrder;
    this.min = min;
    this.max = max;
    this.isInStock = isInStock;
    this.price = price;
    this.priceName = priceName;
    this.quantity = quantity;
    this.opt1 = opt1;
    this.modifiers = modifiers;
  }

  @computed get displayPrice() {
    return this.priceName;
  }

  @action setQuantity(quantity) {
    this.quantity = quantity;
  }
}

class Item {
  id;
  name;
  description;
  addons;
  thumbnail;
  price;
  priceName;
  category;
  isInStock;
  opt1;
  des1;
  promoName;
  discount;
  startDate;
  endDate;
  startTime;
  endTime;
  days;
  promoAvailable;
  constructor({
    id,
    name,
    description,
    addons,
    thumbnail,
    price,
    priceName,
    category,
    isInStock,
    opt1,
    des1,
    promoName,
    discount,
    startDate,
    endDate,
    startTime,
    endTime,
    days,
    chef1,
	sku
  }) {
    let nameAr = name.split('[C:1]');
    let is_customize = false;
    if (nameAr.length > 1) {
      //	console.log(nameAr,name);
      name = nameAr[0];
      is_customize = true;
    }
    this.id = id;
    this.addons = addons;
    this.name = name;
    this.description = description;
    this.thumbnail = thumbnail;
    this.price = price;
    this.priceName = priceName;
    this.category = category;
    this.isInStock = isInStock;
    this.opt1 = opt1;
    this.des1 = des1;
    this.promoName = promoName;
    this.discount = discount;
    this.startDate = startDate;
    this.endDate = endDate;
    this.startTime = startTime;
    this.endTime = endTime;
    this.days = days;
    this.chef1 = chef1;
    this.isCustomize = is_customize;
    this.sku = sku;
    //console.log(this.days,'77');
  }

  @computed get displayPrice() {
    return this.priceName;
  }

  @computed get hasAddons() {
    return this.addons
      ? Object.keys(this.addons).length > 0
        ? true
        : false
      : false;
  }
}

class ItemModal {
  @observable multiCheckBox = false;
  @observable isVisible;
  @observable isUpdate;
  @observable item;
  @observable.shallow addons = [];
  @observable.shallow notes = [];
  @observable.shallow checkboxOptions = [];
  @observable.shallow selectedtOpts = [];
  @observable isMenuPopup = false;
  @observable isClickedStepper = false;
  @observable item_popup_name = "";
  specialRequestHandler;

  constructor({ isVisible, item }) {
    this.isVisible = isVisible;
    this.isUpdate = false;
    this.item = item;
    this.isMenuPopup = false;

    /*this.multiCheckBox=false;
    this.checkboxOptions = [];
    this.selectedtOpts = [];*/
  }


  @action setMultiChoice(status, options) {
    this.multiCheckBox = status;
    this.checkboxOptions = options;
    this.item_popup_name = "";
    this.isMenuPopup = false;
    this.footerBtn = [];
  }

  @action setMultiChoiceWithFooter(status, options, footerbtn, item_popup_name) {
    // console.log(item_popup_name);
    this.multiCheckBox = status;
    this.checkboxOptions = options;
    this.footerBtn = footerbtn;
    this.isMenuPopup = true;
    this.item_popup_name = item_popup_name;
  }

  @action closeModal() {
    this.isVisible = false;
  }

  // We will clone the addon
  @action addAddon() {
    let rawJS = toJS(this.item.addons);
    let cloned = {};
    for (let category in rawJS) {
      let list = rawJS[category];
      let mapped = list.map(addon => {
        return new AddonItem({ ...toJS(addon) });
      });
      cloned[category] = mapped;
    }
    this.addons.push(cloned);
    this.notes.push("");
  }
  @action makeAddonObject(addon) {
    return new AddonItem({ ...addon });
  }
  @action setItem(item,rewardObj) {
    this.item = item;
    this.isVisible = true;
    this.isUpdate = false;
    this.addons = [];
    this.notes = [];
    this.isClickedStepper = false;
    this.rewardItem = rewardObj;
    // Clone the addon by default
    this.addAddon();
  }

  @action showUpdateModal(item, addons, notes, specialRequestHandler) {
    this.item = item;
    this.isVisible = true;
    this.isClickedStepper = false;
    this.isUpdate = true;
    this.addons = addons;
    this.notes = notes;
    this.actualAddon=addons.length;
    this.specialRequestHandler = specialRequestHandler;
  }

  // addonListIndex, addonIndex - Used to check if addon has hit the limit of group max
  @action addonQuantityHandler(addonListIndex, key, addonIndex, quantity) {
    const isReduce =
      this.addons[addonListIndex][key][addonIndex].quantity - quantity === 1;

    if (isReduce) {
      this.addons[addonListIndex][key][addonIndex].quantity = quantity;
    } else {
      const groupAddon = this.addons[addonListIndex][key];
      const totalAddons = groupAddon.length;
      // Iterate through all addons in the group to count quantity if hit max
      const max = groupAddon[0].max;

      // If max = 0 means no limit, we just set the quantity
      if (max === 0) {
        this.addons[addonListIndex][key][addonIndex].quantity = quantity;
      } else {
        let currentQuantity = 0;

        for (let i = 0; i < totalAddons; i++) {
          currentQuantity += groupAddon[i].quantity;
        }

        if (currentQuantity !== max) {
          this.addons[addonListIndex][key][addonIndex].quantity = quantity;
        }
      }
    }
  }

  @action singleAddonQuantityHandler(
    addonListIndex,
    key,
    addonIndex,
    quantity
  ) {
	  console.log(this.addons);
    const totalAddons = this.addons[addonListIndex][key].length;
    // Iterate through all addons in the group to count quantity if hit max
    for (let i = 0; i < totalAddons; i++) {
      if (i === addonIndex) {
        this.addons[addonListIndex][key][i].setQuantity(quantity);
      } else this.addons[addonListIndex][key][i].setQuantity(0);
    }
  }

  @action updateSpecialRequest(addonListIndex, value) {
    this.notes[addonListIndex] = value;
  }

  @action removeAddon(addonListIndex) {
    this.addons.splice(addonListIndex, 1);
    this.notes.splice(addonListIndex, 1);
  }

  @action checkRequiredQuantity(layout='') {
    let qty = 0;
    _.each(this.addons, (addonList, addonListIndex) => {
	if((layout===4 && addonListIndex===0) || layout==='')
	{
      let keys = Object.keys(addonList);
      _.each(keys, (key, index) => {

        let tQty = 0;
        _.each(addonList[key], (addon, addonIndex) => {
          tQty += addon.quantity;

        });

        if (addonList[key][0].min > 0 && tQty < addonList[key][0].min) {
          qty += (addonList[key][0].min - tQty);
        }
      });
	}
    });
    return qty;
  }
  @action checkTotalAddonsQuantity(ind) {

    let tQty = 0;
    _.each(this.addons, (addonList, addonListIndex) => {
      if ((this.item.isCustomize && addonListIndex === ind) || (!this.item.isCustomize)) {

        let keys = Object.keys(addonList);
        _.each(keys, (key, index) => {
          _.each(addonList[key], (addon, addonIndex) => {
            tQty += addon.quantity;

          });
        });
      }

    });
    return tQty;
  }
}

class MenuStore {
  tag = '';
  tokenAccess = "foodzaps";
  currencyCode = 'MYR';
  currencyDecimal = 2;
  currencySymbol = "$";
  itemModal;
  tagExpiry;
  verificationCode;
  isSearchDirty = false;
  isPreOrder = false;
  isHideLogout = false;
  isAnimation = 1;
  hideSearchHandler;
  rawTranslation = undefined;
  operationDay = "1,2,3,4,5";
  collectionAdvanceDay = 7;
  collectionIntervalTime = 5;
  isLoginWithoutEnabled = 1;  
  horizontalMenuClickedKey = '';
  backendApiDomainUrl = '';
  loadingClass = "";
  //enableHorizontalMenu = '0';
  pineAppleOwnerkey = '';
  takeaway = 0;
  showHome = "1";
  menu_type = "0";
  login_option = "1";
  isRemarksVisible = 0;
  activePromo = "";
  googleAddItem = 'Add Item';
  googleRemoveItem = 'Remove Item';
  googleTrashItem = 'Trash Item';
  defaultPromoSelected = "";
  areaLimit = 0;
  searchType = '';
  isSetMealEdit = false;
  isCustomPowerBy= '';
  @observable store = {
    name: "Loading",
    logo: "",
    allLang: undefined,//null,
    menuVer: "",
    isDev: false,
    hasWaiter: undefined,
    hasCashier: undefined,
    addressUrl: undefined,
    contactUrl: undefined,	
    address: undefined,
  };

  @observable test;
  @observable tag;
  @observable controller;
  @observable dishes;
  @observable layout;
  @observable horizontalMenuSelectedIndex = ['0'];
  @observable horizontalMenuSelectedKey = '';
  @observable.shallow menuList = {};
  @observable.shallow filterMenuList = {};
  @observable searchResult = {};
  @observable rewardsResult = {};
  @observable.shallow notAvailable = [];
  @observable.shallow categoryList = [];
  @observable.shallow promoList = [];
  @observable.shallow operatingHours = [];
  @observable isLoading = false;
  @observable translation = translation; //translation['en'];
  @observable currentLanguage = '0'; //'en';
  @observable auto_modifier = '0';
  @observable transfer_type = 0;
  @observable bill_type = 0;
  @observable check_type = 0;
  @observable.shallow tagsList = [];
  // By default, everyone needs to login
  @observable isLoginFormExist = false;
  @observable isLoginModalVisible = false;
  @observable isHomeModalVisible = false;
  @observable isValidPostQa = "";
  @observable showCheckLastOrder = false;
  @observable showlastOrderPupup = "";
  @observable isOldThirtyMinsExist = false;
  @observable openSetModal = false;
  @observable PromoMenuKey = "0";

  @observable.shallow horizontalCategoryList = [];
  // If url contains login=false, we set bypass flag to true
  @observable socialBypass = false;
  @observable socialOpt = false;
  @observable socialCheckout = false;
  @observable.shallow socialProviders = []; // a dictinary of providers
  @observable.shallow rewardsList = []; // a dictinary of providers
  // Setup our social structure
  @observable social = {};


  @observable preQuestionsList = {};
  @observable.shallow preQuestionFileds = [];
  @observable.shallow flashHeaderMsg = [];
  // @observable flashOffset = "";
  @observable activeAccordian = "";
  @observable actionModalVisisble = "";
  @observable postQuestionsList = {};
  @observable.shallow postQuestionFileds = [];

  @observable.shallow paymentGateways = [];
  @observable showPaymentOption = false;
  @observable showRepeatBtn = true;
  @observable stripeKey = null;
  @observable shareBtns = "";
  @observable spotOrderObj = {};
  @observable isRecommended = false;
  @observable showHeaderCart = false;
  @observable isRewardModalVisible = false;
  @observable isEberLoginExist = false;
  @observable customTexts ={};
  @observable websocket = null;
  @observable isWebsocketEnabled = true;
  @observable openRewardListModal = false;
  @observable bwcGateway ={};
  @observable memberDiscount ="";
  @observable mwEndpoint ="";
  @observable mwMerchantId ="";
  @observable mwStoreId ="";
  /*@observable.shallow todayPromotions = [];
  @observable.shallow otherDayPromotions = [];*/
  constructor() {
    this.itemModal = new ItemModal({
      isVisible: false,
      isClickedStepper: false,
      item: {},
      addons: []
    });

    this.controller = window.location.pathname.split("/")[1];
  }
  /* @action setTodayPromo()
   {
    if(this.todayPromotions.length===0) 
    {
                let allDays=['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
              let weekday = allDays[new Date().getDay()];
           let datStr='day'+weekday;
           //console.log(item.days);
           const timestamp = Date.now();
         	
       _.each(this.promotions, promo => {
           let data=this.promos[promo];			
                let item=data[0]; 
            //console.log(new Date(item.endDate),'tt');
               if(item.days!==undefined && item.days[datStr]===1 && (item.endDate===0 || (timestamp>=item.startDate && timestamp<=item.endDate)))
           {
             this.todayPromotions.push(promo);
           }
           else{
             this.otherDayPromotions.push(promo);
           }
       });
    }
    //return todayPromotions
   }*/
   @action resetCocoMenu(item) {
       let data=this.horizontalCategoryList;
	   let ind=data.indexOf(item);
	   if(data.length>0 && ind>-1)
	   {
		  this.horizontalMenuSelectedKey=item; 
		  this.horizontalMenuSelectedIndex=[ind.toString()]; 
	   }

  } 
  @action showHideLastOrderCheck() {
    this.showCheckLastOrder = false;
    //console.log(this.showCheckLastOrder);
  }
  @action checkIsPickUpOrDelivery()
  {
     let tVal=this.getTagValue();
	 if(tVal==='online' && this.layout===4)
	 {
		return true; 
	 }
	 return false;
  }
  @action getDeliveryType() {
	 let resp={input_method:'NA',input_day:'NA'};
	 const preQuestionsInfo = localStorage.getItem(`${this.controller}_preQuestions`);
     let fullAddress=[];
	if (preQuestionsInfo && this.preQuestionFileds.length>0) {

		let preQuestionsInfoArr = JSON.parse(preQuestionsInfo);
		if(preQuestionsInfoArr['input_method'] && preQuestionsInfoArr['input_method']!=='')
		{
			resp.input_method=preQuestionsInfoArr['input_method'];
		}
        if(preQuestionsInfoArr['input_name'] && preQuestionsInfoArr['input_name']!=='')
		{
			resp.input_name=preQuestionsInfoArr['input_name'];
			fullAddress.push(resp.input_name);
		}  
        if(preQuestionsInfoArr['input_contact'] && preQuestionsInfoArr['input_contact']!=='')
		{
			resp.input_contact=preQuestionsInfoArr['input_contact'];
			fullAddress.push(resp.input_contact);
		}	
        if(preQuestionsInfoArr['input_address'] && preQuestionsInfoArr['input_address']!=='')
		{
			resp.input_address=preQuestionsInfoArr['input_address'];
			if(resp.input_method==='Delivery')
			{
			 fullAddress.push(resp.input_address);	
			}		
		}
        if(preQuestionsInfoArr['input_address_loc'] && preQuestionsInfoArr['input_address_loc']!=='')
		{
			resp.input_address_loc=preQuestionsInfoArr['input_address_loc'];
			if(resp.input_method!=='Delivery')
			{
			 fullAddress.push(resp.input_address_loc);	
			}					
		}		
		if(preQuestionsInfoArr['input_day'] && preQuestionsInfoArr['input_day']!=='')
		{
			resp.input_day=preQuestionsInfoArr['input_day'];
			if(resp.input_method==='Delivery')
			{
			fullAddress.push(resp.input_day);
			}
		}
		
		if(preQuestionsInfoArr['input_time'] && preQuestionsInfoArr['input_time']!=='')
		{
			resp.input_time=preQuestionsInfoArr['input_time'];
			if(resp.input_method==='Delivery')
			{
			fullAddress.push(resp.input_time);
			}
		}
		
		
	}
	resp.fullAddress=fullAddress.join(', ');
	return resp;
  }
  @action resetMeuList() {
    this.horizontalCategoryList = [];
    let activePromo = this.activePromo;

    let keyChar = this.promotions[activePromo];
    let data = this.promos[keyChar];
    // console.log(data,activePromo,keyChar);
    this.filterMenuList = _.groupBy(data, item => {
      if (this.horizontalCategoryList.indexOf(item.category) < 0) {
        this.horizontalCategoryList.push(item.category);
      }
      return item.category;
    });
    //this.horizontalCategoryList=this.filterMenuList;
    // console.log(this.filterMenuList,'filteree'); 
    if (this.horizontalCategoryList.length > 0) {
      this.horizontalMenuSelectedKey = this.horizontalCategoryList[0];
    }
    // console.log(this.horizontalMenuSelectedKey,'999');
  }

  @action isAvailable(item) {
    if (item.promoName === '' || !item.promoName) {
      return true;
    }
	if(!item.isInStock && this.layout===4)
	{
		return false;
	}
    //let isProAvail = this.checkTagAction('promo', item.promoName.toLowerCase());
    let tagObject = this.checkTagAction();
  	//console.log(tagObject);
    let isProAvail = true;
    if (tagObject.promo && tagObject.promo !== "") {
      let p = item.promoName.toLowerCase();
      let promoSplit = tagObject.promo.split(',');
      if (promoSplit.length > 0) {
        isProAvail = false;
        _.forEach(promoSplit, function (prom) {
          let promAr = prom.split('#');
          //console.log(promAr[0].toLowerCase(),'===',p,promAr[0].trim().toLowerCase()===p.trim());
          let pm = promAr[0].toLowerCase().trim().split('|');
          p = p.trim();
          //			console.log(pm,p);
          if (pm.indexOf(p) > -1) {
            isProAvail = true;
            return;
          }

        });
      }
    }
    
    if (!isProAvail) {
      //console.log(item.promoName,'yyy');
      return false;
    }

    let dayname = moment().day();
    if(this.isPreOrder)
    {
      this.getMenuDateTime();
      let OldDate = localStorage.getItem('menudate');
      dayname = moment(OldDate).day();
    }

    let weekday1 = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'][dayname];
    let datStr1 = 'day' + weekday1;
    /*if(tagPromoAction)
    {
    }*/
    /*let ntA=this.notAvailable;
    var index = ntA.indexOf(item.id)
    if (index !== -1) {
      this.notAvailable.splice(index, 1);
    }*/
    // let curTime = (new Date()).getTime();
    let curDate = new Date();
    var curTime = parseInt((curDate.getHours() * 3600) + (curDate.getMinutes() * 60));
    //console.log(curTime,item.startTime,item.endTime,'iiii');
    const timestamp = Date.now();
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'][new Date().getDay()];
    let datStr = 'day' + weekday;
    //console.log(item.days);
    if ((timestamp > item.endDate && item.endDate !== 0) || (timestamp < item.startDate && item.startDate !== 0)) {
      //this.notAvailable.push(item.id);
      return false;
      //console.log(item.id,'date');
    }
    else if ((this.isPreOrder && this.isPreOrder && item.days !== undefined && item.days[datStr1] !== 1) || (!this.isPreOrder && item.days !== undefined && item.days[datStr] !== 1)) {
      //this.notAvailable.push(item.id);
      return false;
      //console.log(item.id,'day');
    }
    //else if((curTime<item.startTime && item.startTime!==0) || (curTime>item.endTime && item.endTime!==0))
    else if (item.startTime !== item.endTime) {
      if (item.startTime > item.endTime) {
        if (curTime >= item.startTime || curTime <= item.endTime) {
          return true;
        }
        else {
          //this.notAvailable.push(item.id);
          return false;
        }
      }
      else {
        if (curTime >= item.startTime && curTime <= item.endTime) {
          return true;
        }
        else {
          //this.notAvailable.push(item.id);
          return false;
        }
      }
    }
    return true;
  }
  @action getLastOrder() {
    const ORDER_SAVE_KEY = `${this.controller}_order`;
    const orders = localStorage.getItem(ORDER_SAVE_KEY);
    // console.log(orders);
    if (orders) {

      const jsonOrders = JSON.parse(orders);
      if (jsonOrders.length > 0) {
        let lastOrd = jsonOrders[jsonOrders.length - 1];
        return lastOrd;
      }
    }

    return null;
  }
  @action getLastOrderDiff() {
    // return this. getDiffeOrderTime(lastOrd);
    let lastOrd = this.getLastOrder();
    if (lastOrd) {
      // let lastOrd=jsonOrders[jsonOrders.length-1];
      return this.getDiffeOrderTime(lastOrd);
    }

    return false;
  }
  @action getDiffeOrderTime(lastO) {
    let createdTime = new Date(lastO.timestamp).valueOf();
    //console.log(createdTime);
    let currentTime = new Date().valueOf();
    let diff = (currentTime - createdTime) / (1000 * 60);
    if (diff < 30 && (this.check_type === 1 || this.transfer_type === 1)) {
      return true;
    }
    return false;

  }
  @action uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }
  @action setupMenu(tag, code, test, cacheStatus, payment, defaultPromo, tmatch, eberToken) {

    // If tag and code in URL, set both
    if (tag || code || test || cacheStatus || payment || defaultPromo || tmatch || eberToken) {

      if (tag) {
        let oldtag = this.getTagValue();
        localStorage.setItem(`${this.controller}_old_tag`, oldtag);
        this.tag = tag;
        localStorage.setItem(this.controller, JSON.stringify({ tag: tag, timestamp: new Date() }));
        if (oldtag && oldtag !== "" && oldtag !== null && tag.toLowerCase() !== oldtag) {
          localStorage.removeItem(`${this.controller}_noRedirect`);
          localStorage.removeItem(`${this.controller}_cart`);
        }
        /*const postQuestionsInfo = localStorage.getItem(`${this.controller}_postQuestions`);
        if (postQuestionsInfo) {
          let postQuestionsInfoArr = JSON.parse(postQuestionsInfo);
          if(postQuestionsInfoArr.system_tag && postQuestionsInfoArr.system_tag!==tag)
          {
            postQuestionsInfoArr.system_tag=tag;
            postQuestionsInfoArr=JSON.stringify(postQuestionsInfoArr);

            localStorage.removeItem(`${this.controller}_postQuestions`);
            localStorage.setItem(`${this.controller}_postQuestions`, postQuestionsInfoArr);               
          }
        }*/
      }
      /*else
      {
        localStorage.removeItem(this.controller);
      }*/

      if (code) {
        this.code = code;
        localStorage.setItem(
          `${this.controller}_code`,
          JSON.stringify({
            code: code,
            timestamp: new Date()
          })
        );
      }
      /* else
       {
         localStorage.removeItem(`${this.controller}_code`); 
       }*/

      if (test) {
        this.test = test;
        localStorage.setItem(`${this.controller}_test`, test);

      }

      if (cacheStatus) {
        localStorage.setItem(`${this.controller}_cacheStatus`, cacheStatus);
      }
      if (defaultPromo) {
        localStorage.setItem(`${this.controller}_defaultPromo`, defaultPromo);
      }
      let pathname = window.location.pathname;
      if (payment) {

        // let paymentDetails=JSON.parse(payment);
        let cc = this.controller;
        localStorage.setItem(`${cc}_payment`, payment);
        //console.log(pathname);			 
      }
      if (tmatch) {
        localStorage.setItem(`tmatch`, tmatch);
      }

      if (tmatch && tmatch !== "") {
        let t = this.getTagValue();
        if (tmatch !== t) {
          return Promise.resolve();
        }

      }
	  if(eberToken)
	  {
		let cc = this.controller;
        localStorage.setItem(`${cc}_eber_token`, eberToken);
	  }
      window.location = window.location.origin + pathname;
      return Promise.resolve();
    }

    // Prepare operating hour
    //console.log(localStorage.getItem(`${this.controller}_partnerTX`));
    this.isLoading = true;
    let isCached = (localStorage.getItem(`${this.controller}_cacheStatus`) === 'false' ? true : false);
    return API.getMenu(isCached)
      .then(rawData => {
        this.isLoading = false;
        this.loadingClass = "loadedClass";
        this.dishes = rawData.dishes;
        let testData = localStorage.getItem(`${this.controller}_test`);
        //console.log(testData);
        if (testData) {
          const tests = JSON.parse(testData);

          _.each(tests, (e, i, l) => {
            //console.log(i,e,'testttttttt');
            if (typeof e === "string") {
              rawData[i] = e;
            }
            else {
              rawData[i] = JSON.stringify(e);
            }
          });
          localStorage.removeItem(`${this.controller}_test`);
        }
        let dPromo = localStorage.getItem(`${this.controller}_defaultPromo`);
        if (dPromo) {
          this.defaultPromoSelected = dPromo;
          localStorage.removeItem(`${this.controller}_defaultPromo`);
        }
        
		let realTag = this.getRealTagValue();
        if (rawData.redirect_url &&  rawData.redirect_url!== "" && realTag!=="") {
         let noredirect= localStorage.getItem(`${this.controller}_noRedirect`);
         if(noredirect===true || noredirect==='true')
         {
            //no redirection 
         }
         else
         {          
           let rUrl=rawData.redirect_url;
           let rUrlArray=rUrl.split('#TAG#');
           rUrl=rUrlArray.join(realTag);
           window.location.href=rUrl;
         }
        }
        this.layout = rawData.layout === "grid" ? 1 : 0;
        if (rawData.layout === "gridcircle") {
          this.layout = 2;
        }
        else if (rawData.layout === "lightlist") {
          this.layout = 3;
        }
        else if (rawData.layout === "cocolist") {
          this.layout = 4;
        }
        //this.layout = 4;
        if (rawData.auto_modifier) {
          this.auto_modifier = rawData.auto_modifier;
        }
		if (rawData.custom_powerby) {
          this.isCustomPowerBy = rawData.custom_powerby;
        }
        if (rawData.check_type) {
          this.check_type = rawData.check_type;
        }
        if (rawData.transfer_type) {
          this.transfer_type = rawData.transfer_type;
        }
        if (rawData.bill_type) {
          this.bill_type = rawData.bill_type;
        }
		if (rawData.pre_order && rawData.pre_order===1) {
          this.isPreOrder = true;
        }
        if (rawData.hide_logout && rawData.hide_logout.toString()==="1") {
          this.isHideLogout = true;
        }
        if (rawData.show_repeat_button==="false" || rawData.show_repeat_button===false) {
          this.showRepeatBtn = false;
        }
        if (rawData.mwEndpoint && rawData.mwEndpoint!=="") {
          this.mwEndpoint = rawData.mwEndpoint;
		   localStorage.setItem(`${this.controller}_mwEndpoint`, this.mwEndpoint);
        }
		if (rawData.mwMerchantId && rawData.mwMerchantId!=="") {
          this.mwMerchantId = rawData.mwMerchantId;
		   localStorage.setItem(`${this.controller}_mwMerchantId`, this.mwMerchantId);
        }
		if (rawData.mwStoreId && rawData.mwStoreId!=="") {
          this.mwStoreId = rawData.mwStoreId;
        }
        //this.auto_modifier = 1;
        this.store = {
          name: rawData.restaurantName ? rawData.restaurantName : "",
          logo: rawData.restaurantLogo,
          // allLang: {'en':'English','cn':'Chinese'},
          allLang: rawData.language,
          menuVer: rawData.menuVer,
          controller: rawData.controller,
          addressUrl: rawData.addressUrl,
          contactUrl: rawData.contactUrl,
		  address:(rawData.address?rawData.address:''),
          /* latitude: rawData.latitude,
               longitude: rawData.longitude,
               areaLimit: rawData.areaLimit?parseFloat(rawData.areaLimit):0*/
        };
        
        if (rawData.custom_texts  !== undefined && rawData.custom_texts  !=="") {
         try{
          if(typeof rawData.custom_texts === 'object')
          {
            this.customTexts =rawData.custom_texts;
          }
          else{
            this.customTexts = JSON.parse(rawData.custom_texts);
          }          
         }
         catch (err) {
          console.log("Error parsing custom_texts",rawData.custom_texts);
         } 
        }
        if (rawData.share !== undefined) {
          this.shareBtns = rawData.share;
        }
        if (rawData.currency_decimal !== undefined) {
          //console.log(44);
          this.currencyDecimal = rawData.currency_decimal;
        }
        if (rawData.search_type !== undefined) {
          //console.log(44);
          this.searchType = rawData.search_type;
        }
        //console.log(this.searchType);
        if (this.currencyDecimal === "") {
          this.currencyDecimal = 2;
        }

        if (rawData.currency_symbol !== undefined) {
          //console.log(55);
          this.currencySymbol = rawData.currency_symbol;
        }
        if (this.currencySymbol === "") {
          this.currencySymbol = '$';
        }
        //console.log(rawData.server,'rawData.server');		
        if (rawData.server !== undefined) {
          this.backendApiDomainUrl = rawData.server;
          //set based on controller id
          localStorage.setItem(`${rawData.controller}_apiserver`, this.backendApiDomainUrl);
          //console.log(localStorage.getItem(`${rawData.controller}_apiserver`));
        }

        if (rawData.takeaway !== undefined) {
          this.takeaway = rawData.takeaway;
        }
        if (rawData.remarks !== undefined) {
          this.isRemarksVisible = rawData.remarks;
        }
		if (rawData.recommended === true || rawData.recommended === 'true' || rawData.recommended === 1 || rawData.recommended === '1') {
          this.isRecommended = true;
        }
		// if (rawData.display_recommeded === true || rawData.display_recommeded === 'true') {
          // this.displayRecomendation = true;
        // }

        if (rawData.menu_type !== undefined) {
          this.menu_type = rawData.menu_type;
        }
		if(this.layout === 4)
		{
			this.menu_type ='4';			
		}
     
    if(this.controller==='MarutamaMW' || this.store.controller==='a53aec44-e9ad-4771-b9a8-41a1e0993e54')
    {
      this.showHeaderCart=true;
    }
        if (rawData.login_option !== undefined) {
          this.login_option = rawData.login_option;
        }
        if (rawData.showHome !== undefined) {
          this.showHome = rawData.showHome;
        }
 
        if ("tags_action" in rawData) {
          if (rawData.tags_action !== "") {
            const tagsActionParse = JSON.parse(rawData.tags_action);
            _.each(tagsActionParse, (e, i, l) => {
              if (e.tag) {
                e.tag = e.tag.toLowerCase();
              }
              if (e.promo) {
                e.promo = e.promo.toLowerCase();
              }
              this.tagsList.push(e);
            });            
          }
        }
        
        if ("status" in rawData) {
          if (rawData.status === 0) {
            this.store.isDev = true;
          } else {
            this.store.isDev = false;
          }
        } else {
          this.store.isDev = false;
        }
        if ("animation" in rawData) {
          this.isAnimation = rawData.animation;
        }
        // Setup remoteService like calling waiter and bill
        if ("remoteService" in rawData) {
          try {
            if (rawData.remoteService !== "") {
              const remoteService = JSON.parse(rawData.remoteService);

              if ("waiter" in remoteService)
                this.store.hasWaiter = remoteService.waiter;

              if ("cashier" in remoteService)
                this.store.hasCashier = remoteService.cashier;
            }
          } catch (err) {
            console.log("Error parsing remote service");
          }
        }

        // Set the controller id which is different from controller name
        // Don't ask me why, it's just the way it is
        localStorage.setItem(`${this.controller}_id`, rawData.controller);

        if ("tag_expired_ms" in rawData) {
          this.tagExpiry = rawData.tag_expired_ms;
        } else {
          this.tagExpiry = 900000;
        }



        // Check for translation
        let language = '0'; //'en';
        if ("translation" in rawData) {
          try {
            this.rawTranslation = JSON.parse(rawData.translation);
            const cachedLanguage = localStorage.getItem(`${this.controller}_lang`);
            if (cachedLanguage) language = cachedLanguage;
            if (cachedLanguage === 'en' || cachedLanguage === 'cn') {
              language = (cachedLanguage === 'en' ? '0' : '1');
              localStorage.setItem(`${this.controller}_lang`, language);
            }
            this.setAppLanguage(language);
          } catch (err) {
            console.error(
              "Error setting translation - no translation value received"
            );
          }
        }
        const cachedLanguage = localStorage.getItem(
          `${this.controller}_lang`
        );
        if (cachedLanguage) language = cachedLanguage;
        if (language === "1") {
          let lngsArray = this.store.allLang.split('|');
          if (lngsArray.length === 1) {
            language = 0;
           }
        }
        localStorage.setItem(`${this.controller}_lang`, language);
        if (rawData.widget && rawData.widget !== "") {

          const scriptList = JSON.parse(rawData.widget);

          for (let i = 0; i < scriptList.length; i++) {
            try {
              const script = document.createElement("script");
              script.src = scriptList[i];
              document.body.appendChild(script);

              document.getElementsByTagName("html")[0].appendChild(script);
              /*script.onload = () => {
                     this.setAppLanguage(language);
              };*/
            } catch (err) {
              console.error("Error injecting script");
              console.error(err);
            }
          }
        }
 

        if ("master_theme" in rawData) {
          const link = document.createElement("link");
          link.href = `${window.location.protocol}//${window.location.host
            }/theme/${rawData.master_theme}.css`;
          link.type = "text/css";
          link.rel = "stylesheet";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        if(this.layout===4)
        {
        const link = document.createElement("link");
        link.href = `${window.location.protocol}//${window.location.host
          }/theme/misscoconut.css`;
        link.type = "text/css";
        link.rel = "stylesheet";
        document.getElementsByTagName("head")[0].appendChild(link);
        }
        if ("operation" in rawData) {
          const rawOperatingHours = rawData.operation.split(",");
          _.each(rawOperatingHours, hours => {
            const splitted = hours.split("-");
            let start = parseInt(splitted[0]);
            let end = parseInt(splitted[1]);
            if (start > end) {
              end = end + 2400;
            }
            this.operatingHours.push({
              start: start, // pad zeros
              end: end
            });
          });
        }

        // Check if operating hours is empty = 24hrs
        if ("pickup" in rawData) {
          rawData.pickup = JSON.parse(rawData.pickup);
          if (!("time" in rawData.pickup) || rawData.pickup.time.length === 0) {
            this.operatingHours.push({
              start: "00:00",
              end: "23:59"
            });
          } else {
            const rawOperatingHours = rawData.pickup.time.split(",");
            _.each(rawOperatingHours, hours => {
              const splitted = hours.split("-");
              this.operatingHours.push({
                start: splitted[0], // pad zeros
                end: splitted[1]
              });
            });
          }

          if ("days" in rawData.pickup) {
            this.operationDay = rawData.pickup.days;
          }

          if ("interval_time" in rawData.pickup) {
            this.collectionIntervalTime = rawData.pickup.interval_time;
          }

          if ("advance_day" in rawData.pickup) {
            this.collectionAdvanceDay = rawData.pickup.advance_day;
          }
        }

        // Check if there's operating hours set using pickup / operation else we just open it 24h
        if (this.operatingHours.length === 0) {
          this.operatingHours.push({
            start: "00:00",
            end: "23:59"
          });
        }
		
		
        let tagObj = this.checkTagAction();
        let display = true;
        if (tagObj.display === "0" || parseInt(tagObj.display) === 0) {
          display = false;
        }
        let isAvailablePromos=this.getPromosData(tagObj);
        let menuObj = this;
        let parsedItems = _.map(rawData.dishes, function (item) {
         
          if (item.devOnline === 0) {
            //console.log(item);
            return;
          }

          const addons = [];
          // Extract any set items
          if ("set" in item.prices[0]) {
            const addon_list = item.prices[0].set;
            // We group the addons by groupName resulting in
            // { group1: [AddonItem], group2: [AddonItem] }
            addons.push(
              _.groupBy(
                addon_list.map(addon => {
                  let payload = {
                    id: addon.id,
                    name: _selectLang(addon.name, language),
                    description: "des" in addon ? addon.des : null,
                    isInStock: addon.disable ? false : true,
                    price: parseFloat(addon.price).toFixed(2),
                    priceName: addon.price_value,
                    opt1: addon.opt1?addon.opt1:'',
                    //quantity: addon.usage_val === 0.5 ? 0 : addon.usage_type
                  };

                  if (addon.usage_val < 1) {
                    payload.quantity = 0;
                    let detail = JSON.parse(addon.usage_other).cat_set_meal;
                    //const detail = JSON.parse(addon.usage_other).cat_set_meal;
                    if (detail.length > 0) {
                      detail = detail[0];
                      if (detail.min > detail.max) {
                        detail.min = 0;
                        detail.max = 0;
                      }
                      payload.min = detail.min;
                      payload.max = detail.max;

                      payload.groupName = detail.name;
                      payload.groupOrder =
                        "order" in detail ? detail.order : null;
                    }
                  }
                  else {
                    payload.quantity = addon.usage_val;
                  }

                  return new AddonItem(payload);
                }),
                a => a.groupName
              )
            );
          }

          let price;
          if ("price" in item.prices[0]) {
            price = parseFloat(item.prices[0].price).toFixed(2);
          } else {
            price = parseFloat(
              item.prices[0].value.replace(/[^0-9.]+/, "")
            ).toFixed(2);
          }

          let priceName;

          if ("price_value" in item.prices[0]) {
            priceName = item.prices[0].price_value;
          } else {
            priceName = item.prices[0].value;
          }

          let days = {};
          days.dayMon = item.dayMon !== undefined ? item.dayMon : 1;
          days.dayTue = item.dayTue !== undefined ? item.dayTue : 1;
          days.dayWed = item.dayWed !== undefined ? item.dayWed : 1;
          days.dayThur = item.dayThur !== undefined ? item.dayThur : 1;
          days.dayFri = item.dayFri !== undefined ? item.dayFri : 1;
          days.daySat = item.daySat !== undefined ? item.daySat : 1;
          days.daySun = item.daySun !== undefined ? item.daySun : 1;

          _.each(addons[0], (addonList, addonListIndex) => {

            let keys = Object.keys(addonList);
            _.each(keys, (key, index) => {

              //console.log(addonList[key].min);
              let min = parseInt(addonList[key].min);
              let max = parseInt(addonList[key].max);
              let quantity = parseInt(addonList[key].quantity);

              if (quantity === 0 && addonList.length === min && min === max) {

                _.each(addonList, (addon, addonIndex) => {
                  addons[0][addonListIndex][addonIndex].quantity = 1;
                  
                });
              }
            });
          });
		  let des1= item.des1 ? item.des1 : '';
          let itemObj = new Item({
            id: item.prices[0].id,
            category: _selectLang(item.catName.trim(), language),
            name: _selectLang(item.name, language),
            description: item.des ? _selectLang(item.des, language) : _selectLang(des1, language),
            thumbnail: item.image,
            price: price,
            priceName: priceName,
            addons: addons[0],
            isInStock: item.disable ? false : true,
            opt1: item.opt1 ? item.opt1 : '',
            des1: des1,
            promoName: item.promoName ? _selectLang(item.promoName.trim(), language) : "",
            discount: item.discount ? item.discount : 0,
            startDate: item.startDate ? item.startDate : 0,
            endDate: item.endDate ? item.endDate : 0,
            startTime: item.startTime ? item.startTime : 0,
            endTime: item.endTime ? item.endTime : 0,
            days: days,
            chef1: item.chef1 ? item.chef1 : 0,
			sku:item.SKU?item.SKU:''
          });
         
          if ((tagObj.display === "2" || tagObj.display === 2) && isAvailablePromos.indexOf(itemObj.promoName.toLowerCase()) < 0 )
          {
          return;
          }
          if (!display) {
            let isAvaial = menuObj.isAvailable(itemObj);
             
            if (!isAvaial) {
              return;
            }
          }
          return itemObj;
        });

        parsedItems = _.pick(parsedItems, val => {
          return val;
        });
		
		let avialArra=[];
		let notAvailArray=[];
		_.map(parsedItems, item => {
			 let avail=this.isAvailable(item);
		    if(avail)
		   {
			   avialArra.push(item);
		   }
		   else{
			   notAvailArray.push(item);
		   }
		});
		parsedItems=avialArra.concat(notAvailArray);	
        // console.log(parsedItems);
        this.promos = _.groupBy(parsedItems, item => {
          return item.promoName.toLowerCase();
        });

        this.promoList = _.map(Object.keys(this.promos), item => {
          return item.trim();
        });
        // console.log(this.promos,this.promoList);
        this.menuList = _.groupBy(parsedItems, item => {
          return item.category;
        });

        this.filterMenuList = this.menuList;
        if (this.promoList.length > 0) {
          this.activePromo = 0;
          if (this.defaultPromoSelected !== '') {
            let datas = this.promotions;
            let ind = datas.indexOf(this.defaultPromoSelected.toLowerCase());
            //console.log(ind,datas);
            if (ind > -1) {
              this.activePromo = ind;
            }
            else {
              this.defaultPromoSelected = '';
            }
          }
          this.resetMeuList();
        }

        this.searchResult = this.filterMenuList;

        this.categoryList = _.map(Object.keys(this.menuList), item => {
          return item.trim();
        });
		//this.prepareMenu();
		

        //console.log(this.categoryList,'fffffff');
        this.isLoading = false;

        if ("verify_code" in rawData) {
          if (rawData.verify_code === "") this.verificationCode = null;
          else this.verificationCode = rawData.verify_code.split(",");
        } else {
          this.verificationCode = null;
        }
        let menable = localStorage.getItem(`${this.controller}_enable_membership_account`);
        if (menable === null) {
          localStorage.setItem(`${this.controller}_enable_membership_account`, true);
        }
        let socialInfo = false;
        let socilD = localStorage.getItem(`${this.controller}_social`);
        if (socilD !== null) {
          socialInfo = true;
          this.isLoginModalVisible = false;
          this.social = JSON.parse(socilD);
        }
       // this.socialProviders.push({"eber": "975d6e9a-b5ff-4d9e-9b17-f7da16fd194a"});
        const paymentRes = parseInt(localStorage.getItem('hideMenu'));
        // Parse rawData for social login
        if ("login" in rawData) {
          const socialParsed = JSON.parse(rawData.login);
          if ("providers" in socialParsed) {
            _.each(socialParsed["providers"], (e, i, l) => {
              //console.log(e);
              this.socialProviders.push(e);
			 // const keys = Object.keys(e);
			  if(e.eber && !this.isBWCMethod())
			  {
				  this.isEberLoginExist=true;
			  }
            });
          }
          localStorage.removeItem("socialCheckout");
          const socialBypass = localStorage.getItem(`${this.controller}_socialBypass`);
          if ("opt" in socialParsed && (socialBypass || socialInfo)) {
            this.isLoginModalVisible = false;
            this.socialCheckout = false;
            this.socialBypass = true;
            localStorage.setItem("socialCheckout", false);
          }
          else {

            if ("checkout" in socialParsed) {

              if (socialParsed.checkout === "2") {
                this.isLoginWithoutEnabled = 2;
              }
              if (socialParsed.checkout !== "0") {
                this.socialBypass = false;
                localStorage.setItem("socialCheckout", true);
                this.socialCheckout = true;
                // console.log(paymentRes,'555');
                if (paymentRes !== 2) {
                  this.isLoginFormExist = true;
                }
              }
              if (socialParsed.checkout === "0") {
                this.isLoginFormExist = false;
                this.socialBypass = true;
                localStorage.setItem("socialCheckout", false);
                this.socialCheckout = false;
              }


            }
          }

        }

        let tag = this.getTagValue();
       // let abc='';
        if ("pre_action" in rawData) {
          if (rawData.pre_action !== "") {

            const preActionParsed = JSON.parse(rawData.pre_action);
            _.each(preActionParsed, (e, i, l) => {
              let tags = [];
              if (e.checktag) {
                tags = e.checktag.toLowerCase().split(',');
              }
              if (((tag && tags.length > 0 && tags.includes(tag)) || tags.length === 0)) {
                if (e.type === "flash") {
                  if (this.flashHeaderMsg.length === 0) {
                    this.flashHeaderMsg.push(e.data);
                  }
                }
                else {
                  this.preQuestionFileds.push(e);
                }
              }
            });

          }
        }
		
        if ("post_action" in rawData) {
          if (rawData.post_action !== "") {
            //rawData.post_action=''
            const postActionParsed = JSON.parse(rawData.post_action);
            let isScript_loaded = false;
            _.each(postActionParsed, (e, i, l) => {

              if (!isScript_loaded && (e.type === "address" || e.type === "location")) {
                const script = document.createElement("script");
                script.src = configParams.googleMapUrl + e.key + "&libraries=places";
                document.body.appendChild(script);
                document.getElementsByTagName("html")[0].appendChild(script);
                isScript_loaded = true;
              }
              this.postQuestionFileds.push(e);

            });


          }
        }

        if (this.postQuestionFileds.length > 0 || this.preQuestionFileds.length > 0) {
          this.resetPostQA();
          localStorage.removeItem("is_opened_pre_question");
          localStorage.removeItem("is_opened_post_question");
        }
		const spotM = this.isSpotMethod();
		const bwcM = this.isBWCMethod();
		if (spotM) {
		  const script = document.createElement("script");
		  script.src = configParams.spotMicroJs;
		  document.body.appendChild(script);
		  document.getElementsByTagName("html")[0].appendChild(script);              
		}
        if ("payment" in rawData && rawData.payment.length > 0) {
          let isJsLoaded = false;
          const payments = JSON.parse(rawData.payment);
          
          _.each(payments, (e, i, l) => {
            // console.log(e);
            if (!spotM) {
              if (e.gateway === 'stripe') {
                this.stripeKey = e.appid;
              }
              else if (e.gateway === 't05pay' && !isJsLoaded) {

                let url = "https://" + window.location.hostname + '/secure_pay_prod.min.js?v=2';
                if (e.sandbox === "1" || parseInt(e.sandbox) === 1) {
                  url = "https://" + window.location.hostname + '/secure_pay_staging.min.js?v=5';
                }
                else if (e.sandbox === "2" || parseInt(e.sandbox) === 2) {
                  url = "https://" + window.location.hostname + '/secure_pay_staging_live.min';
                }
                //console.log(url);
                const script = document.createElement("script");
                script.src = url;
                document.body.appendChild(script);
                document.getElementsByTagName("html")[0].appendChild(script);
                isJsLoaded = true;
              }
              //console.log(e);	
            }
            if(bwcM && e.gateway === 'bwc')
            {
			  this.bwcGateway=e;
              this.paymentGateways.push(e);
            }
            else if((!spotM && e.gateway !== 'spot_t05pay') || (spotM && (e.gateway === 'spot_t05pay' || e.gateway === 'cash')))
            {
              this.paymentGateways.push(e);
            }
			
          });

        }
        if (paymentRes !== 2 && (cachedLanguage || (!cachedLanguage && (!rawData.language || rawData.language.split('|').length === 1)))) {
          this.enableLastOrderCheck();

        }
		
		//this.bwcGateway={"name":"BWC","icon":"https://upwork.foodzaps.com/theme/img/wallet.png","gateway":"bwc", server:"http://localhost:8081/"};
		//this.paymentGateways.push(this.bwcGateway);
        //console.log(this.paymentGateways,'tt',rawData.payment);
        // this.paymentGateways.push({"name":"IPay88","icon":"https://www.foodzaps.com/public/img/grab.png","gateway":"ipay88","appid":"M12504","sandbox":"1","key":"sy5BsB6kaX"});		  
        //this.paymentGateways.push({"name":"AliPay","icon":"https://www.foodzaps.com/public/img/grab.png","gateway":"alipay","appid":"ebuytes100100100","channelid":"1245fe51a2a64b8588dedac67c7419ef","key":"1F57B40EA13EA503DE748AD2A36A093D"});		  
        // this.paymentGateways.push({ "name": "T05Pay", "icon": "https://www.foodzaps.com/public/img/t05.png", "gateway": "t05pay", "sandbox": "test", "server": "https://gcstagpayverify.foodzaps.com/", "appid": "71630f2a13874fc2a68d5f3dd7a6df4b", "currency": "SGD" });		  
        // this.paymentGateways.push({ "name": "DBA","icon": "https://www.foodzaps.com/public/img/cash.png","gateway":"dba","appid":"168900001522","sandbox":"1" });		  
        // this.paymentGateways.push({ "name": "Cash","icon": "https://www.foodzaps.com/public/img/cash.png","gateway":"cash" });		  
        // console.log(JSON.stringify(this.paymentGateways));
        let uuid = localStorage.getItem('uuid');
        if (uuid === "" || uuid === null) {
          localStorage.setItem('uuid', this.uuidv4());
        }
        return Promise.resolve();
      })
      .catch(err => {
        console.log("Error MenuStore :", err);
        this.isLoading = false;
        return Promise.reject(err);
      });
  }
  @action htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.innerHTML;
  }
  @action sendGoogleAnalytics(eventcat, itemName, cName, val) {
    try {
      window.gtag('event', itemName, { 'event_category': eventcat, 'event_label': cName, 'value': val });
    } catch (err) {
		 console.log('sendGoogleAnalytics',err);
		}
  }
  @action sendGoogleAnalyticsOrder( val,oid) {
    try {
	    if(this.isKopikingControler())
		{
			window.gtag('event','order_success', {  'event_category': 'order',  'event_label': this.controller,  'value': val, 'order_id':oid}); 
		}
         
    } catch (err) { 
	  console.log('sendGoogleAnalyticsOrder',err);
	}
  }

  @action convertTime(time24) {
    let nextday = "";
    if (time24 > 2400) {
      nextday = " next day ";
      time24 = time24 - 2400;
    }


    let ts = time24.toString();
    if (ts.length === 1) {
      ts = "000" + ts;
    } else if (ts.length === 2) {
      ts = "00" + ts;
    } else if (ts.length === 3) {
      ts = "0" + ts;
    }
    //console.log(ts,'hhhhhhhhhh');
    let H = ts.substr(0, 2);
    let hr = (H % 12) || 12;
    hr = (hr < 10) ? ("0" + hr) : hr;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = nextday + hr + ':' + ts.substr(2, 3) + ampm;
    //console.log(ts);
    return ts;
  }
  // Save the social information passed from Login.js and set loginModal hidden
  @action saveSocial(user) {
    this.social = user;
    localStorage.setItem(`${this.controller}_social`, JSON.stringify(user));

    this.isLoginModalVisible = false;
    //this.enablePreQuestionPopup();
    this.enableHomePopup();
  }
  @action getLogo = () => {
    let logo=this.store.logo;
	if(this.isUnisolnControler())
	{
		let tagval=this.getTagValue();
		let tables1=['plq1','plq2','plq3','plq4','plq5'];
		let tables2=['ion1','ion2','ion3','ion4','ion5'];
		console.log(tables1.indexOf(tagval),'6666',tables2.indexOf(tagval));
		if(tables1.indexOf(tagval)>-1)
		{
			logo="https://www.foodzaps.com/public/gym/logo01.jpeg";
		}
		else if(tables2.indexOf(tagval)>-1)
		{
			logo="https://www.foodzaps.com/public/gym/logo02.jpeg";
		}
	}
	return logo;
  };

  @action getTagValue() {

    let tag = this.getRealTagValue();
    tag = tag.toLowerCase();
    return tag;
  }
  @action getRealTagValue() {
    let cachedTag = localStorage.getItem(this.controller);
    let tag = "";
    if (cachedTag) {
      cachedTag = JSON.parse(cachedTag);
      tag = cachedTag.tag;
    }
    return tag;
  }
  @action resetPostQA() {
    /*let cachedTag = localStorage.getItem(this.controller);
    let tag = "";
    let clearInputs = "";
    if (cachedTag) {
      cachedTag = JSON.parse(cachedTag);
      tag = cachedTag.tag.toLowerCase();
    }
    tag = tag.toLowerCase();
    if (this.tagsList.length > 0 && tag !== "") {
      for (let ta = 0; ta < this.tagsList.length; ta++) {
        let taObj = this.tagsList[ta];
        if (taObj.tag) {
          let tagsstr = taObj.tag.toString();
          let tagOption = tagsstr.split(',');
          if (tagOption.indexOf(tag) !== -1) {
            clearInputs = (taObj.clear ? taObj.clear : '');
            break;
          }
        }
      }
    }*/
    let tagObject = this.checkTagAction();
    if (tagObject.clear) {
      let clearInputs = tagObject.clear.split(',');
      if (clearInputs.length > 0) {
        let postQuestionsInfoArr = [];
        let preQuestionsInfoArr = [];
        const postQuestionsInfo = localStorage.getItem(`${this.controller}_postQuestions`);
        if (postQuestionsInfo) {
          postQuestionsInfoArr = JSON.parse(postQuestionsInfo);
        }

        const preQuestionsInfo = localStorage.getItem(`${this.controller}_preQuestions`);
        if (preQuestionsInfo) {
          preQuestionsInfoArr = JSON.parse(preQuestionsInfo);
        }

        _.each(this.clearInputs, qa => {
          delete postQuestionsInfoArr[qa];
          delete preQuestionsInfoArr[qa];

        });

        this.saveQuestion(postQuestionsInfoArr, "post");
        this.saveQuestion(preQuestionsInfoArr, "pre");

      }
      else {
        localStorage.removeItem(`${this.controller}_preQuestions`);
        localStorage.removeItem(`${this.controller}_postQuestions`);
      }

    }
  }
  @action saveQuestion(data, action) {
    let result = data;
    if(action === "post" && data.input_method!=="" && data.input_method!==undefined)
    {
      if(data.input_method.trim().toLowerCase()==="self collection" || data.input_method.trim().toLowerCase()==="pick up" )
      {
        delete data['input_address_postal_delivery'];
        delete data['input_address_postal_val'];
      }
    } 
    data = JSON.stringify(data);
    if (action === "post") {
      localStorage.removeItem(`${this.controller}_postQuestions`);
      localStorage.setItem(`${this.controller}_postQuestions`, data);
      localStorage.setItem("is_opened_post_question", 1);
    }
    else {
      localStorage.removeItem(`${this.controller}_preQuestions`);
      localStorage.setItem(`${this.controller}_preQuestions`, data);
      localStorage.setItem("is_opened_pre_question", 1);
      if (result.input_time) {
        localStorage.setItem('menutime', result.input_time);
      }
      if (result.input_day) {
        if (this.isPreOrder) {
          localStorage.setItem('menudate', result.input_day);          
          this.prepareMenu();
          this.enableHomePopup();
        }
      }      
    }

    this.actionModalVisisble = "";

  }

  @action savePreQuestion(data) {
    localStorage.setItem(`${this.controller}_preQuestions`, data);
    this.actionModalVisisble = "";
    localStorage.setItem("is_opened_pre_question", 1);
  }
  @action savePostQuestion(data) {

    localStorage.removeItem(`${this.controller}_postQuestions`);
    localStorage.setItem(`${this.controller}_postQuestions`, data);
    this.actionModalVisisble = "";
    localStorage.setItem("is_opened_post_question", 1);
  }
  @action checkPriceDisplay(item) {
    if(item.promoName==='Outlet Order' && this.isTotoitoastControler())
    {
      return false;
    }
    return true;
  }
  @action isTotoitoastControler() {
    if(this.store.controller==='5b8911e6-3ba7-44d3-b24e-b6ddc512f88f')
    {
      return true;
    }
    return false;
  }
  @action isUnisolnControler() {
  if(this.isCustomPowerBy.toString()==='1')
    {
      return true;
    }
    return false;
  }
  @action isKopikingControler() {
  if(this.controller==='kopiking' || this.store.controller==='91c48b03-4cb8-43eb-b299-62ef21bd18e7')
    {
      return true;
    }
    return false;
  }
  

  @action getPromosData(tagAtObj)
  {
    let allPromos=[];
    if(tagAtObj.promo)
	  { 
      let promoSplit = tagAtObj.promo.split(',');
		  if(promoSplit.length>0)
		  {	 
			_.forEach(promoSplit, function(prom) {
				let promAr=prom.split('#');
        allPromos.push(promAr[0]);			
			});				  
		  }
	  }
    return allPromos;
  }
  @action checkTagAction() {
    let tagObj = {};
    let cachedTag = localStorage.getItem(this.controller);
    let tag = "";
    if (cachedTag) {
      cachedTag = JSON.parse(cachedTag);
      tag = cachedTag.tag.toLowerCase();
    }
    tag = tag.toLowerCase();
    //console.log('tag=',tag,this.tagsList);
    if (this.tagsList.length > 0) {
      for (let ta = 0; ta < this.tagsList.length; ta++) {
        let taObj = this.tagsList[ta];
        let tagOption = [];
        if (taObj.tag && taObj.tag === '*' && tag !== "") {
          tagObj = taObj;
          break;
        }
        else if (taObj.tag) {
          let tagsstr = taObj.tag.toString().toLowerCase();
          tagOption = tagsstr.split(',');
        }
        //console.log(tagOption,tag,tagOption.indexOf(tag));
        if (tagOption.length === 0 || tagOption.indexOf(tag) !== -1) {
          tagObj = taObj;
          break;
        }
      }
    }
    //if (actiontype === 'promo' && !tagMatched) {
    //isValueFound = true;
    //}
    // console.log(actiontype, p,isValueFound);
    return tagObj;
  }
  @action enablePreQuestionPopup() {
    let isopend = localStorage.getItem("is_opened_pre_question");
    // console.log(isopend);
   // let tagObject = this.checkTagAction();//'pre_qa', '0'
    let tagPreAction = this.checkPreQuestionPopup();
    // if (tagObject.pre_qa && tagObject.pre_qa === "0") {
      // tagPreAction = false;
    // }
    if (this.preQuestionFileds.length > 0 && isopend !== "1" && tagPreAction) {
      this.actionModalVisisble = "pre";
    }
    else {
      //this.enableLoginPopup();
      //this.enableLastOrderCheck();
    }
  }
  @action checkPreQuestionPopup() {
    let tagObject = this.checkTagAction();//'pre_qa', '0'
    let tagPreAction = true;
    if (tagObject.pre_qa && tagObject.pre_qa === "0") {
      tagPreAction = false;
    } 
   return tagPreAction;	
  }
  @action enableLastOrderCheck() {
    this.isOldThirtyMinsExist = this.getLastOrderDiff();
    if (this.isOldThirtyMinsExist) {
      this.showCheckLastOrder = true;
      localStorage.setItem("is_opened_pre_question", 1);
    }
    else {

      this.enableLoginPopup();
      setTimeout(() => {
        this.enablePreQuestionPopup();
      }, 100);
      // this.enableHomePopup();  
      // this.enablePreQuestionPopup(); 
    }
  }
  @action byPassSocialLogin() {
    this.isLoginModalVisible = false;
    localStorage.setItem(`${this.controller}_socialBypass`, true);
    localStorage.setItem("socialCheckout", false);
    this.socialCheckout = false;
    this.enableHomePopup();
  }
  @action enableHomePopup() {
    if (this.layout!==4 && this.showHome === "1" && this.promotions.length > 1 && this.defaultPromoSelected === '') {
      this.isHomeModalVisible = true;
    }
    else {
      //this.enablePreQuestionPopup();
    }
  }
  @action enableLoginPopup() {
    if (this.login_option === "1" && this.isLoginFormExist) {
      this.isLoginFormExist = false;
      this.isLoginModalVisible = true;
    }
    else {
      this.enableHomePopup();
    }
  }
  @action isSpotMethod() {
     let isSpot = localStorage.getItem("spotMethod");
    /*if (window.location.search.indexOf("method=") !== -1) {
      return window.location.search.split("method=")[1].split("&")[0].toLowerCase() === 'spot';
    } else return false;*/
	return isSpot==='spot'?true:false;
  }
  @action isBWCMethod() {
     //let bwc = localStorage.getItem("bwcMethod");
	  let cc = this.controller;
	 let bwc =localStorage.getItem(`${cc}_bwcMethod`);
	return bwc==='bwc'?true:false;
  }
  @action callSpotCreateOrder() {
    if(this.isSpotMethod())
	{
		 let orderObj=this.spotOrderObj;
		 console.log(orderObj);
		 window.microapps.createOrder(orderObj).then(creteOrderRes => {
       console.log('spot order created', creteOrderRes);
       const message = {
        "imageUrl": "https://www.foodzaps.com/public/img/message_spot.png",
        "title": "⚠️ Pay after your meal",
        "text": "Please pay at the counter after your meal. This is an order only; GPay did not process any payment yet.",
        "recipients": [
          {
            "order": {
              "id": creteOrderRes.orderId
            }
          }
        ]
      }
       console.log("Create Message", message)
        API.createSpotMessage(message)
		  }).catch(err => {
                    console.log(err);
					//thisObj.handleErrorWithMsg(err.message);
         });
	}
  }
  @action checkPostFields() {
    let needToOpend = false;
    //let tagPreAction = this.checkTagAction('post_qa', '0');
    let tagObject = this.checkTagAction();//'post_qa', '0'
    let tagPreAction = true;
    if (tagObject.post_qa && tagObject.post_qa === "0") {
      tagPreAction = false;
    }
    let isopend = localStorage.getItem("is_opened_post_question");

    if (this.postQuestionFileds.length > 0 && isopend !== "1" && tagPreAction && this.isValidPostQa === "") {
      needToOpend = true;
      this.actionModalVisisble = "post";
      //localStorage.setItem("is_opened_post_question",1); 
    }
    return needToOpend;
  }
  @action logout() {

    //localStorage.clear();
    // console.log(localStorage.length,'lll');
    for (let k = 0; k < localStorage.length; k++) {
      let key = localStorage.key(k);
      let keyS = key.split('_');
      // console.log(key);
      if (keyS[0].trim() === this.controller) {
        // console.log(key);
        localStorage.removeItem(key);
      }
    }
    localStorage.removeItem("socialCheckout");
    localStorage.removeItem(`${this.controller}_social`);
    localStorage.removeItem(`${this.controller}_preQuestions`);
    localStorage.removeItem(`${this.controller}_postQuestions`);
    localStorage.removeItem(`${this.controller}_cart`);
    localStorage.removeItem(`${this.controller}_order`);
    localStorage.removeItem(`${this.controller}_noRedirect`);
    localStorage.removeItem('menuDate');
    localStorage.removeItem('menutime');
    localStorage.removeItem(this.controller);    
    window.location.reload();
  }

  @action resetMenu() {
    return new Promise((resolve, reject) => {
      this.searchResult = this.menuList;
      this.isSearchDirty = false;

      this.hideSearchHandler();
      resolve();
    });
  }
  @action getCategories(promo) {
    let cats = [];

    let pProducts = this.promos[promo];
    _.each(pProducts, pro => {

      let index = cats.indexOf(pro.category);
      //console.log(index);
      if (index === -1) {
        cats.push(pro.category);
      }
    });
    //console.log(cats);
    return cats;
  }
  @computed get operatingTime() {
    let time = [];
    _.each(this.operatingHours, operatingHour => {
      //let start = moment(operatingHour.start, "HH:mm").format("h:mma");
      let start = this.convertTime(operatingHour.start);
      // let end = moment(operatingHour.end, "HH:mm").format("h:mma");
      let end = this.convertTime(operatingHour.end);
      time.push(`${start} to ${end}`);
    });

    return time.join(",");
  }

  @computed get menu() {
    return this.menuList;
  }

  @computed get categories() {
    return this.categoryList;
  }
  @computed get promotions() {
    return this.promoList;
  }
  @computed get promotionsLength() {
    if(this.promoList.length>0 &&this.promoList[0]!=="")
	{
		return this.promoList.length;
	}
	return 0;
  }

  @action menulists() {
    let data = this.promos[this.activePromo];
    //console.log(data);
    let newItem = _.groupBy(data, item => {
      return item.category;
    });
    return newItem;
  }

  @action checkVerificationCode(code) {
    // At least 1 character needs to be entered
    if (code.length === 0) return false;
    // No verification code required, return true
    if (this.verificationCode === null) return true;
    else {
      for (let i = 0; i < this.verificationCode.length; i++) {
        if (
          this.verificationCode[i] === code ||
          this.verificationCode[i] === "*"
        )
          return true;
      }
      return false;
    }
  }

  @action getItem(id) {
    for (let keys in this.categoryList) {
      const category = this.categoryList[keys];
      for (let i = 0; i < this.menuList[category].length; i++) {
        if (this.menuList[category][i].id === id)
          return this.menuList[category][i];
      }
    }
    return false;
  }

  @action getItemByCat(id, category) {
    if (this.menuList.length === 0 || this.menuList[category] === undefined) {
      return false;
    }
    for (let i = 0; i < this.menuList[category].length; i++) {
      if (this.menuList[category][i].id === id)
        return this.menuList[category][i];
    }
    return false;
  }
  @action getMenuDateTime() {
    let OldDate = localStorage.getItem('menudate');
    let time = localStorage.getItem('menutime');
    let Today = moment().format("YYYY-MM-DD");
    if (OldDate===null || OldDate==="" || moment(OldDate).isBefore(Today)) {
          OldDate=Today;
          localStorage.setItem('menudate', OldDate);
    }
    OldDate = moment(OldDate, "YYYY-MM-DD").format("ddd, ll");
    return OldDate + ' ' + time;
  }


  // Clones a map of addon from an item object
  @action cloneAddon(item) {
    let rawJS = toJS(item.addons);
    let cloned = {};
    for (let category in rawJS) {
      let list = rawJS[category];
      let mapped = list.map(addon => {
        return new AddonItem({ ...toJS(addon) });
      });
      cloned[category] = mapped;
    }
    return cloned;
  }

  @action setAppLanguage(language) {
    // Change the whole app text based on the language
    //console.log(this.rawTranslation);
    if (this.rawTranslation) {
      const keys = Object.keys(this.rawTranslation);
      for (let i = 0; i < keys.length; i++) {

        const key = keys[i];
        const words = this.rawTranslation[key].split("|");
        this.translation[key] = words[language];
        // console.log(key+':"'+words[language]+'",');
      }
    }
    //console.log(language,'language');
    //console.log(translation[language],'selected');
    //console.log(translation,'allll');
    //console.log( translationR,'777');
    //let restTrans=window.translationR;
    /*if(restTrans!==undefined && restTrans[language]!==undefined)
    {		  
          // console.log('called user translation');
       this.translation=restTrans[language];
    }
    else if(translation[language]!==undefined)
    {
       this.translation=translation[language];
    }*/
  }
  @action prepareMenu()
  {
	const language = localStorage.getItem(`${this.controller}_lang`);
	
	/*let OldDate = localStorage.getItem('menudate');
	let dayname = moment(OldDate).day();
	let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'][dayname];
    let datStr = 'day' + weekday;*/
	
	  let tagObj = this.checkTagAction();
    let display = true;
    if (tagObj.display === "0" || tagObj.display === 0) {
      display = false;
    }
    let isAvailablePromos=this.getPromosData(tagObj);
    let menuObj = this;
    let parsedItems = _.map(this.dishes, function (item) {
     
      if (item.devOnline === 0) {
        return;
      }     
      const addons = [];
      // Extract any set items
      if ("set" in item.prices[0]) {
        const addon_list = item.prices[0].set;
        // We group the addons by groupName resulting in
        // { group1: [AddonItem], group2: [AddonItem] }
        addons.push(
          _.groupBy(
            addon_list.map(addon => {
              let payload = {
                id: addon.id,
                name: _selectLang(addon.name, language),
                description: "des" in addon ? addon.des : null,
                isInStock: addon.disable ? false : true,
                price: parseFloat(addon.price).toFixed(2),
                priceName: addon.price_value
                // quantity: addon.usage_val === 0.5 ? 0 : addon.usage_type
              };

              if (addon.usage_val < 1) {
                payload.quantity = 0;
                const detail = JSON.parse(addon.usage_other).cat_set_meal[0];
                if (detail !== undefined) {
                  payload.min = 0;
                  payload.max = 1;
                  payload.groupName = 'not defined';
                  if (detail.min && detail.max && detail.min > detail.max) {
                    detail.min = 0;
                    detail.max = 0;
                  }
                  if (detail.min !== undefined)
                    payload.min = detail.min;

                  if (detail.max !== undefined)
                    payload.max = detail.max;

                  if (detail.name !== undefined)
                    payload.groupName = detail.name;

                  if (detail.order !== undefined)
                    payload.groupOrder = "order" in detail ? detail.order : null;
                }
              }
              else {
                console.log(addon.usage_val);
                payload.quantity = addon.usage_val;
              }

              return new AddonItem(payload);
            }),
            a => a.groupName
          )
        );
      }

      let price;
      if ("price" in item.prices[0]) {
        price = parseFloat(item.prices[0].price).toFixed(2);
      } else {
        price = parseFloat(
          item.prices[0].value.replace(/[^0-9.]+/, "")
        ).toFixed(2);
      }

      let priceName;

      if ("price_value" in item.prices[0]) {
        priceName = item.prices[0].price_value;
      } else {
        priceName = item.prices[0].value;
      }
      
      let days = {};
      days.dayMon = item.dayMon !== undefined ? item.dayMon : 1;
      days.dayTue = item.dayTue !== undefined ? item.dayTue : 1;
      days.dayWed = item.dayWed !== undefined ? item.dayWed : 1;
      days.dayThur = item.dayThur !== undefined ? item.dayThur : 1;
      days.dayFri = item.dayFri !== undefined ? item.dayFri : 1;
      days.daySat = item.daySat !== undefined ? item.daySat : 1;
      days.daySun = item.daySun !== undefined ? item.daySun : 1;

      let itemObj = new Item({
        id: item.prices[0].id,
        category: _selectLang(item.catName.trim(), language),
        name: _selectLang(item.name, language),
        description: item.des ? _selectLang(item.des, language) : "",
        thumbnail: item.image,
        price: price,
        priceName: priceName,
        addons: addons[0],
        isInStock: item.disable ? false : true,
        opt1: item.opt1 ? item.opt1 : '',
        des1: item.des1 ? item.des1 : '',
        promoName: item.promoName ? _selectLang(item.promoName.trim(), language) : "",
        discount: item.discount ? item.discount : 0,
        startDate: item.startDate ? item.startDate : 0,
        endDate: item.endDate ? item.endDate : 0,
        startTime: item.startTime ? item.startTime : 0,
        endTime: item.endTime ? item.endTime : 0,
        days: days,
        chef1: item.chef1 ? item.chef1 : 0,
		sku:item.SKU?item.SKU:''
      });
      if ((tagObj.display === "2" || tagObj.display === 2) && isAvailablePromos.indexOf(itemObj.promoName.toLowerCase()) < 0 )
      {
        return;
      }
     if (!display) {
        let isAvaial = menuObj.isAvailable(itemObj);
        if (!isAvaial) {
          return;
        }
      }
      return itemObj;
    });


    parsedItems = _.pick(parsedItems, val => {
      return val;
    });

	let avialArra=[];
	let notAvailArray=[];
	_.map(parsedItems, item => {
		 let avail=this.isAvailable(item);
		if(avail)
	   {
		   avialArra.push(item);
	   }
	   else{
		   notAvailArray.push(item);
	   }
	});
	parsedItems=avialArra.concat(notAvailArray);
		
    this.promos = _.groupBy(parsedItems, item => {
      return item.promoName.toLowerCase();
    });


    this.promoList = _.map(Object.keys(this.promos), item => {
      return item.trim();
    });

    this.menuList = _.groupBy(parsedItems, item => {
      return item.category;
    });

    this.filterMenuList = this.menuList;
    if (this.promoList.length > 0) {
      this.resetMeuList();
    }
    this.searchResult = this.filterMenuList;
    this.categoryList = _.map(Object.keys(this.menuList), item => {
      return item.trim();
    });
  }

  @action changeLanguage(language) {
    const cachedLanguage = localStorage.getItem(`${this.controller}_lang`);

    localStorage.setItem(`${this.controller}_lang`, language);
	this.prepareMenu();
  /*/  let tagObj = this.checkTagAction();
    let display = true;
    if (tagObj.display === "0" || tagObj.display === 0) {
      display = false;
    }
    let menuObj = this;
    let parsedItems = _.map(this.dishes, function (item) {    
      if (item.devOnline === 0) {
        //console.log(item);
        return;
      }

      const addons = [];
      // Extract any set items
      if ("set" in item.prices[0]) {
        const addon_list = item.prices[0].set;
        // We group the addons by groupName resulting in
        // { group1: [AddonItem], group2: [AddonItem] }
        addons.push(
          _.groupBy(
            addon_list.map(addon => {
              let payload = {
                id: addon.id,
                name: _selectLang(addon.name, language),
                description: "des" in addon ? addon.des : null,
                isInStock: addon.disable ? false : true,
                price: parseFloat(addon.price).toFixed(2),
                priceName: addon.price_value
                // quantity: addon.usage_val === 0.5 ? 0 : addon.usage_type
              };

              if (addon.usage_val < 1) {
                payload.quantity = 0;
                const detail = JSON.parse(addon.usage_other).cat_set_meal[0];
                if (detail !== undefined) {
                  payload.min = 0;
                  payload.max = 1;
                  payload.groupName = 'not defined';
                  if (detail.min && detail.max && detail.min > detail.max) {
                    detail.min = 0;
                    detail.max = 0;
                  }
                  if (detail.min !== undefined)
                    payload.min = detail.min;

                  if (detail.max !== undefined)
                    payload.max = detail.max;

                  if (detail.name !== undefined)
                    payload.groupName = detail.name;

                  if (detail.order !== undefined)
                    payload.groupOrder = "order" in detail ? detail.order : null;
                }
              }
              else {
                console.log(addon.usage_val);
                payload.quantity = addon.usage_val;
              }

              return new AddonItem(payload);
            }),
            a => a.groupName
          )
        );
      }

      let price;
      if ("price" in item.prices[0]) {
        price = parseFloat(item.prices[0].price).toFixed(2);
      } else {
        price = parseFloat(
          item.prices[0].value.replace(/[^0-9.]+/, "")
        ).toFixed(2);
      }

      let priceName;

      if ("price_value" in item.prices[0]) {
        priceName = item.prices[0].price_value;
      } else {
        priceName = item.prices[0].value;
      }

      let days = {};
      days.dayMon = item.dayMon !== undefined ? item.dayMon : 1;
      days.dayTue = item.dayTue !== undefined ? item.dayTue : 1;
      days.dayWed = item.dayWed !== undefined ? item.dayWed : 1;
      days.dayThur = item.dayThur !== undefined ? item.dayThur : 1;
      days.dayFri = item.dayFri !== undefined ? item.dayFri : 1;
      days.daySat = item.daySat !== undefined ? item.daySat : 1;
      days.daySun = item.daySun !== undefined ? item.daySun : 1;

      let itemObj = new Item({
        id: item.prices[0].id,
        category: _selectLang(item.catName.trim(), language),
        name: _selectLang(item.name, language),
        description: item.des ? _selectLang(item.des, language) : "",
        thumbnail: item.image,
        price: price,
        priceName: priceName,
        addons: addons[0],
        isInStock: item.disable ? false : true,
        opt1: item.opt1 ? item.opt1 : '',
        des1: item.des1 ? item.des1 : '',
        promoName: item.promoName ? _selectLang(item.promoName.trim(), language) : "",
        discount: item.discount ? item.discount : 0,
        startDate: item.startDate ? item.startDate : 0,
        endDate: item.endDate ? item.endDate : 0,
        startTime: item.startTime ? item.startTime : 0,
        endTime: item.endTime ? item.endTime : 0,
        days: days,
        chef1: item.chef1 ? item.chef1 : 0
      });
      if (!display) {
        let isAvaial = menuObj.isAvailable(itemObj);

        if (!isAvaial) {
          return;
        }
      }
      return itemObj;
    });


    parsedItems = _.pick(parsedItems, val => {
      return val;
    });

    this.promos = _.groupBy(parsedItems, item => {
      return item.promoName.toLowerCase();
    });


    this.promoList = _.map(Object.keys(this.promos), item => {
      return item.trim();
    });

    this.menuList = _.groupBy(parsedItems, item => {
      return item.category;
    });

    this.filterMenuList = this.menuList;
    if (this.promoList.length > 0) {
      this.resetMeuList();
    }
    this.searchResult = this.filterMenuList;


    this.categoryList = _.map(Object.keys(this.menuList), item => {
      return item.trim();
    });*/

    this.setAppLanguage(language);
    if (!cachedLanguage) {
      this.enableLoginPopup();
      setTimeout(() => {
        this.enablePreQuestionPopup();
      }, 100);
    }
  }
}

export default MenuStore;