import { observable, computed, action, reaction } from "mobx";
import _ from "underscore";
import API from "../libs/api";
import moment from "moment";

class CartEntry {
  @observable item;
  @observable.shallow addons = [];
  @observable notes = [];
  reactionHandler;

  constructor({ item, addons, notes,reward, reactionHandler }) {
    this.item = item;
    this.addons = addons;
    this.notes = notes;
    this.reward = reward?reward:{};
    this.reactionHandler = reactionHandler;

    reaction(
      () => {
        reactionHandler(this.item.id, this.addons.length);
      },
      () => { }
    );
  }

  @computed get hasNote() {
    for (let i = 0; i < this.notes.length; i++) {
      if (this.notes[i] !== "") return true;
    }
    return false;
  }

  @computed get quantity() {
    //console.log(this.addons.length);
    // console.log('p');
    return this.addons.length;
  }
  
  @action removeAddon(addonListIndex) {
    this.addons.splice(addonListIndex, 1);
  }

  // Pattern = 1401 1 a222 1 notes, 1401 1 a111 b111 1
  // b prefix stands for mandatory
  @action payload() {
    let payload = [];

    _.each(this.addons, (addonList, addonListIndex) => {
		
	 if((this.item.isCustomize && addonListIndex===0) || !this.item.isCustomize)
	 {	 		 
       let header = this.item.id+" 1";

      // Remember to replace commas with another character
	  
      if (this.notes[addonListIndex] && this.notes[addonListIndex] !== "") {
		  header=header+" "+this.notes[addonListIndex].replace(/,/g, " ");       
      } 
	  if(this.reward && this.reward.redeem_code && this.reward.redeem_code!=="")
	  {
		  header=header+" [EberDiscount]"; 
	  }
      payload.push(header);

      const sorted = this.sortAddons(addonList);

      // Push the mandatory items first
      if ("undefined" in sorted) {
        _.each(sorted["undefined"], addon => {
          payload.push(`b${addon.id} ${addon.quantity} `);
        });
      }

      delete sorted["undefined"];
      const keys = Object.keys(sorted);
      for (let i = 0; i < keys.length; i++) {
        _.each(sorted[keys[i]], addon => {
			if(addon.modifiers && addon.modifiers!=="")
			{
			  payload.push(`a${addon.id} ${addon.quantity} ${addon.modifiers} `);
			}	
			else
			{
			   payload.push(`a${addon.id} ${addon.quantity} `);
			}         
        });
      }
	 } 
    });

    return payload.join(", ");
  }

  // Return a sorted addon list by using groupOrder presented in addons
  @action sortAddons(addonList) {
    let sortedList = {};

    _.each(addonList, (addonGroup, index) => {
      _.each(addonGroup, (addon, index) => {
        if (index === 0) sortedList[addon.groupOrder] = [];

        if (addon.quantity > 0) sortedList[addon.groupOrder].push(addon);
      });
    });
    return sortedList;
  }

  @action updateSpecialRequest(index, text) {
    this.notes[index] = text;
  }  
}

class CartStore {
  menuStore;
  orderStore;
  tabHandler;
  @observable.shallow entries = []; // Stores an array of CartEntry
  @observable.shallow quantity_map = new Map();
  remarks = "";
  takeAway = true;
  CustomOrderId = '';

  constructor(menuStore, orderStore) {
    this.menuStore = menuStore;
    this.orderStore = orderStore;
    // Iterate through menuList to setup quantity_map with initial values
    /* _.each(this.menuStore.menuList, val => {
       _.each(val, item => {
         this.quantity_map.set(item.id, 0);
       });
     });
 	
   const pendingOrder=localStorage.getItem(`${this.menuStore.controller}_cart`);
   if(pendingOrder)
   {
     if (pendingOrder !== "undefined") {
         const jsonPendingOrders = JSON.parse(pendingOrder);
         //this.entries = jsonPendingOrders.map(entry => new CartEntry({ ...entry }));
     _.each(jsonPendingOrders, entry => {
       //this.entries.push(new CartEntry({ ...entry }));
       this.addItem({ ...entry });
           this.quantity_map.set(entry.item.id, entry.addons.length);
     });
     //_.each(this.entries, entry => {			
        //  this.quantity_map.set(entry.item.id, entry.addons.length);
     //});
       }
      // this.entries=JSON.parse(pendingOrder);
       	
   } */
  }
   @action _callWebSocket(){
	    
    console.log('websocket called.......');
    this.menuStore.websocket = new WebSocket("wss://ivnkjexidh.execute-api.ap-southeast-1.amazonaws.com/staging/");
    let uuid=localStorage.getItem('uuid');
    this.menuStore.websocket.onopen = () => {
        this.menuStore.websocket.send(JSON.stringify({
            action: "subscribe",
            topic: "public-order-update",
            merchantId: this.menuStore.mwMerchantId,
			device_type: "amenu",
            device_id:uuid
        }));
        // websocket.close();
    };
    
	let obj=this;
    this.menuStore.websocket.onmessage = function(str) {		
        console.log("SNS message received ", str);
		if(str.data)
		{
		    let res=JSON.parse(str.data);
			//obj.orderStore.updateOrder(res);
			 obj.orderStore._callSocketCheckOrderStatus(res.request);
		}

    };
    
    this.menuStore.websocket.onclose = function(event) {
        if (event.wasClean) {
            console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            console.log('[close] Connection died');
        }
    };
    
    this.menuStore.websocket.onerror = function(error) {
        console.log(`[error]`);
    };
  } 
 @action addOrderByBackend(payload,id)
 {  
  let orderId = payload.refno;  
  let pingurl=payload.pingUrl;
		if(id!=="")
		{
			orderId=id;
			pingurl=null;		
		}
    	let allOrders=this.orderStore.lists;
		let newOrderlist=[];
        let isOrderFound=false;
		_.each(allOrders, ordr => {
		  if(ordr.id===payload.refno)
		  {
			  isOrderFound=true;
			  ordr.id=id;
			  ordr.pingUrl=pingurl;
		  }
		  newOrderlist.push(ordr);
		});
		if(isOrderFound)
		{
			this.orderStore.lists=newOrderlist;
		    this.orderStore.saveOrders(); 
		}
		else if(this.entries.length>0)
		{
			this.menuStore.resetPostQA(); 
			localStorage.removeItem("is_opened_pre_question");
			this.orderStore.addOrder({
			id: orderId,
			status: null, // defaulted to pending status
			cartEntries: this.entries,
			pingUrl:payload.pingUrl
			});
			// Empty the cart
			this.empty();
		} 
 }
 
 @action addOrderBySocket(payload)
 {  
        let orderId = payload.tempOrderId;  
    	let allOrders=this.orderStore.lists;
		let newOrderlist=[];
        let isOrderFound=false;
		_.each(allOrders, ordr => {
		  if(ordr.id===orderId)
		  {
			  isOrderFound=true;
			  ordr.status=payload.status;
			  ordr.priceReceipt=payload;
			  
		  }
		  newOrderlist.push(ordr);
		});
		console.log(isOrderFound,'55555555555');
		if(isOrderFound)
		{
			console.log(isOrderFound,'333');
			this.orderStore.lists=newOrderlist;
		    this.orderStore.saveOrders(); 
		}
		else if(this.entries.length>0)
		{
			console.log(isOrderFound,'isOrderFoundisOrderFoundisOrderFoundisOrderFoundisOrderFound');
			this.menuStore.resetPostQA(); 
			localStorage.removeItem("is_opened_pre_question");
			this.orderStore.addOrderWithSocket({
			id: orderId,
			status: payload.status?payload.status:0, // defaulted to pending status
			cartEntries: this.entries,
			//orderNo:payload.uuid,
			request:payload.request,
			table:payload.table,
			pax:payload.pax,
			isSocketAPi:true,
			priceReceipt:'',
			//pingUrl:payload.pingUrl
			});
			// Empty the cart
			this.empty();
		} 
 }
 @action prepareOrderParams(itemIds, order) {
    //let itemIds=this.state.excludedItesm;

    let payloadOrder = [];
    let filterCustomizeOrder = [];
    let entries = this.convertTOEntry(order.cartEntries, false);
    _.map(entries, entry => {
		if((entry.item.isCustomize && !filterCustomizeOrder.indexOf(entry.item.id)) || !entry.item.isCustomize )
		{
			  if (itemIds==="checkTable" || itemIds.indexOf(entry.item.id) > -1) {
				payloadOrder.push(entry.payload());
			  }
		}
    });
    if (payloadOrder.length === 0) {
      return '';
    }
	let uuid=localStorage.getItem('uuid');
    payloadOrder = payloadOrder.join(", ");
    let payload = "";
    payload += "&order=" + encodeURIComponent(payloadOrder);
    payload += "&orderNo=" + order.orderNo;
    payload += "&key=" + order.id;
    payload += "&uuid=" + uuid;
    return payload;
  }

  @computed get cart() {
    return this.entries;
  }

  @computed get size() {
    return _.reduce(
      this.entries,
      (memo, entry) => {
		 
        //return memo + entry.addons.length;
        return memo + this.quantity_map.get(entry.item.id);
      },
      0
    );
  }
  @computed get cartQty() {
    return _.reduce(
      this.entries,
      (memo, entry) => {
		 
        //return memo + entry.addons.length;
        return memo + entry.addons.length;
      },
      0
    );
  }
  @computed get freeQty() {
    return _.reduce(
      this.entries,
      (memo, entry) => {
		  let price=parseFloat(entry.item.price);
		  if(price>0)
		  {
			return memo + 0;  
		  }
		  else{
			  return memo + this.quantity_map.get(entry.item.id);
		  }
            
      },
      0
    );
  }
    @computed get orderAmount() {
    let total = 0;
    _.each(this.entries, entry => {
       total+=parseFloat(entry.item.price) * entry.addons.length;
      _.each(entry.addons, addonList => {
        const keys = Object.keys(addonList);
        for (let i = 0; i < keys.length; i++) {
          const category = keys[i];
          if (category !== "undefined") {
            for (let x = 0; x < addonList[keys[i]].length; x++) {
              total +=
                addonList[keys[i]][x].price * addonList[keys[i]][x].quantity;
            }
          }
        }
      });
	 
    });
  
    total=total.toFixed(parseFloat(this.menuStore.currencyDecimal));
	return total;
  }
  @computed get total() {
    let total = 0;
    _.each(this.entries, entry => {
      let sTotal= parseFloat(entry.item.price) * entry.addons.length;
      _.each(entry.addons, addonList => {
        const keys = Object.keys(addonList);
        for (let i = 0; i < keys.length; i++) {
          const category = keys[i];
          if (category !== "undefined") {
            for (let x = 0; x < addonList[keys[i]].length; x++) {
              sTotal +=
                addonList[keys[i]][x].price * addonList[keys[i]][x].quantity;
            }
          }
        }
      });
	  let {priceAmt}=this.calculateRewardDiscoutPrice(entry.reward,sTotal);
	  total += parseFloat(priceAmt);
    });
    let social=this.menuStore.social;
	if(social.rewards && social.rewards.length>0)
	{
		let selectedReward=social.rewards[0];
		if(selectedReward.pos_redeem_sku===null && selectedReward.pos_redeem_category===null)
		{
			    let amt=selectedReward.pos_redeem_amount;
				if(selectedReward.pos_redeem_method==='percentage')
				{				
				   amt=parseFloat(total)*selectedReward.pos_redeem_percentage/100;
				}		
			 total -= parseFloat(amt) ;
		}
	}
    total=total.toFixed(parseFloat(this.menuStore.currencyDecimal));
	if(total<0)
	{
	 total=0.00;	
	}
	return total;
  }
  @action calculateRewardDiscoutPrice(selectedReward,total)
  {
	let actualPrice= parseFloat(total).toFixed(parseFloat(this.menuStore.currencyDecimal)); 	
	if(selectedReward && selectedReward.redeem_code && selectedReward.redeem_code !=="")
	{
		
			    let amt=selectedReward.pos_redeem_amount;
				if(selectedReward.pos_redeem_method==='percentage')
				{				
				   amt=parseFloat(total)*selectedReward.pos_redeem_percentage/100;
				}		
			 total -= parseFloat(amt) ;
	}
	
	if(total<0)
	{
	 total=0;	
	}
	let priceAmt= parseFloat(total).toFixed(parseFloat(this.menuStore.currencyDecimal)); 
	return {actualPrice,priceAmt}; 
  }
  @action entryTotal(entry) {
    let total = 0; 
      total += parseFloat(entry.item.price) * entry.addons.length;
      _.each(entry.addons, addonList => {
        const keys = Object.keys(addonList);
        for (let i = 0; i < keys.length; i++) {
          const category = keys[i];
          if (category !== "undefined") {
            for (let x = 0; x < addonList[keys[i]].length; x++) {
              total +=
                addonList[keys[i]][x].price * addonList[keys[i]][x].quantity;
            }
          }
        }
      });
     if(parseFloat(entry.item.price) ===0)
	 {
		//total = parseFloat(total) * entry.addons.length; 
	 }
    return total.toFixed(parseFloat(this.menuStore.currencyDecimal));
  }
  @action checkCartsStatus() {
    _.each(this.entries, entry => {
      this.updateNotavilable(entry.item);
	  // if(entry.reward && entry.reward.id && entry.reward.id!=="")
	  // {
		  // rewardName=entry.reward.redeem_name;
	  // }
    });
	//return rewardName;
  }
  @action checkRewardItemInCart() {
    let rewardName="";
    _.each(this.entries, entry => {
     // this.updateNotavilable(entry.item);
	  if(entry.reward && entry.reward.redeem_code && entry.reward.redeem_code!=="")
	  {
		  rewardName=entry.reward.redeem_name?entry.reward.redeem_name:'';
	  }
    });
	return rewardName.trim();
  }
  @action updateNotavilable(item) {
    let ntA = this.menuStore.notAvailable;
    var index = ntA.indexOf(item.id)
    if (ntA.length > 0 && index !== -1) {
      this.menuStore.notAvailable.splice(index, 1);
    }

    let avail = this.menuStore.isAvailable(item);
    if (!avail) {
      this.menuStore.notAvailable.push(item.id);
    }
  }
  @action renderOlderOrder() {

    //const { itemModal } = this.menuStore;

    _.each(this.menuStore.menuList, val => {
      _.each(val, item => {
        this.quantity_map.set(item.id, 0);
      });

    });
    //this.menuStore.notAvailable=[];
    const pendingOrder = localStorage.getItem(`${this.menuStore.controller}_cart`);
    if (pendingOrder) {
      if (pendingOrder !== "undefined") {
        const jsonPendingOrders = JSON.parse(pendingOrder);
        this.entries = this.convertTOEntry(jsonPendingOrders, true);
		_.each(this.entries, entry => {	
			if(entry.item.isCustomize && entry.item.hasAddons)
			{
				let tQty=this.getAddonsQty(entry.addons);
				//console.log(tQty,'999');
				//this.quantity_map.set(entry.item.id, tQty);
				this.updateQuantity(entry, tQty);
			}					
		});
		//this.resetEntries();
      }
    }
  }
  @action convertTOEntry(jsonPendingOrders, isMapping) {
    const { itemModal } = this.menuStore;
    let allEntries = [];
    _.each(jsonPendingOrders, entry => {
      let item = this.menuStore.getItemByCat(entry.item.id, entry.item.category);
      if (item) {
        let allAdones = entry.addons;
		//console.log(entry.item.name,allAdones);
        _.each(allAdones, (add, index) => {
          _.each(add, (sadd, sindex) => {
            _.each(sadd, (adItem, aindex) => {
              let cloned = itemModal.makeAddonObject(adItem);
              allAdones[index][sindex][aindex] = cloned;
            });

          });
        });
        let enty = new CartEntry({
          item: item,
          addons: allAdones,
          notes: entry.notes,
		  reward:entry.reward,
          reactionHandler: (itemId, quantity) => {
            if (isMapping) {			
              this.quantity_map.set(itemId, quantity);
            }

          }
        });

        allEntries.push(enty);
        //this.updateNotavilable(item);
      }
    });
    return allEntries;
  }

  @action repeatOrder(order) {
    let enteries=order.cartEntries;
    if(!enteries || enteries===null)
    {
      let orderList=this.orderStore.lists;
      //console.log(orderList,'000');
     /* orderList.forEach(oder => {*/
      for(let ol=orderList.length-1;ol>0;ol--)
      {
        //console.log(5);
        let oder=orderList[ol];
        if(oder.id===order.id)
        {
          
          enteries=oder.cartEntries;
          //return true;
          break;
        }
      }
     // });
    }
    _.each(enteries, entry => {
      this.addItem({ ...entry });
      this.quantity_map.set(entry.item.id, entry.addons.length);
      //this.menuStore.isAvailable(entry.item);
    });

  }
  @action updateQuantity(entry, quantity) {
    const difference = Math.abs(entry.addons.length - quantity);

    for (let i = 0; i < difference; i++) {
      if (quantity > entry.addons.length) {
        this.addItem({ item: entry.item, addons: null, notes: [""] });

        let faddonList = entry.addons[0];
        //console.log(faddonList);
        //entry.addons[entry.addons.length-1]=faddonList;
        let lastaddonList = entry.addons[entry.addons.length - 1];
        //console.log(lastaddonList,faddonList);
        let keys = Object.keys(faddonList);
        _.each(keys, (key, index) => {

          _.each(faddonList[key], (addon, addonIndex) => {
            lastaddonList[key][addonIndex].quantity = faddonList[key][addonIndex].quantity;
          });
        });

        entry.addons[entry.addons.length - 1] = lastaddonList;
        this.menuStore.sendGoogleAnalytics(this.menuStore.googleAddItem, entry.item.name, this.menuStore.controller, entry.item.price * entry.addons.length*100);
      } else {
        entry.addons.pop();
        entry.notes.pop();
        this.menuStore.sendGoogleAnalytics(this.menuStore.googleRemoveItem, entry.item.name, this.menuStore.controller, entry.item.price * entry.addons.length*100);

        if (quantity === 0) this.removeItem(entry.item);
      }
    }
  }

  // Menu items can be added or removed
  @action addItemFromMenu(item, quantity) {

    // let entry = null;
    // for (let i = 0; i < this.entries.length; i++) {
      // if (item.id === this.entries[i].item.id) entry = this.entries[i];
    // }
    let entry = this.getEntry(item);
    // Entry found means we can either add or remove item
    if (entry) {
      this.updateQuantity(entry, quantity);
    } else {
      this.addItem({ item: item, addons: null, notes: [""] });
	  if(quantity>1) //for coco
	  {
		this.addItemFromMenu(item, quantity) ;
	  }
    }
    return entry;
  }
  @action getAddonsIds(itemAddon,addonSlected)
  {
	  //console.log(addonSlected,'addonSlected');
	  let firstItemAdon=itemAddon[0];
	  let firstAddonSlected=addonSlected[0];
	   let keys = Object.keys(firstAddonSlected);
	   let isFoundMatch=true;
	  // let selAddonIds=[];
        _.each(keys, (key, index) => {			  
          _.each(firstAddonSlected[key], (addon, addonIndex) => { 
             let existing=firstItemAdon[key][addonIndex];       
             let existingMod=existing.modifiers?existing.modifiers:'';  
             let newMod=addon.modifiers?addon.modifiers:'';  
			if(addon.quantity===1 && existing.quantity===1 && existingMod===newMod)
			{
				
			}
			else if(addon.quantity===1 || existing.quantity===1)
			{
				isFoundMatch=false;
				//break;
			}
          });	
           
     });
	  return isFoundMatch;
   }
  @action getLeastAddonPrice(itemAddon)
  {
	   let price=0;
	   _.each(itemAddon, addonList => {
	  //console.log(firstAddonSlected,'itemAddon');
	   let keys = Object.keys(addonList);
	  
        _.each(keys, (key, index) => {			  
          _.each(addonList[key], (addon, addonIndex) => { 
		 
		  let addPr=parseFloat(addon.price);
			if(addPr >0 && (addPr < price || price===0))
			{
				price=addPr;
			}
			
          });	
        });   
     });
	  return parseFloat(price);
   }
   @action getAddonsNames(addonSlected)
  {
       let firstAddonSlected=addonSlected[0];
	   let keys = Object.keys(firstAddonSlected);	  
	   let selAddonName=[];
        _.each(keys, (key, index) => {			  
          _.each(firstAddonSlected[key], (addon, addonIndex) => { 
			 if(addon.quantity===1 && addon.groupName)
			{
				selAddonName.push(addon.name);
			}
          });	
           
     });
	  return selAddonName.join(', ');
   }
  
  @action getAddonsNamesList(addonSlected)
  {
       let firstAddonSlected=addonSlected[0];
	   let keys = Object.keys(firstAddonSlected);	  
	   let selAddonName=[];
        _.each(keys, (key, index) => {			  
          _.each(firstAddonSlected[key], (addon, addonIndex) => { 
			 if(addon.quantity===1 && addon.groupName)
			{
             let name=addon.name+(addon.modifiers && addon.modifiers!==""? ' ['+addon.modifiers+']':'');	
 			selAddonName.push(name);
			}
          });	
           
     });
	  return selAddonName;
   }
   @action addItemGroupByAddons(itemModal) {
    let item=itemModal.item;
	let addons=itemModal.addons;
	let notes=itemModal.notes;
//	let quantity=itemModal.addons.length;
	let isUpdate=itemModal.isUpdate;
	let reward=itemModal.rewardItem;
	let isRwardItem=false;
	if(reward && reward.redeem_code && reward.redeem_code!=="")
	{
	isRwardItem=true;
	}
     // if(!item.hasAddons)
	 // {
		 // this.addItemFromMenu(item, quantity);
		 // return;
	 // }
    let entry = null;
	let is_found=false;
    for (let i = 0; i < this.entries.length; i++) {
      if (item.id === this.entries[i].item.id) 
	  {
			let rewardEntry=	this.entries[i].reward;
			let isRwardEntryItem=false;
			if(rewardEntry && rewardEntry.redeem_code && rewardEntry.redeem_code!=="")
			{
			isRwardEntryItem=true;

			}
		  is_found=this.getAddonsIds(this.entries[i].addons,addons);
		  if(!isRwardItem && !isRwardEntryItem && is_found && notes[0]===this.entries[i].notes[0])
		  {
			 entry= this.entries[i];
			 break;
		  }
		  // else if(!is_found && notes[0]===this.entries[i].notes[0])
		  // {
			 // entry= this.entries[i];
			 // break; 
		  // }
	  }  
    }
	if (entry) {
      if (addons === null) {
        entry.addons.push(this.menuStore.cloneAddon(item));
      } else {
		  if(isUpdate)
		  {
			  entry.addons=[];
		  }		  
        _.each(addons, addon => {
          entry.addons.push(addon);
        });
      }

      if (notes === null) {
        entry.notes.push([""]);
      } else {
        _.each(notes, note => {
          entry.notes.push(note);
        });
      }
    } else {
      this.entries.push(
        new CartEntry({
          item: item,
          addons: addons ? addons : [this.menuStore.cloneAddon(item)],
          notes: notes,
		  reward:reward,
          reactionHandler: (itemId, quantity) => {
            this.quantity_map.set(itemId, quantity);
          }
        })
      );
    }
	this.resetEntries();
  }
  @action getEntry(item) {

    let entry = null;
    for (let i = 0; i < this.entries.length; i++) {
      if (item.id === this.entries[i].item.id) entry = this.entries[i];
    }
    return entry;
  }
  @action getItemQty(item) {
    let qty = 0;
    for (let i = 0; i < this.entries.length; i++) {
      if (item.id === this.entries[i].item.id) {
		  qty=qty+this.entries[i].addons.length;
	  }
    }
	//console.log(qty,this.entries[0],item);
    return qty;
  }
  @action getRewardItemQty(item) {
    let qty = 0;
    for (let i = 0; i < this.entries.length; i++) {
      if (item.id === this.entries[i].item.id) {
		  if(this.entries[i].reward && this.entries[i].reward.id &&  this.entries[i].reward.id!=="")
		  {
			 qty=1; 
		  }
	  }
    }
	//console.log(qty,this.entries[0],item);
    return qty;
  }
  @action resetRewardItem(id) { 
        let entries=[];
        for (let i = 0; i < this.entries.length; i++) {
		  if(this.entries[i].reward && this.entries[i].reward.redeem_code && this.entries[i].reward.redeem_code!=="")
		  {
			
		  }
		  else
		  {
			 entries.push(this.entries[i]); 
		  }
    }
	this.entries=entries;
    this.resetEntries();	
  }
  
  @action appliedRewardName() {   
    let social=this.menuStore.social;
	if(social.rewards && social.rewards.length>0)
	{
		return social.rewards[0].redeem_name;
	}
    return "";
  }
  @action checkValidRedeemAmount() {   
    let social=this.menuStore.social;
	if(this.menuStore.isBWCMethod() && social.rewards && social.rewards.length>0)
	{
		let reObj=social.rewards[0];
	    if(reObj.pos_redeem_method && reObj.pos_redeem_method==="percentage")
		{	
			return "";
		}
		else
		{
			let pos_redeem_amount=parseFloat(reObj.pos_redeem_amount);		
			let total=parseFloat(this.orderAmount);
			if(pos_redeem_amount > total)
			{
				return "error";
			}
		}
	}
    return "";
  }
  // Item must be an item model
  // addons is an array of objects
  @action addItem({ item, addons, notes }) {
    // Check if an entry is there previously
    // let entry = null;
    // for (let i = 0; i < this.entries.length; i++) {
      // if (item.id === this.entries[i].item.id) entry = this.entries[i];
    // }
    let entry = this.getEntry(item);
    // If addons == null, we take from the base
    // Entry found, we need to update quantity and addonMap index
    if (entry) {
      if (addons === null) {
        entry.addons.push(this.menuStore.cloneAddon(item));
      } else {
        _.each(addons, addon => {
          entry.addons.push(addon);
        });
      }

      if (notes === null) {
        entry.notes.push([""]);
      } else {
        _.each(notes, note => {
          entry.notes.push(note);
        });
      }
    } else {
      this.entries.push(
        new CartEntry({
          item: item,
          addons: addons ? addons : [this.menuStore.cloneAddon(item)],
          notes: notes,
          reactionHandler: (itemId, quantity) => {
            this.quantity_map.set(itemId, quantity);
          }
        })
      );
    }

    //this.updateNotavilable(item);
    localStorage.setItem(`${this.menuStore.controller}_cart`, JSON.stringify(this.entries));
  }

  @action removeItem(item) {
    for (let i = 0; i < this.entries.length; i++) {
      if (this.entries[i].item.id === item.id) {
        this.entries[i].addons = [];
        this.entries.splice(i, 1);
      }
    }
    let ntA = this.menuStore.notAvailable;
    var index = ntA.indexOf(item.id)
    if (ntA.length > 0 && index !== -1) {
      this.menuStore.notAvailable.splice(index, 1);
    }
	this.resetEntries();
    //localStorage.setItem(`${this.menuStore.controller}_cart`, JSON.stringify(this.entries));
  }
  @action removeItemIndexWise(item,ind) {

        this.entries[ind].addons = [];
        this.entries.splice(ind, 1);   
        this.resetEntries();		
		//localStorage.setItem(`${this.menuStore.controller}_cart`, JSON.stringify(this.entries));
		
		let ntA = this.menuStore.notAvailable;
		var index = ntA.indexOf(item.id)
		if (ntA.length > 0 && index !== -1) {
		this.menuStore.notAvailable.splice(index, 1);
		}
  }
  

  // For verification
  @action isCodeExpired() {
    const cachedCode = localStorage.getItem(
      `${this.menuStore.controller}_code`
    );
    if (cachedCode === null) return true;
    else {
      // Check if expired
      const timestamp = JSON.parse(cachedCode).timestamp;
      const expiryLength = this.menuStore.tagExpiry;

      const expiry = moment(timestamp).add(expiryLength, "millisecond");
      const now = moment();

      return now > expiry;
    }
  }

  @action isTagExpired() {
    const cachedTag = localStorage.getItem(this.menuStore.controller);
    if (cachedTag === null) return true;
    else {
      const timestamp = JSON.parse(cachedTag).timestamp;
      const expiryLength = this.menuStore.tagExpiry;

      const expiry = moment(timestamp).add(expiryLength, "millisecond");
      const now = moment();
      if(now > expiry)
      {
        localStorage.removeItem(`${this.menuStore.controller}`);
      }
      return now > expiry;
    }
  }

  // Check operating hours
  @action checkOperatingHour() {
    // Check if current time within opening/closing time
    const d = new Date();
    let cHr = d.getHours();
    let cMin = d.getMinutes();

    cMin = (cMin < 10) ? ("0" + cMin) : cMin;
    let current = cHr + '' + cMin;
    let isClosed = true;
    _.each(this.menuStore.operatingHours, operatingHour => {

      let start = parseInt(operatingHour.start);
      let end = parseInt(operatingHour.end);

      let cTime = parseInt(current);
      if (start > cTime) {
        cTime = parseInt((24 + cHr) + '' + cMin);
      }
      if (start > end) {
        end = end + 2400;
      }
      //console.log(start,' <=', cTime,' &&',  end,' >= ',cTime);

      if (start <= cTime && end >= cTime) {
        isClosed = false;
        //break;
      }
    });

    if (isClosed === true) {
      return {
        title: this.menuStore.translation.shop_close,
        content: [
          this.menuStore.translation.shop_open,
          this.menuStore.operatingTime
        ]
      };
    } else {
      return true;
    }
  }

  @action removeCachedCode() {
    localStorage.removeItem(`${this.menuStore.controller}_code`);
  }
  @action getItemsListForSocket() {
    let payloadOrder = [];
    let filterCustomizeOrder = [];
    _.map(this.entries, entry => {		
		if((entry.item.isCustomize && filterCustomizeOrder.indexOf(entry.item.id)<0) || !entry.item.isCustomize )
		{
			//payloadOrder.push(entry.payload());
			
			filterCustomizeOrder.push(entry.item.id);
			
  _.each(entry.addons, (addonList, addonListIndex) => {

	  // if(this.reward && this.reward.redeem_code && this.reward.redeem_code!=="")
	  // {
		  // header=header+" [EberDiscount]"; 
	  // }
      // payload.push(header);
	     let item=entry.item;
			payloadOrder.push({
				"productId":item.id,
				"parentProductId": 0,
				"quantity": 1,
				"name": item.name,
				"description": entry.notes[addonListIndex].replace(/,/g, " "),
				"onlineProductId": item.id,
				"image": item.thumbnail,
				"discount": 0
			});
			

      const sorted = entry.sortAddons(addonList);

      // Push the mandatory items first
      if ("undefined" in sorted) {
        _.each(sorted["undefined"], addon => {
          //payload.push(`b${addon.id} ${addon.quantity} `);
		  console.log(addon,'addonaddonaddonaddon');
		  payloadOrder.push({
				"productId":item.id,
				"parentProductId": item.id,
				"quantity": addon.quantity,
				"name": addon.name,
				"description": "",
				"onlineProductId": addon.id,
				"image": item.thumbnail,
				"discount": 0,
			});
		  
		  
        });
      }

      delete sorted["undefined"];
      const keys = Object.keys(sorted);
      for (let i = 0; i < keys.length; i++) {
        _.each(sorted[keys[i]], addon => {
			// if(addon.modifiers && addon.modifiers!=="")
			// {
			  // payload.push(`a${addon.id} ${addon.quantity} ${addon.modifiers} `);
			// }	
			// else
			// {
			   // payload.push(`a${addon.id} ${addon.quantity} `);
			// } 
             payloadOrder.push({
				"productId":item.id,
				"parentProductId": item.id,
				"quantity": addon.quantity,
				"name": addon.name,
				"description": (addon.modifiers && addon.modifiers!==""?addon.modifiers:""),
				"onlineProductId": addon.id,
				"image": item.thumbnail,
				"discount": 0,
				//"notes":entry.notes[addonListIndex].replace(/,/g, " ")
			});			
        });
      }
    });
	
	
		}
	});

    //payloadOrder = payloadOrder.join(", ");
    return payloadOrder;
  }
  @action getOrderList() {
    let payloadOrder = [];
    let filterCustomizeOrder = [];
    _.map(this.entries, entry => {		
		if((entry.item.isCustomize && filterCustomizeOrder.indexOf(entry.item.id)<0) || !entry.item.isCustomize )
		{
			payloadOrder.push(entry.payload());
			filterCustomizeOrder.push(entry.item.id);
		}
	});

    payloadOrder = payloadOrder.join(", ");
    return encodeURIComponent(payloadOrder);
  }
  @action getOrderItems()
  {
    let payloadOrder = [];
    let filterCustomizeOrder = [];
    _.map(this.entries, entry => {		
		if((entry.item.isCustomize && filterCustomizeOrder.indexOf(entry.item.id)<0) || !entry.item.isCustomize )
		{
			payloadOrder.push(entry.item.name+"( "+entry.quantity+" )");
			filterCustomizeOrder.push(entry.item.id);
		}
	});
	return payloadOrder;

  }
   @action getCartItemsName()
  {
    let payloadOrder = [];
    let filterCustomizeOrder = [];
    _.map(this.entries, entry => {		
		if((entry.item.isCustomize && filterCustomizeOrder.indexOf(entry.item.id)<0) || !entry.item.isCustomize )
		{
			payloadOrder.push(entry.item.name);
			filterCustomizeOrder.push(entry.item.id);
		}
	});
	return payloadOrder;

  }
  @action getSpotOrderItems(currency) {
    let payloadOrder = [];
    let filterCustomizeOrder = [];
    _.map(this.entries, entry => {		
		if((entry.item.isCustomize && filterCustomizeOrder.indexOf(entry.item.id)<0) || !entry.item.isCustomize )
		{
			payloadOrder.push({
			title: entry.item.name,
      quantity:entry.quantity,
			price: {currency: currency, value: entry.item.price}
			});
			filterCustomizeOrder.push(entry.item.id);
		}
	});
	return payloadOrder;
  }
  @action prepareWebSocketParams() {
	  
	 let wsParams={}; 
	// let tempOrderId=this.menuStore.uuidv4();
	// wsParams.tempOrderId=tempOrderId;
	 let uuid=localStorage.getItem('uuid');
	 wsParams.request=uuid;
	
	wsParams.table= this.menuStore.getRealTagValue();
	wsParams.pax ="1";
	wsParams.store={ "id":this.menuStore.mwStoreId,"name":this.menuStore.store.controller};
	wsParams.type ="amenu";
	wsParams.controller =this.menuStore.store.controller;
	wsParams.items =this.getItemsListForSocket();	
	return wsParams;
  }
  @action prepareParams(onlinePaymentPayload) {
	let payloadOrder=this.getOrderList();

    let payload = "";
    let social = localStorage.getItem(`${this.menuStore.controller}_social`);

    if (social) {
		console.log(this.menuStore.memberDiscount);
		if(this.menuStore.memberDiscount!=="" && this.menuStore.isBWCMethod())
		{
			let social1=JSON.parse(social);
			console.log(social1);
			if(social1.rewards)
			{
				social1.rewards.push(this.menuStore.memberDiscount);
				social=JSON.stringify(social1);
			}
		}
      payload += "&social=" + social;
    }
	const input_promocode = localStorage.getItem(`input_promocode`);

    if (input_promocode) {
      payload += "&input_promocode=" + input_promocode;
    }
   
    const preQuestionsInfo = localStorage.getItem(`${this.menuStore.controller}_preQuestions`);
    let uniqueArray = [];
    if (preQuestionsInfo) {
      let preQuestionsInfoArr = JSON.parse(preQuestionsInfo);
      _.map(this.menuStore.preQuestionFileds, qa => {
        if (qa.tag && uniqueArray.indexOf(qa.tag) < 0) {
          uniqueArray.push(qa.tag);
          payload += "&" + qa.tag + "=" + (preQuestionsInfoArr[qa.tag] !== undefined ? encodeURIComponent(preQuestionsInfoArr[qa.tag]) : " ");
          if (qa.save === "1") {
            preQuestionsInfoArr[qa.tag] = "";
          }
        }
      });
      let result = JSON.stringify(preQuestionsInfoArr);
      this.menuStore.savePreQuestion(result);
    }

    const postQuestionsInfo = localStorage.getItem(`${this.menuStore.controller}_postQuestions`);
    if (postQuestionsInfo && this.menuStore.postQuestionFileds.length>0) {
      let postQuestionsInfoArr = JSON.parse(postQuestionsInfo);
     
      _.map(postQuestionsInfoArr, (val,i) => {
        if(['currentStep','error','loading','lastStep','address_sub_field'].indexOf(i) < 0) {
           if(typeof val === 'object')
           {
            payload += "&" + i + "=" + encodeURIComponent(JSON.stringify(val));
           }
           else
           {
			   if(i==="input_contact" && postQuestionsInfoArr["phone_code"])
			   {
				   val=postQuestionsInfoArr["phone_code"]+val;
			   }
            payload += "&" + i + "=" + encodeURIComponent(val);
           }
           
        }
      });
      //let result=JSON.stringify(postQuestionsInfoArr);
      let result = JSON.stringify(postQuestionsInfoArr);
      this.menuStore.savePostQuestion(result);
    }
    //console.log(payload,'tttt');

    if (this.remarks !== '') {
      payload += "&remark=" + encodeURIComponent(this.remarks);
    }
    if (this.takeAway) {
      payload += "&method=takeaway";
    }
    //console.log(this.menuStore.login_option);
    let memberE = localStorage.getItem(`${this.menuStore.controller}_enable_membership_account`);
    if (this.menuStore.socialProviders.length>0) {      
       payload += "&enable_membership_account=" + memberE;
    }

    if (onlinePaymentPayload) {
      payload += "&" + onlinePaymentPayload;
    }
    payload += "&order=" + payloadOrder;
    let uuid=localStorage.getItem('uuid');
	payload += "&uuid=" + uuid;
	
	let receipt_id=localStorage.getItem('receipt_id');
	payload += "&receipt_id=" + receipt_id;
    return payload;
  }
  @action prepareOrderSummary(onlinePaymentPayload) {

    if (this.entries.length === 0) {
      return Promise.reject({
        title: this.menuStore.translation.cart_empty_prompt_title,
        content: this.menuStore.transation.cart_empty_prompt_info
      });
    }
    if (!navigator.onLine) {
      return Promise.reject({
        title: this.menuStore.translation.no_internet
      });
    }
    //console.log(navigator.onLine);
    let payload = this.prepareParams(onlinePaymentPayload);
    return new Promise((resolve, reject) => {
      API.prepareOrderRequest(payload)
        .then(res => {
          resolve(res);
          return;
        }).catch(err => {
          console.log(err);
          reject({
            title: this.menuStore.translation.error,
            content: this.menuStore.translation.error_network
          });
        });
    });
  }

  @action prepareOrderAmount(customOrderId) {
    return new Promise((resolve, reject) => {
      API.calculateTaxs(customOrderId)
        .then(res => {
          /*if(res.status!==1)
     {
     reject({
     title: this.menuStore.translation.error,
     content: this.menuStore.translation.error_network
     }); 
     }
     else{*/
          resolve(res);
          //}
        })
        .catch(err => {
          console.log(err);
          reject({
            title: this.menuStore.translation.error,
            content: this.menuStore.translation.error_network
          });
        });
    });
  }
  // verificationCode is null if not required
  @action checkout(verificationCode, onlinePaymentPayload) {
    let isNoGateway=false;
    if(onlinePaymentPayload==='nogateway')
	{
		onlinePaymentPayload='';
		isNoGateway=true;
	}
    if (this.entries.length === 0) {
      return Promise.reject({
        title: this.menuStore.translation.cart_empty_prompt_title,
        content: this.menuStore.transation.cart_empty_prompt_info
      });
    }
    if (!navigator.onLine) {
      return Promise.reject({
        title: this.menuStore.translation.no_internet
      });
    }
    //console.log(navigator.onLine);
    let payload = this.prepareParams(onlinePaymentPayload);

    const cachedCode = localStorage.getItem(
      `${this.menuStore.controller}_code`
    );

    if (cachedCode) {
      verificationCode = JSON.parse(cachedCode).code;
    }
    return new Promise((resolve, reject) => {
      API.createOrder(payload, verificationCode)
        .then(res => {			
			
			
          if (res.indexOf("PHP Error") !== -1 || res.length === 0) {			 
            reject({
              title: this.menuStore.transation.error,
              content: this.menuStore.transation.error_network
            });
          } else {
			const orderIds = res.split(":")
			if(orderIds[0]==="success")
		    {
				this.menuStore.resetPostQA();
				localStorage.removeItem("is_opened_post_question");
				localStorage.removeItem("input_promocode");

				const orderId = orderIds[1];
                let spotOrderId="";
				
				if((onlinePaymentPayload.toString().indexOf('"method":"cash"') > 0 || isNoGateway) && this.menuStore.isSpotMethod())
				{
				this.menuStore.spotOrderObj.orderId=orderId;
                this.menuStore.spotOrderObj.total.value=this.total.toString();						
				this.menuStore.callSpotCreateOrder();
				 spotOrderId=orderId;
				}
				
				this.orderStore.addOrder({
				  id: orderId,
				  status: null, // defaulted to pending status
				  cartEntries: this.entries,
				  table:this.menuStore.getRealTagValue(),
				  spotOrderId:spotOrderId
				});

			
                let tamount=this.total;	
				// Empty the cart
				this.empty();
				resolve({
				  title: this.menuStore.translation.success,
				  content: `${this.menuStore.translation.checkout_response
					} ${orderId}`,
				  orderId: orderId
				});
                this.menuStore.sendGoogleAnalyticsOrder(tamount,orderId);				
			}
			else
			{
				reject({
				title: this.menuStore.translation.error,
				content: res!==""?res:this.menuStore.translation.error_network
				});
			}
          }
        })
        .catch(err => {
          console.log(err);
          reject({
            title: this.menuStore.translation.error,
            content: this.menuStore.translation.error_network
          });
        });
    });
  }
  @action resetEntries() {
    localStorage.setItem(`${this.menuStore.controller}_cart`, JSON.stringify(this.entries));
  }
  @action empty() {
    _.each(this.entries, entry => {
      this.quantity_map.set(entry.item.id, 0);
    });

    this.entries = [];

    localStorage.setItem(`${this.menuStore.controller}_cart`, JSON.stringify(this.entries));
    this.menuStore.notAvailable = [];
  }
  @action checkQuantityValidation(item,qtyNew)
  {
	  let tagObj = this.menuStore.checkTagAction(); 
	 
	  let qty=(this.size+qtyNew);
	 
	  let freeQty=this.freeQty;
	   //console.log('cart store=>',qtyNew,'=>',freeQty,'qty=>',this.size);
	  let price=parseFloat(item.price);
	  if(price===0)
	  {
		  freeQty=freeQty+qtyNew;
	  }
	  let erroMsg="";
      if(price===0 && tagObj.max_free_qty && tagObj.max_free_qty>-1 && freeQty>tagObj.max_free_qty){
		 erroMsg=tagObj.max_free_qty_message; 
	  }	
	  if(erroMsg==="" && tagObj.max_qty && tagObj.max_qty>-1 && qty>tagObj.max_qty){
		 erroMsg=tagObj.max_qty_message; 
	  }  		   
	  //console.log(qtyNew,'=>',freeQty,'qty=>',qty);
	  return erroMsg;
  }
  @action getAddonIndex(addonList) {
   let ind=0;
    _.each(addonList, (addonGroup, mainIndex) => {
		if(ind===0)
		{
			   let keys = Object.keys(addonList[mainIndex]);
			_.each(keys, (key, index) => {
			_.each(addonList[mainIndex][key], (addon, addonIndex) => {
				
			    if(addon.quantity>0)
				{
					ind=mainIndex;					
				}

			  });
			});
		}
    });
    return ind;
  }
  @action getAddonsQty(addons)
  {
	let checkIndex=this.getAddonIndex(addons);
	let tQty = 0;
		_.each(addons, (addonList, addonListIndex) => {	
         if(checkIndex===addonListIndex)
		 {	 		 
			let keys = Object.keys(addonList);
			_.each(keys, (key, index) => {
			_.each(addonList[key], (addon, addonIndex) => {
			tQty += addon.quantity;

			});
			});
		 }
	});
    return tQty;
  }

  @action getSetMealQty(addonList)
  {
    let qty=0;
    _.each(addonList, (addonGroup, ind) => {
    _.each(addonGroup, (addon, index) => {
      qty+=addon.quantity;
    });
  });
    return qty;
  }
  @action checkTotoitoastOutlet() {
    let status='';
    let error="";
    _.each(this.entries, entry => {
      let status1=this.menuStore.checkPriceDisplay(entry.item);
      if(status!=="" && status1!==status)
      {
        error="error";
      }
      status=status1;
    });
    if(error!=="")
    {
        return error;
    }
    return status;
  }
}

export default CartStore;
