import React from "react";
import { Modal} from "antd-mobile";
import { observer, inject } from "mobx-react";
import _ from "underscore";
import HeaderPopup from "./HeaderPopup";
import MenuDate from "./MenuDate";
import PowerBy from "./PowerBy";

@inject("cartStore")
@observer
class Home extends React.Component {
	constructor(props) 
	{
       super(props);
	   this.imageInterval=null;
       this.state = {images: [] };
     }
	componentDidMount () {
        if (this.props.onMounted) {
            this.props.onMounted({
                reloadModal: res => this.reloadModal(res)
            })
        }
			
		//this._refreshImages();
		
	 }
	reloadModal=(res)=>
	{
		//just reload the modal
		if(res===2)
		{
		this._refreshImages();
		 console.log('home called');
		}		
		
		
	}
_refreshImages()
{   
   // const { cartStore } = this.props;
		  
		this._setImages();
		clearInterval(this.imageInterval);
		if(this.props.cartStore.menuStore.isHomeModalVisible)
		{	     		
		this.imageInterval=setInterval(() => {
		this._setImages();
		},5000);
		}
     	   
}
_getPromoImage(promo)
{
	  let tagAtObj=this.props.cartStore.menuStore.checkTagAction();
	  let img='';
      let p=promo.toLowerCase();
     
	  if(tagAtObj.promo)
	  { 
           let promoSplit = tagAtObj.promo.split(',');
		  if(promoSplit.length>0)
		  {	 
			_.forEach(promoSplit, function(prom) {
				let promAr=prom.split('#');
				if (promAr[0]===p) {
				 if(promAr[1])
				 {
					 img=promAr[1];
				 }
				return;
				} 
			
			});				  
		  }
	  }
	return img; 
	  
}
_setImages()
{
	//console.log(777);
	const { cartStore } = this.props;
  	let state=this.state;
	    
	_.map(cartStore.menuStore.promotions, item => {
       
		let index=cartStore.menuStore.promotions.indexOf(item);
		//let data=cartStore.menuStore.promos[item];
		let pname=item.toLowerCase();
		let img=this._getPromoImage(item);
		let fimage="";
		if(pname!=="" && img!=="")
		{
			fimage=img;
		}
		else{
			let data=this._getItemObj(item); 					
			let num=Math.floor(Math.random() * Math.floor(data.length));
			let imgObj=data[num];
			fimage=imgObj.thumbnail;
		}
      // console.log(item,img,'=>',pname);		
		
		state.images[index]=fimage;	
	});
	this.setState({state});
}
_getItemObj(item)
{
	let allData=this.props.cartStore.menuStore.promos[item];
	let data = _.filter(allData, item => {
				//console.log(item.chef1===1);
	     return item.chef1===1;
			
          });
 
	 /*data = _.pick(data, val => {
        return val;
      });*/
    //console.log(data.length,item);
	if(data.length===0)
	{
		data.push(allData[0]);
	}
	//console.log(data,'ll',data.length);
	return data;
}
_getPromoboxHtml(index,avail,displayTimeText,item)
{
	        return (
               <li className="promoList" key={"promo-"+index}>
			   <div className="inneList"
			   onClick={() => {
                    if(avail)
					{	
                        
                         this.props.cartStore.menuStore.horizontalMenuSelectedKey= item;					   
						/*if(this.props.cartStore.menuStore.menu_type!=='2')
						{
						    var ele = document.querySelectorAll(".am-accordion-header");
						    ele[index].click();	
						}
						else
						{	*/
					         this.props.cartStore.menuStore.activePromo=index;
                             this.props.cartStore.menuStore.resetMeuList();
                             this.props.cartStore.menuStore.searchResult =this.props.cartStore.menuStore.filterMenuList;
					          let ele = document.querySelectorAll(".am-accordion-header");
					          if(ele.length>0)
							  {
                               let elem=ele[index];								  
						        elem.style.cursor = "pointer";
								elem.click();								
							  }		  
                            
					   // }
						
					var catsheader = document.querySelectorAll(".menuHeader");
                    catsheader[0].scrollIntoView({  block: "start",inline: "nearest", behavior: "smooth" });
					this.props.cartStore.menuStore.isHomeModalVisible =false;
					 if(this.props.cartStore.menuStore.is_popup_menu==="1" && this.props.cartStore.menuStore.menu_type!=='2')
					 {
						 document.getElementById("menuContainer").classList.remove('toggleMenuCls');
						  document.getElementById("menuContainer").classList.add('toggleMenuCls');
					 }
					 clearInterval(this.imageInterval);
					}				    
				}}		   
			   
			   >
			   <div className={avail?"imgStr":"imgStr notAvai"}>
			   <img src={this.state.images[index]}
			    className={"promoImage"}
			    id={"promoImage-"+index}
                alt="promo"				
			   /> 
			       {displayTimeText!=="" ?(
				   <div className="heading" >{displayTimeText}</div>
				   ):('')}
				 
			   </div>
			     <div className="sponso">   
				   <div className={avail?"spofoot":"spofoot notAvai"} style={{textTransform: 'capitalize'}}><span>{item}</span></div>
				 </div>
			   </div>
			   </li>
        );
}
_renderPromo(datas)
{
    const { cartStore } = this.props;
    if (datas.length === 0) {
      return ( '' );
    } else {
		let firstCall=0;
     	let existingImage=this.state.images.length;
		let avialArra=[];
		let notAvailArray=[];
		// let tagAtObj=this.props.cartStore.menuStore.checkTagAction();
		// let img='';
		// let pList=[];
		// if(tagAtObj.promo)
		// {
			// let promoList=tagAtObj.promo.split('#');
             // if(promoList[1])
			 // {
				 // img=promoList[1];
			 // }				 
			// promoList=promoList[0];
			// pList=promoList.split(',');
			
		// }
       _.map(datas, item => {
		    let index=datas.indexOf(item);
		   // let data=cartStore.menuStore.promos[item]; 
		    let data=this._getItemObj(item); 
			//console.log(data1[0],'hh');
		    let fItem=data[0]; 
            let avail=cartStore.menuStore.isAvailable(fItem);
			if(avail && firstCall===0)
			{
				firstCall=1;
				this.props.cartStore.menuStore.activePromo=index;
			}
		   let displayTimeText=this.prepareTimeString(avail,fItem);
		   if(existingImage===0)
		   {
			   //let pname=item.toLowerCase();
			    let fimage=fItem.thumbnail;			   
				let pname=item.toLowerCase();
				let img=this._getPromoImage(item);
				if(pname!=="" && img!=="")
				{
				fimage=img;
				}					  
		       this.state.images.push(fimage);
	       }
		   let html=this._getPromoboxHtml(index,avail,displayTimeText,item);
		   if(avail)
		   {
			   avialArra.push(html);
		   }
		   else{
			   notAvailArray.push(html);
		   }

      });
	  let tagObj = cartStore.menuStore.checkTagAction();
	  if(tagObj.display==="0" || parseInt(tagObj.display)===0)
	  {
		return avialArra;
	  }
	  return avialArra.concat(notAvailArray);	  
    }
  }
    getTimeFormat(item)
	{
		            if(item.startTime>0 && item.endTime>0)
					{
		            let sMins=((item.startTime%3600)/60).toString();
					//let startTime=(parseInt(item.startTime/3600)+':'+(sMins.length===1?'0'+sMins:sMins));
					let startTime=(parseInt(item.startTime/3600)+(sMins.length===1?'0'+sMins:sMins));

					let eMins=((item.endTime%3600)/60).toString();
					//let endTime=(parseInt(item.endTime/3600)+':'+(eMins.length===1?'0'+eMins:eMins));
					let endTime=(parseInt(item.endTime/3600)+(eMins.length===1?'0'+eMins:eMins));

					let start = this.props.cartStore.menuStore.convertTime(startTime);
					let end = this.props.cartStore.menuStore.convertTime(endTime);

					return `${start} to ${end}`;
					}
					else{
						return '';
					}
	}
	prepareTimeString(avail,item)
	{
		 if(!avail)
		 {
			// console.log(item);
			        let allDays=['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
			       let weekday = allDays[new Date().getDay()];
					let datStr='day'+weekday;
					//console.log(item.days);
					const timestamp = Date.now();
					if(timestamp>item.endDate && item.endDate!==0)
					{
						return this.props.cartStore.menuStore.translation.not_available;
					}
					else if(!this.props.cartStore.menuStore.isPreOrder && item.days!==undefined && item.days[datStr]===1 )
					{
						return this.getTimeFormat(item);
						
					}
					else
					{						
						return this.props.cartStore.menuStore.translation.not_available;
					}
					
			
		 }
		 else
		 {
					return this.getTimeFormat(item);
		 }
		
	}		
			   
   render = () => {
	   return (	
      <Modal
        visible={this.props.visible}
        style={{ overflow: "hidden", height: "100%", textAlign: "center" }}
        className="homeModal newModal" 
		//onAfterOpen={() => {console.log('modal2'); }}	
		//afterClose={() => { console.log('afterClose'); }}
      >
	         <HeaderPopup toggleTag={this.props.toggleTag}/>
			 <MenuDate mdateTime={this.props.cartStore.menuStore.getMenuDateTime()}/>
	         <div className="homeWrap">			    
			    <ul>
				{this._renderPromo(this.props.cartStore.menuStore.promotions)}
				
               </ul>
			   <PowerBy/>
          </div> 
      </Modal>
    );	
  };	
}

export default Home;
