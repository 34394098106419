import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { Modal, Button, List } from "antd-mobile";
import _ from "underscore";
import { Icon } from "antd";
import moment from "moment";
import {RenderAddon,RenderNotes } from "./Addon";

const TOP_HEIGHT = 40;
const BOTTOM_HEIGHT = 40;
const Item = List.Item;
const Brief = Item.Brief;

class SuccessInfo extends React.Component {

  renderItems()
  {
	  let {cartStore,order}=this.props;
  
	  return _.map(order.cartEntries, entry => {
			return (
			  <Fragment key={entry.item.id}>
				<OrderItem entry={entry} cartStore={cartStore} />
			  </Fragment>
			);
		});
  } ;
  render() {
    let {cartStore,order}=this.props;
    let showRepeatBtn=cartStore.menuStore.showRepeatBtn;
    
    let widper="100%";
    let isCheck=cartStore.menuStore.getDiffeOrderTime(order);
   if(isCheck && showRepeatBtn)
   {
    widper="33%";
   }
   else if(isCheck || showRepeatBtn){
    widper="50%";
   }
   let receipt={};
   try
   {
	 receipt=JSON.parse(order.priceReceipt);  
   }
   catch (err) {
         
    }
	let totalamt=receipt.total?receipt.total.toFixed(2):"0.00";
		if(totalamt<0)
		{
			totalamt="0.00";
		}
	let html=this.props.receipt;	
    if(order.isSocketAPi===true && html && html!=="")
	{
		html=encodeURIComponent(html);
	}
    return (
      <Modal
        visible={this.props.visible}
        style={{ overflow: "hidden", height: "100%", textAlign: "left",wordBreak:"break-all" }}
		className="successModel"
      >
        <div
          style={{
            position: "fixed",
            top: 0,
            height: `${TOP_HEIGHT}px`,
            width: "100%"
          }}
        >
		
		{cartStore.menuStore.layout===4?(
		<div className="info_header">
		<span className="backBtn"
		onClick={()=>{this.props.closeModal();}}
		><Icon key="1" type="back" style={{ fontSize: '25px', color: '#fff' }}  
				
				/></span>
		<span className="logoclass">			
		<img src={cartStore.menuStore.getLogo()} alt="logo" style={{ height: "40px" }}
          onClick={()=>{this.props.closeModal();}}
        />
		</span>
		<span className="homeclass">
		<Icon key="1" type="home" style={{ fontSize: '25px', color: '#fff' }}  
				onClick={()=>{this.props.closeModal();}}
				/> </span></div>
		):(<div className="info_header">
            {this.props.translation.success}
            <span
              className="info_close"
              onClick={() => {
                this.props.closeModal();
              }}
            >
              {this.props.translation.x}
            </span> </div>
		  )}
         
          <div
            style={{
              position: "fixed",
              overflow: "scroll",
              height: "calc(100% - 170px)",
              width: "100%"
            }}
			className="thankyouscreen"
          >
			{cartStore.menuStore.layout===4?(<div style={{ paddingTop: 15, maxWidth: 480, margin: "0 auto" }} className="orderItems">
				{order.isNew &&(<div className="thanksclass"><span className="tickicon"></span><span className="welcomemsg"><h2>Thank you!</h2>Your order has been placed.</span></div>)}
			   <p className="dateheading"><span className="orderids">Order ID {order.id} </span><span className="dateorder">{moment(order.time,'DD/MM/YYYY HH:mm:ss').format('DD MMM, HH:mm')}</span></p>
			   {this.renderItems()}
			   <div className="priceInfo">
					   <div className="priceRow"><span>Sub Total</span><span>${receipt.sub?receipt.sub.toFixed(2):0.00}</span></div>			   
					   {receipt.taxBefore && receipt.taxBefore.toFixed(2)>0 ?(<div className="priceRow"><span>{receipt.taxBefore_name?receipt.taxBefore_name:"Service Charge"}</span><span>${receipt.taxBefore?receipt.taxBefore.toFixed(2):0.00}</span></div>):('')}
					  <div className="priceRow"><span>{receipt.discount_name?receipt.discount_name:"Discount"}</span><span>-${receipt.discount?Math.abs(receipt.discount.toFixed(2)):0.00}</span></div>	
                       {receipt.discount_by_value && ( <div className="priceRow"><span>{receipt.discount_by_value_name?receipt.discount_by_value_name:"Discount"}</span><span>-${receipt.discount_by_value?Math.abs(receipt.discount_by_value.toFixed(2)):0.00}</span></div>)}					  
					  <div className="priceRow"><span>{receipt.tax1_name?receipt.tax1_name:"GST"}</span><span>${receipt.tax1?receipt.tax1.toFixed(2):0.00}</span></div>
					  <div className="priceRow"><span className="priceLabelTotal">{receipt.total_name?receipt.total_name:'Total'}</span><span className="priceavlue">${totalamt}</span></div>					 
			   </div>
			</div>
			):
			(<div style={{ paddingTop: 15, maxWidth: 480, margin: "0 auto" }}><div
			style={{ padding: 9 }}
			dangerouslySetInnerHTML={{
			__html: decodeURIComponent(html)
			}}
			/> </div>)}
			
			
			
          </div>
          <div
            className="bottom"
            style={{
              position: "fixed",
              height: `${BOTTOM_HEIGHT}px`,
              bottom: 0,
              paddingTop: 6,
              width: "100%"
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: 0,
                padding: 3,
                width: "100%"
              }}
			  
            >
              { cartStore.menuStore.layout!==4 &&(<Button
                 className="bottomBtn"
                style={{width:widper,float:"left"  }}
                onClick={() => {
                  this.props.closeModal();
                }}
              >
                {cartStore.menuStore.customTexts['order_received']!== undefined && cartStore.menuStore.customTexts['order_received']!=="" ?cartStore.menuStore.customTexts['order_received']: this.props.translation.close}
              </Button>)}
              {isCheck?( 
              <Button
              className="bottomBtn"
                style={{ width:widper,float:"left"  }}
                onClick={() => {
                  this.props.checkOrderModal();
                }}
              >
                {this.props.cartStore.menuStore.translation.last_order_btn}
              </Button>):('')}             
              {showRepeatBtn && cartStore.menuStore.layout!==4 && (
			  <Button
                className="bottomBtn"
                style={{ width:widper }}
                onClick={() => {					
				  this.props.repeatOrderFun();	
                  this.props.closeModal();
                }}
              >
                {this.props.cartStore.menuStore.translation.repeat_order_btn}
              </Button>)}
			  
			  {cartStore.menuStore.layout===4 && (
			  <Button
                className="bottomBtn"
                style={{ width:widper }}
                onClick={() => {			  
                  this.props.closeModal();
                }}
              >
                {this.props.cartStore.menuStore.translation.close}
              </Button>)}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const OrderItem = observer(({ entry, cartStore }) => {
  let item = entry.item;
 // let isDisplayPrice=cartStore.menuStore.checkPriceDisplay(item);
   let total=cartStore.entryTotal(entry);
   let {actualPrice,priceAmt}=cartStore.calculateRewardDiscoutPrice(entry.reward,total);
  //let priceTx = parseFloat(item.price) === 0 ? cartStore.menuStore.translation.price_zero : item.priceName;
  let extratag = actualPrice!==priceAmt?(<p><strike className="strikeprice">{cartStore.menuStore.currencySymbol+parseFloat(actualPrice).toFixed(2)}</strike>{cartStore.menuStore.currencySymbol+parseFloat(priceAmt).toFixed(2)}</p>):(
				<p>{cartStore.menuStore.currencySymbol+parseFloat(priceAmt).toFixed(2)}</p>);

let reward=	entry.reward;
let rewardDiv="";
if(reward && reward.redeem_code && reward.redeem_code!=="")
{
	rewardDiv=<div className="disNameBg">{reward.redeem_name}</div>;
}

//let itemNotes= cartStore.getAddonsNamesList(entry.addons); 
  return (
    <List>
      <Item
        wrap
        className="cart_item success_cart"
        thumb={<CartImg entry={entry} cartStore={cartStore} />}
        extra={extratag}
      ><div className="cartItemName"
      >
          <div className="innerItemName">
            <p>{item.name}</p>
            {rewardDiv}   			
            <Brief>                             
					<RenderAddon addonSlected={entry.addons} />
					<RenderNotes  entry={entry}/>
            </Brief>
             
          </div>
        </div>
      </Item>
    </List>
  );
});
const CartImg = observer(({ entry, cartStore }) => {
  return (
    <div className="orderItemImage">
        <img
          src={entry.item.thumbnail}
          alt=""
        />
		<span>x{entry.addons.length}</span> 
    </div>
  );
});
export default SuccessInfo;
