function queryString(obj) {
  let qs = [];
  for (let key in obj) {
    qs.push(`${key}=${obj[key]}`);
  }
  return qs.join("&");
}

const Request = {
  get(url, params) {
    return new Promise(function(resolve, reject) {
      let qs = params === undefined ? "" : "?" + queryString(params);
      let r = new XMLHttpRequest();
      r.onreadystatechange = function(data) {
        if (r.readyState === 4 && r.status >=200 && r.status<405) {
          let response = r.responseText;
          try {
            response = JSON.parse(r.responseText);
          } catch (err) {}

          resolve(response);
        } 
        else if (r.readyState === 4)
        {
            console.log(r);
            //console.log(r.responseText);
            reject(r.responseText);
          }
      };
      r.open("GET", `${url}${qs}`);
      r.send();
    });
  },
  getGZipApi(url, params) {
    return new Promise(function(resolve, reject) {
      let qs = params === undefined ? "" : "?" + queryString(params);
      let r = new XMLHttpRequest();
      r.open("GET", `${url}${qs}`, true);
	  
      r.addEventListener('load', function(ev) {
		  try{
        const response = new window.Zlib.Gunzip(new window.Uint8Array(ev.target.response)).decompress();
        let str = "";
        for (let i=0; i < response.byteLength; i++) {
          str += String.fromCharCode(response[i]);
        }
		
        const res = JSON.parse(str);
        resolve(res);
		 }
		catch (err) {
			reject({error:'outlet'});
		}
      });
      r.responseType = 'arraybuffer';
      r.send();
	 
    });
  },
  post(url, params) {
    return new Promise(function(resolve, reject) {
	//	console.log(params);
      //let qs = params === undefined ? "" : "?" + queryString(params);
      let r = new XMLHttpRequest();
	 
	 r.open('POST', url, true);
      r.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
	  
      r.onreadystatechange = function(data) {
        if (r.readyState === 4 && r.status >=200 && r.status<400) {
          let response = r.responseText;
          try {
            response = JSON.parse(r.responseText);
          } catch (err) {
			  
		  }
        resolve(response);
        } else if (r.readyState === 4)
          {

            reject(r.responseText);
          }
      };
	  
      //r.open("POST", `${url}${qs}`);
      r.send(params);
    });
  },
  postJson(url, params, method) {
    return new Promise(function(resolve, reject) {
	
      let r = new XMLHttpRequest();	 
	 r.open(method, url, true);
      r.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
	  
      r.onreadystatechange = function(data) {
        if (r.readyState === 4 && r.status >=200 && r.status<400) {
          let response = r.responseText;
          try {
            response = JSON.parse(r.responseText);
          } catch (err) {
			  
		  }
        resolve(response);
        } else if (r.readyState === 4)
          {

            reject(r.responseText);
          }
      };
      r.send(JSON.stringify(params));
    });
  },
/*generateShA256(sigString)
{
	return window.SHA256(sigString);
}*/
}
export default Request;
