import React, { Fragment } from "react";
import {Button} from "antd-mobile";
import { observer, inject } from "mobx-react";
import { FaMapMarkerAlt,FaPhone } from "react-icons/fa";
import { Icon } from "antd";
@inject("cartStore")
@observer
class HeaderPopup extends React.Component {
  
  render() {
	  let logo=this.props.cartStore.menuStore.getLogo();
    return (
	<Fragment>
	{this.props.cartStore.menuStore.menu_type!=='4'?(
          <div className="div-header"> 
		  <Icon key="1" type="search"  style={{fontSize: '25px', color: '#08c' }}  
		  onClick={()=>{
			  this.props.cartStore.menuStore.isHomeModalVisible =false;
			  this.props.cartStore.menuStore.resetMeuList();
              this.props.cartStore.menuStore.searchResult =this.props.cartStore.menuStore.filterMenuList;
			  document.getElementById("searchIcon").click();
		  }}
		  
		  />
		  
		  {this.props.cartStore.menuStore.store.allLang!==undefined && this.props.cartStore.menuStore.store.allLang.split('|').length>1 && this.props.cartStore.menuStore.isLoginModalVisible?
				(
				<Button
				className="button-style1"
				onClick={() => {                  
				this.props.changeLanguage();
				}}             
				>  {this.props.cartStore.menuStore.translation.menu_option_language} > </Button>
				):('')}
		  
		       {!this.props.cartStore.menuStore.isLoginModalVisible?
				(
				 <Icon key="2" type="bars"  style={{fontSize: '25px', color: '#08c',float:'right',padding:'10px',paddingTop:'0px' }}   onClick={this.props.toggleTag}/>
				):('')}
				
		     </div>	):('')}
				
				<div className="loginheader">
				{logo?(
				<div className="logorew">
				<img
				src={logo}
				alt={this.props.cartStore.menuStore.store.name}           
				/>
				</div>
				):('')}
				
				<h1 dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(this.props.cartStore.menuStore.store.name) }} />
				<ul className="iconsloginpage">
				{this.props.cartStore.menuStore.store.addressUrl!==undefined && this.props.cartStore.menuStore.store.addressUrl!==''?( <li><a href={this.props.cartStore.menuStore.store.addressUrl} target="_blank"  rel="noopener noreferrer"><FaMapMarkerAlt color={"#666"} size={16} /></a></li>
				):('')}
				
				{this.props.cartStore.menuStore.store.contactUrl !==undefined && this.props.cartStore.menuStore.store.contactUrl !==''?( <li><a href={this.props.cartStore.menuStore.store.contactUrl} target="_blank"  rel="noopener noreferrer"><FaPhone color={"#666"} size={16} /></a></li>
				):('')}
				
               </ul>				
				</div>
				</Fragment>
    );
  }
}
export default HeaderPopup;
