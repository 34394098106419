import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { engineName, engineVersion, deviceDetect } from "react-device-detect";

import CartStore from "./stores/CartStore";
import MenuStore from "./stores/MenuStore";
import OrderStore from "./stores/OrderStore";

const menuStore = new MenuStore();
const orderStore = new OrderStore(menuStore.translation);
const cartStore = new CartStore(menuStore, orderStore);

function isSupported() {
  console.log(engineName + " " + engineVersion);
  if (engineName === "WebKit" && parseFloat(engineVersion) < 537.36) {
    return false;
  } else if (engineName === "Gecko" && parseFloat(engineVersion) < 65) {
    return false;
  }
  return true;
}

function NotSupported() {
  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      Your browser is outdated. Please use the latest version of Chrome Browser
      <span style={{ color: "white" }}>
        {engineName} {engineVersion} - {JSON.stringify(deviceDetect())}
      </span>
    </div>
  );
}

ReactDOM.render(
  <Provider cartStore={cartStore} orderStore={orderStore}>
    {isSupported() ? <App /> : <NotSupported />}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();
