import React, { Fragment } from "react";
import { Button, Modal, List, InputItem, Icon } from "antd-mobile";
import { FiShoppingCart, FiPlus } from "react-icons/fi";
import { observer, inject } from "mobx-react";
import { AddonSingle, AddonMultiple, AddonFixed,ModifierSingle,RenderAddon } from "./Addon";
import Recomendations from "./Recomendations";
import _ from "underscore";
import "../custom.css";
import { set } from "mobx";
import { FaTrashAlt, FaPaperclip } from "react-icons/fa";
import FoodStepper from "./FoodStepper";
import Sharing from "./Social/Sharing";
import { Collapse } from 'antd';
const Item = List.Item;
const Brief = Item.Brief;
const TOP_HEIGHT = 40;
const BOTTOM_HEIGHT = 40;
//const EXP_TRIM =/[&/\\#,+()$~%.'":*?<>{} ]/g;
const alert = Modal.alert;
const { Panel } = Collapse;

  
// We reference menuStore.itemModal.item to get the details
@inject("cartStore")
@observer
class ItemInfo extends React.Component {
  
  _getSetMealErrorMsg=(qty)=>{

		const { itemModal } = this.props.cartStore.menuStore;
           let isCloseClicked=false;	
            if(qty==='minus')
			{				
				if(itemModal.item.hasAddons && itemModal.item.isCustomize)
				{
				this._addItems();
				}
				return false;
			}
            if(qty==='checked')
			{
				isCloseClicked=true;
				qty=0;
				if(itemModal.isUpdate)
				{
					this._addItems();
				}
				
			}				
		    if(qty==='close')
			{
				isCloseClicked=true;
				qty=0;
			}
			
			let diff=qty;
            let qty1=0; 			
			if(qty<0)
			{	
                qty=Math.abs(qty);							
                
				if(itemModal.item.hasAddons && itemModal.item.isCustomize)
				{
				 // qty1=itemModal.checkTotalAddonsQuantity()	;
				  qty1=this.props.cartStore.getAddonsQty(itemModal.addons);	
				  diff=1;
				  if(qty1>0)
				  {
					 this._addItems(); 
				  }
				  
				  //qty1=itemModal.checkTotalAddonsQuantity();
				}
				// else{
				// qty1=itemModal.item.addons.length;
				// }			
				
			}
			let entry=this.props.cartStore.getEntry(itemModal.item);
			if(entry!==null)
			{
			qty1=this.props.cartStore.quantity_map.get(itemModal.item.id);
			    if(itemModal.isUpdate)
				{
					 diff=qty-qty1;
				}			    
			}		
            		
			if(diff<0)
			{
			  diff=1;
			}
			
			if(isCloseClicked){
				diff=0;
			}
	
	   let erroMsg=this.props.cartStore.checkQuantityValidation(itemModal.item,diff);
	   if(erroMsg!=="")
	   {
			alert(this.props.cartStore.menuStore.translation.error, erroMsg, [
			{ text: "Close", onPress: () => { } }
			]);
	   }
	   return erroMsg;
  };
  
  _addItems=()=>{
     const { itemModal } = this.props.cartStore.menuStore;	  
	if(itemModal.item.isCustomize)
	{
	    //let tQty=itemModal.checkTotalAddonsQuantity();
		let tQty=this.props.cartStore.getAddonsQty(itemModal.addons);
		if(tQty>0)
		{
			let entry=this.props.cartStore.getEntry(itemModal.item);
			if(!entry && itemModal.isUpdate)
			{			
				this.props.cartStore.addItem({
                            item: itemModal.item,
                            addons: itemModal.addons,
                            notes: itemModal.notes
                          });	
			}			
			this.props.cartStore.quantity_map.set(itemModal.item.id, tQty);
			
		}
		 else{
			 this.props.cartStore.removeItem(itemModal.item); //removing while edit
		 }
		
	}
	this.props.cartStore.resetEntries();
  }
  // Generate the header for each addon
  _renderAddons() {
    // To automate the sorting using object keys
    const groupedTemplate = {};
    const template = [];
    const { itemModal } = this.props.cartStore.menuStore;
	const isCustmize=itemModal.item.isCustomize;
    // console.log(itemModal.item.addons);
    // If the item has no addons, just skip addon portion
    if (itemModal.item.addons) {
      // Handle the items defaulted in a set menu first (So it appears on the top)
      if (itemModal.item.addons && "undefined" in itemModal.item.addons) {
        _.each(itemModal.item.addons["undefined"], (addon, index) => {
          template.push(
            <AddonFixed
              key={`fixed_${index}`}
              addon={addon}
              price_zero={this.props.cartStore.menuStore.translation.price_zero}
			  isCustomize={isCustmize}
			  screen='iteminfo'
            />
          );
        });
      }
       if(itemModal.addons.length>1 && itemModal.item.isCustomize)
	   {
		  let entry=this.props.cartStore.getEntry(itemModal.item);
		  if(entry)
		  {
			  for(let k=0;k<(itemModal.addons.length-1);k++)
			  {
				 entry.removeAddon(k);  
			  }		 
		  }
		  // let tQty=this.props.cartStore.quantity_map.get(itemModal.item.id);
           // this.props.cartStore.quantity_map.set(itemModal.item.id, tQty);
	   }
      // Iterate through the cart which stores the items which contains the addons
	 // let checkIndex=this.props.cartStore.getAddonIndex(itemModal.addons);
      _.each(itemModal.addons, (addonList, addonListIndex) => {
		  
      // if((itemModal.item.isCustomize && addonListIndex===checkIndex) || !itemModal.item.isCustomize)
	  // {	
  
        let keys = Object.keys(addonList);
        _.each(keys, (key, index) => {
          let tQty = 0;
          let leftQty = parseInt(addonList[key][0].min);
          _.each(addonList[key], (addon, addonIndex) => {
            tQty += addon.quantity;

          });
          leftQty = leftQty - tQty;

          const groupedElement = [];
          if (key !== "undefined") {

            groupedElement.push(
              <div
                className="addonIndexNumber"
                key={`${addonListIndex}_${index}`}
              >
                <span className="spanItemInfo"> {key.toUpperCase()}
                  {addonList[key][0].max === 0
                    ? ""
                    : addonList[key][0].max === 1
                      ? ""
                      : ` (limit ${addonList[key][0].max})`}</span>
                {addonList[key][0].min > 0 ? tQty >= addonList[key][0].min ? <span className="requiredText">&#10004; {this.props.cartStore.menuStore.translation.required_text}</span> : <span className="requiredText bluetext">({leftQty} {this.props.cartStore.menuStore.translation.required_text.toUpperCase()})</span> : ''}
              </div>
            );

            // Add the individual addon items
            _.each(addonList[key], (addon, addonIndex) => {
              if (addon.max !== 1) {
                groupedElement.push(
                  <AddonMultiple
                    key={`${addonListIndex}_${addon.id}`}
                    addon={addon}
                    handler={quantity => {
			         itemModal.addonQuantityHandler(
                        addonListIndex,
                        key,
                        addonIndex,
                        quantity
                      );
                      this.props.cartStore.resetEntries();
                    }}
                    price_zero={
                      this.props.cartStore.menuStore.translation.price_zero
                    }
					isCustomize={itemModal.item.isCustomize}
					screen='iteminfo'
					getSetMealErrorHandler={this._getSetMealErrorMsg}
                  />
                );
              } else {
                groupedElement.push(
                  <AddonSingle
                    key={`${addonListIndex}_${addon.id}`}
                    addon={addon}
                    handler={quantity => {
                      itemModal.singleAddonQuantityHandler(
                        addonListIndex,
                        key,
                        addonIndex,
                        quantity
                      );
                      this.props.cartStore.resetEntries();
                    }}
                    price_zero={
                      this.props.cartStore.menuStore.translation.price_zero
                    }
					isCustomize={itemModal.item.isCustomize}
					screen='iteminfo'
					getSetMealErrorHandler={this._getSetMealErrorMsg}
                  />
                );
              }
            });

            // Set the groupOrder as key and groupedElement array as value in groupedTemplate dictionary so it sorts automatically
            groupedTemplate[
              `${addonList[key][0].groupOrder}${addonList[key][0].groupName}`
            ] = groupedElement;
          }
        });

        template.push(
          <div
            id={`addon_${addonListIndex}`}
            key={addonListIndex}
            className="addonGroup addongrpbg"
            style={{              
              padding: 4,
              paddingLeft: 12,
              textAlign: "left",
              color: "white"
            }}
          >
            {addonListIndex !== 0 && (
              <span
                style={{ textAlign: "left", paddingRight: 6, fontSize: 12 }}
                onClick={() => {
                  itemModal.removeAddon(addonListIndex);
                }}
              >
                <FaTrashAlt />
              </span>
            )}
            Set {itemModal.isUpdate ? "" : addonListIndex + 1}
          </div>
        );
        for (let key in groupedTemplate) {
          template.push(groupedTemplate[key]);
        }

        template.push(
          <InputItem
            key={`specialRequest_${addonListIndex}`}
            className="addonList"
            ref={ref => (this.specialRequest = ref)}
            style={{ width: "100%" }}
            // placeholder={"Enter Special Request"}
            placeholder={itemModal.item.opt1 !== '' ? itemModal.item.opt1 : this.props.cartStore.menuStore.translation.enter_special_request}
            value={itemModal.notes[addonListIndex]}
            defaultValue={itemModal.notes[addonListIndex]}
            onChange={value => {
              itemModal.notes[addonListIndex] = value;

              // This handler will update directly the value in the cart entry
              // String array doesn't seem to be observed
              if (itemModal.specialRequestHandler)
                itemModal.specialRequestHandler(value);
              else set(itemModal.notes, addonListIndex, value);

              this.props.cartStore.resetEntries();

            }}
            onClick={() => {
              if (itemModal.notes[addonListIndex] === "") {
                this._openOptions(itemModal, addonListIndex);
              }
            }
            }
            clear
            disabled={itemModal.item.isInStock ? false : true}
          >
            <FaPaperclip color={"#666"} />
          </InputItem>
        );
	   //}
      });
    }

    return template;
  }

 _renderCoCoAddons() {
    // To automate the sorting using object keys
    //const groupedTemplate = {};
    const template = [];
    const { itemModal } = this.props.cartStore.menuStore;
//	const isCustmize=itemModal.item.isCustomize;
    // console.log(itemModal.item.addons);
    // If the item has no addons, just skip addon portion
	let activeKey=1;
	let panelSection=[];
	let kenum=1;
    if (itemModal.item.addons) {
	 let addonList=itemModal.addons[0];
	 let addonListIndex=0;
     // _.each(itemModal.addons, (addonList, addonListIndex) => {   
        let keys = Object.keys(addonList);		
        _.each(keys, (key, index) => {
          let tQty = 0;
		  let selAddons=[];
          let leftQty = parseInt(addonList[key][0].min);
          _.each(addonList[key], (addon, addonInd) => {
            tQty += addon.quantity;
			if(addon.quantity>0)
			{

				let name=addon.name+(addon.modifiers && addon.modifiers!==""? ' ['+addon.modifiers+']':'');	
				selAddons.push(name);
			}
          });
          leftQty = leftQty - tQty;
           
          const groupedElement = [];
          if (key !== "undefined") {            
           
            // Add the individual addon items
            _.each(addonList[key], (addon, addonIndex) => {
              if (addon.max !== 1) {
                groupedElement.push(
                  <AddonMultiple
                    key={`${addonListIndex}_${addon.id}`}
                    addon={addon}
                    handler={quantity => {
			         itemModal.addonQuantityHandler(
                        addonListIndex,
                        key,
                        addonIndex,
                        quantity
                      );
                      this.props.cartStore.resetEntries();
                    }}
                    price_zero={
                      this.props.cartStore.menuStore.translation.price_zero
                    }
					isCustomize={itemModal.item.isCustomize}
					screen='iteminfo'
					getSetMealErrorHandler={this._getSetMealErrorMsg}
					cartStore={this.props.cartStore}
                  />
                );
              } else {
                groupedElement.push(
                  <AddonSingle
                    key={`${addonListIndex}_${addon.id}`}
                    addon={addon}
                    handler={quantity => {
						if(addon.opt1 && addon.opt1!=="")
						{
							this._setMealOpenOptions(itemModal,addonListIndex,
							key,
							addonIndex,
							quantity,addon.opt1);
						}
						else
						{
							itemModal.singleAddonQuantityHandler(
							addonListIndex,
							key,
							addonIndex,
							quantity
							);
							this.props.cartStore.resetEntries();
						}
                     
					  //console.log(itemModal.item,'itemModal.item.opt1');
                    }}
                    price_zero={
                      this.props.cartStore.menuStore.translation.price_zero
                    }
					isCustomize={itemModal.item.isCustomize}
					screen='iteminfo'
					getSetMealErrorHandler={this._getSetMealErrorMsg}
					cartStore={this.props.cartStore}
                  />
                );
              }
            });

            // Set the groupOrder as key and groupedElement array as value in groupedTemplate dictionary so it sorts automatically
		let requredstring=<div><div className="labelpick"><span className="spanItemInfo"> {key.toUpperCase()}</span>		  {addonList[key][0].min > 0 ? (<span className="requiredText bluetext">Pick {addonList[key][0].min} </span>):(<span className="requiredText bluetext">Optional</span>)}
				  
				  </div><span className="addList">{selAddons.join(', ')}</span></div>
			panelSection.push(<Panel header={requredstring}
			className={tQty >= addonList[key][0].min?"addonslist thisSelected": "addonslist"}
			key={kenum.toString()}
			
			>
			{groupedElement}                
			</Panel>);
           kenum++;
		   //console.log(addonList[key][0].min , leftQty ,'888');
		    if(addonList[key][0].min > 0 && leftQty <=0 && kenum <= keys.length)
			{				
                 activeKey=	kenum;	
			}
          }
		  
        });		       
    }
	if (itemModal.item.opt1 && itemModal.item.opt1!=="")
	{		
		 let optList = [];
		 let optSelList = [];
		 if(itemModal.notes[0] && itemModal.notes[0]!=="")
		 {
			 optSelList=itemModal.notes[0].split(';');
		 }
		let options = itemModal.item.opt1.split(',');
		if (options.length > 0) {
		options[0] = options[0].replace('*', '').trim();
		}
		let singleSelection=false;
		if (options[options.length - 1].trim() !== "") {
		singleSelection = true;
		}
		 options.forEach(function (val) {
			val = val.trim();
			if (val !== "") {
			let isSle=optSelList.indexOf(val)>-1?true:false;	
			optList.push( <ModifierSingle
                   // key={`${addonListIndex}_${addon.id}`}
                   // addon={addon}
                    handler={(val,isSel) => {
						let notesAll=[];
						if(itemModal.notes[0]!=="")
						{
							  notesAll=itemModal.notes[0].split(';');
						}					
						if(singleSelection)
						{
							let NewARr=[];
							if(notesAll.length>0 && options.indexOf(notesAll[0])<0)
							{								
								NewARr.push(notesAll[0]);
								
							}
							if(!isSel)
							{
								NewARr.push(val);
							}
							notesAll=NewARr;
						}
						else
						{							
							if(!isSel)
							{
								notesAll.push(val);
							}
							else
							{
								notesAll.splice(notesAll.indexOf(val), 1);
							}							
						}
						optSelList=notesAll;
						let newMessage=notesAll.join(';');
						itemModal.notes[0] = newMessage;

						// This handler will update directly the value in the cart entry
						// String array doesn't seem to be observed
						if (itemModal.specialRequestHandler)
						itemModal.specialRequestHandler(newMessage);
						else set(itemModal.notes, 0, newMessage);					  
					  
                    }}
                    opt={val}
					isChecked={isSle}
                  />);
			}
		 });
		 let onlySlected=[];
		  options.forEach(function (val) {
			val = val.trim();
			if (val !== "") {
				
				if(optSelList.indexOf(val)>-1){
					onlySlected.push(val);
				}				
			 }
		  });
		let requredstring=<div><div className="labelpick"><span className="spanItemInfo">Modifiers</span><span className="requiredText bluetext">Optional</span></div><span className="addList">{onlySlected.length>0?'['+onlySlected.join(',')+']':''}</span></div>;
			panelSection.push(<Panel header={requredstring}
			className={onlySlected.length>0?"addonslist thisSelected":"addonslist"}
			key={kenum.toString()}
			
			>
			{optList}                
			</Panel>); 
		
		
	}
	if (itemModal.item.addons || (itemModal.item.opt1 && itemModal.item.opt1!==""))
	{
		let dfautK=["1"];
        if(!itemModal.item.addons)
		{
			activeKey="";
			dfautK=[""];
		}			
		template.push(
			<Collapse key={kenum.toString()} defaultActiveKey={dfautK} 
			activeKey={this.props.cartStore.menuStore.activeAccordian===""?[activeKey.toString()]:[this.props.cartStore.menuStore.activeAccordian.toString()]}   
			accordion={true}
			destroyInactivePanel={true}
			onChange={(key) => {
					if(key)
					{
					 this.props.cartStore.menuStore.activeAccordian=key;	
					}
					else
					{
						this.props.cartStore.menuStore.activeAccordian++;
					}	
                }}			
			>
				{panelSection}
			</Collapse>
            );
	}
    //this.props.cartStore.menuStore.activeAccordian=activeKey;
    return template;
  }
  // Set-meal items has special request rendered at the bottom of the addons
  // Non set-meal can have multiple special request based on the quantity
  _renderSpecialRequest = () => {
    const { itemModal } = this.props.cartStore.menuStore;

    if (itemModal.item.hasAddons && itemModal.layout!==4) {
      return null;
    } else {
      return _.map(itemModal.notes, (note, index) => {
        return (
          <Fragment key={`notes_${index}`}>
            <div
              className="specialRheader"
            >
              {index !== 0 && (
                <span
                  style={{ textAlign: "left", paddingRight: 6, fontSize: 12 }}
                  onClick={() => {
                    itemModal.removeAddon(index);
                  }}
                >
                  <FaTrashAlt />
                </span>
              )}
              {this.props.cartStore.menuStore.translation.set} {index + 1}
            </div>
            <InputItem
              ref={ref => (this.specialRequest = ref)}
              style={{ width: "100%" }}
              placeholder={itemModal.item.opt1 !== '' ? itemModal.item.opt1 : this.props.cartStore.menuStore.translation.enter_special_request}
              value={note}
              defaultValue={note}
              onChange={value => {
                set(itemModal.notes, index, value);
                this.props.cartStore.resetEntries();
              }}
              onClick={() => {
                if (note === "") {
                  this._openOptions(itemModal, index);
                }
              }
              }
              clear
              disabled={itemModal.item.isInStock ? false : true}
            >
              <FaPaperclip color={"#666"} />
            </InputItem>
          </Fragment>
        );
      });
    }
  };
 _setMealOpenOptions = (itemModal,addonListIndex,	key,addonIndex,	quantity,opt1) => {
  //  const { itemModal } = this.props.cartStore.menuStore;
    let lprops = this.props;
    if (opt1 !== "") {
      let optList = [];
      let optSelList = [];
      let options = opt1.split(',');
      if (options.length > 0) {
        options[0] = options[0].replace('*', '').trim();
      }
      let singleSelection=false;
      if (options[options.length - 1].trim() !== "") {
        singleSelection = true;
      }
	  let modifiers1=itemModal.addons[addonListIndex][key][addonIndex].modifiers;
	  if(modifiers1 && modifiers1!=="")
	  {
		  optSelList=modifiers1.split(';');
	  }
	  let k=0;
      options.forEach(function (val) {
        val = val.trim();
        if (val !== "") {
          k++;
          let idstr="itemOptMenu"+itemModal.item.id+k;
          optList.push(
            <div className={optSelList.indexOf(val)>-1?"optselection selectedOpt":"optselection"} key={val}
              id={idstr}
              onClick={() => { 
                 let element = document.querySelector('#' +idstr);
                let notesAll=[];
				let modifiers=itemModal.addons[addonListIndex][key][addonIndex].modifiers;
						if(modifiers && modifiers!=="")
						{
							  notesAll=modifiers.split(';');
						}
						if (notesAll.indexOf(val) >-1) { 				
						   notesAll.splice(notesAll.indexOf(val), 1);
						    element.className = element.className.replace(" selectedOpt", "");
						}
						else
						{
							notesAll.push(val);
							element.className += " selectedOpt";
						}
						
						optSelList=notesAll;
						let newMessage=notesAll.join(';');
						itemModal.addons[addonListIndex][key][addonIndex].modifiers = newMessage;
			            if(singleSelection)
						{
							itemModal.singleAddonQuantityHandler(
							addonListIndex,
							key,
							addonIndex,
							quantity
							);
							lprops.cartStore.resetEntries();
							itemModal.setMultiChoice(false, []);
							//console.log(itemModal.addons,'yyyy');
						}						
              }}
            >
              {val}
            </div>
          );
        }
      });
    
		 optList.push(<div
			  className="row btnClose btnCloseTopmargin optionBottom"
			  key="btn"
			  onClick={() => {
				if(!singleSelection)
				{
					itemModal.singleAddonQuantityHandler(
					addonListIndex,
					key,
					addonIndex,
					quantity
					);
					lprops.cartStore.resetEntries();
					itemModal.setMultiChoice(false, []);
				}
				
			  }}
			>
			  {!singleSelection?this.props.cartStore.menuStore.translation.ok:''}
			</div>
			);
		  itemModal.setMultiChoice(true, optList);
	}
  };
  _openOptions = (itemModal, index) => {

      let lprops = this.props;
    if (itemModal.item.opt1 !== "") {
      let optList = [];
      let optSelList = [];
      let options = itemModal.item.opt1.split(',');
      if (options.length > 0) {
        options[0] = options[0].replace('*', '').trim();
      }
      let singleSelection=false;
      if (options[options.length - 1].trim() !== "") {
        singleSelection = true;
      }
      let k=0;
      options.forEach(function (val) {
        val = val.trim();
        if (val !== "") {
          k++;
          let idstr="itemOptMenu"+itemModal.item.id+k;
          optList.push(
            <div className="optselection" key={val}
              id={idstr}
              onClick={() => {                
                let element = document.querySelector('#' +idstr);
                let cls = "selectedOpt";              
                let allNotes = itemModal.notes[index];
                if (allNotes.trim() !== "") {
                  allNotes = allNotes.trim().split(';');
                }
                else {
                  allNotes = [];
                }

               if (allNotes.indexOf(val) === -1) {  
                element.className += " " + cls;
                allNotes.push(val);
              }
              else {
                allNotes.splice(optSelList.indexOf(val), 1);
                element.className = element.className.replace(" selectedOpt", "");
              }             
                allNotes = allNotes.join(';');
                itemModal.notes[index] = allNotes;
                if (itemModal.specialRequestHandler)
                {
                  itemModal.specialRequestHandler(allNotes);
                }                 
                else {
                  set(itemModal.notes, index, allNotes);
                }
                lprops.cartStore.resetEntries();
                if(singleSelection)
                {
                  itemModal.setMultiChoice(false, []);
                }
              }}
            >
              {val}
            </div>
          );
        }
      });
    
     optList.push(<div
          className="row btnClose btnCloseTopmargin"
          key="btn"
          onClick={() => {
            itemModal.setMultiChoice(false, []);
          }}
        >
          {this.props.cartStore.menuStore.translation.ok}
        </div>
        );
          
      itemModal.setMultiChoice(true, optList);
    }
  };
  
  renderCocoModel()
  {	
	const { itemModal } = this.props.cartStore.menuStore;
    let qtyReqLeft = itemModal.checkRequiredQuantity();
    let btnTxt = this.props.cartStore.menuStore.translation.add;
	
	let amt=this.props.cartStore.getLeastAddonPrice(itemModal.addons);
    let priceTx = parseFloat(itemModal.item.price) === 0 ? "From "+ this.props.cartStore.menuStore.currencySymbol+parseFloat(amt).toFixed(2):itemModal.item.displayPrice;

    let layoutType = this.props.cartStore.menuStore.layout;
    let defaultImage = false;
    if (layoutType === 3 && itemModal.item.thumbnail) {
      defaultImage = itemModal.item.thumbnail.includes('/dish');
    }

    let ua = (navigator.userAgent || navigator.vendor || window.opera);
    let isFb = ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1));
    let bottomM = 10;
    if (isFb) {
      bottomM = 45;
    }
//	let backbtn="20%";
	let addcrtbtn="40%";
    let itemNotes = [];
	let entry=this.props.cartStore.getEntry(itemModal.item);
		if(entry)
		{
			if (entry.notes.length > 0) {
			_.each(entry.notes, (note, index) => {
			if (note !== '') { itemNotes.push(note); }
			});
			}
		}
	itemNotes = itemNotes.join(', ');
    let qty = this.props.cartStore.getItemQty(itemModal.item);
	
	//if(itemModal.hasAddons && qty>0 itemModal.isClickedStepper)
	if(itemModal.isClickedStepper && qty>0)
	{
		btnTxt=this.props.cartStore.menuStore.translation.update_item;
	}
	else if(itemModal.item.hasAddons && qty>0)
	{
		btnTxt=this.props.cartStore.menuStore.translation.make_another;
	}

	return (
	<Fragment>	
          <div
            style={{
              position: "fixed",
              // overflow: "scroll",
              height: "calc(100% - 52px - 40px)",
              width: "100%"
            }}

            >
            <div  style={{
              overflow: "scroll",
              maxHeight: "calc(100% - 10px)",
              width: "100%"
            }} >
              <Item
                className="itemInfoBox"
                multipleLine
              >

                <div className="mainInfodiv">
				   <div className="info_header">            
					<span
					  className="info_close"
					  onClick={() => {
							this.props.cartStore.menuStore.isSetMealEdit=false;					  
							
							//let qty=itemModal.checkTotalAddonsQuantity();  
							// let qty=this.props.cartStore.getAddonsQty(itemModal.addons);
							// if(itemModal.isUpdate && itemModal.item.isCustomize)
							// {
											  
								// if(qty===0)
								// {
								// this.props.cartStore.removeItem(itemModal.item);
								// }
								// else{
									// this._addItems();
								// }

							// }					
							// let validateSetMeals=this._getSetMealErrorMsg('close');
													
							// if (validateSetMeals==="" && (!itemModal.isUpdate || qtyReqLeft === 0)) {
								// //this._addItems();
								// itemModal.closeModal();
							// }
                            itemModal.closeModal();
					  }}
					>
					  {this.props.cartStore.menuStore.translation.x}
					</span>
				  </div>
				 <div className="webViewPopup" 
				 style={{
             // position: "fixed",
              overflow: "scroll",
              height: "calc(100% - 52px - 40px)",
              width: "100%"
            }}> 
                  <div className="lt-section detailItemImg">
                    <img src={itemModal.item.thumbnail} id="cartInfoImage" alt={itemModal.item.name} className={defaultImage ? "hideLightImage" : ""} />
                  </div>

                  <div className="rt-section" id="rt-section">
                    <div>
                      <div className="iteminfotitle">{itemModal.item.name}</div>
					  <Brief style={{ whiteSpace: "initial" }}>
                      {itemModal.item.description}
                      </Brief>
                      <div className="pricediv" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(priceTx) }} />                      
                    </div>
				
					<Fragment>
					{this._renderSimilarCartIems()}					
					</Fragment>						
			      </div>
			    </div>
                </div>
			</Item>
            </div>
          <div
            className="bottom"
            style={{
              position: "fixed",
              height: `${BOTTOM_HEIGHT}px`,
              bottom: `${bottomM}px`,
              width: "100%",
			  zIndex:1
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: 0,
                padding: 3,
                width: "100%"
              }}
            >
			<Fragment>			
			{itemModal.item.isInStock  && (
                    <Button
                      className={qtyReqLeft === 0 ? "info_addToCart" : "info_addToCart bluetext"}
                      //icon={<FiShoppingCart color="#fff" />}
                      style={{
                        width: addcrtbtn,
                        float: "left"
                      }}
                      onClick={() => {		
                         itemModal.closeModal();
                         //this.setState({openSetModal:true});	
						 if((!itemModal.isClickedStepper && qty>0) ||  qty===0)
						 {
							this.props.cartStore.menuStore.openSetModal=true;		 
						 }  		
                       }				  
					 }
                    >
                      {btnTxt}
					 </Button>)}
                  </Fragment>               
              </div>
          </div>
		 </div>  
		  </Fragment>
	);  	
  };
  renderAddonsHtml()
  {
	  
	const { itemModal } = this.props.cartStore.menuStore;
	let amt=itemModal.item.price * itemModal.addons.length;
	if(itemModal.item.hasAddons)
	{
	amt= this.props.cartStore.entryTotal(itemModal);
	}

   let qtyReqLeft = itemModal.checkRequiredQuantity(4);


    let ua = (navigator.userAgent || navigator.vendor || window.opera);
    let isFb = ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1));
    let bottomM = 0;
    if (isFb) {
      bottomM = 45;
    }
//	let backbtn="20%";
	//let addcrtbtn="40%";
	let addonListIndex=0;
	let optArray=[];
	let onlySR="";
	if(itemModal.notes[addonListIndex] && itemModal.notes[addonListIndex]!=="")
	{
		onlySR=itemModal.notes[addonListIndex].split(';');
		//onlySR =  onlySRArra.filter(e =>  e);
		//onlySR = onlySR.filter(true);		
		onlySR=onlySR[0];
		if(itemModal.item.opt1 && itemModal.item.opt1!=="")
		{
			if(itemModal.item.opt1 && itemModal.item.opt1!=="")
			{
			   optArray=itemModal.item.opt1.split(",");	
			}
			if(optArray.indexOf(onlySR) > -1)
			{
				onlySR="";
			}
		}
	}
	let isDisableCnt=false;
	if(!itemModal.item.isInStock)
	{
	isDisableCnt=true;	
	}
	if(itemModal.rewardItem && itemModal.rewardItem.redeem_code && itemModal.rewardItem.redeem_code!=="")
	{
	 isDisableCnt=true;
	}
	return (
	<Fragment>	
	<div  className="afterAddCart">
         <div className="info_header">
            {this.props.cartStore.menuStore.translation.detail}
            <span
              className="info_close"
              onClick={() => {
                  this.props.cartStore.menuStore.openSetModal=false;
				  if(parseInt(itemModal.isVisible)!==2)
				  {
					 itemModal.isVisible=true; 
				  }
				  else
				  {
					  itemModal.isVisible=false; 
				  }
				  
				  if(itemModal.isUpdate && !itemModal.item.hasAddons)
				  {
						if(itemModal.addons.length===0)
						{
						this.props.cartStore.removeItem(itemModal.item);
						}
					  
					   const difference = Math.abs(itemModal.addons.length - itemModal.actualAddon);
						for (let i = 0; i < difference; i++) {
						if (itemModal.actualAddon > itemModal.addons.length) {
						  itemModal.addAddon();						  
						} else {
						  itemModal.addons.pop();
						  itemModal.notes.pop();
						
						 }
					   }
					//	this.props.cartStore.resetEntries();				  
				  }				 
						
              }}
            >
              {this.props.cartStore.menuStore.translation.x}
            </span>
          </div>	
          <div
           className="infoBoxWrapper"
            >
            <div>
              <Item
                className="itemInfoBox"
                multipleLine
              >
			  <div className="insideCartPopup">
                <div className="mainInfodiv">
                  <div className="lt-section">
                   	<div className="iteminfoname">{itemModal.item.name}	</div>
                    <Brief style={{ whiteSpace: "initial" }}>
                      {itemModal.item.description}
                    </Brief>
                  </div>

                   <div className="cartScreen">   
                    {this._renderCoCoAddons()}	
						{itemModal.item.hasAddons && qtyReqLeft<1 && (<Recomendations page="iteminfo" />)}				
                      <div className="specialRequestCoco">
					  <textarea
					        
							key={`specialRequest_${addonListIndex}`}
							className="addonList"
							ref={ref => (this.specialRequest = ref)}
							style={{ width: "100%" }}
							// placeholder={"Enter Special Request"}
							placeholder="Add special request"
							value={onlySR}
							onChange={value => {
							  let msg=value.target.value;
							 let notesAll=[];
								if(itemModal.notes[0]!=="")
								{
								notesAll=itemModal.notes[0].split(';');
								}	
							  
							  if(onlySR!=="" && optArray.indexOf(onlySR) < 0)
							  {
							       notesAll.splice(0, 1);
							  }
							   let newMessage=msg;
							  if(notesAll.length>0)
							  {
								  newMessage=msg+';'+notesAll.join(';');
							  }
							 
							  itemModal.notes[addonListIndex] = newMessage;

							  // This handler will update directly the value in the cart entry
							  // String array doesn't seem to be observed
							  if (itemModal.specialRequestHandler)
								itemModal.specialRequestHandler(newMessage);
							  else set(itemModal.notes, addonListIndex, newMessage);							  
							  //this.props.cartStore.resetEntries();

							}}
							clear="true"
							disabled={itemModal.item.isInStock ? false : true}
						  >							
						  </textarea>
                    </div>                    
                  </div>
                </div>
               </div>
			</Item>
            </div>
          </div>
          <div
            className="bottom"
            style={{
              position: "fixed",
              height: `${BOTTOM_HEIGHT}px`,
              bottom: `${bottomM}px`,
              paddingTop: 6,
              width: "100%"
            }}
          >
            <div
              className="bottomSection"
            >
             <div className="priceSection"><span>SGD {parseFloat(amt).toFixed(2)}</span>
		   	 <FoodStepper
                            min={itemModal.isUpdate?0:1}
                           // max={itemModal.rewardItem && itemModal.rewardItem.redeem_code ?1:100000000}
                            value={itemModal.addons.length}
                            onChange={quantity => {
							if(quantity===0)
							{													
							alert(
								<div className="cartItem"></div>,
								<div dangerouslySetInnerHTML={{ __html:this.props.cartStore.menuStore.htmlDecode(this.props.cartStore.menuStore.translation.delete_item) }}/>,
								[
								  {
									text: this.props.cartStore.menuStore.translation.close
								  },
								  {
										text: this.props.cartStore.menuStore.translation.yes,
										onPress: () => {
											this.props.cartStore.removeItem(itemModal.item);
											itemModal.closeModal();
											this.props.cartStore.menuStore.openSetModal=false;
											this.props.cartStore.menuStore.sendGoogleAnalytics(this.props.cartStore.menuStore.googleTrashItem, itemModal.item.name, this.props.cartStore.menuStore.controller, itemModal.item.price * itemModal.addons.length*100);
											
										}
								  }						  
								]
							  );	
							}
							else
							{
                              const difference = Math.abs(
                                itemModal.addons.length - quantity
                              );
						      for (let i = 0; i < difference; i++) {
                                if (quantity > itemModal.addons.length) {
                                  itemModal.addAddon();
                                } else {
                                  itemModal.addons.pop();
                                  itemModal.notes.pop();                                 
                                }
                              }
							}
                             // this.props.cartStore.resetEntries();
                            }}
                            showNumber
                            disabled={isDisableCnt}
							_getSetMealErrorMsg={this._getSetMealErrorMsg}
							screen="iteminfo"
							steperPosition="itemInfoTop"
							isCustomize={itemModal.item.isCustomize}
                          />
			 
			 </div>
			  
			  
			  <Button
                     // icon={<FiPlus />}
                      className="info_addToCart"
                      onClick={() => {
						    //console.log(itemModal.isUpdate,'isUpdate');
							this.props.cartStore.addItemGroupByAddons(itemModal);
                            this.props.cartStore.resetEntries();
							this.props.cartStore.menuStore.openSetModal=false;
							itemModal.isVisible=false;
							this.props.cartStore.menuStore.isRewardModalVisible=false;
							//this.props.cartStore.menuStore.resetMenu();
							
                        
                      }}
                      style={{ width: "40%", float: "left" }}
					  disabled={qtyReqLeft>0?true:false}
                    >
                      {this.props.cartStore.menuStore.translation.add_to_cart}
					 </Button>
			  
            </div>
          </div>
        </div>
	  </Fragment>
	);
  };
  renderNonCocoLayout()
  {
	const { itemModal } = this.props.cartStore.menuStore;
    let isDisplayPrice=this.props.cartStore.menuStore.checkPriceDisplay(itemModal.item);
    let avail = this.props.cartStore.menuStore.isAvailable(itemModal.item);
    let qtyReqLeft = itemModal.checkRequiredQuantity();
    let btnTxt = this.props.cartStore.menuStore.translation.add_to_cart;
    let closeBtn = this.props.cartStore.menuStore.translation.close;
    if (qtyReqLeft > 0) {
      btnTxt = closeBtn = qtyReqLeft + ' ' + this.props.cartStore.menuStore.translation.required_text.toUpperCase();
    }
    let priceTx = parseFloat(itemModal.item.price) === 0 ? this.props.cartStore.menuStore.translation.price_zero : itemModal.item.displayPrice;

    let layoutType = this.props.cartStore.menuStore.layout;
    let defaultImage = false;
    if (layoutType === 3 && itemModal.item.thumbnail) {
      defaultImage = itemModal.item.thumbnail.includes('/dish');
    }

    let ua = (navigator.userAgent || navigator.vendor || window.opera);
    let isFb = ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1));
    let bottomM = 0;
    if (isFb) {
      bottomM = 45;
    }
	let backbtn="20%";
	let addcrtbtn="40%";
	if(itemModal.item.isCustomize)
	{
		 backbtn="50%";
	 addcrtbtn="50%";
	}
	return (
	<Fragment>
	<div className="info_header">
            {this.props.cartStore.menuStore.translation.detail}
            <span
              className="info_close"
              onClick={() => {
					this.props.cartStore.menuStore.isSetMealEdit=false;					  
					
					//let qty=itemModal.checkTotalAddonsQuantity();  
					let qty=this.props.cartStore.getAddonsQty(itemModal.addons);
					if(itemModal.isUpdate && itemModal.item.isCustomize)
					{
									  
						if(qty===0)
						{
						this.props.cartStore.removeItem(itemModal.item);
						}
						else{
							this._addItems();
						}

					}					
					let validateSetMeals=this._getSetMealErrorMsg('close');
											
					if (validateSetMeals==="" && (!itemModal.isUpdate || qtyReqLeft === 0)) {
					    //this._addItems();
					    itemModal.closeModal();
					}

              }}
            >
              {this.props.cartStore.menuStore.translation.x}
            </span>
          </div>
          <div
            style={{
              position: "fixed",
              overflow: "scroll",
              height: "calc(100% - 52px - 40px)",
              width: "100%"
            }}

            >
            <div >
              <Item
                className="itemInfoBox"
                multipleLine
              >

                <div className="mainInfodiv">
                  <div className="lt-section">
                    <img src={itemModal.item.thumbnail} id="cartInfoImage" alt={itemModal.item.name} className={defaultImage ? "hideLightImage" : ""} />
					          <Sharing></Sharing>			
                   
                  </div>

                  <div className="rt-section" id="rt-section">
                    <div>
                      {itemModal.item.name}
                       {isDisplayPrice && (
                      <Brief>
                        <div dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(priceTx) }} />
                      </Brief>
                      )}
                    </div>
                    <div className="stepperInfo">
                      {itemModal.item.isInStock && avail && !this.props.cartStore.menuStore.isSetMealEdit && !itemModal.item.isCustomize ? (
                        <Fragment>
                          <FoodStepper
                            min={1}
                            value={itemModal.addons.length}
                            onChange={quantity => {
                              const difference = Math.abs(
                                itemModal.addons.length - quantity
                              );
                              for (let i = 0; i < difference; i++) {
                                if (quantity > itemModal.addons.length) {
                                  itemModal.addAddon();
                                  this.props.cartStore.menuStore.sendGoogleAnalytics(this.props.cartStore.menuStore.googleAddItem, itemModal.item.name, this.props.cartStore.menuStore.controller, itemModal.item.price * itemModal.addons.length*100);
                                } else {
                                  itemModal.addons.pop();
                                  itemModal.notes.pop();
                                  this.props.cartStore.menuStore.sendGoogleAnalytics(this.props.cartStore.menuStore.googleRemoveItem, itemModal.item.name, this.props.cartStore.menuStore.controller, itemModal.item.price * itemModal.addons.length*100);
                                }
                              }
                              this.props.cartStore.resetEntries();
                            }}
                            showNumber
                            disabled={itemModal.item.isInStock ? false : true}
							_getSetMealErrorMsg={this._getSetMealErrorMsg}
							screen="iteminfo"
							steperPosition="itemInfoTop"
							isCustomize={itemModal.item.isCustomize}
                          />
                          <span style={{ color: "gray", fontSize: 12 }} className="removeBtninfo"
                            onClick={() => {
                              this.props.cartStore.removeItem(itemModal.item);
                              this.props.cartStore.resetEntries();
                              itemModal.closeModal();
                            }}

                          >
                            <FaTrashAlt />Remove
                      </span>
                        </Fragment>
                      ) : null}
                      {!itemModal.item.isInStock ? (
                        <span style={{ color: "gray", fontSize: 12 }}>
                          {
                            this.props.cartStore.menuStore.translation
                              .out_of_stock
                          }
                        </span>
                      ) : null}
                      {!avail ? (
                        <span style={{ color: "gray", fontSize: 12 }}>
                          {
                            this.props.cartStore.menuStore.translation
                              .not_available
                          }

                        </span>
                      ) : null}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(itemModal.item.des1) }} className="descripttionStyle" />
                    <div className="specialRequestInput">
                      {this._renderSpecialRequest()}
                    </div>
                    {this._renderAddons()}
                  </div>
                </div>


              </Item>
            </div>
          </div>
          <div
            className="bottom"
            style={{
              position: "fixed",
              height: `${BOTTOM_HEIGHT}px`,
              bottom: `${bottomM}px`,
              paddingTop: 6,
              width: "100%"
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: 0,
                padding: 3,
                width: "100%"
              }}
            >
			<Fragment>
              {itemModal.isUpdate || !itemModal.item.isInStock ? (
                
                  <Button
                    onClick={() => {                     
                      this.props.cartStore.menuStore.isSetMealEdit = false;
                      let qty = this.props.cartStore.getAddonsQty(itemModal.addons);
                      if (itemModal.item.hasAddons && itemModal.item.isCustomize) {
                        if (qty === 0) {
                          this.props.cartStore.removeItem(itemModal.item);
                        }
                        else {
                          this._addItems();
                        }

                      }
                      let validateSetMeals = this._getSetMealErrorMsg('close');
                      if (qtyReqLeft === 0 && validateSetMeals === "") {
                        //this._addItems();
                        itemModal.closeModal();
                      }
                      let headingNum=1;
                      _.each(itemModal.addons, (addonGroup, ind) => {
                          
                           let sQty=this.props.cartStore.getSetMealQty(addonGroup);
                           let adItemId="addon_"+ind+"_"+itemModal.item.id;
                           let hedadingId=adItemId+"_heading";                          
                           if(sQty>0)
                           {
                            document.getElementById(adItemId).style.display = "block";
                            document.getElementById(hedadingId).innerHTML="Set "+headingNum;
                            headingNum++;
                           }
                           else{
                            document.getElementById(adItemId).style.display = "none";
                           }

                      });                     
                     
                    }}
                    className={qtyReqLeft === 0 ? "info_addToCart" : "info_addToCart bluetext"}
                  //style={{ backgroundColor: "#000", color: "#fff" }}
                  >
                    {closeBtn}
                  </Button>
               
              ) : ('')}
			 
				   {!itemModal.isUpdate && itemModal.item.isInStock ? (
                    <Button
                      icon={<Icon type="left" size="md" />}
                      className="info_addToCart"
                      onClick={() => {						
						//this.props.cartStore.removeItem(itemModal.item);
						let qty=itemModal.addons.length;
						if(itemModal.item.hasAddons && itemModal.item.isCustomize)
						{
						// qty=itemModal.checkTotalAddonsQuantity();	
                         qty=this.props.cartStore.getAddonsQty(itemModal.addons);						 
						} 
						if(qty===0)
						{
						this.props.cartStore.removeItem(itemModal.item);
						}								
                        itemModal.closeModal();
                      }}
                      style={{ width: backbtn, float: "left" }}
                    >
			  </Button>):('')}
					 {!itemModal.isUpdate && itemModal.item.isInStock && !itemModal.item.isCustomize? (
                    <Button
                      icon={<FiPlus />}
                      className="info_addToCart"
                      onClick={() => {
						 let qty=itemModal.addons.length;	

						 if(itemModal.item.hasAddons && itemModal.item.isCustomize)
						 {
						     //qty=itemModal.checkTotalAddonsQuantity();						   
						     qty=this.props.cartStore.getAddonsQty(itemModal.addons);						   
						 }

					    let validateSetMeals=this._getSetMealErrorMsg(qty+1);	
						if(validateSetMeals==="")
						{
							itemModal.addAddon();

							setTimeout(() => {
							if (itemModal.item.hasAddons) {
							document
							.getElementById(
							`addon_${itemModal.addons.length - 1}`
							)
							.scrollIntoView({
							block: "start",
							inline: "nearest",
							behavior: "smooth"
							});
							}
							}, 50);							
						}
                        
                      }}
                      style={{ width: "40%", float: "left" }}
                    >
                      {this.props.cartStore.menuStore.translation.add_more_set}
					 </Button>):('')}
					 {!itemModal.isUpdate && itemModal.item.isInStock ? (
                    <Button
                      className={qtyReqLeft === 0 ? "info_addToCart" : "info_addToCart bluetext"}
                      icon={<FiShoppingCart color="#fff" />}
                      style={{
                        width: addcrtbtn,
                        float: "left"
                      }}
                      onClick={() => {
						let qty=itemModal.addons.length;	
					
						if(itemModal.item.hasAddons && itemModal.item.isCustomize)
						{
                            qty=this.props.cartStore.getAddonsQty(itemModal.addons);								
						}				
                        let validateSetMeals=this._getSetMealErrorMsg(qty);						
                        if(qtyReqLeft === 0 && validateSetMeals==="" && qty>0) {
                          this.props.cartStore.addItem({
                            item: itemModal.item,
                            addons: itemModal.addons,
                            notes: itemModal.notes
                          });
						if(itemModal.item.hasAddons && itemModal.item.isCustomize)
						{
							 this._addItems();													
						}
						
		                  
                          // console.log(itemModal);
                          //itemModal.closeModal();
                          this.props.cartStore.menuStore.sendGoogleAnalytics(this.props.cartStore.menuStore.googleAddItem, itemModal.item.name, this.props.cartStore.menuStore.controller, itemModal.item.price * itemModal.addons.length*100);

                          if (itemModal.addons.length > 0) {
                            _.each(itemModal.addons, (addonList, addonListIndex) => {
                              let keys = Object.keys(addonList);
                              _.each(keys, (key, index) => {

                                //let tQty=0;
                                _.each(addonList[key], (addon, addonIndex) => {

                                  if (addon.quantity > 0) {
                                    this.props.cartStore.menuStore.sendGoogleAnalytics(this.props.cartStore.menuStore.googleAddItem, addon.name, this.props.cartStore.menuStore.controller, addon.price * addon.quantity*100);
                                  }
                                });
                              });
                            });
                          }
							itemModal.closeModal();
                        }			
							 		
                      }}
                    >
                      {btnTxt}
					 </Button>):('')}
                  </Fragment>               
            </div>
          </div>
		  </Fragment>
	);
  	
  };
  _renderSimilarCartIems()
  {  
    let emptyNotes=[];
	let notesItems=[];
      _.map(this.props.cartStore.entries, (entry, index) => {
			let spRequets=[];
			if (entry.notes.length > 0) {
			_.each(entry.notes, (note, index) => {
			if (note !== '' && spRequets.length===0) { spRequets.push(note); }
			});
			}
			spRequets = spRequets.join(', ');
		  	if(spRequets!=="")
			{
				notesItems.push(<GroupAddonItem entry={entry}  cartStore={this.props.cartStore} _getSetMealErrorMsg={this._getSetMealErrorMsg} ind={index} spRequets={spRequets}/>);
			}
			else
			{
				emptyNotes.push(<GroupAddonItem entry={entry}  cartStore={this.props.cartStore} _getSetMealErrorMsg={this._getSetMealErrorMsg} ind={index} spRequets={spRequets}/>);
			}
       
      });
    return emptyNotes.concat(notesItems);	  
  };
  render() {
    return (
	 <Fragment>
      <Modal
        visible={this.props.visible}
        style={{ overflow: "hidden", height: "100%", textAlign: "left" }}
           className={"menutype"+this.props.cartStore.menuStore.menu_type+" infolayout"+this.props.cartStore.menuStore.layout}
      >
	  {!this.props.cartStore.menuStore.openSetModal && <div
          style={{
            position: "fixed",
            top: 0,
            height: `${TOP_HEIGHT}px`,
            width: "100%",
			minWidth:"325px"
          }}
        >
		{this.props.cartStore.menuStore.layout===4?(<div className="cocosetmeal">{this.renderCocoModel()}</div>):(		
		<div className="nococosetmeal">{this.renderNonCocoLayout()}</div>)}
          
	  </div>}
      </Modal>
	  
	  <Modal
        visible={this.props.cartStore.menuStore.openSetModal}
        style={{ overflow: "hidden", height: "100%", textAlign: "left" }}
           className={"setmealsls infolayout"+this.props.cartStore.menuStore.layout}
		   afterClose={()=>{
			   this.props.cartStore.menuStore.activeAccordian="";
		   }}
        >
        <div
          style={{
            position: "fixed",
            top: 0,
            height: `${TOP_HEIGHT}px`,
            width: "100%",
			minWidth:"325px"
          }}
        >		
		<div>{this.renderAddonsHtml()}</div>          
        </div>
      </Modal>
	  </Fragment>
    );
  }
}
const GroupAddonItem = observer(({ entry, cartStore,_getSetMealErrorMsg,ind,spRequets }) => {
 
   const { itemModal } = cartStore.menuStore;
   console.log(itemModal,entry.item,'55555555');
   if(!entry.addons || itemModal.item.id!==entry.item.id)
   {
	   return ('');
   }
    let reward=	entry.reward;
	let isRwardItem=false;
	let isDisableCnt=false;
	if(!itemModal.item.isInStock)
	{
	isDisableCnt=true;	
	}
    if(reward && reward.redeem_code && reward.redeem_code!=="")
    {
		isRwardItem=true;
		isDisableCnt=true;	
	}	
	
   //let itemNotes= cartStore.getAddonsNamesList(entry.addons); 
   let priceAmt=cartStore.entryTotal(entry);
   return (
         <div className="addSection" key={"increment"+entry.item.id+'inde'+ind}>
						<div className="itemNotes">
							<RenderAddon addonSlected={entry.addons} />
							{spRequets!=="" &&
							<div className="briefoptions">[{spRequets}]</div>
							}
						
						<Button
						onClick={()=>{
							let addon=cartStore.entries[ind].addons;
							if(itemModal.rewardItem && itemModal.rewardItem.redeem_code && itemModal.rewardItem.redeem_code!=="")
							{
								addon=[];
							addon.push(cartStore.entries[ind].addons[0]);
							}
							if(!isRwardItem)
							{
								cartStore.menuStore.itemModal.showUpdateModal(
								cartStore.entries[ind].item,
								addon,
								cartStore.entries[ind].notes
								);
								
								//let {itemModal}=cartStore.menuStore;
								cartStore.menuStore.itemModal.isVisible=2;
								cartStore.menuStore.openSetModal=true;
							}
						}}
						className="edititeminfo"
						disabled={isRwardItem}
						>
						edit
						</Button> 
						</div>
						<div>
						<FoodStepper
                            min={0}
                            value={entry.addons.length}
                            onChange={quantity => {
							   itemModal.isClickedStepper=true;
							   if(quantity===0)
							   {													
								alert(
									<div className="cartItem"></div>,
									<div dangerouslySetInnerHTML={{ __html:cartStore.menuStore.htmlDecode(cartStore.menuStore.translation.delete_item) }}/>,
									[
									  {
										text: cartStore.menuStore.translation.close
									  },
									  {
											text: cartStore.menuStore.translation.yes,
											onPress: () => {
												cartStore.removeItemIndexWise(entry.item,ind);
												
											}
									  }						  
									]
								  );	
								}									
                                else if (quantity > entry.addons.length) {
									entry.addons.push(entry.addons[0]);				     
								  
                                } else {
                                   entry.addons.pop();
                                   entry.notes.pop();
                                }
                              cartStore.resetEntries();
                            }}
                            showNumber
                            disabled={isDisableCnt}
							_getSetMealErrorMsg={_getSetMealErrorMsg}
							screen="addonitem"
							steperPosition="addonitem"
							isCustomize={itemModal.item.isCustomize}
						/>
						</div>
						<div>SGD {parseFloat(priceAmt).toFixed(2)}</div>
					</div>
       );
});
export default ItemInfo;
