import React ,{ Fragment } from "react";
import { List } from "antd-mobile";
import { observer } from "mobx-react";
import FoodStepper from "./FoodStepper";
import _ from "underscore";

const Item = List.Item;

const AddonFixed = observer(({ addon, price_zero,isCustomize,screen,cartStore }) => {
	let nameAd=addon.name;
	if(isCustomize)
	{
		if(screen==='cart')
		{
			nameAd=addon.name +' ('+addon.priceName+')';
		}
		else if(screen==='iteminfo')
		{
			nameAd=addon.priceName;
		}
	}
  return (
    <Item
      className="itemAddon"
      extra={<span className="gray">{addon.quantity}</span>}
    >
      {nameAd}{" "}
      {parseFloat(addon.price) === 0 ? price_zero : `(${addon.priceName})`}
    </Item>
  );
});

const AddonSingle = observer(({ addon, handler, price_zero,isCustomize,screen,getSetMealErrorHandler ,cartStore}) => {
	let nameAd=addon.name;
	if(isCustomize)
	{
		if(screen==='cart')
		{
			nameAd=addon.name +' ('+addon.priceName+')';
		}
		else if(screen==='iteminfo')
		{
			nameAd=addon.priceName;
		}
	}
	
	let inputTag=<input
          name={addon.id}
          type="checkbox"
          checked={addon.quantity === 1 ? true : false}
          onChange={() => {}}
          disabled={addon.isInStock ? "" : "disabled"}
        />;
	if(cartStore && cartStore.menuStore.layout===4)
	{
		nameAd = <Fragment><span className="addsName">{addon.name}</span>{addon.price>0 && <span className="addonPrice"> +SGD {addon.price}</span>}</Fragment>;
		inputTag=<div class="themeCheckbox">
		<input
          name={addon.id}
          type="checkbox"
          checked={addon.quantity === 1 ? true : false}
          onChange={() => {}}
          disabled={addon.isInStock ? "" : "disabled"}
        />
		<span class="dotter">&nbsp;</span>
		</div>;
	}
  return (
    <Item
      className="itemAddon addoncheckbox"
      extra={inputTag}
      onClick={() => {
			let qty=addon.quantity === 0 ? 1 : 0;
			if (addon.isInStock) handler(qty);
			//console.log(qty);

			if(qty>0)
			{	
                //getSetMealErrorHandler('minus1');		
				let validateSetMeals=getSetMealErrorHandler('checked');
				if (validateSetMeals!=="")
				{
					handler(0);
				}
	        }
			else{
			   getSetMealErrorHandler('minus');
			}
			
      }}
    >
	  <div className="addonNames" >{nameAd}</div>
		{(!cartStore || cartStore.menuStore.layout!==4) && (<span className="gray">
        {parseFloat(addon.price) === 0 ? price_zero : `(${addon.priceName})`}
		  </span>)}
    </Item>
  );
});

const ModifierSingle = observer(({handler,opt,isChecked}) => {
		let nameAd = <Fragment><span className="addsName">{opt}</span></Fragment>;
    let inputTag=<div class="themeCheckbox">
		<input
          name={"modifier"}
          type="checkbox"
          checked={isChecked}
          onChange={() => {
			  //console.log(2,opt,e);
		  }}         
        />
		<span class="dotter">&nbsp;</span>
		</div>;

  return (
    <Item
      className="itemAddon addoncheckbox"
      extra={inputTag}
      onClick={() => {
			//console.log(opt,isChecked);
			handler(opt,isChecked);
			
      }}
    >
	  <div className="addonNames" >{nameAd}</div>		
    </Item>
  );
});

const AddonMultiple = observer(({ addon, handler, price_zero,isCustomize,screen,getSetMealErrorHandler,cartStore}) => {
	let nameAd=addon.name;
	if(isCustomize)
	{
		if(screen==='cart')
		{
			nameAd=addon.name +' ('+addon.priceName+')';
		}
		else if(screen==='iteminfo')
		{
			nameAd=addon.priceName;
		}
	}
	if(cartStore && cartStore.menuStore.layout===4)
	{
		nameAd = <Fragment><span className="addsName">{addon.name}</span>{addon.price>0 && <span className="addonPrice"> +SGD {addon.price}</span>}</Fragment>;
	}
  return (
    <Item
      className="itemAddon"
      extra={
        <FoodStepper
          style={{
            width: "100%",
            minWidth: "100px",
            maxWidth: "150px"
          }}
          flexWidth={true}
          showNumber
          size="small"
         // min={addon.min}
          max={addon.max === 0 ? 99 : addon.max}
          value={addon.quantity}
          onChange={quantity => {
            handler(quantity);
          }}
          disabled={addon.isInStock ? "" : "disabled"}
		  _getSetMealErrorMsg={getSetMealErrorHandler}
        />
      }
    >
		<div className="addonNames" >{nameAd}</div>
		{(!cartStore || cartStore.menuStore.layout!==4) && (<span className="gray">
		{parseFloat(addon.price) === 0 ? price_zero : `(${addon.priceName})`}
		</span>)}
    </Item>
  );
});

const AddonSummary = observer(({ addon, price_zero,isCustomize,screen }) => {
	let nameAd=addon.name;
	if(isCustomize)
	{
		if(screen==='cart')
		{
			nameAd=addon.name +' ('+addon.priceName+')';
		}
		else if(screen==='iteminfo')
		{
			nameAd=addon.priceName;
		}
	}
  return addon.quantity ? (
    <Item extra={addon.quantity} className="addonSummary">
       {nameAd}{" "}
      <span className="gray">
        {parseFloat(addon.price) === 0 ? price_zero : `(${addon.priceName})`}
      </span>
    </Item>
  ) : null;
});

const RenderAddon = observer(({addonSlected}) => {
	
	 let firstAddonSlected=addonSlected[0];
	 let keys = Object.keys(firstAddonSlected);
	 let allAddonsElem=[];
        _.each(keys, (key, index) => {			  
          _.each(firstAddonSlected[key], (addon, addonIndex) => { 
			 if(addon.quantity===1 && addon.groupName)
			{
              //let name=addon.name+(addon.modifiers && addon.modifiers!==""? ' ['+addon.modifiers+']':'');	
 			  allAddonsElem.push(<div className="briefoptions addontitle">{addon.name}</div>);
			  if(addon.modifiers && addon.modifiers!=="")
			  {
				   allAddonsElem.push(<div className="briefoptions modifierright">{' ['+addon.modifiers+']'}</div>);
			  }
 			 
			}
          });	
           
    });
  return allAddonsElem;
});

const RenderNotes = observer(({entry}) => {
let spRequets=[];
if (entry.notes.length > 0) {
_.each(entry.notes, (note, index) => {
if (note !== '' && spRequets.length===0) { spRequets.push(note); }
});
}
spRequets = spRequets.join(', ');
	if(spRequets!=="")
	{
	return (<div className="briefoptions">             
		[{spRequets}]
		</div>);	
	}
	return('');
		
});

export { AddonFixed, AddonSingle, AddonMultiple, AddonSummary,ModifierSingle,RenderAddon,RenderNotes };
