import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { List, Button, Modal, Accordion } from "antd-mobile";
import ItemInfo from "./ItemInfo";
import { Icon } from "antd";

import { FaCheckCircle, FaPlusCircle, FaAngleRight } from "react-icons/fa";
import { throttle } from "lodash";
import FoodStepper from "./FoodStepper";
import MenuDate from "./MenuDate";
import configParam from "../libs/params";
//import PreQuestions from "./PreQuestions";
//const EXP_TRIM = /[&/\\#,+()$~%.'":*?<>{} ]/g;
const Item = List.Item;
const alert = Modal.alert;
const Menu = observer(({ cartStore, searchResult }) => {
  const menuToDisplay = searchResult;
  if (cartStore.menuStore.isLoading) {
    return <div className="loadingDiv"><p>Loading...</p><img src="https://www.foodzaps.com/public/img/loading.gif" alt="loading..." /></div>;
  } else {

    //console.log(cartStore.menuStore.activePromo,'yy');
    const paymentRes = localStorage.getItem('hideMenu');
    //localStorage.removeItem('hideMenu');

    let claName = "menuContainer fixedMenuSection" + (cartStore.menuStore.menu_type === '0' ? " fixedMenuImage" : "");
    if (cartStore.menuStore.menu_type === "1") {
      //console.log('first');
      if (paymentRes) {
        //console.log('second');
        claName = "menuContainer popupMenu toggleMenuCls";
        //localStorage.removeItem('hideMenu');
      }
      else {
        claName = "menuContainer popupMenu";
      }

    }

    return (
      <div className={claName} id="menuContainer">
        <div className="sideMenuContainer">
          <div className={paymentRes ? "smallIconArrow hideshowArrow" : "smallIconArrow"}

            id="smallIconArrow"
            onClick={() => {
              if (cartStore.menuStore.menu_type === "1") {
                document.getElementById("menuContainer").classList.toggle('toggleMenuCls');
              }
            }}
          > <FaAngleRight /></div>
          {cartStore.menuStore.promotions.length > 0 && cartStore.menuStore.promotions[0] !== "" ? (

            <Accordion accordion openAnimation={{}} defaultActiveKey={cartStore.menuStore.promotions.length === 1 ? "0" : ""} className="my-accordion"
              onChange={(key) => {
                if (key === undefined) {
                  return false;
                }
                if (cartStore.menuStore.menu_type === "1") {
                  document.getElementById("menuContainer").classList.toggle('toggleMenuCls');
                }
                cartStore.menuStore.horizontalMenuSelectedKey = key;
                cartStore.menuStore.activePromo = key;
                cartStore.menuStore.resetMeuList();
                cartStore.menuStore.searchResult = cartStore.menuStore.filterMenuList;
              }}
            >
              {cartStore.menuStore.promotions.map((item, index) => (

                <Accordion.Panel header={item} key={index} id={"menutest-" + index} className={"menutest-" + index} style={{ cursor: "pointer" }}>

                  <List className="my-list">
                    {cartStore.menuStore.getCategories(item).map((cat, indexca) => (
                      <SideMenuEntry
                        item={cat}
                        menuStore={cartStore.menuStore}
                        key={`promo${index}${indexca}`}
                      />
                    ))}
                  </List>
                </Accordion.Panel>

              ))}
            </Accordion>
          ) : (
              <List className="sideMenu">
                {cartStore.menuStore.categories.map((item, index) => (
                  <SideMenuEntry
                    item={item}
                    menuStore={cartStore.menuStore}
                    key={`side${index}`}
                  />
                ))}
                <Item
                  className="sizeMenuItem extraEmpty">
                  &nbsp;
			</Item>
              </List>
            )}
        </div>
		
        <div className={cartStore.menuStore.layout === 2 ? "mainMenuContainer gridcirclebgwhte" : "mainMenuContainer"}
          //onLoad={(k)=>{document.getElementsByClassName('mainMenuContainer').addEventListener("scroll");}}
          onScroll={(el) => {
            let pos = 95;
            if(cartStore.menuStore.menu_type==='2')
			{
				pos = 115;
			}
            else if(cartStore.menuStore.layout===4)
			{
			    pos = 290;
			}
            else if(cartStore.menuStore.flashHeaderMsg.length>0)
            {
              pos = pos+15+(15*cartStore.menuStore.flashHeaderMsg.length);
            } 
            // else if(cartStore.menuStore.layout===4)
            // {
              // pos = 1500;
            // }			
            //console.log(pos);
            let d = document.elementFromPoint(0, pos);
         //   console.log(d);
            if (d.firstChild === null || d.firstChild === undefined || d.firstChild.attributes === undefined || d.firstChild.attributes.length === 0) {
              return;
            }

            let firstChTop = d.firstChild.getBoundingClientRect().top;
            let diffTopback = d.offsetHeight + firstChTop;
            let allT = d.attributes;
            let valT = allT[0].value;
            //console.log(valT,pos);	
			if(cartStore.menuStore.layout===4)
			{
				 if (allT[0].name === 'id') {
					 //console.log(valT,'jkkk');
					 let data=cartStore.menuStore.horizontalCategoryList;
					 let ind=data.indexOf(valT);
					 if(ind>-1)
					 {						 
						 cartStore.menuStore.horizontalMenuSelectedIndex=[ind.toString()];
                         document.getElementById("submenucoco"+ind).scrollIntoView();
					 }
					// console.log(valT,ind);
				 }
			}
			else
			{		
            let totalDiff=400;			
            let diffTop=80;
			
            if(cartStore.menuStore.flashHeaderMsg.length>0)
            {
              diffTop=120;
            }
			// if(cartStore.menuStore.layout===4)
            // {
				 // diffTop=400;
				 // totalDiff=600;	
			// }
            if (d.attributes.length > 0 && (firstChTop > diffTop || (diffTopback > totalDiff && cartStore.menuStore.horizontalMenuClickedKey === ''))) {

              if (allT[0].name === 'id') {

                var x = document.getElementById("hitem-" + valT);
				if(x)
				{
					x=x.parentElement;
				}
				cartStore.menuStore.resetCocoMenu(valT);
                if (x !== null && x !== undefined && (cartStore.menuStore.horizontalMenuClickedKey === valT || cartStore.menuStore.horizontalMenuClickedKey === '')) {
                  cartStore.menuStore.horizontalMenuClickedKey = "";
                  let cElement = document.getElementsByClassName("active");
                  //console.log(cElement,'app');									 
                  if (cElement.length > 0) {
                    // console.log(cElement);
                    while (cElement.length > 0) {
                      cElement[0].classList.remove('active');
                    }
                  }
                  x.classList.add('active');
                  x.scrollIntoView(true);									 

                }
              }
            }

            let allCats = document.querySelectorAll('.itemCatList');
            if (allCats.length > 0) {
              //let fDiv=allCats[0].attributes;
              let lDivElement = allCats[allCats.length - 2];
              if (lDivElement) {
                let lDiv = lDivElement.attributes;

                let lastElement = allCats[allCats.length - 1];
                if (lastElement) {
                  let lastDiv = lastElement.attributes;
                  //console.log(diffTopback);									
                  if (cartStore.menuStore.horizontalMenuClickedKey === lastDiv[0].value && !lDivElement.classList.contains("active") && ((diffTopback > 300 && diffTopback < 400 && lDiv[0].value === valT))) {
                    cartStore.menuStore.horizontalMenuClickedKey = "";

                  }
                }
              }
            }
		   }
          }}
        >
		<MenuDate mdateTime={cartStore.menuStore.getMenuDateTime()}/>
          {Object.keys(menuToDisplay).map((key, index) => {
            return (
              <MenuEntry
                header={key}
                items={menuToDisplay[key]}
                cartStore={cartStore}
                key={`entry${index}`}
              />
            );
          })}
		  {cartStore.menuStore.isKopikingControler() && (
			<div className="fkopi" style={{marginTop:"10px"}}>
				<div className="flinks">

				   <a href="/term-conditions.html" target="_blank" rel="noopener noreferrer">{cartStore.menuStore.translation.term_condition} </a>
				   <a href="/privacy-policy.html" target="_blank" rel="noopener noreferrer">{cartStore.menuStore.translation.privacy_policy} </a>
				   <a href="/return-policy.html" target="_blank" rel="noopener noreferrer">{cartStore.menuStore.translation.return_policy} </a>
				   <a href="/faq.html" target="_blank" rel="noopener noreferrer">{cartStore.menuStore.translation.faq} </a>
				</div>
     		</div>)}
        </div>

        <ItemInfo visible={cartStore.menuStore.itemModal.isVisible} />
        <Modal
          visible={cartStore.menuStore.itemModal.multiCheckBox}
          transparent={true}
          maskClosable={false}
          animationType="slide-up"
          title={cartStore.menuStore.itemModal.isMenuPopup ? cartStore.menuStore.itemModal.item_popup_name : cartStore.menuStore.translation.opt_selection_title}
          className={cartStore.menuStore.itemModal.isMenuPopup ? "App table_question spacebottom" : "App table_question"}
          closable={cartStore.menuStore.itemModal.isMenuPopup}
          onClose={() => {
            //  console.log(555);
            cartStore.menuStore.itemModal.multiCheckBox = false;
          }}
          footer={cartStore.menuStore.itemModal.footerBtn}
        >
          <div className="row borderTop">
            {cartStore.menuStore.itemModal.checkboxOptions}
          </div>
        </Modal>
      </div>
    );
  }
});

const MenuEntry = observer(({ header, items, cartStore }) => {
 //<CocoListEntry key={index} item={item} cartStore={cartStore} />
  if (cartStore.menuStore.layout ===4) {
    return (
	  <div id={header} className="gridItemBox itemCatList">	    
        <div className="menuHeader">{header}</div>
		  <section className={"gridContainer listvw"}>
          {items.map((item, index) => (        
          <div className="menuItemsFlex" key={index+"-cocl"}>		  
			<CocoListEntry key={index} item={item} cartStore={cartStore} />
		  </div>	
          ))}
        </section>
      </div>
    );
  }
  else if (cartStore.menuStore.layout === 0) {
    return (
      <div id={header} style={{ padding: 6 }} className="itemCatList">
        <div className="menuHeader">{header}</div>
        {items.map((item, index) => (
          <ListEntry key={index} item={item} cartStore={cartStore} />
        ))}
      </div>
    );
  }
  else if (cartStore.menuStore.layout === 3) {
    return (
      <div id={header} style={{ padding: 6 }} className="itemCatList">
        <div className="menuHeader">{header}</div>
        {items.map((item, index) => (
          <ListLightEntry key={index} item={item} cartStore={cartStore} />
        ))}
      </div>
    );
  }
  else {
    return (
      <div id={header} className="gridItemBox itemCatList">
        <div className="menuHeader">{header}</div>
        <section className={cartStore.menuStore.layout === 2 ? "gridContainer listvw newGridtheme" : "gridContainer listvw"}>
          {items.map((item, index) => (
            <GridEntry key={index} item={item} cartStore={cartStore} />
          ))}
        </section>
      </div>
    );
  }
});
const GridEntry = observer(({ item, cartStore }) => {
  let avail = cartStore.menuStore.isAvailable(item);
  let priceTx = parseFloat(item.price) === 0 ? cartStore.menuStore.translation.price_zero : item.displayPrice;
  let infoItem = item.name.includes('#INFO#');
  let name = item.name.replace('#INFO#', '');
  let infoClass = "";
  // let bgwhite="";
  // let link1="";
  let link = "";

  const chef1 = item.chef1 || 0;

  if (infoItem) {
    infoClass = "infoItem";
    let regex = /href\s*=\s*(['"])(https?:\/\/.+?)\1/ig;
    let links = [];
    name.replace(regex, function (url) {
      links.push(url);
      return url;
    });
    if (links.length > 0) {
      link = links[links.length - 1];
      link = link.replace('href=', '');
      link = link.replace(/["']/g, "");
      //link=link.replace("'","");
    }
    //console.log(link);
  }
  let qty = cartStore.quantity_map.get(item.id);
  name = _appendQty(qty, name);
  let btnCls="gridItemButton";
  if(cartStore.menuStore.layout === 2)
  {
	  btnCls+=" break-new-theme-btn";
  }
  if(qty>0)
  {
	  btnCls+=" qtyc";
  }
  return (
    <div className={item.promoName.replace(/ /g, '-').toLowerCase() + " itemslist gridItem " + infoClass} id={item.id}>
      <div className={infoItem ? "imgdv gridcircle" : "gridcircle"}>
        {
          chef1 === 1 ? (
            <span className="chefRecommended"></span>
          ): null
        }
        <div className="thumbnailPlaceholder" />
        <img
          className="gridEntryImage imageLoaded"
          src={item.thumbnail}
          alt=""
          onClick={() => {
            //console.log(88);
            if (item.isInStock && avail && !infoItem) {
              //cartStore.menuStore.itemModal.setItem(item);
			  manageItemInfo(item,cartStore);
            }
            else if (infoItem && link !== "") {
             // window.gtag('event', 'CLICK', { 'event_category': 'URL', 'event_label': link, 'value': 1 });
              cartStore.menuStore.sendGoogleAnalytics('URL', 'CLICK', link, 1);
              window.open(link, '_blank');
            }
          }}
        />
      </div>
      <div style={{ padding: 6 }} className={infoItem ? "cont mobilepadding" : "mobilepadding"}>
        <span className="itemPrice" dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(priceTx) }} />
        <br />
        <span className="max-lines" style={{ minHeight: "2.8em" }} dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(name) }}
          onClick={() => {
            if (infoItem && link !== "") {
            //  window.gtag('event', 'CLICK', { 'event_category': 'URL', 'event_label': link, 'value': 1 });
              cartStore.menuStore.sendGoogleAnalytics('URL', 'CLICK', link, 1);
              //window.open(link,'_blank');
            }
          }}
        />
        {infoItem ? (
          <span className="max-lines" style={{ minHeight: "2.8em" }} dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(item.description) }} />) : null}
      </div>
      {item.isInStock && avail && !infoItem ? (
        <Button
          size={cartStore.menuStore.layout === 2 ? "large" : "small"}
          icon={
            qty > 0 ? (
              <FaCheckCircle color={"green"} />
            ) : (
                <FaPlusCircle />
              )
          }
          className={btnCls}
          onClick={() => {
            // cartStore.menuStore.itemModal.setItem(item);
            //console.log(5);
            specialRequest(cartStore, item, 1, 1);
          }}
        >
          {cartStore.menuStore.translation.cart}
        </Button>
      ) : null}


      {!avail ? (
        <Button
          className="gridItemButton"
          size="small"
          style={{ color: "gray" }}
          disabled
        >
          {cartStore.menuStore.translation.not_available}
        </Button>

      ) : null}
      {!item.isInStock && !infoItem ? (
        <Button
          className="gridItemButton"
          size="small"
          style={{ color: "gray" }}
          disabled
        >
          {cartStore.menuStore.translation.out_of_stock}
        </Button>


      ) : null}
    </div>
  );
});

const ListEntry = observer(({ item, cartStore }) => {
  let avail = cartStore.menuStore.isAvailable(item);
  let priceTx = parseFloat(item.price) === 0 ? cartStore.menuStore.translation.price_zero : item.displayPrice;
  let infoItem = item.name.includes('#INFO#');
  let name = item.name.replace('#INFO#', '');

  const chef1 = item.chef1 || 0;
  
  return (
    <div
      style={{
        display: "flex",
        paddingBottom: 15,
        position: "relative",
        width: "100%"
      }}
      className={item.promoName.replace(/ /g, '-').toLowerCase() + " itemslist"}
      key={item.id}
      id={item.id}
    >
      <div
        style={{
          paddingRight: 6,
          position: "relative"
        }}
        onClick={() => {
          if (item.isInStock && avail) {
            //cartStore.menuStore.itemModal.setItem(item);
			manageItemInfo(item,cartStore);
          }
        }}
        className="customList"
      >
        {
          chef1 === 1 ? (
            <span className="chefRecommended"></span>
          ): null
        }
        <div className="thumbnailPlaceholder" />
        <img
          className="listEntryImage imageLoaded"
          src={item.thumbnail}
          alt=""
        />
      </div>

      <div className="li-text" style={{ paddingLeft: "15px" }}>
        <h4 className="li-head max-lines" dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(name) }} />
        <p className="li-sub" style={{ paddingTop: 3 }}>
          <span className="itemPrice" dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(priceTx) }} />

          {!item.isInStock && (
            <span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
            >
              {cartStore.menuStore.translation.out_of_stock}
            </span>
          )}
          {!avail && (
            <span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
            >
              {cartStore.menuStore.translation.not_available}
            </span>
          )}
        </p>

        {item.isInStock && avail && !infoItem ? (
          <div>
            <FoodStepper
              value={
                cartStore.quantity_map.get(item.id)
                  ? cartStore.quantity_map.get(item.id)
                  : 0
              }
              min={0}
              onChange={throttle(
                quantity => {
                  /* if (item.hasAddons) {
                     cartStore.menuStore.itemModal.setItem(item);
                   } else {
                       cartStore.addItemFromMenu(item, quantity);
           cartStore.menuStore.sendGoogleAnalytics(cartStore.menuStore.googleAddItem,item.name,cartStore.menuStore.controller,item.price*quantity);
                   }*/
                  // console.log(quantity);
                  specialRequest(cartStore, item, -quantity, 0);
                },
                250,
                { maxWait: 1000 }
              )}
              disabled={item.isInStock ? false : true}
			  screen="menuscreen"
			  checkQuanityError={checkQuanityError}
			  cartStore={cartStore}
			  item={item}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
});
const CocoListEntry = observer(({ item, cartStore }) => {
  let avail = cartStore.menuStore.isAvailable(item);
 // let amt= cartStore.getLeastAddonPrice([item.addons]);
  let amt=0;
  if(item.hasAddons)
  {
	  amt= cartStore.getLeastAddonPrice([item.addons]);
  }
  let priceTx = parseFloat(item.price) === 0 ? "From "+cartStore.menuStore.currencySymbol+parseFloat(amt).toFixed(2):item.displayPrice;
  let infoItem = item.name.includes('#INFO#');
  let name = item.name.replace('#INFO#', '');
  let qty = 0;
 // let qty1 = cartStore.getItemQty(item);

  if(cartStore.cartQty>0)
  {
	  qty = cartStore.getItemQty(item);
  }  
  //console.log(cartStore.cartQty,qty);
  const chef1 = item.chef1 || 0;
  let parentCls=item.promoName.replace(/ /g, '-').toLowerCase() + " itemslist";
  if(chef1===1)
  {
	  parentCls=parentCls+" parentChef";
  }
  return (
    <div
      style={{
        display: "flex",
        paddingBottom: 15,
        position: "relative",
        width: "100%"
      }}
      className={parentCls}
      key={item.id}
      id={item.id}
    >
      <div
        style={{
          paddingRight: 6,
          position: "relative"
        }}
        onClick={() => {
          if (item.isInStock && avail) {
            //cartStore.menuStore.itemModal.setItem(item);
			manageItemInfo(item,cartStore);
          }
        }}
        className="customList"
      >
        {
          chef1 === 1 ? (
            <span className="chefRecommended"></span>
          ): null
        }
        <div className="thumbnailPlaceholder" />
        <img
          className="listEntryImage imageLoaded"
          src={item.thumbnail}
          alt=""
        />
      </div>

      <div className="li-text" style={{ paddingLeft: "15px" }}>
        <h4 className="li-head max-lines" dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(name) }} />
		<div className="max-lines" style={{ minHeight: "2.8em" }} dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(item.description) }} />
		<div className="priceDetails">
        <p className="li-sub" style={{ paddingTop: 3 }}>
          <span className="itemPrice" dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(priceTx) }} />
       </p>
        
       {!item.isInStock && (
            <div><span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
			  // onClick={() => {
						// specialRequest(cartStore, item, 1, 1);
						// }}
            >
              {cartStore.menuStore.translation.out_of_stock}
            </span></div>
          )}
          {!avail && item.isInStock && (
		  <div>
            <span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
            >
              {cartStore.menuStore.translation.not_available_now}
            </span>
			</div>
          )}		
        {item.isInStock && avail && !infoItem ? (
				  <div>
				   {qty>0 ?(
					<FoodStepper
					  value={qty}
					  min={0}
					  onChange={throttle(
						quantity => {                 
						  specialRequest(cartStore, item, -quantity, 0);
						},
						250,
						{ maxWait: 1000 }
					  )}
					  disabled={item.isInStock ? false : true}
					  screen="menuscreen"
					  checkQuanityError={checkQuanityError}
					  cartStore={cartStore}
					  item={item}
				/>):(
                     <div>				
					<div
					className={"food-stepper-container single"}
					>					   
						<span
						onClick={() => {
						specialRequest(cartStore, item, 1, 1);
						}}
						className="food-stepper-select plus"
						>
						<Icon type="plus" style={{ width: "15px", height: "15px" }} />
						</span>
					</div></div>)}
				  
				  </div>
        ) : null}
		</div>
      </div>
    </div>
  );
});
const ListLightEntry = observer(({ item, cartStore }) => {
  let avail = cartStore.menuStore.isAvailable(item);
  let priceTx = parseFloat(item.price) === 0 ? cartStore.menuStore.translation.price_zero : item.displayPrice;
  if (!priceTx || priceTx.trim() === "") {
    priceTx = "&nbsp;";
  }

  let infoItem = item.name.includes('#INFO#');
  let name = item.name.replace('#INFO#', '');
  let defaultImage = item.thumbnail.includes('/dish');

  const chef1 = item.chef1 || 0;

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        width: "100%"
      }}
      className={item.promoName.replace(/ /g, '-').toLowerCase() + " itemslist thinnercls"}
      key={item.id}
      id={item.id}
    >
      {!defaultImage ? (<div
        style={{
          paddingRight: 6,
          position: "relative",

        }}
        onClick={() => {
          if (item.isInStock && avail) {
            //cartStore.menuStore.itemModal.setItem(item);
			manageItemInfo(item,cartStore);
          }
        }}
        className="customList"
      >
        {
          chef1 === 1 ? (
            <span class="chefRecommended"></span>
          ): null
        }
        <div className="thumbnailPlaceholder" />
        <img
          className="listEntryImage imageLoaded"
          src={item.thumbnail}
          alt=""
        />
      </div>) : ('')}

      <div className={defaultImage ? "li-text paddingleft0" : "li-text paddingleft15"}>
        <h4 className="li-head max-lines" dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(name) }}
          onClick={() => {
            if (item.isInStock && avail) {
              //cartStore.menuStore.itemModal.setItem(item);
			  manageItemInfo(item,cartStore);
            }
          }}
        />
        <p className="li-sub" style={{ paddingTop: 3 }}>
          <span className="itemPrice" dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(priceTx) }}
            onClick={() => {
              if (item.isInStock && avail) {
                //cartStore.menuStore.itemModal.setItem(item);
				manageItemInfo(item,cartStore);
              }
            }}
          />

          {!item.isInStock && (
            <span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
            >
              {cartStore.menuStore.translation.out_of_stock}
            </span>
          )}
          {!avail && (
            <span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
            >
              {cartStore.menuStore.translation.not_available}
            </span>
          )}
        </p>

        {item.isInStock && avail && !infoItem ? (
          <div
            className={defaultImage ? 'defaultimgRight rightstepper' : 'rightstepper'}
          >
            <FoodStepper
              value={
                cartStore.quantity_map.get(item.id)
                  ? cartStore.quantity_map.get(item.id)
                  : 0
              }
              min={0}
              onChange={throttle(
                quantity => {
                  /* if (item.hasAddons) {
                     cartStore.menuStore.itemModal.setItem(item);
                   } else {
                     cartStore.addItemFromMenu(item, quantity);
                       cartStore.menuStore.sendGoogleAnalytics(cartStore.menuStore.googleAddItem,item.name,cartStore.menuStore.controller,item.price*quantity);
                   }*/
                  specialRequest(cartStore, item, -quantity, 0);
                },
                250,
                { maxWait: 1000 }
              )}
              disabled={item.isInStock ? false : true}
			   screen="menuscreen"
			  checkQuanityError={checkQuanityError}
			  cartStore={cartStore}
			  item={item}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
});
const SideMenuEntry = ({ item, menuStore }) => (

  <Item
    wrap={true}
    multipleLine={true}
    className="sizeMenuItem"
    onClick={() => {
      if (menuStore.menu_type === "1") {
        document.getElementById("menuContainer").classList.toggle('toggleMenuCls');
      }
      //document.getElementById("menuContainer").classList.toggle('toggleMenuCls');
      if (menuStore.isSearchDirty) {
        menuStore.resetMenu().then(() => {
          if (menuStore.isAnimation === 1) {
            document.getElementById(item).scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
          }
          else {
            document.getElementById(item).scrollIntoView({ block: "start", inline: "nearest" });
          }
        });
      } else {
        if (menuStore.isAnimation === 1) {
          document.getElementById(item).scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
        }
        else {
          document.getElementById(item).scrollIntoView({ block: "start", inline: "nearest" });
        }
      }
    }}
  >
    {item}
  </Item>
);
const RewardList = observer(({ visible,cartStore,onPressFunction,onSelectFunction,onCloseFunction }) => {
	let userInfo=cartStore.menuStore.social;
	let rewards=userInfo.rewards;
	let isValidFound=false;
		if(cartStore.menuStore.rewardsList && cartStore.menuStore.rewardsList.length>0 && rewards && rewards.length>0)
		{
			let RList=rewards[0];
			cartStore.menuStore.rewardsList.forEach(function (val) {
				if(val.redeem_code===RList.redeem_code)
				{
					isValidFound=true;
				}			
			});	
		}
	return (<Modal placement="left" onClose={() => {				
				onPressFunction();
				}}
				className="profileDrawer"  	
				closable={false}
				visible={visible}>
				<div className="info_header">
				<span className="backBtn"
				onClick={()=>{
				onPressFunction();
				}}
				>
				</span>
				<span className="logoclass">			
				<img src={cartStore.menuStore.getLogo()} alt="logo" style={{ height: "40px" }}
				//onClick={()=>{console.log(5555);}}
				/>
				</span>
				<span className="homeclass">
				</span>
				</div>
				<div className="loginRegister rewardCenter" >   
				<div className="formSection">
				 <div className="rewardsHeading">
				   <div className="nameinfo">
				        <div className="namesection"><p style={{ marginBottom: "0",fontSize:"16",fontWeight:"700" }}>{userInfo.name?userInfo.name:'Hi,'}</p><p className="bgclor">member</p></div><div style={{display:"flex"}}><h1 style={{fontSize:"22px", paddingLeft:"15px",fontWeight:"700"}}>{userInfo.points?userInfo.points:'0'}</h1><p style={{fontSize:"12px",paddingTop:"12px"}}>Points</p></div>
						
				   </div>
				   <div className="textSelect">  Select to redeem reward</div>
				   </div>
				   <div className="itemRwScroll">
				    {cartStore.menuStore.rewardsList.map((item, index) => (
				      <Reward key={index+"-reward"} index={index} item={item} onSelectFunction={onSelectFunction} selectedRewrads={rewards}  isValidFound={isValidFound}   />
					 ))}
				   </div>
					 <div className="emptydiv">&nbsp;&nbsp;</div>
				</div>			
				</div>  
				<div className="continueWithRwContainer">
                  <div className="continueWithRw formSection">
				     <p style={{ marginBottom: "0",paddingLeft:"15px",fontSize:"12px",fontWeight:"700" }}
					 onClick={()=>{
				       onCloseFunction();
				}}
					 >Continue without redeeming<i className="continueWithRwArrow"></i></p>
				   </div>					
				</div>					
				</Modal>);
});
const Reward = observer(({ index,item,onSelectFunction,selectedRewrads,isValidFound }) => {
	    let found=false;
		let rewardImage=configParam.rewardImage;
		if(item.image_url && item.image_url!=="")
		{
			rewardImage=item.image_url;
		}

		let totalSlect=selectedRewrads?selectedRewrads.length:0;
		if(totalSlect>0)
		{
			selectedRewrads.forEach(function (val) {
				if(val.redeem_code===item.redeem_code)
				{
					found=true;
				}			
			});	
		}
		
		return (<div className={isValidFound?"rewardPoints disableReward":"rewardPoints"}
		key={"reward-"+index}
		onClick={()=>{
			    // if(found)
				// {
				   // onSelectFunction('');	
				// }
				// else
				// {
				  onSelectFunction(item);	
				//}
				
		}}
		>
		<div className="rewardGiftIcon" style={{backgroundImage:'url('+rewardImage+')'}}></div>
		<div style={{color:(isValidFound?"#979797":"")}} className={found?"rewardWhiteBox activeRwBox":"rewardWhiteBox"}><p className="rwrdAmtCenterText">{item.redeem_name}</p>
			{/*<p className="rwrdAmtCenterText">{item.points_needed} Points</p>*/}
			{found?(<p className="removereward">Remove</p>):('')}
		</div>
		</div>);
});
const RewardItemList = observer(({ visible,cartStore,selectedReward, onPressFunction,menuToDisplay }) => {
	let rewardName=cartStore.checkRewardItemInCart();
	return (	
		<Modal
		visible={visible}
		style={{ overflow: "hidden", height: "100%", textAlign: "center" }}
		className="profileDrawer"  
		>
		<div className="info_header">
		<span className="backBtn"
		onClick={()=>{
		  onPressFunction();
		}}
		>
		</span>
		<span className="logoclass">			
		<img src={cartStore.menuStore.getLogo()} alt="logo" style={{ height: "40px" }}
		//onClick={()=>{console.log(5555);}}
		/>
		</span>
		<span className="homeclass">
		</span>
		</div>	
		<div className="fixedMenuSection" >   
		<div className="mainMenuContainer rewardMenuContainer">
		  <div style={{color:"black",fontWeight:"700"}} >{selectedReward.redeem_name}</div>
		  <div style={{color:"black",fontWeight:"500",fontSize:"13px"}} >Select eligible item for reward</div>
		   {Object.keys(menuToDisplay).map((key, index) => {
            return (
              <RewardMenuEntry
                header={key}
                items={menuToDisplay[key]}
                cartStore={cartStore}
                key={`rewardItem${index}`}
				selectedReward={selectedReward}
				rewardName={rewardName}
              />
            );
          })}
		  {menuToDisplay.length===0?(
		  <div>Items not found</div>
		  ):('')}
		</div>			
		</div>
		<div className="continueWithRwContainer" style={{paddingTop:"15px"}}>
		<div className="continueWithRw formSection">
		<p style={{ marginBottom: "0",paddingLeft:"15px",fontSize:"12",fontWeight:"700" }}
		onClick={()=>{
			cartStore.menuStore.isRewardModalVisible=false;
		  //this.props.onPressClose();
		}}
		>Continue  <i className="continueWithRwArrow"></i></p>
		 </div>			
		</div>			
		</Modal>);
});
const RewardMenuEntry = observer(({ header, items, cartStore, selectedReward,rewardName }) => {
    return (
	  <div id={header} className="gridItemBox itemCatList">	  
       <div className="">	  
        <div className="menuHeader" style={{textAlign:"left"}}>{header}</div>
		  <section className={"gridContainer listvw"}>
          {items.map((item, index) => (        
          <div className="menuItemsFlex rewardItemsFlex" key={index+"-relist"}>		  
			<RewardItemListEntry key={index} item={item} cartStore={cartStore} selectedReward={selectedReward} rewardName={rewardName}/>
		  </div>	
          ))}
        </section>
		 </div>
      </div>
    );
  });
const RewardItemListEntry = observer(({ item, cartStore,selectedReward,rewardName }) => {
  let avail = cartStore.menuStore.isAvailable(item);
  let amt=0;
  if(item.hasAddons)
  {
	  amt= cartStore.getLeastAddonPrice([item.addons]);
  }
   let priceTx =item.displayPrice;
   if(parseFloat(item.price) === 0)
   {
	  priceTx = "From "+cartStore.menuStore.currencySymbol+parseFloat(amt).toFixed(2);
   }
   else
   {
	   let {actualPrice,priceAmt}=cartStore.calculateRewardDiscoutPrice(selectedReward,item.price);
	   if(actualPrice!==priceAmt)
	   {
		   priceTx=<Fragment><strike className="strikeprice">{cartStore.menuStore.currencySymbol+parseFloat(actualPrice).toFixed(2)}</strike>{cartStore.menuStore.currencySymbol+parseFloat(priceAmt).toFixed(2)}</Fragment>;
	   }	      
   }
  let infoItem = item.name.includes('#INFO#');
  let name = item.name.replace('#INFO#', '');
  let qty = cartStore.getRewardItemQty(item);
  const chef1 = item.chef1 || 0;
  let parentCls=item.promoName.replace(/ /g, '-').toLowerCase() + " itemslist";
  if(chef1===1)
  {
	  parentCls=parentCls+" parentChef";
  }
  return (
    <div
      style={{
        display: "flex",
        paddingBottom: 15,
        position: "relative",
        width: "100%"
      }}
      className={parentCls}
      key={item.id}
      id={item.id}
    >
      <div
        style={{
          paddingRight: 6,
          position: "relative"
        }}       
        className="customList"
      >
        {
          chef1 === 1 ? (
            <span className="chefRecommended"></span>
          ): null
        }
        <div className="thumbnailPlaceholder" style={{borderRadius:"20px"}}/>
        <img
          className="listEntryImage imageLoaded rwImageRadius"
          src={item.thumbnail}
          alt=""
        />
      </div>

      <div className="li-text" style={{ paddingLeft: "15px",textAlign: "left" }}>
        <h4 className="li-head max-lines" style={{color:"black",fontWeight:"800"}} dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(name) }} />{rewardName}
		<div className="max-lines" style={{ minHeight: "2.8em" }} dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(item.description) }} />
		<div className="priceDetails" style={{display:"flex",paddingTop:"25px"}}>
        <p className="li-sub" style={{ paddingTop: 3 }}>
          <span className="itemPrice" >{priceTx}</span>
            
	    </p>
        
       {!item.isInStock && (
            <div><span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
            >
              {cartStore.menuStore.translation.out_of_stock}
            </span></div>
          )}
          {!avail && item.isInStock && (
		  <div>
            <span
              style={{
                display: "block",
                color: "gray",
                fontSize: 12,
                paddingTop: 9,
                width: 138,
                textAlign: "center"
              }}
            >
              {cartStore.menuStore.translation.not_available_now}
            </span>
			</div>
          )}		
              {item.isInStock && avail && !infoItem ? (
				  <div>
				   {qty > 0 ?(
				    <div>				
					<div
					className={"food-stepper-container single"}
					>					   
						<span
						onClick={() => {
							cartStore.removeItem(item);
						}}
						className="food-stepper-select tick rewardMenuList"
						>
						
						</span>
					</div></div>
				   ):(	   
                     <div>				
					<div
					className={"food-stepper-container single"}
					>					   
						<span
						onClick={() => {
							//manageItemInfo(item,cartStore);
							cartStore.menuStore.itemModal.setItem(item,selectedReward);
						}}
						className="food-stepper-select plus rewardMenuList"
						>
						
						</span>
					</div></div>)}
				  
				  </div>
        ) : null}
		</div>
      </div>
    </div>
  );
});  
function checkQuanityError(cartStore, item, qty) {
	let qty1=qty;
	let isNextproceed=true;
	if(qty<0)
	{
		let enty=cartStore.getEntry(item);
		qty1=1;
		if(enty)
		{
			 qty=Math.abs(qty); 
			let oldQty=cartStore.quantity_map.get(item.id);
			//console.log(qty,'<',oldQty);
			if(qty<oldQty)
			{
			  qty1=0;
              isNextproceed=false;			  
			}
		}		
	}
	if(!isNextproceed)
	{
		return true;
	}
	let erroMsg=cartStore.checkQuantityValidation(item,qty1);
	if(erroMsg!=="")
	{
	alert(cartStore.menuStore.translation.error, erroMsg, [
	{ text: "Close", onPress: () => { } }
	]);
	   return false;
	}
	return true;
}
function specialRequest(cartStore, item, qty, isSingleQty) {

   let errFound=checkQuanityError(cartStore, item, qty);
   if(qty<0)
   {
	  qty=Math.abs(qty); 
   }
   if(!errFound)
   {
	   return false;
   }	   
   else if (item.hasAddons || cartStore.menuStore.layout===4) {
	   manageItemInfo(item,cartStore);
    
  } else {
    let cQty = cartStore.quantity_map.get(item.id);
    // console.log(isSingleQty,cQty,qty);
    let starCnt = item.opt1.split('*');
    if (item.opt1 !== "" && cartStore.menuStore.auto_modifier.toString() === '1' && starCnt.length === 1 && ((qty > cQty && isSingleQty === 0) || isSingleQty === 1)) {
      let priceTx = parseFloat(item.price) === 0 ? cartStore.menuStore.translation.price_zero : item.displayPrice;
      if (!priceTx || priceTx.trim() === "") {
        priceTx = "";
      }
      let name = item.name.replace('#INFO#', '');
      let item_popup_name = _renderName(cartStore.menuStore.htmlDecode(name), cartStore.menuStore.htmlDecode(priceTx));
      let optList = [];
      let optSelList = [];
      let footerbtn = [];
      let options = item.opt1.split(',');
      let singleSelection = false;
      if (options.length > 0) {

        options[0] = options[0].replace('*', '').trim();
        if (options[options.length - 1].trim() !== "") {
          singleSelection = true;
        }
        let k=0;
        options.forEach(function (val) {
          val = val.trim();
          //let namStr = val.replace(EXP_TRIM, "");
          if (val !== "") {
            k++;
            let idstr="optMenu"+item.id+k;
            optList.push(
              <div className="optselection" key={val}
                id={idstr}
                onClick={(l) => {
                  let element = document.querySelector('#' +idstr);
                  let cls = "selectedOpt";
                  //	let arr = element.className.split(" ");
                  if (optSelList.indexOf(val) === -1) {
                    if (singleSelection && optSelList.length > 0) {
                        optSelList=[];   
                        let oldone = document.querySelector('.selectedOpt');                          
                        oldone.className = oldone.className.replace(" selectedOpt", "");     
                    }
                    element.className += " " + cls;
                    optSelList.push(val);
                  }
                  else {
                    optSelList.splice(optSelList.indexOf(val), 1);
                    element.className = element.className.replace(" selectedOpt", "");
                  }

                }}
              >
                {val}
              </div>
            );
          }
        });

        footerbtn.push({
          text: cartStore.menuStore.translation.more,
          onPress: () => {
            //cartStore.menuStore.item_popup_name="";
            cartStore.menuStore.itemModal.setMultiChoiceWithFooter(false, [], [], "");
            cartStore.menuStore.itemModal.setItem(item,{});
          }

        });
        footerbtn.push({
          text: cartStore.menuStore.translation.add,
          onPress: () => {

            //cartStore.menuStore.item_popup_name="";
            let obj = {};
            obj.item = item;
            obj.addons = null;
            obj.notes = [optSelList.join(';')];
            cartStore.addItem(obj);
            //cartStore.addItemFromMenu(item, 1);
            cartStore.menuStore.sendGoogleAnalytics(cartStore.menuStore.googleAddItem, item.name, cartStore.menuStore.controller, item.price * 1 * 100);
            cartStore.menuStore.itemModal.setMultiChoiceWithFooter(false, [], [], "");
          }

        });

        //  cartStore.menuStore.itemModal.setMultiChoice(true,optList);
        cartStore.menuStore.itemModal.setMultiChoiceWithFooter(true, optList, footerbtn, item_popup_name);
      }
    }
    else {
      if (isSingleQty === 1) {
        let obj = {};
        obj.item = item;
        obj.addons = null;
        obj.notes = [""];
        cartStore.addItem(obj);
      }
      else {
        cartStore.addItemFromMenu(item, qty);
      }

      // cartStore.menuStore.sendGoogleAnalytics(cartStore.menuStore.googleAddItem, item.name,cartStore.menuStore.controller,item.price*1);
    }

    //}
  }
}
function _renderName(name, priceTx) {
  return (
    <div>
      <span className="max-lines fontboldstyle" style={{ minHeight: "2.8em" }} dangerouslySetInnerHTML={{ __html: name }} />
      <p className="li-sub" style={{ paddingTop: 3 }}>
        <span className="itemPrice" dangerouslySetInnerHTML={{ __html: priceTx }} />
      </p>
    </div>
  )
}
function _appendQty(qty, name) {
  if (qty > 0) {
    return '<span class="qtyc">' + qty + 'x ' + name + '</span>';
  }
  return name;
}
function manageItemInfo(item,cartStore)
{
      if(item.isCustomize)
	   {
		let enty=cartStore.getEntry(item);
		if(enty)
		{
			cartStore.menuStore.itemModal.showUpdateModal(
              enty.item,
              enty.addons,
              enty.notes
            );          
          return false;
		}  
	   }
	 cartStore.menuStore.itemModal.setItem(item,{});
}
export {Menu,RewardItemList,RewardList};
