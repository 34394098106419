const FacebookParser = raw => {
  return {
    id: raw._profile.id,
    name: raw._profile.name,
    email: raw._profile.email,
    provider: "facebook"
  };
};

const GoogleParser = raw => {
  return {
    id: raw._profile.id,
    name: raw._profile.name,
    email: raw._profile.email,
    provider: "google"
  };
};

const InstagramParser = raw => {
  return {
    id: raw._profile.id,
    name: raw._profile.name,
    email: raw._profile.email,
    //email: raw._profile.name,
    provider: "instagram"
  };
};
const TwitterParser = raw => {
  return {
    name: raw.name,
    email: raw.email,
    provider: "twitter"
  };
};

const Parser = {
  facebook: FacebookParser,
  google: GoogleParser,
  instagram: InstagramParser,
  twitter: TwitterParser
};

export default Parser;
