import React, { Component,Fragment } from "react";
import { TabBar, SearchBar, Modal, NavBar,Button } from "antd-mobile";
import { Icon,Drawer } from "antd";
import { FiShoppingCart, FiList, FiLayers, FiMaximize,FiMenu } from "react-icons/fi";
import {Menu,RewardItemList,RewardList} from "./components/Menu";
import Cart from "./components/Cart";
import Order from "./components/Order";
import PreQuestions from "./components/ActionQuestions";
import LoginModal from "./components/Social/Login";
import HomeModal from "./components/Home";
import CocoMenu from "./components/CocoMenu";
import configParam from "./libs/params";

import API from "./libs/api";
import { inject, observer } from "mobx-react";
import PromptPopup from "./components/Popup";
import { deviceDetect } from "react-device-detect";
//import {FaUserCircle,FaUserEdit } from "react-icons/fa";
import ScrollMenu from 'react-horizontal-scrolling-menu';
//import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
// import DevTools from "mobx-react-devtools";
import _ from "underscore";
const alert = Modal.alert;
const axios = require('axios');
// Milliseconds
const BUFFER_TIME = 10000;
const accessToken = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("accessToken=") !== -1) {
      return window.location.search.split("accessToken=")[1].split("&")[0];
    } else return undefined;
  }
})();
const eberToken = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("eber_token=") !== -1) {
      return window.location.search.split("eber_token=")[1].split("&")[0];
    } else return undefined;
  }
})();
const tag = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("login=false") !== -1) {
      localStorage.setItem(`${this.props.cartStore.menuStore.controller}_socialBypass`, true);
    }
    if (window.location.search.indexOf("tag=") !== -1) {
      return window.location.search.split("tag=")[1].split("&")[0];
    } else return undefined;
  }
})();

const tmatch = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("tmatch=") !== -1) {
      return window.location.search.split("tmatch=")[1].split("&")[0].toLowerCase();
    } else return undefined;
  }
})();

const code = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("code=") !== -1) {
      return window.location.search.split("code=")[1].split("&")[0];
    } else return undefined;
  }
})();

const noRedirect = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("noRedirect=") !== -1) {
      return window.location.search.split("noRedirect=")[1].split("&")[0];
    } else return undefined;
  }
})();

const test = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("test=") !== -1) {
      let url = decodeURIComponent(window.location.search);
      return url.split("test=")[1].split("&")[0];
    } else return undefined;
  }
})();

const cacheStatus = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("cache=") !== -1) {
      return window.location.search.split("cache=")[1].split("&")[0];
    } else return undefined;
  }
})();

const defaultPromo = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("promo=") !== -1) {
     // console.log(window.location.search);
      let firstPart = decodeURIComponent(window.location.search).split("promo=")[1].split("=");
     // console.log(firstPart);
      if (firstPart.length > 1) {
        firstPart = firstPart[0].split('&');
        firstPart.pop();
      }
      firstPart = firstPart.join('&');
      return firstPart;
    } else return undefined;
  }
})();

const payment = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("payment=") !== -1) {
      let url = decodeURI(window.location.search);
      return url.split("payment=")[1].split("&")[0];
    }
    /*else if (window.location.search.indexOf("customOrderId=") !== -1) {
        let url=decodeURI(window.location.search);	
            let status=url.split("status=")[1].split("&")[0];		
            let customOrderId=url.split("customOrderId=")[1].split("&")[0];		
            let paylod={'customOrderId':customOrderId,'status':status,'method':'t05pay'};
        paylod=JSON.stringify(paylod);
 
      return paylod;
     }*/
    else if (window.location.search.indexOf("paymentResponse=") !== -1) {
      let url = decodeURI(window.location.search);
      let paylod = url.split("paymentResponse=")[1].split("&")[0];
      //console.log(paylod);
      return paylod;
    }
    else return undefined;
  }
})();

const method = (() => {
  if (window.location.search) {
    if (window.location.search.indexOf("method=") !== -1) {
      return window.location.search.split("method=")[1].split("&")[0];
    } else return undefined;
  }
})();

const NavigationBar = ({ name, logo, toggleSearch, toggleTag, page, cartStore,showTabsPage }) => {
  let rightContent;
 
  /*if (page === "menu") {
    rightContent = [
      // Search button
     
      <Icon key="1" type="bars" style={{fontSize: '25px', color: '#08c' }}  onClick={toggleTag} />
    ];
  } else if (page === "order") {
    rightContent = [<Icon key="1" type="bars"  style={{fontSize: '25px', color: '#08c' }}  onClick={toggleTag} />];
  }*/
  let clsName = "menuTitle";
  //console.log(name.length);
  if (name.length > 16) {
    clsName = "shortTitleMenu";
  }
  if (!logo) {
    clsName += " noimage";
  }
  if(cartStore.menuStore.layout===4)
  {
      rightContent = [<div className="serachIocnRight"><Icon
        key="0"
        type="search"
        style={{ marginRight: "25px", fontSize: '30px', color: '#191919' }}
        onClick={toggleSearch}
        id="searchIcon"
      /></div>];
    
   return (
    <NavBar
      style={{ position: "fixed", top: "0", width: "100%", zIndex: "999" }}
      mode="light"
      className={name.length > 16 ? "mainmenu navBar": "mainmenu shortTitle"}
      leftContent={<div className="leftbarmenu"><FiMenu style={{ fontSize: '29px', color: '#191919' }} onClick={toggleTag} /></div>}
      rightContent={rightContent}
    >
      {
        logo ? <img src={logo} alt="logo"
          onClick={() => {
            cartStore.menuStore.enableHomePopup();
          }}
        /> : null
      }
	  {
        !logo && name && name!=="" ? 
	   <span className={clsName}
        onClick={() => {
          cartStore.menuStore.enableHomePopup();
        }}
        dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(name) }} />
		: null
      }
    </NavBar>
  );
  }
  else
  {
    rightContent = [<Icon key="1" type="bars" style={{ fontSize: '25px', color: '#08c' }} onClick={toggleTag} />];
    if(cartStore.menuStore.showHeaderCart)
    {
      rightContent = [<Fragment><FiShoppingCart size={22}  onClick={() => {
        showTabsPage('cart');
          }}    /><span className="cart-text"  onClick={() => {
        showTabsPage('cart');
          }}>{cartStore.size}</span>&nbsp;&nbsp;<Icon key="1" type="bars" style={{ fontSize: '25px', color: '#08c' }} onClick={toggleTag} /></Fragment>];
    }
    
   return (
    <NavBar
      style={{ position: "fixed", top: "0", width: "100%", zIndex: "999" }}
      mode="light"
      className={name.length > 16 ? "mainmenu navBar": "mainmenu shortTitle"}
      leftContent={<Icon
        key="0"
        type="search"
        style={{ marginRight: "25px", fontSize: '25px', color: '#08c' }}
        onClick={toggleSearch}
        id="searchIcon"
      />}
      rightContent={rightContent}
    >
      {
        logo ? <img src={logo} alt="logo" style={{ height: "40px" }}
          onClick={() => {
            cartStore.menuStore.enableHomePopup();
          }}
        /> : null
      }
	  {
        name && name!=="" ? 
	   <span className={clsName}
        onClick={() => {
          cartStore.menuStore.enableHomePopup();
        }}
        dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(name) }} />
		: null
      }
    </NavBar>
  );
    }
};

@inject("cartStore")
@observer
class App extends Component {
  constructor(props) {
    super(props);
    //this.menuItems = MenuH(list, selected);
    this.state = {
      page: "menu",

      // buffer for calling waiter/bill
      lastCalledWaiter: null,
      lastCalledBill: null,

      // Navigation bar / Popups
      isSearching: false,
      tablePopup: false,
      searchResult: this.props.cartStore.menuStore.menuList,

      // Prompt popup
      promptPopup: false,
      isFull: false,
      promptTitle: "",
      promptContent: "",
      promptLoading: true,
      closePopup: null,

      // Language popup
      langPopup: false,
      tag: this.props.cartStore.menuStore.tag,

      tabClickCounter: 0,
      fullScreenTitle: "Full Screen",
	  menuDrawer:false,
	  //profileDrawer:false,
	  description:"",
	  singleReward:{name:"",image_url:"",description:""},
	  selectedReward:{name:"",image_url:"",description:""}
    };

    this.props.cartStore.menuStore.hideSearchHandler = () => {
      this.setState({
        isSearching: false
      });

      this.searchInput.state.value = "";
      // console.log(this.searchInput);
    };
  }

  componentDidMount() {
    //window.onpopstate =this.setBackPage;
    //window.AddEventListener('onpopstate', this.setBackPage);
    window.history.pushState({ page: 'menu' }, 'menu');
    let obj = this;
    window.onpopstate = function (event) {
      obj.setBackPage(event);
    };
    if (!navigator.onLine) {
      this.openModalNoInternet();
      return;
    }
    // Load the menu passing tag if exist
    this._initMenu();
    //this.setState({ fullScreenTitle:this.props.cartStore.menuStore.translation.full_screen});
    // Load tag stored in cache
    const cachedTag = localStorage.getItem(
      this.props.cartStore.menuStore.controller
    );
    if (cachedTag) {
      /*this.setState({
        tag: JSON.parse(cachedTag).tag
      });*/
      this.props.cartStore.menuStore.tag = JSON.parse(cachedTag).tag;
    }

    // Store a handler to navigate to cart after checkout
    this.props.cartStore.tabHandler = page => {
	  /*if (page === "cart" && this.props.cartStore.menuStore.layout===4) {
             this.setState({ cartDrawer: true, isSearching: false }, () => { });
      }	
	  else{*/
		   this.setState({ page: page, isSearching: false});
	 // }     
      this.props.cartStore.menuStore.searchResult = this.props.cartStore.menuStore.filterMenuList;

    };
    //localStorage.setItem(`${this.controller}_payment`, payment );	
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	 var ele = document.querySelectorAll(".am-tabs-tab-bar-wrap .am-tab-bar-tab");
    if (iOS && ele.length>3) {     
      var lastEle = ele[ele.length - 1];
      lastEle.style.display = "none";
    }

    const paymentRes = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_payment`);
    if (paymentRes && payment === undefined) {
      localStorage.setItem('hideMenu', 2);
      //this.props.cartStore.tabHandler("cart");					   

    }

  }
  /* componentWillUnmount() {
   window.onpopstate = () => {}
   }*/

  setBackPage = (event) => {
    event.preventDefault();
   // console.log(event.state);
    if (event.state !== null && event.state.page !== '') {
      this.setState({ page: event.state.page, isSearching: false }, () => { });
    }
  }
  goFull = () => {


    var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

    var docElm = document.querySelector("body");
    var title = "";
    var ele = document.querySelectorAll(".am-tabs-tab-bar-wrap .am-tab-bar-tab");
    var lastEle = ele[ele.length - 1];
    //lastEle.parentNode.removeChild(lastEle);
    if (!isInFullScreen) {

      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }
      else if (docElm.webkitEnterFullScreen) {
        docElm.webkitEnterFullScreen();
      }
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
      lastEle.style.display = "none";
      title = this.props.cartStore.menuStore.translation.normal_full_screen;
      //window.gtag('event', 'Full Screen', { 'event_category': 'Click', 'event_label': this.props.cartStore.menuStore.controller, 'value': 1 });
      this.props.cartStore.menuStore.sendGoogleAnalytics('Click', 'Full Screen', this.props.cartStore.menuStore.controller, 1);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      lastEle.style.display = "block";
      title = this.props.cartStore.menuStore.translation.full_screen;
      //window.gtag('event', 'Exit Full Screen', { 'event_category': 'Click', 'event_label': this.props.cartStore.menuStore.controller, 'value': 1 });
      this.props.cartStore.menuStore.sendGoogleAnalytics('Click', 'Exit Full Screen', this.props.cartStore.menuStore.controller, 1);
    }
    this.setState({ isFull: !this.state.isFull, fullScreenTitle: title });

  }

  _initMenu() {
    
    this.setState({
      promptPopup: false,
      closePopup: this._closePopup,
      fullScreenTitle:this.props.cartStore.menuStore.translation.full_screen
    });
      if(noRedirect && noRedirect!=="")
      {
      localStorage.setItem(`${this.props.cartStore.menuStore.controller}_noRedirect`,noRedirect);
      }
     if (tmatch && tmatch !== "" ) {
      let t=this.props.cartStore.menuStore.getTagValue();
      if (tag && tag!=="")
      {
          t=tag;
      }
      t=t.toLowerCase();      
      if(tmatch !== t)
      {
        let closeupScan = [
          {
            text: this.props.cartStore.menuStore.translation.scan,
            onPress: () => {
              window.location = configParam.scanUrl;
            }
          }
        ];
        this.setState({
          promptPopup: true,
          promptTitle: this.props.cartStore.menuStore.translation.error,
          promptContent: this.props.cartStore.menuStore.translation.scan_again,
          promptLoading: false,
          closePopup: closeupScan
        });
      }
    }
   
    
    if(method && method==='bwc')
	{
		let cc = this.props.cartStore.menuStore.controller;	
		localStorage.setItem(`${cc}_bwcMethod`,method);
        	
		localStorage.setItem(`${cc}_black_token`,accessToken);		
	}
	else if(method)
	{
		localStorage.setItem('spotMethod',method);
	}
    this.props.cartStore.menuStore
      .setupMenu(tag, code, test, cacheStatus, payment, defaultPromo, tmatch,eberToken)
      .then(res => {
       
	    
	     
        this.props.cartStore.renderOlderOrder();
        const paymentRes = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_payment`);
        if (paymentRes && payment === undefined) {
          localStorage.setItem('hideMenu', 2);
          this.props.cartStore.tabHandler("cart");

        }
		else if(this.props.cartStore.menuStore.isBWCMethod())
		{				
			this.setBlackAndWhiteRewardTerms();
		}
		else
		{
			this.setEberRewardList();
		}
        if (this.props.cartStore.menuStore.showHome === "1" && this.props.cartStore.menuStore.promotions.length > 0) {
          this.updateHomeModal.reloadModal(2);
        }
        this.props.cartStore._callWebSocket();
        if (
          this.props.cartStore.menuStore.store.allLang === "" ||
          this.props.cartStore.menuStore.store.allLang === undefined || this.props.cartStore.menuStore.store.allLang.split('|').length < 2
        )
          return;
        else {
          const lang = localStorage.getItem(
            `${this.props.cartStore.menuStore.controller}_lang`
          );


          if (lang === null) {
            this._changeLang();
          }
        }

      })
      .catch(err => {
        let msgContent = this._renderErrorMessage();
        let closeup = [
          {
            text: this.props.cartStore.menuStore.translation.retry,
            onPress: () => {
              this._initMenu();
            }
          },
          {
            text: this.props.cartStore.menuStore.translation.scan,
            onPress: () => {
              window.location = configParam.scanUrl;;
            }
          }
        ];
        if (err.error === 'outlet') {
          msgContent = this.props.cartStore.menuStore.translation.outlet_not_found;
          closeup = [
            {
              text: this.props.cartStore.menuStore.translation.scan,
              onPress: () => {
                window.location = configParam.scanUrl;;
              }
            }
          ];
        }
        this.setState({
          promptPopup: true,
          promptTitle: this.props.cartStore.menuStore.translation.error,
          promptContent: msgContent,
          promptLoading: false,
          closePopup: closeup
        });
      });
  }
  _renderTabs = () => {
    const tabs = [
      {
        key: "menu",
        icon: <FiList size={22} />,
        title: this.props.cartStore.menuStore.translation.menu
      },
      {
        key: "cart",
        icon: <FiShoppingCart size={22} />,
        title: this.props.cartStore.menuStore.translation.cart
      },
      {
        key: "order",
        icon: <FiLayers size={22} />,
        title: this.props.cartStore.menuStore.translation.order
      }
      
    ];
     if(!this.props.cartStore.menuStore.isSpotMethod())
	 {
		tabs.push({
        key: "fullscreen",
        icon: <FiMaximize size={22} />,
        title: this.props.cartStore.menuStore.translation.full_screen
      }); 
	 }
     let isDelievryOrPickup=this.props.cartStore.menuStore.checkIsPickUpOrDelivery();	 
    return tabs.map((value, index, list) => {
       // let portCls='viewPort';
	   // if(this.state.page === "menu")
	   // {
		   // portCls+=' menuPort';
		   // if(this.props.cartStore.menuStore.menu_type === '2')
		   // {
			  // portCls+=' menuview'; 
		   // }
		   
	   // }
	   
      return (
        <TabBar.Item
          title={value.title.toUpperCase()}
          key={value.key}
          id={"tab-" + value.key}
          icon={value.icon}
          selectedIcon={value.icon}
          onPress={() => {
            // console.log(this.state.page,value.key,this.props.cartStore.menuStore.menu_type);
			if(this.state.page ==='order' && this.props.cartStore.menuStore.isWebsocketEnabled)
			{
				let uuid=localStorage.getItem('uuid');
				this.props.cartStore.orderStore._callSocketCheckOrderStatus(uuid);
			}
            this._showTabsView(value.key);
          }
          }
          badge={value.key === "cart" ? this.props.cartStore.size : null}
          selected={this.state.page === value.key}
        >   
           		
       {value.key !== "fullscreen" && this.state.page === "menu" && this.props.cartStore.menuStore.layout===4 && (
	        <>
		    <CocoMenu />
			<div className={"viewPort menuview"+this.props.cartStore.menuStore.layout+ (!isDelievryOrPickup?" noaddress":"")+(this.props.cartStore.menuStore.promotions.length < 2?" nosubmenu":"")}>         
            {this._renderPage()}
            </div>
			 </>
		    )}
			{value.key !== "fullscreen" && this.state.page === "menu" && this.props.cartStore.menuStore.layout!==4 && (
			<div className={"viewPort menuview"+this.props.cartStore.menuStore.menu_type}>         
            {this._renderPage()}
            </div>
			)}
		   {value.key !== "fullscreen" && this.state.page !== "menu" &&(
		   <div className={"viewPort"}>         
            {this._renderPage()}
            </div>)}	
        </TabBar.Item>
      );
    });
  };
_showTabsView=(key)=>{
	
	if (this.state.page === "menu" && key === "menu" && this.props.cartStore.menuStore.showHome === "1" && this.props.cartStore.menuStore.promotions.length > 1) {
              //this.props.cartStore.menuStore.enableHomePopup();
              this.props.cartStore.menuStore.isHomeModalVisible = true;
              this.updateHomeModal.reloadModal(2);
            }
            else if (this.props.cartStore.menuStore.menu_type === "1") {
              localStorage.removeItem('hideMenu');
              if (key !== "menu") {
                document.getElementById("menuContainer").classList.add('toggleMenuCls');
                document.getElementById("smallIconArrow").classList.add('hideshowArrow');
              }
              else {
                document.getElementById("menuContainer").classList.toggle('toggleMenuCls');
                document.getElementById("smallIconArrow").classList.remove('hideshowArrow');
              }
            }

            if (key === "fullscreen") {
              this.goFull();
            }
            else {
              window.history.pushState({ page: key }, key);
              if (key === "cart") {
                this.props.cartStore.checkCartsStatus();
              }

              if (this.state.page === "cart" && key === "cart") {

                if (this.props.cartStore.size > 0) {
                  document.getElementById("checkoutButton").click();
                }

              } else {
				  
                this.setState({ page: key, isSearching: false }, () => { });
                if (key === "order") {
                  //window.ga('send', { hitType: 'event', eventCategory: 'order', eventAction: 'order list',  eventLabel:this.props.cartStore.menuStore.controller });
                  //window.gtag('event', 'order list', { 'event_category': 'Click', 'event_label': this.props.cartStore.menuStore.controller, 'value': 1 });
                  this.props.cartStore.menuStore.sendGoogleAnalytics('Click', 'order list', this.props.cartStore.menuStore.controller, 1);
                }
              }
            }
    };
  _renderTableTitle = () => {
	  let uuid=localStorage.getItem('uuid');
    return (
      <div>

        <div className="table_popup_header">
          Login as {this.props.cartStore.menuStore.social.name ? this.props.cartStore.menuStore.social.name : 'Guest'}
        </div>
        <div style={{ fontSize: "0.9em", paddingTop: 10 }}>
          {this.props.cartStore.menuStore.translation.menu_option_title}{" "}
          {this.props.cartStore.menuStore.tag}
        </div>
        <div style={{ fontSize: "0.9em", paddingTop: 10 }}>
          ({this.props.cartStore.menuStore.translation.menu_option_description}{" "}
          {this.props.cartStore.menuStore.store.menuVer})
        </div>
		<div style={{color:"#808080", paddingTop: 10 }}>
         <small>{uuid}</small>
        </div>

      </div>
    );
  };

  _toggleSearch = () => {	
	
	if(!this.state.isSearching && this.state.page!=='menu')
	{
		this._showTabsView('menu');
	}
	this.setState(
      { isSearching: !this.state.isSearching, selectedTab: "menu" },
      () => {
        if (this.state.isSearching){
			this.searchInput.focus();
		} 
      }
    );
  };

  _searchMenu = searchTerm => {
    if (searchTerm) {
      // Update flag
      this.props.cartStore.menuStore.isSearchDirty = true;

      // Clean search term
      searchTerm = searchTerm.trim().toLowerCase();
      let listData = this.props.cartStore.menuStore.menuList;
      if (this.props.cartStore.menuStore.searchType === 'promo' && this.props.cartStore.menuStore.promotions.length > 1) {
        listData = this.props.cartStore.menuStore.filterMenuList;
      }
      // Search through menu
      let res = _.mapObject(
        listData,
        (val, key, obj) => {
          // 1 - One-to-one comparison for menu categories
          // if (key.trim().toLowerCase() === searchTerm ) return val;

          // 2 - Check if dish name contains search term
          let filtered = _.filter(val, food => {
            return food.name.toLowerCase().includes(searchTerm) || food.category.toLowerCase().includes(searchTerm) || food.promoName.toLowerCase().includes(searchTerm);

          });
          if (filtered.length) return filtered;
        }
      );

      // Clean undefined
      res = _.pick(res, val => {
        return val;
      });

      this.props.cartStore.menuStore.searchResult = res;

    } else {
      this.props.cartStore.menuStore.searchResult = this.props.cartStore.menuStore.filterMenuList;
    }

  };
  
 _searchRewardItems = ()=> {
	let  selectedReward=this.state.selectedReward;
    if (selectedReward && selectedReward.redeem_code) {
		let skuTerm=selectedReward.pos_redeem_sku?selectedReward.pos_redeem_sku:'';
		let categoryTerm=selectedReward.pos_redeem_category?selectedReward.pos_redeem_category:'';
	
      skuTerm = skuTerm.trim().toLowerCase();
      categoryTerm = categoryTerm.trim().toLowerCase();
	  skuTerm=skuTerm.split(',');
	  categoryTerm=categoryTerm.split(',');
	  skuTerm = skuTerm.filter(function(el) { return el; });
	  categoryTerm = categoryTerm.filter(function(el) { return el; });
      let listData = this.props.cartStore.menuStore.menuList;
     
      // Search through menu
      let res = _.mapObject(
        listData,
        (val, key, obj) => {
          // 1 - One-to-one comparison for menu categories
          // if (key.trim().toLowerCase() === searchTerm ) return val;

          // 2 - Check if dish name contains search term
          let filtered = _.filter(val, food => {
            return skuTerm.indexOf(food.sku.toLowerCase())>-1 || categoryTerm.indexOf(food.category.toLowerCase())>-1;

          });
          if (filtered.length) return filtered;
        }
      );

      // Clean undefined
      res = _.pick(res, val => {
        return val;
      });
      this.props.cartStore.menuStore.rewardsResult = res;
    }
	else
	{
		this.props.cartStore.menuStore.rewardsResult = [];
	}

  };

  _toggleTag = () => {
	 if(this.props.cartStore.menuStore.layout===4)
	 {
		 console.log(666);
		this.setState({
         menuDrawer: true
       }); 
	 }
	else{
		this.setState({
      tablePopup: true
       });
	}	 
    
  };

  // Takes in a function which will be invoked. The prompt will be updated the same way
  _requestService = fx => {
    this.setState({
      promptPopup: true
    });
    if (fx === API.callForBill) {
    }
    fx()
      .then(res => {
        this.setState({
          promptTitle: this.props.cartStore.menuStore.translation.success,
          promptContent: this.props.cartStore.menuStore.translation
            .menu_success_response,
          promptLoading: false
        });
      })
      .catch(err => {
        this.setState({
          promptTitle: this.props.cartStore.menuStore.translation.error,
          promptContent: this.props.cartStore.menuStore.translation
            .option_fail_content,
          promptLoading: false
        });
      });
  };

  _changeLang = () => {
    const langOptions = this.props.cartStore.menuStore.store.allLang;
    //const langArray=langOptions.split("|");
    if (langOptions !== undefined) {
      let buttons = [];
      _.each(langOptions.split("|"), (val, index) => {
        buttons.push({
          text: val,
          onPress: () => {
            //window.gtag('event', val, { 'event_category': 'language', 'event_label': this.props.cartStore.menuStore.controller, 'value': 1 });
            this.props.cartStore.menuStore.changeLanguage(index);
            this.updateLoginModal.reloadModal(9);
            this.updateHomeModal.reloadModal(1);
            this.props.cartStore.menuStore.sendGoogleAnalytics('language', val, this.props.cartStore.menuStore.controller, 1);
          }
        });
      });

      alert(
        this.props.cartStore.menuStore.translation.option_select_language,
        <div>
          {
            this.props.cartStore.menuStore.translation
              .option_select_language_description
          }
        </div>,
        buttons
      );
    }
  };

  _renderPage = () => {
    switch (this.state.page) {
      case "menu":
        return (
          <div className={this.props.cartStore.menuStore.flashHeaderMsg.length>0?"menuPage":""}
          >
            <Menu cartStore={this.props.cartStore}
              searchResult={this.props.cartStore.menuStore.searchResult}
			  cartItems={this.props.cartStore.cartQty}
			  showTabsPage={this._showTabsView}
			  extrav={this.props.cartStore.cartQty}
            /></div>
        );
      case "cart":
		   return <Cart cartStore={this.props.cartStore} showTabsPage={this._showTabsView} />;
      case "order":
        return <Order showTabsPage={this._showTabsView} />;
      default:
        return null;
    }
  };
  updateLoginModalMounted(reloadModal) {
    this.updateLoginModal = reloadModal
  };
  updateHomeModalMounted(reloadModal) {
    this.updateHomeModal = reloadModal
  };
  _renderErrorMessage = () => {
    return (
      <div dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(this.props.cartStore.menuStore.translation.error_menu) }} />
    );
  };
  _renderSearchBar = () => {
    return (
      <SearchBar
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 9999,
          display: this.state.isSearching ? "flex" : "none"
        }}
        ref={ref => (this.searchInput = ref)}
        placeholder={this.props.cartStore.menuStore.layout===4?this.props.cartStore.menuStore.translation.search_placeholder:this.props.cartStore.menuStore.translation.search}
        maxLength={30}
        cancelText={this.props.cartStore.menuStore.translation.cancel}
        onClear={() => {
          this.setState({
            isSearching: false
          });
        }}
        onChange={this._searchMenu}
        className="searchbar"
      />
    );
  };

  _renderTagPopup = () => {
    const cachedTag = localStorage.getItem(
      this.props.cartStore.menuStore.controller
    );

    let options = [];
    let social=this.props.cartStore.menuStore.social;
    if (!this.props.cartStore.orderStore.isEmpty) {
      options.push({
        text: this.props.cartStore.menuStore.translation
          .menu_option_clear_past_order,
        onPress: () => {
          alert(
            this.props.cartStore.menuStore.translation.option_reset_order,
            this.props.cartStore.menuStore.translation
              .option_reset_order_confirmation,
            [
              {
                text: this.props.cartStore.menuStore.translation.cancel
              },
              {
                text: this.props.cartStore.menuStore.translation.confirm,
                onPress: () => {
                  this.props.cartStore.orderStore.clearOrders();
                //  window.gtag('event', 'Clear Order', { 'event_category': 'Click', 'event_label': this.props.cartStore.menuStore.controller, 'value': 1 });
                  this.props.cartStore.menuStore.sendGoogleAnalytics('Click', 'Clear Order', this.props.cartStore.menuStore.controller, 1);
                  alert(
                    this.props.cartStore.menuStore.translation
                      .option_reset_complete,
                    null,
                    [
                      {
                        text: this.props.cartStore.menuStore.translation.close
                      }
                    ]
                  );
                }
              }
            ]
          );
        }
      });
    }

    if (cachedTag) {
      if (this.props.cartStore.menuStore.store.hasWaiter) {
        options.unshift({
          text: this.props.cartStore.menuStore.translation
            .menu_option_call_waiter,
          onPress: () => {
            this.callWaiterfunction();
          }
        });
      }

      if (this.props.cartStore.menuStore.store.hasCashier) {
        options.unshift({
          text: this.props.cartStore.menuStore.translation
            .menu_option_call_bill,
          onPress: () => {
          //  window.gtag('event', 'Cashier', { 'event_category': 'Click', 'event_label': this.props.cartStore.menuStore.controller, 'value': 1 });
            this.props.cartStore.menuStore.sendGoogleAnalytics('Click', 'Cashier', this.props.cartStore.menuStore.controller, 1);
            const lastCalled = this.state.lastCalledBill;
            if (lastCalled) {
              const now = Date.now();
              if (now - lastCalled < BUFFER_TIME) {
                this.setState({
                  tablePopup: false,
                  promptPopup: true,
                  promptLoading: false,
                  promptTitle: this.props.cartStore.menuStore.translation
                    .success,
                  promptContent: this.props.cartStore.menuStore.translation
                    .menu_success_response
                });

                return;
              }
            }
            if (!navigator.onLine) {
              this.openModalNoInternet();
              return;
            }
            this._requestService(() => {
              return API.callForBill(
                this.props.cartStore.menuStore.store.hasCashier
              );
            });
            this.setState({
              tablePopup: false,
              promptLoading: true,
              promptTitle: this.props.cartStore.menuStore.translation
                .menu_option_request_bill,
              lastCalledBill: Date.now()
            });
          }
        });
      }
    }

    if (
      this.props.cartStore.menuStore.store.allLang !== undefined &&
      this.props.cartStore.menuStore.store.allLang !== "" && this.props.cartStore.menuStore.store.allLang.split('|').length > 1
    ) {
      options.push({
        text: this.props.cartStore.menuStore.translation.menu_option_language,
        onPress: () => {
          this._changeLang();
          this.setState({
            tablePopup: false
          });
        }
      });
    }

    if (this.props.cartStore.menuStore.store.isDev === 0) {
      options.push({
        text: this.props.cartStore.menuStore.translation
          .menu_option_device_info,
        onPress: () => {
          alert(JSON.stringify(deviceDetect()));
          this.setState({
            tablePopup: false
          });
        }
      });
    }
    if (this.props.cartStore.menuStore.showHeaderCart) {
      if (this.state.page !== 'menu' && this.state.page !== 'cart') {
        options.push({
          text: this.props.cartStore.menuStore.translation.cart,
          onPress: () => {
            this._showTabsView('cart');
          }
        });
      }
      if (this.state.page !== 'order') {
        options.push({
          text: this.props.cartStore.menuStore.translation.order,
          onPress: () => {
            this._showTabsView('order');
          }
        });
      }
      if (this.state.page !== 'menu') {
        options.push({
          text: this.props.cartStore.menuStore.translation.menu,
          onPress: () => {
            this._showTabsView('menu');
          }
        });
      }
    }
    if (!this.props.cartStore.menuStore.isHideLogout) {
      options.push({
        text: this.props.cartStore.menuStore.translation.logout,
        onPress: () => {
          
          //this.props.cartStore.entries=[];
          this.setState({
            tablePopup: false
          },()=>{ this.props.cartStore.menuStore.logout();});
          // this.props.cartStore.orderStore.clearOrders();
        }
      });
    }
   if(social && (social.provider==='eber' || social.provider==='bwc')){	
	options.push({
		text: this.props.cartStore.menuStore.translation.rewards,
		onPress: () => {
		this.props.cartStore.menuStore.openRewardListModal=true;	
		// this.setState({
		// profileDrawer:true
		// });
		}
	});		
   }
    options.push({
      text: this.props.cartStore.menuStore.translation.close,
      onPress: () => {
        this.setState({
          tablePopup: false
        });
      }
    });

    return (
      <Modal
        visible={this.state.tablePopup}
        transparent={true}
        maskClosable={false}
        animationType="slide-up"
        closable={true}
        className="App table_popup"
        title={this._renderTableTitle()}
        onClose={() => {
          this.setState({
            tablePopup: false
          });
        }}
        footer={options}
      />
    );
  };
  _renderLastOrderToCheck = () => {
    let lastOrderOptions = [];
    
    lastOrderOptions.push({
      text: this.props.cartStore.menuStore.translation.new_order,
      onPress: () => {
        this.props.cartStore.menuStore.showHideLastOrderCheck();
        /* this.setState({
         showCheckOrder: true
           });*/
        // console.log(this.props.cartStore.menuStore.showCheckLastOrder);
      }
    });
    if (this.props.cartStore.menuStore.check_type === 1) {
      lastOrderOptions.push({
        text: this.props.cartStore.menuStore.translation.last_order,
        onPress: () => {
          this.props.cartStore.menuStore.showlastOrderPupup = 1;
          this.props.cartStore.menuStore.showHideLastOrderCheck();
          this.props.cartStore.tabHandler("order");
        }
      });
    }

    let oldT = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_old_tag`);
    let ctagVal = this.props.cartStore.menuStore.getTagValue();
    if (oldT && ctagVal && ctagVal !== oldT && this.props.cartStore.menuStore.transfer_type === 1) {
      lastOrderOptions.push({
        text: this.props.cartStore.menuStore.translation.change_table,
        onPress: () => {
          this.props.cartStore.menuStore.showlastOrderPupup = 2;
          this.props.cartStore.menuStore.showHideLastOrderCheck();
          this.props.cartStore.tabHandler("order");
        }
      });
    }
    lastOrderOptions.push({
      text: this.props.cartStore.menuStore.translation.close,
      onPress: () => {
        this.props.cartStore.menuStore.showHideLastOrderCheck();
      }
    });
    return (
      <Modal
        visible={this.props.cartStore.menuStore.showCheckLastOrder}
        transparent={true}
        maskClosable={false}
        animationType="slide-up"
        closable={false}
        className="App table_popup checkorder"
        title={this.props.cartStore.menuStore.translation.welcome_back}
        footer={lastOrderOptions}
      />
    );
  };
  _closePopup = () => {
    this.setState(
      {
        promptPopup: false
      },
      () => {
        this.setState({
          promptLoading: true,
          promptTitle: "",
          promptContent: ""
        });
      }
    );
  };

  _renderPromptPopup = () => {
    return (
      <PromptPopup
        visibility={this.state.promptPopup}
        title={this.state.promptTitle}
        closePopup={this.state.closePopup}
        content={this.state.promptContent}
        isLoading={this.state.promptLoading}
        cartStore={this.props.cartStore}
      />
    );
  };
  openModalNoInternet = () => {
    this.setState({
      tablePopup: false,
      promptLoading: false,
      promptPopup: true,
      promptTitle: this.props.cartStore.menuStore.translation.no_internet
    });
  };
  callWaiterfunction = () => {
   // window.gtag('event', 'Waiter', { 'event_category': 'Click', 'event_label': this.props.cartStore.menuStore.controller, 'value': 1 });
    this.props.cartStore.menuStore.sendGoogleAnalytics('Click', 'Waiter', this.props.cartStore.menuStore.controller, 1);
    const lastCalled = this.state.lastCalledWaiter;
    if (lastCalled) {
      const now = Date.now();
      if (now - lastCalled < BUFFER_TIME) {
        this.setState({
          tablePopup: false,
          promptPopup: true,
          promptLoading: false,
          promptTitle: this.props.cartStore.menuStore.translation
            .success,
          promptContent: this.props.cartStore.menuStore.translation
            .menu_success_response
        });

        return;
      }
    }

    if (!navigator.onLine) {
      this.openModalNoInternet();
      return;
    }
    this._requestService(() => {
      return API.callForWaiter(
        this.props.cartStore.menuStore.store.hasWaiter
      );
    });

    this.setState({
      tablePopup: false,
      promptLoading: true,
      promptTitle: this.props.cartStore.menuStore.translation
        .menu_option_waiter_title,
      lastCalledWaiter: Date.now()
    });

  };
  setBlackAndWhiteRewardTerms=()=>{
	  
	    let cc = this.props.cartStore.menuStore.controller;
		let bwToken=localStorage.getItem(`${cc}_black_token`);
		console.log('bwToken',bwToken);
		
		const config = {
		headers: { accessToken: bwToken}
		}
       axios.get(configParam.bwProfileUrl,config).then(res=>{
		        let pData=res.data;
				if(pData.success && pData.success==="failure")
				{
					alert(pData.message);
				}
				else if(pData.error)
				{
					 alert(pData.error.exception);
				}
				else
				{					
					this.props.cartStore.menuStore.rewardsList=[];
					let rData=[];
					if(pData.redeemable_list && pData.redeemable_list.length>0)
					{
					   rData=pData.redeemable_list;				
					}
					this.props.cartStore.menuStore.rewardsList=rData;	
					let social= localStorage.getItem(`${cc}_social`);
					
					let pRes = { name: pData.display_name, eberId:pData.id ,  provider: 'bwc',email: pData.email,username:pData.username,phone_code:pData.phone_code,phone:pData.phone,address:'',points:0,rewards:[],accessToken:bwToken};
					
					if(pData.address && pData.address!=="")
					{
					pRes.address=pData.address;
					}
					if(pData.credits_balance)
					{
						pRes.credits_balance=pData.credits_balance;
					}
					if(pData.points && pData.points.length>0)
					{
					pRes.points=pData.points[0].points;
					}
					if(social!=="")
					{
						social=JSON.parse(social);
						// if(social && social.eberId===pData.id)
						// {
							// pRes.rewards=social.rewards;
						// }
						if(social && social.rewards && social.rewards.length>0)
						{
							let reW=social.rewards[0];
							rData.forEach((el)=>{
								if(el.redeem_code=== reW.redeem_code)
								{
								  pRes.rewards.push(el);
								}
							});
						}							
					}
					if(pData.memberDiscount)
					{
						this.props.cartStore.menuStore.memberDiscount={currency:"SGD",pos_redeem_percentage:pData.memberDiscount,pos_redeem_amount_after_tax: false,pos_redeem_amount_currency: "",pos_redeem_method:"percentage",redeem_code:"IsMemberDiscount",redeem_name: "memberDiscount",redeem_type: ""};
						
						// if(pRes.rewards && pRes.rewards.length===0)
						// {
							// pRes.rewards.push(this.props.cartStore.menuStore.memberDiscount);
						// }
					}					
					this.props.cartStore.menuStore.saveSocial(pRes);
					// this.setState({profileDrawer:true});
					this.props.cartStore.menuStore.openRewardListModal=true;
				}
		
			 }).catch(err => {				  
				// this.setState({ isCheckingEber: 2});
				 console.log('error while get black & white user info',err);
			 });
	
  };
  setRewardTerms=(reward)=>{
	  
	    let cc = this.props.cartStore.menuStore.controller;
		let eberToken=localStorage.getItem(`${cc}_eber_token`);

		const config = {
			headers: { Authorization: `Bearer ${eberToken}` }
		}
		this.setState({ description: 'Checking Details...'});	
	axios.get(configParam.eberRewardsUrl+reward.redeem_code,config).then(res=>{
		if(res.tac && res.tac!=="")
		{
		  this.setState({ description: res.tac});	
		}
		else
		{
			this.setState({ description: ''});
		}				
	}).catch(err => {
	  
	 console.log('error while geteber reward details',err);
	});
	
  }
  setEberRewardList=()=>{
	  
	    let cc = this.props.cartStore.menuStore.controller;
		let eberToken=localStorage.getItem(`${cc}_eber_token`);
         if(eberToken!=="" &&  this.props.cartStore.menuStore.isEberLoginExist && !this.props.cartStore.menuStore.isBWCMethod())
		 {
			const config = {
			headers: { Authorization: `Bearer ${eberToken}` }
			}
     		axios.get(configParam.eberProfileUrl,config).then(res=>{
				
				let pData=res.data;
				this.props.cartStore.menuStore.rewardsList=[];
				let rData=[];
				if(pData.redeemable_list.length>0)
				{
                   rData=pData.redeemable_list;	
				}
                this.props.cartStore.menuStore.rewardsList=rData;
				this.props.cartStore.menuStore.isLoginModalVisible = false;
                this.props.cartStore.menuStore.openRewardListModal=true;
				let social= localStorage.getItem(`${this.props.cartStore.menuStore.controller}_social`);
				
				let pRes = { name: pData.display_name, eberId:pData.id ,  provider: 'eber',email: pData.email,username:pData.username,phone_code:pData.phone_code,phone:pData.phone,address:'',points:0,rewards:[]};
				
				if(pData.address && pData.address!=="")
				{
				pRes.address=pData.address;
				}
				if(pData.points && pData.points.length>0)
				{
				pRes.points=pData.points[0].points;
				}
				if(social!=="")
				{
					social=JSON.parse(social);
					if(social && social.eberId===pData.id)
					{
						pRes.rewards=social.rewards;
					}
				}
				this.props.cartStore.menuStore.saveSocial(pRes);
			 }).catch(err => {	
				 console.log('error while eber login',err);
			 });
			 
		 }
  }
  render() {
    //const { selected } = this.state;
    // Create menu from items
    // const menu = this.menuItems;
    let ua = (navigator.userAgent || navigator.vendor || window.opera);
    let isFb = ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1));

    let fbcls = "";
    if (isFb) {
      fbcls = "fbAppInCls";
    }

    if (this.props.cartStore.menuStore.flashHeaderMsg.length>0) {
      fbcls = fbcls+" flashexist";
    }
    fbcls = fbcls+" menutype"+this.props.cartStore.menuStore.menu_type;
  let insoloCls="";
  let isUniSolon=this.props.cartStore.menuStore.isUnisolnControler();
  if(isUniSolon)
  {
    insoloCls="unisoloSec";
  }
  let cartTitle=this.props.cartStore.menuStore.translation.cart_title;
  let orderTitle=this.props.cartStore.menuStore.translation.order_past_title;
  if(this.props.cartStore.menuStore.layout===4)
  {
	  orderTitle=<img src={this.props.cartStore.menuStore.getLogo()} alt="logo" style={{ height: "40px" }}
          onClick={() => {
            //this.props.cartStore.menuStore.enableHomePopup();
          }}
        />;
	cartTitle=this.props.cartStore.menuStore.translation.my_cart_title;
  }
  let social=this.props.cartStore.menuStore.social;
 // let rewardName=this.props.cartStore.appliedRewardName();
 return (
      <div className="App">
        <div
          className={(this.state.page === 'menu' && this.props.cartStore.menuStore.menu_type === '2' ? "appMain menuAppMain " : "appMain") + " " + this.props.cartStore.menuStore.loadingClass + " " + fbcls+ " " + insoloCls}
          id="mainAppDiv"
        >
          {this.props.cartStore.menuStore.flashHeaderMsg.length>0 && this.state.page === "menu" ?(
              <div className="headerflash" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(this.props.cartStore.menuStore.flashHeaderMsg.join('')) }} />
              ):('')} 
          {this.state.page === "menu" ? (             
            <NavigationBar
              name={this.props.cartStore.menuStore.store.name}
              logo={this.props.cartStore.menuStore.getLogo()}
              toggleSearch={this._toggleSearch}
              toggleTag={this._toggleTag}
              tag={this.props.cartStore.menuStore.tag}
              page={this.state.page}
              cartStore={this.props.cartStore}
			  showTabsPage={this._showTabsView}
            />

          ) : (
              <NavBar
                 className="carthead"               
                icon={
					this.props.cartStore.menuStore.layout===4?<Icon key="1" type="left" style={{ fontSize: '25px', color: '#fff' }}  
					onClick={()=>{this._showTabsView('menu');}}
					/>:''
                }
				rightContent={this.props.cartStore.menuStore.showHeaderCart?<Icon key="1" type="bars" style={{ fontSize: '25px', color: '#fff' }}  
				onClick={this._toggleTag}
				/>:<span className="homeIcon"
				onClick={()=>{this._showTabsView('menu');}}>
				</span> }
              >
			  
				<span style={{ fontWeight: 600 }}>
                    {this.state.page === "cart" ? cartTitle :orderTitle}
                  </span>
			  </NavBar>
            )}

          {this._renderSearchBar()}
          {this.props.cartStore.menuStore.menu_type === '2' && this.state.page === "menu" ? (
            <ScrollMenu
             hideArrows={true}
             scrollBy={2}
              menuClass={this.props.cartStore.menuStore.horizontalCategoryList.length < 5 ? "horizontal-menu scrollMore" : "horizontal-menu "}
              data={
                this.props.cartStore.menuStore.horizontalCategoryList.map(text => {
                  return (
                    <div className={`menu-item`} key={text} id={"hitem-" + text}>{text}</div>
                  )
                })
              }
              selected={this.props.cartStore.menuStore.horizontalMenuSelectedKey !== '' ? this.props.cartStore.menuStore.horizontalMenuSelectedKey : this.state.selected}
              scrollToSelected={true}
              inertiaScrolling={false}
              wheel={true}
              onSelect={(key) => {
                this.props.cartStore.menuStore.horizontalMenuClickedKey = key;
                document.getElementById(key).scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
                var x = document.getElementById("hitem-" + key).parentElement;
                let allCats = document.querySelectorAll('.itemCatList');
                if (allCats.length > 0) {
                  //let fDiv=allCats[0].attributes;
                  let lDivElement = allCats[allCats.length - 1];
                  let lDiv = lDivElement.attributes;
                  if (lDiv[0].value === key) {

                    let cElement = document.getElementsByClassName("active");
                    //console.log(cElement,'app');									 
                    if (cElement.length > 0) {
                      // console.log(cElement);
                      while (cElement.length > 0) {
                        cElement[0].classList.remove('active');
                      }
                    }

                    x.classList.add('active');

                  }
                }
               }
              }
            />) : ('')} 
          {this.props.cartStore.menuStore.layout === 4 ? (
		   <Fragment>
    			<Drawer placement="left" onClose={() => {
					  this.setState({
						menuDrawer: false
					  });
					}}
				    	
				 closable={true}
		         visible={this.state.menuDrawer}>
                       
					   <div className="drawerLogo"
					   onClick={()=>{
						   //this._showTabsView('menu');
							this.setState({
							menuDrawer: false
							});
						}}
					   ><img src={this.props.cartStore.menuStore.getLogo()} alt="promo" className="imageQa" /></div>
						{social && (social.provider==='eber' || social.provider==='bwc') && (<div className="drawermenu" onClick={()=>{
						   this.setState({
							menuDrawer: false,
							//profileDrawer:true
							});
							this.props.cartStore.menuStore.openRewardListModal=true;
						}}
						>						
						{this.props.cartStore.menuStore.translation.rewards}</div>)}
						
						<div className="drawermenu"
						onClick={()=>{
						//this._showTabsView('order');
						  this.setState({
							menuDrawer: false
							},()=>{this._showTabsView('order');});
						}}
						>Past Orders
						</div>	
                        <div className="drawermenu"
						onClick={()=>{
						  
						}}
						>Promos
						</div>	
                        <div className="drawermenu"
						onClick={()=>{
						  
						}}
						>Invite Friends
						</div>
                        
						{ this.props.cartStore.menuStore.store.allLang !== undefined &&
						this.props.cartStore.menuStore.store.allLang !== "" && this.props.cartStore.menuStore.store.allLang.split('|').length > 1 && (
						<div className="drawermenu"
						onClick={()=>{
						this._changeLang();
						this.setState({
						tablePopup: false,
						menuDrawer: false
						});
						}}
						>{this.props.cartStore.menuStore.translation.menu_option_language}
						</div>	)} 
						<div className="footermenu" onClick={()=>{
						//this._showTabsView('menu');
						this.props.cartStore.menuStore.logout();
						}}
						>	
						Logout</div>
						
						
						
				</Drawer>				
				<Modal
				className="profileDrawer"  	
				closable={false}
				visible={this.state.singleRewardDrawer}>
				<div className="info_header">
				<span className="backBtn"
				onClick={()=>{
				this.setState({
				//profileDrawer: true,
				singleRewardDrawer: false,
				});
				this.props.cartStore.menuStore.openRewardListModal=true;
				}}
				>
				</span>
				<span className="logoclass">			
				<img src={this.props.cartStore.menuStore.getLogo()} alt="logo" style={{ height: "40px" }}
				//onClick={()=>{console.log(5555);}}
				/>
				</span>
				<span className="homeclass">
				</span>
				</div>
				<div className="loginRegister rewardCenter redeemScrn" >   
				<div className="formSection">
				   <div className="rewardImage">
				      <img src={this.state.singleReward.image_url?this.state.singleReward.image_url:configParam.rewardImage} alt="reward" />
				   </div>
				   <div>{this.state.singleReward.redeem_name}</div>
				   <div style={{ paddingTop:"15px"}} dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(this.state.description) }} />	
				</div>			
				</div>
               <div  className="rwRedeemBtn">
			    <Button
					onClick={()=>{
						let social=this.props.cartStore.menuStore.social;
						let selectedReward=this.state.singleReward;
						let rewardObj={};
						 rewardObj.redeem_code=selectedReward.redeem_code;
						 rewardObj.pos_redeem_category=selectedReward.pos_redeem_category?selectedReward.pos_redeem_category:null;
						 rewardObj.pos_redeem_method=selectedReward.pos_redeem_method?selectedReward.pos_redeem_method:null;
						 rewardObj.pos_redeem_extra=selectedReward.pos_redeem_extra?selectedReward.pos_redeem_extra:null;
						 if(rewardObj.pos_redeem_method==='amount')
						 {
							  rewardObj.pos_redeem_amount=selectedReward.pos_redeem_amount?selectedReward.pos_redeem_amount:null;
						 }
						 else
						 {
							  rewardObj.pos_redeem_percentage=selectedReward.pos_redeem_percentage?selectedReward.pos_redeem_percentage:null;
						 }						
						 rewardObj.pos_redeem_sku=selectedReward.pos_redeem_sku?selectedReward.pos_redeem_sku:null;
						 rewardObj.redeem_code=selectedReward.redeem_code;
						 rewardObj.redeem_name=selectedReward.redeem_name?selectedReward.redeem_name:null;
						 rewardObj.redeem_type=selectedReward.type?selectedReward.type:null;
                        
						
						let rewards=[];
						rewards.push(rewardObj);
						social.rewards=rewards;
						this.props.cartStore.menuStore.saveSocial(social);
						this.props.cartStore.resetRewardItem(rewardObj.redeem_code);	


						  alert(
							<div>
							<img src="/theme/img/rwardCart.svg" alt="Cart Icon"/>
							<p>Reward has been added to your cart</p>
							</div>,
							null,
							[
							{
							text: 'OK',
							onPress: () => {
								this.setState({selectedReward: this.state.singleReward,singleRewardDrawer: false},()=>{
									
									if((selectedReward.pos_redeem_sku===null && selectedReward.pos_redeem_category===null) || social.provider==='bwc')
									{
									   this._searchMenu();
									}						
									else
									{									
										this._searchRewardItems();
										this.props.cartStore.menuStore.isRewardModalVisible=true;
									}
								});
							 }
							}
							]
							);
						
					}}
					className="homebtn"
					>
					REDEEM
				</Button>
               </div>			   
				</Modal>
					<RewardList visible={this.props.cartStore.menuStore.openRewardListModal}
					cartStore={this.props.cartStore}
					onPressFunction={()=>{
						this.setState({
						menuDrawer: true,
					//	profileDrawer: false,
						});
						this.props.cartStore.menuStore.openRewardListModal=false;
					}}
					onSelectFunction={(item)=>{
						console.log(555555555555555);
						let userInfo=this.props.cartStore.menuStore.social;
	                    let rewards=userInfo.rewards;
						let found=false;
						rewards.forEach(function (val) {
						if(val.redeem_code===item.redeem_code)
						{
							found=true;
						}			
						});

						if(found)
						{
						   this.setState({
							singleReward:{name:"",image_url:"",description:""}
							});	
							//let social=this.props.cartStore.menuStore.social;
							this.props.cartStore.resetRewardItem('');
							
							let rewards=[];
							userInfo.rewards=rewards;
							this.props.cartStore.menuStore.saveSocial(userInfo);
														
						}
						else if(rewards && rewards.length===0)
						{							
							this.setState({
							singleRewardDrawer: true,
							//profileDrawer: false,
							singleReward:item
							});	
                            this.props.cartStore.menuStore.openRewardListModal=false;	
                            if(userInfo.provider==='eber')
							{
								this.setRewardTerms(item);
							}							
						}
						/*else
						{
							this.setState({
							singleRewardDrawer: true,
							singleReward:item
							});	
                            this.props.cartStore.menuStore.openRewardListModal=false;	
						}*/
						
					}}
					onCloseFunction={()=>{
						this.props.cartStore.menuStore.openRewardListModal=false;
					}}	
					/>
				</Fragment>
          ) : ('')}			
			 <TabBar prerenderingSiblingsNumber={0} ref={this.tabbar}>
            {this._renderTabs()}
            </TabBar>
            { this.state.page==='menu' && this.props.cartStore.cartQty>0 && this.props.cartStore.menuStore.layout===4 &&(
			<div className="checkoutBtn checkouthome">
			<Button
            onClick={()=>{this._showTabsView('cart');}}
			className="homebtn"
			>
			<div className="chkoutwrapper"><Icon type="shopping-cart" /><span className="cart-text">{this.props.cartStore.cartQty}</span> </div>
				<span className="cart-price">SGD {this.props.cartStore.total}</span>{this.props.cartStore.menuStore.translation.checkout}<Icon type="right" />
			</Button>
			</div>
			)}
          {this._renderTagPopup()}
          {this._renderPromptPopup()}
          {this._renderLastOrderToCheck()}
          {this.props.cartStore.menuStore.actionModalVisisble==="pre"?(         
          <PreQuestions
            showModal={this.props.cartStore.menuStore.actionModalVisisble==="pre"}
           //showErrorMessage={this.showErrorMessage}
           questionFileds={this.props.cartStore.menuStore.preQuestionFileds}
           action_type="pre"
           action_opend_key="is_opened_pre_question"
           prerenderingSiblingsNumber={1}
          /> ):('')}
          <LoginModal visible={this.props.cartStore.menuStore.isLoginModalVisible} callWaiterfunction={this.callWaiterfunction} changeLanguage={this._changeLang} onMounted={reloadModal => this.updateLoginModalMounted(reloadModal)} />
          <HomeModal visible={this.props.cartStore.menuStore.isHomeModalVisible} changeLanguage={this._changeLang} onMounted={reloadModal => this.updateHomeModalMounted(reloadModal)} toggleTag={this._toggleTag} />
		  <RewardItemList visible={this.props.cartStore.menuStore.isRewardModalVisible}
		   cartStore={this.props.cartStore}
		   selectedReward={this.state.selectedReward}
           onPressFunction={()=>{
			            this.props.cartStore.menuStore.isRewardModalVisible=false;
						this.props.cartStore.menuStore.openRewardListModal=true;
					}}
		  
          menuToDisplay = {this.props.cartStore.menuStore.rewardsResult}		  
		  />	
          <div className="emptydiv">&nbsp;&nbsp;</div>
        </div>
      </div>

    );
  }
}

export default App;
