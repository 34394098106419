import React, { Fragment } from "react";
import {Icon,Modal,InputItem} from "antd-mobile";
import { observer, inject } from "mobx-react";
import { FiCalendar} from "react-icons/fi";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import PromptPopup from "./Popup";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import API from "../libs/api";
import Params from "../libs/params";

const monthNames = ["Jan", "Feb", "Mar", "April", "May", "June",
	"July", "Aug", "Sept", "Oct", "Nov", "Dec"
];
const daysWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

@inject("cartStore")
@observer
class ActionQuestions extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			currentStep: 0,
			loading: "",
			address_sub_field: false,
			footerBtn:[],
			errors:{},
			msg:{},
			promptPopup: false,
			promptTitle: "",
			promptContent: "",
			promptLoading: false,
			answers:{},
			isPostalValid:false,
			showBackButton: true
		};
		//this.askedPostQA=[];
	}
	componentDidMount() {
		let savedPostQ=false;
		if(this.props.action_type==="post")
		{
			savedPostQ = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_postQuestions`);
		}
		if(this.props.action_type==="pre"){
			savedPostQ = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_preQuestions`);
		}

		if (savedPostQ) {			
			savedPostQ = JSON.parse(savedPostQ);
			let state=this.state;
			state.loading = "";
			state.errors = {};
			state.answers=savedPostQ;

			this.setState(state,()=>{
				if(this.props.action_type==="post")
		        {
				this._restPostQAValue();
				}
			} );
		}
		if (this.props.questionFileds.length > 0) {
			this._firstLoad();
		}

	}
	handleChange = (name, address) => {
		let state=this.state;
		state.answers[name]=address;
		state.answers[name + "_loc"]='{}';
		if (address === "" || address === "undefined") {			
			state.address_sub_field=false;
		}
		this.setState(state);

	}
	handleSelect = (address, field) => {
		let nme = field.tag;
			let state=this.state;
			state.answers[nme]=address;
			state.address_sub_field=true;
			this.setState(state,
				()=>{
					this._callGeoCode(field);
				});
	}
	showErrorMessage=(obj)=> {
		this.setState({      
		  promptTitle: obj.title,
		  promptLoading: false,
		  closePopup: this._defaultClose()
		},() => {
			this.setState({
			  promptContent: obj.error.toString(),
		  promptPopup: true
		});
	  });
	  }
	_callGeoCode=(field)=>{
		let nme = field.tag;
		let address=this.state.answers[nme];

		geocodeByAddress(address)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
	
				let dis = this._getDistance(field.latitude, field.longitude, latLng.lat, latLng.lng);	
		
				let ljson = '{"latitude":"' + latLng.lat + '","longitude":"' + latLng.lng + '","distance":"' + dis + '"}';
				let stat=this.state;
				if (field.areaLimit && dis < field.areaLimit) {	
					stat.answers[nme + "_loc"]=ljson;
	
					this.setState(stat);
				}
				else if (field.areaLimit && dis > field.areaLimit) 
				{
					this._callSaveAndClose();
    				  stat.answers[nme + "_loc"]='{}';			
					  this.setState(stat,()=>{
						this.showErrorMessage(field);
					  });
				}
				else
				{
					stat.answers[nme + "_loc"]=ljson;	
					this.setState(stat);
				}

			}).catch(error => {
				console.error('Error', error);
				this._callSaveAndClose();
			   let stat=this.state;	
			   stat.answers[nme + "_loc"]='{}';		
				this.setState(stat,()=>{
					this.showErrorMessage(field);
				 });
			});
	}
	_restPostQAValue = () => {
		if (this.props.cartStore.menuStore.social) {
			let stateObj = this.state;
			if (!this.state.answers.input_name || this.state.answers.input_name === "" || this.state.answers.input_name === "undefined") {
				
				stateObj.answers.input_name = this.props.cartStore.menuStore.social.name;
			}
			if (!this.state.answers.input_email || this.state.answers.input_email === "" || this.state.answers.input_email === "undefined") {
				
				stateObj.answers.input_email = this.props.cartStore.menuStore.social.email;
			}
			if (!this.state.answers.input_contact || this.state.answers.input_contact === "" || this.state.answers.input_contact === "undefined") {
				
				stateObj.answers.input_contact = this.props.cartStore.menuStore.social.phone;
			}
			if (!this.state.answers.input_address || this.state.answers.input_address === "" || this.state.input_address === "undefined") {

				stateObj.answers.input_address = this.props.cartStore.menuStore.social.address;
			}
			this.setState(stateObj);
		}
		let field_extra = false;
		if (this.state.answers.input_address && this.state.answers.input_address !== "" && this.state.answers.input_address !== "undefined") {
			field_extra = true;
		}
		this.setState({ address_sub_field: field_extra });
	}
	_handleClickAction=()=>{
		let error = this._validateField();
		let state=this.state;
		state.errors=error;		
		if(Object.keys(error).length===0) {									
		this._checkEmptyTag();
			this.setState(state,()=>{
				this._next();	
			});									
		}
		else{
			this.setState(state,()=>{
				this.refreshComponenets(this.state.currentStep);
			});
		}
	}
    _setSystemTag=(field,val)=> {
		let tag = this.props.cartStore.menuStore.getTagValue();
		if (tag==="" && ((field.checkemptytag && field.checkemptytag.toLowerCase() === "system_tag") ||  (field.tag && field.tag.toLowerCase() === "system_tag"))) {
			this.props.cartStore.menuStore.tag = val;
			localStorage.setItem(this.props.cartStore.menuStore.controller, JSON.stringify({ tag: val, timestamp: new Date() }));
	
	   }	
		
	}
	_checkEmptyTag=()=>{
		let allQa = this.props.questionFileds;

		let q = allQa[this.state.currentStep];
		let state = this.state.answers;
		let val = state[q.tag];
		if (val === undefined) {
			val = "";
		}
		if (q.type === "multi")
		{
			let totalNum=q.total_fields;
			//let htmlFields=[];
			for(let k=1;k<=totalNum;k++)
			{	
				let tag=q['tag_'+k];			
				val = state[tag];
				let fObj=this._setFieldObject(q,k);
				this._setSystemTag(fObj,val);
			}
		}
		else{
			this._setSystemTag(q,val);
		}

	}
	_validateField() {
		let errorStr = "";
		let allQa = this.props.questionFileds;
		// let allQa=this.fieldsQA;
		
		let q = allQa[this.state.currentStep];
		let state = this.state.answers;
		let errorsArray={};
		let val = state[q.tag];
		if (val === undefined) {
			val = "";
		}	
		val=val.trim();
		if (q.type === "multi")
		{
			let totalNum=q.total_fields;
			//let htmlFields=[];
			for(let k=1;k<=totalNum;k++)
			{
				//let fObj={};
				//let title=q['title_'+k];
				let fObj=this._setFieldObject(q,k);
			    // let tag=q['tag_'+k];
				// let type=q['type_'+k];
				// let min=q['min_'+k];
				// let max=q['max_'+k];
				
				let tag=fObj.tag;
				let type=fObj.type;
				
				val = state[tag];
				let err="";
				if(type==='date')
				{
					//let fObj=this._setFieldObject(q,k);
					//fObj.type=type;					
					err=this._validateDateValue(fObj,val);
					// if(err!=="")
					// {
						// errorsArray[tag]=err;
					// }
					
				}
                else if(type==='postal')
				{
					
				}					
                else{
					err=this._validateValue(fObj,val);	
				}				
				/*else if ( (min>0 && !val) || (val && min > val.length)) {
					//errorStr += title + ": minimum chars "  + min +"<br/>";
					//errorsArray[tag]="Minimum chars "  + min;
					err="Minimum chars "  + min;
				}
				else if  (val && q.search_max < val.length) {
					//errorStr += title + ": maximum chars " + max +"<br/>";
					//errorsArray[tag]="Maximum chars " + max;
					err="Maximum chars " + max;
				}
				else 
				{
					//let fObj=this._setFieldObject(q,k);
					err=this._validateEmail(fObj,val);
					// if(err!=="")
					// {
						// errorsArray[tag]=err;
					// }
				}*/
				
				if(err!=="")
				{
					if(fObj.error && fObj.error!=="")
					{
						err=fObj.error;
					}
					errorsArray[tag]=err;
				}
			}
		}
		else if (q.type === "address") {

			let addressVal = "";
			if (state[q.address_tag]) {
				addressVal = state[q.address_tag];
			}
			let noteVal = "";
			if (state[q.note_tag]) {
				noteVal = state[q.note_tag];
			}
			let locTag = q.tag + "_loc";
			let loc = {};
			if (state[locTag]) {
				loc = JSON.parse(state[locTag]);
			}

			if ((q.search_min>0 && !val) || (val && q.search_min > val.length)) {
				//errorStr += q.search_text + " minimum chars " + q.search_min;
				errorsArray[q.tag]="Minimum chars " + q.search_min;
			}
			else if (val && q.search_max < val.length) {
				//errorStr += q.search_text + " maximum chars " + q.search_max;
				errorsArray[q.tag]="Maximum chars " + q.search_max;
			}
			if ((q.address_min>0 && !addressVal) || (addressVal && q.address_min > addressVal.length)) {
				//errorStr += q.address_text + " minimum chars " + q.search_min;
				errorsArray[q.address_tag]= "Minimum chars " + q.search_min;
			}
			else if (addressVal && q.address_max < addressVal.length) {
				//errorStr += q.address_text + " maximum chars " + q.search_max;
				errorsArray[q.address_tag]= "Maximum chars " + q.search_max;
			}
			if ((q.note_min>0 &&  !noteVal) || ( noteVal && q.note_min > noteVal.length)) {
				//errorStr += q.note_text + " minimum chars " + q.search_min;
				errorsArray[q.note_tag]="Minimum chars " + q.search_min;
			}
			else if (noteVal && q.note_max < noteVal.length) {
				//errorStr += q.note_text + " maximum chars " + q.search_max;
				errorsArray[q.note_tag]= " Maximum chars " + q.search_max;
			}
			if (!loc.latitude || loc.latitude <= 0) {
				//errorStr = "invalid location";
				errorsArray[locTag]= "invalid location";;
				this.setState({ [q.tag]: "", errors:errorsArray }
					, () => {
							this._callSaveAndClose();
							//localStorage.removeItem(this.props.action_opend_key);
							this.showErrorMessage(q);
					});
				
				return errorsArray;
			}

		}
		else if (q.type === "date") {
			errorStr=this._validateDatevalue(q,val);
		}
		else if(q.type==='postal')
		{		

		}
		else if (q.min === "1" && q.type === "location" && val === "") {
			errorStr = "Allow location";
		}
		else if (q.must === "1" && val === "") {
			errorStr = this.props.cartStore.menuStore.translation.required_fields
		}
		else{
			//errorStr=this._validateEmail(q,val);			
			errorStr=this._validateValue(q,val);			
		}
		if (errorStr !== "") {
			if(q.error && q.error!=="")
			{
				errorStr=q.error;
			}
			errorsArray[q.tag]= errorStr;			
		}

		return errorsArray;

	}
	_validateValue(q,val)
	{
		let errorStr="";
		if(!val)
		{
			val="";
		}
		if(q.tag==="input_contact" && q.prefix)
		{
			let state = this.state.answers;
			val=state['phone_code'].replace('+','')+val;
		}
		val=val.trim();
		let regex=new RegExp(q.regex);	
		
		if (q.regex && q.regex!=='undefined' &&  val!== "" && q.regex!=="" && !regex.test(val))
		{
			   errorStr= "Invalid format";	
		}
		else if (q.min !== undefined && q.min !== null && q.min !== "" && parseInt(val.length) < parseInt(q.min)) {
			errorStr = "Minimum chars " + q.min;
		}
		else if (q.type!=='option_day' && q.max !== undefined && q.max !== null && q.max !== "" && parseInt(val.length) > parseInt(q.max)) {
			errorStr = "Maximum chars " + q.max;
		}
		else if(q.type==='email' && (val!=="" || (q.min && q.min!==""  && parseInt(q.min)>0)))
		{
			 let re = /\S+@\S+\.\S+/;        
             errorStr =re.test(val)?'':'Invalid Email';			
		}
		else if (q.must === "1" && val === "") {
			errorStr = this.props.cartStore.menuStore.translation.required_fields;
		}
		return errorStr;
	}
	/*_validateEmail(q,val)
	{
        if(!val)
		{
			val="";
		}
		if(q.type==='email' && (val!=="" || (q.min && q.min!==""  && parseInt(q.min)>0)))
		{
			let re = /\S+@\S+\.\S+/;        
             return (re.test(val)?'':'Invalid Email');			
		}
		return "";
        
	}*/
	_validatePostalRange(q)
	{
		let obj={};
		let answers = this.state.answers;
		let val1 = answers[q.tag];
		if(!val1)
		{
			val1="";
		}
		
		let state=this.state;
		//delete state.errors[q.tag];
		state.errors[q.tag]=this.props.cartStore.menuStore.layout===4?'Checking address':'Loading Delivery Fee...';
		state.isPostalValid=false;
		this.setState(state);							 
		
		let apiRQInd=-1;
		let val = parseInt(val1);
		let minr=parseInt(q.min);
		let maxr=parseInt(q.max);
		let valength=val1.toString().length;
		let allCheck=q.check;
		let isFound=false;

		if((!val && minr) ||(val && q.min && valength<minr))
		{
			obj= { value:-1, message:'Minimum '+q.min+' Chars'};
		}
		else if( q.type!=='option_day' && ((!val && maxr) ||(val && q.max && valength>maxr)))
		{
			obj= {value:-1, message:'Maximum '+q.max+' Chars'};
		}		
		else if(q.check && q.check.length>0)
		{						
			allCheck.forEach(function(vl, index) {
		
					if(vl.action && vl.action==="estimate_api")
					{
					apiRQInd=index;	
					 return false;
					}
					else
					{
						let min=parseInt(vl.min);
						let max=parseInt(vl.max);
		
						if(val>=min && val<=max && !isFound)
						{	
							isFound=true;
							obj={value:vl.value,message:vl.message};
							//cnt++;						
							return false;
						} 
				   }					
			});	
		}

		return new Promise((resolve) => {
	    // let msg="";		
	    if(this.props.cartStore.menuStore.layout===4 )
		{	
			if(val1==="" || val1.length<6)
			{
				obj= { value:-1, message:'Minimum 6 Chars'};
			    resolve(obj);
			}
			else{
				
					let vl=allCheck[apiRQInd];			
					this._callShippingApi(val1,vl).then(res1 => {
                         	
                       this._callZipCodeBasedAddress(val1).then(res => {
				           //msg=res.message;	
						   res1.sgaddress=res.message;							   
					       resolve(res1);
					});	
			   });
			}			
				 
		}		
		else if(apiRQInd>-1)
		{
			let vl=allCheck[apiRQInd];			
			this._callShippingApi(val1,vl).then(res => {				
				resolve(res);
			});	 
		}
		else
		{
			resolve(obj);
		}
	});
		
        
	}
	_callShippingApi(val1,vl)
	{
		//address=  address!==""?address:'NA';
		let answers = this.state.answers;
		let tAmt=parseFloat(this.props.cartStore.total);
		let controllerID=this.props.cartStore.menuStore.store.controller;
		let cartItems=this.props.cartStore.getOrderItems();
		cartItems=cartItems.join('\n');
		if(answers['input_other'])
		{
			cartItems=answers['input_other']+'\n'+cartItems;
		}
			let params={
				note: answers['input_address_note']?answers['input_address_note']:'NA',
				postal_code: parseInt(val1),
				address: answers['input_address']?answers['input_address']:'NA',
				name: answers['input_name']?answers['input_name']:'NA',
				phone: answers['input_contact']?"+"+answers['input_contact']:'NA',
				amount: tAmt,
				payment_method: "PAID",
				description: cartItems
			};
		 let url=vl.url+"?token="+vl.token+"&controller="+controllerID;	
		 return new Promise((resolve) => {
		 API.checkShippingCharge(url,params)
			.then(res => {	
				let obj1={value:-1,message:res.message};
				if(res.estimated_delivery_fee)
				{		
					let dObj={method: vl.method,data:res.delivery};	
					obj1={value:res.estimated_delivery_fee,message:"Delivery Fee $"+parseFloat(res.estimated_delivery_fee).toFixed(2),delivery:dObj};	
				}
			
				 resolve(obj1);	
 
			})
			.catch(err => {
			  console.log(err);
			  err=JSON.parse(err);
			  let obj1={value:-1,message:err.message};
			  resolve(obj1);
			});
		 });
	}
	_callZipCodeBasedAddress(zipcode)
	{
		let url=Params.postalApi;
		let params="APIKey="+Params.postalApiKey+"&APISecret="+Params.postalApiSecret+"&Postcode="+zipcode;
		
		 return new Promise((resolve) => {
		 API.requestAddressBasedPostal(url,params)
			.then(res => {
				let obj1={};
				if(res.IsSuccess===true)
				{		
					//let dObj={method: vl.method,data:res.delivery};
                     res.value=1;				
                     res.message=res.Postcodes[0].BuildingNumber+' '+res.Postcodes[0].StreetName;				
					obj1=res;	
				}
				else
				{
					obj1={value:-1,message:''};
				}	
				 resolve(obj1);	
 
			})
			.catch(err => {
			  console.log(err);
			 // err=JSON.parse(err);
			  let obj1={value:-1,message:''};
			  resolve(obj1);
			});
		 });
	}
	_callZipcodeValidation(field,nme)
	{
		 
		   this._validatePostalRange(field).then(resObj=> {
				    let state=this.state;
					if(resObj.value>-1)
					{
						delete state.errors[nme];
						state.msg[nme]=resObj.message;
						state.answers[nme+'_val']=resObj.value; 
						state.answers[nme+'_delivery']=resObj.delivery; 
						if(this.props.cartStore.menuStore.layout===4)
						{
    						state.answers['input_address']=resObj.sgaddress; 
						}
						state.isPostalValid=true;
					}
					else
					{					
						delete state.msg[nme];
						state.errors[nme]=resObj.message;					 
					}

					this.setState(state,()=>{
						this.refreshComponenets(this.state.currentStep);
					});

		 });
	}
	_setFieldObject(q,k)
	{
		let fObj={};
			fObj.title=q['title_'+k];
			fObj.data=q['data_'+k];
			fObj.type=q['type_'+k];
			fObj.value=q['value_'+k];
			fObj.tag=q['tag_'+k];
			fObj.lines=(q['lines_'+k]?q['lines_'+k]:1);		
			if(q['style_'+k])
			{
			fObj.style=q['style_'+k];
			}
			if(q['min_'+k])
			{
				fObj.min=q['min_'+k];
			}
			if(q['max_'+k])
			{
				fObj.max=q['max_'+k];
			}
			if(q['disableDay_'+k])
			{
				fObj.disableDay=q['disableDay_'+k];
			}
			if(q['disableDate_'+k])
			{
				fObj.disableDate=q['disableDate_'+k];
			}
			if(q['startTime_'+k])
			{
				fObj.startTime=q['startTime_'+k];
			}
			if(q['error_'+k])
			{
				fObj.error=q['error_'+k];
			}
			if(q['checkemptytag_'+k])
			{
				fObj.checkemptytag=q['checkemptytag_'+k];
			}
			if(q['check_'+k])
			{
				fObj.check=q['check_'+k];
			}
			if(q['regex_'+k])
			{
				fObj.regex=q['regex_'+k];
			}	
            if(q['must_'+k])
			{
				fObj.must=q['must_'+k];
			}
            if(q['prefix_'+k])
			{
				fObj.prefix=q['prefix_'+k];
			}			
			return fObj
	}
	_validateDateValue(field,val)
	{
		    let min=(field.min !== undefined ? field.min : 0);
			let max=(field.max !== undefined ? field.max : 30);
			if(field.startTime)
			{		
				let curDate = new Date();		
				let curTime = parseInt((curDate.getHours()+curDate.getMinutes()/100)*100);
				if(curTime>field.startTime)
				{
					min=(min+1);
					max=(max+1);
				}
			}

			let minVal1=this._getDateFormat(min);
			let maxVal1=this._getDateFormat(max);
			let minVal=new Date(minVal1);
			let maxVal=new Date(maxVal1);
			let selVal=new Date(val);
			let errorStr="";
		   if(minVal.toString()==="Invalid Date" || maxVal.toString()==="Invalid Date" || selVal.toString()==="Invalid Date" || selVal<minVal || selVal>maxVal)
		   {
			errorStr = "Date should be between "+minVal1+" and "+maxVal1;
			return errorStr;
		   }

			//let curDate = new Date();
			let weekdays = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			let weekday =selVal.getDay();
			if(field.disableDay && field.disableDay!=="")
			{
				let dayArra=field.disableDay.split(',').map(Number);
				if(dayArra.includes(weekday))
				{
					errorStr=weekdays[weekday];
				}
				
			}
			//let dateOff=[];
			if(errorStr==="" && field.disableDate && field.disableDate!=="")
			{
				let dateArra=field.disableDate.split(',');
				let d1=new Date(val);
				dateArra.forEach(function(value, index) {
					let d2=new Date(value);
					if(d1.toDateString() === d2.toDateString())
					{
						let month = d2.getMonth() + 1;
						let day = d2.getDate();
						let year =d2.getFullYear();
						//errorStr=month + "/" + day + "/" + year;
						errorStr= year+ "/" +month + "/" + day ;
						return false;
					} 
				});				
			}
			
			if(errorStr!=="")
			{	
				if(field.error)
				{
                      errorStr=field.error+errorStr;
				}
				else{
                  errorStr="Service is not available on "+errorStr;
				}				
				
			}
			return errorStr;
		  
	}
	_firstLoad = () => {
			let state=this.state;
			state.loading = "";
			state.errors = {};
			state.msg = {};
			this.setState(state,()=>{

			} );

		if (this.props.questionFileds.length > 0) {
			let currentStep = this._getNextInd(-1);
			let allQA = this.props.questionFileds;
		     let tLength = allQA.length;
			 if(currentStep<tLength)
			 {
              this._showNextQA(currentStep);
			 }
			 else{
				this.props.cartStore.menuStore.isValidPostQa=1;//all invalid
				localStorage.setItem(this.props.action_opend_key,1);
				this.props.cartStore.menuStore.actionModalVisisble="";
			 }			
		}
	}
	_next = () => {
		let currentStep = this.state.currentStep;
		let nextStep = this._getNextInd(currentStep);
		this._showNextQA(nextStep);
	}
	_getNextInd = (lInd) => {

		//let cachedTag = localStorage.getItem(this.props.cartStore.menuStore.controller);
		let tag = this.props.cartStore.menuStore.getTagValue();
		/*if (cachedTag) {
			cachedTag = JSON.parse(cachedTag);
			tag = cachedTag.tag.toLowerCase();
		}*/

		let allQA = this.props.questionFileds;

		let tLength = allQA.length;
		let lastInd = -1;
	
		let i = lInd + 1;
		let e = allQA[i];
		if (!e) {
			return i;
		}
		let checkTagValues=[];
        if (e.checktag && e.checktag!=="") {	
			let checkTagValuesAr=e.checktag.split(',');

			checkTagValues = checkTagValuesAr.map(v => v.toLowerCase());
	      }
		  let checkNotTagValues=[];
        if (e.checknotag && e.checknotag!=="") {	
			let checkNotTagValuesAr=e.checknotag.split(',');
			checkNotTagValues = checkNotTagValuesAr.map(v => v.toLowerCase());
	      }
		  
		if (e.type && e.type === "remove") {

			if (e.checkinputtag) {
				let tagMatch = e.checkinputtag;
				let fValue = this.state.answers[tagMatch];

				let keysNotIncVal = [];
				if (e.checkinputnotinclude) {
					keysNotIncVal = e.checkinputnotinclude.split(',');
				}
				let keysIncVal = [];
				if (e.checkinputinclude) {
					keysIncVal = e.checkinputinclude.split(',');
				}

				let state = this.state.answers;



				if (e.checkinputemptyvalue && fValue === "") {
					delete state[e.tag];
				}
				else if (e.checkinputnotemptyvalue && fValue !== "") {
					delete state[e.tag];
				}
				else if (e.checkinputnotinclude && !keysNotIncVal.includes(fValue)) {
					delete state[e.tag];
				}
				else if (e.checkinputinclude && keysIncVal.includes(fValue)) {
					delete state[e.tag];
				}
				else if (e.checkinputnotequalvalue && (parseFloat(e.checkinputnotequalvalue) !== parseFloat(fValue) || e.checkinputnotequalvalue !== fValue)) {
					delete state[e.tag];
				}
				else if (e.checkinputequalvalue && (parseFloat(e.checkinputequalvalue) === parseFloat(fValue) || e.checkinputequalvalue === fValue)) {
					delete state[e.tag];
				}
				else if (e.checkinputgreatervalue && parseFloat(e.checkinputgreatervalue) < parseFloat(fValue)) {
					delete state[e.tag];
				}
				else if (e.checkinputsmallervalue && parseFloat(e.checkinputsmallervalue) > parseFloat(fValue)) {
					delete state[e.tag];
				}				
				this.setState({answers:state});

			}
			else {
				let state = this.state.answers;
				delete state[e.tag];				
				this.setState({answers:state});
			}
		}
		else if (e.type && e.type === "set" ) {
			  /*  let state = this.state;
				delete state[e.tag];
				this.setState(state);*/
				let state = this.state.answers;
				let tagMatch = e.checkinputtag;
				let fValue = this.state.answers[tagMatch];
				//let fValue = this.askedPostQA[tagMatch];
               // this.askedPostQA
				let keysNotIncVal = [];
				if (e.checkinputnotinclude) {
					keysNotIncVal = e.checkinputnotinclude.split(',');
				}
				let keysIncVal = [];
				if (e.checkinputinclude) {
					keysIncVal = e.checkinputinclude.split(',');
				}
				if (tag === "" && e.checkemptytag && e.checkemptytag.toLowerCase() === "system_tag") {
					this._setSystemTag(e,e.data);
					
				}
				else if (e.checknotag && e.checknotag.toLowerCase() !== tag) {
					state[e.tag]=e.data;
		
				 }
				else if (e.checkinputemptyvalue && fValue === "") {
				state[e.tag]=e.data;
	
				}
				else if (e.checkinputnotemptyvalue && fValue !== "") {
					state[e.tag]=e.data;
		
				}
				else if (e.checkinputnotinclude && !keysNotIncVal.includes(fValue)) {
					state[e.tag]=e.data;
		
				}
				else if (e.checkinputinclude && keysIncVal.includes(fValue)) {
					state[e.tag]=e.data;
	
				}
				else if (e.checkinputnotequalvalue && (parseFloat(e.checkinputnotequalvalue) !== parseFloat(fValue) || e.checkinputnotequalvalue !== fValue)) {
					state[e.tag]=e.data;
		
				}
				else if (e.checkinputequalvalue && (parseFloat(e.checkinputequalvalue) === parseFloat(fValue) || e.checkinputequalvalue === fValue)) {
					state[e.tag]=e.data;
		
				}
				else if (e.checkinputgreatervalue && parseFloat(e.checkinputgreatervalue) < parseFloat(fValue)) {
					state[e.tag]=e.data;
		
				}
				else if (e.checkinputsmallervalue && parseFloat(e.checkinputsmallervalue) > parseFloat(fValue)) {
					state[e.tag]=e.data;
		
				}	
				this.setState({answers:state},()=>{
				});	
		}
		else if (tag === "" && e.checkemptytag && e.checkemptytag.toLowerCase() === "system_tag") {
			lastInd = i;
		}
		else if (tag !== "" && e.checkemptytag && e.checkemptytag.toLowerCase() === "system_tag") {
           
		}
		else if (tag !== "" && e.checktag && (checkTagValues.includes(tag) || e.checktag==="*")) {
			lastInd = i;
		}
	   else if (e.checktag && (tag === "" || !checkTagValues.includes(tag))) {	
		    
	    }
		else if (tag !== "" && e.checknotag && !checkNotTagValues.includes(tag)) {			
			lastInd = i;
		}
		/*else if (e.checknotag && e.checknotag.toLowerCase() !== tag) {
			lastInd = i;
		}*/
		/*else if (e.checknotag) {
			lastInd = i + 1;
		}*/
		else if (e.checkinputtag && e.checkinputtag !== "") {
			let tagMatch = e.checkinputtag;
			     let fValue = this.state.answers[tagMatch];
				 if(!fValue)
				 {
					 fValue='';
				 }
			   // let fValue = this.askedPostQA[tagMatch];
				let keysNotIncVal = [];
				if (e.checkinputnotinclude) {
					keysNotIncVal = e.checkinputnotinclude.split(',');
				}
				let keysIncVal = [];
				if (e.checkinputinclude) {
					keysIncVal = e.checkinputinclude.split(',');
				}
                if(tagMatch==="input_day" && fValue)
				{
					fValue=fValue.split(')');
					fValue=fValue[0].split('(');
					if(fValue.length>1)
					{
						fValue=fValue[1];
					}
					else{
                        fValue=fValue[0];
					}
				}
				if (e.checkinputemptyvalue && fValue === "") {
					lastInd = i;
				}
				else if (e.checkinputnotemptyvalue && fValue !== "") {
					lastInd = i;
				}
				else if (e.checkinputnotinclude && (!keysNotIncVal.includes(fValue) || fValue==="")) {
					lastInd = i;
				}
				else if (e.checkinputinclude && keysIncVal.includes(fValue) && fValue!=="") {
					lastInd = i;
				}
				else if (e.checkinputnotequalvalue && fValue!=="" && (parseFloat(e.checkinputnotequalvalue) !== parseFloat(fValue) || e.checkinputnotequalvalue !== fValue)) {
					lastInd = i;
				}
				else if (e.checkinputequalvalue && (parseFloat(e.checkinputequalvalue) === parseFloat(fValue) || e.checkinputequalvalue === fValue)) {
					lastInd = i;
				}
				else if (e.checkinputgreatervalue && parseFloat(e.checkinputgreatervalue) < parseFloat(fValue)) {
					lastInd = i;
				}
				else if (e.checkinputsmallervalue && parseFloat(e.checkinputsmallervalue) > parseFloat(fValue)) {
					lastInd = i;
				}
				else
				{

				}
				
		}
		else {
			lastInd = i;
		}


		if (lastInd > -1) {
			return lastInd;
		}
		else {
			let nextInd = lInd + 1;

			if (tLength > nextInd) {
				return this._getNextInd(nextInd);
			}
			return nextInd;
		}
	}
	_showNextQA = (currentStep) => {
		let allQA = this.props.questionFileds;
		if (allQA[currentStep]) {
			let cQA = allQA[currentStep];
			if (cQA.default !== undefined) {
				  let state=this.state;
				  state.answers[cQA.tag]="";
				this.setState(state);
			}
			let extraField = cQA.tag + "_1";
			if (cQA[extraField] && cQA[cQA.tag] && cQA[cQA.tag] !== "") {
				this.setState({ address_sub_field: true });
			}
			let isBack=true;
			if(cQA.button_back===false || cQA.button_back==="false")
			{
				isBack=false;
			}
			this.setState({ showBackButton: isBack },()=>{
				this.refreshComponenets(currentStep);
			});
			//this.askedPostQA.push();
			
			
		}
       this._callSaveAction(currentStep);
		

	}
	_callSaveAction=(currentStep)=>{
		this.setState({ currentStep: currentStep }
			, () => {
				//this.setState({loading: true});		
				let lastStep = this.props.questionFileds.length - 1;
				let tagObject = this.props.cartStore.menuStore.checkTagAction();//'post_qa', '0'
				if (currentStep > lastStep || (this.props.cartStore.menuStore.actionModalVisisble!=="" && ((this.props.action_type==="post" && tagObject.post_qa && tagObject.post_qa==="0") || (this.props.action_type==="pre" && tagObject.pre_qa && tagObject.pre_qa==="0")))) {
					this._callSaveAndClose();
					localStorage.setItem(this.props.action_opend_key,1);
					if(this.props.action_type==="post")
					{
						var evt = document.createEvent('Event');
						evt.initEvent('click', true, true);

						let ele = document.querySelector("#checkoutButton");
						ele.dispatchEvent(evt);
						ele.click();
					}
					
					this.props.cartStore.menuStore.actionModalVisisble = "";
				}
			});

	}

	_saveState = (key, value) => {
		//value=value.trim();
		let state=this.state;
		state.answers[key] = value;
		if((key==='input_address' || key==='input_address_note') && state.answers['input_address_postal_delivery'])
		{
            let deliverObj={...state.answers['input_address_postal_delivery']};
			if(deliverObj && deliverObj['data'] && deliverObj['data']['recipient'])
			{
				if(key==='input_address')
				{
					deliverObj['data']['recipient']["location"]["address"]=value;
				}
				else if(key==='input_address_note')
				{
					deliverObj['data']['recipient']["location"]["notes"]=value;
				}
				state.answers['input_address_postal_delivery']=deliverObj;
			}
		}


			this.setState(state,()=>{
				this.refreshComponenets(this.state.currentStep);
			});
		
	}
	_saveStateAndMoveNext = (key    , value) => {
		//value=value.trim();
		let state=this.state;		
		state.answers[key] = value;
			this.setState(state,()=>{
				 this._next();	
			});
		
	}
	refreshComponenets=(cStep)=>{
		let allQA = this.props.questionFileds;
		if (allQA[cStep]) {
				let nextQa=[];
				let cQA=allQA[cStep];
				let btns=this.renderNextButton(cQA);
				nextQa.push(this.filedGenerator(cQA,cStep));
				let stateOld=this.state;
				stateOld.type=cQA.type;
				stateOld.footerBtn=btns;
				stateOld.postqa_content=nextQa;
				this.setState(stateOld,()=>{
				
				});
			}
	}
	_prev() {
		this.props.cartStore.menuStore.actionModalVisible = "";
		let currentStep = this.state.currentStep
		// If the current step is 2 or 3, then subtract one on "previous" button click
		currentStep = currentStep <= 0 ? 0 : currentStep - 1;
		this.setState({
			currentStep: currentStep
		});		
		this.props.cartStore.menuStore.actionModalVisible = this.props.action_type;
	}
	_callSaveAndClose() {
		let result = this.state.answers;
		this.props.cartStore.menuStore.actionModalVisible = "";			
		this.props.cartStore.menuStore.saveQuestion(result,this.props.action_type);	
		if(this.props.action_type==="post")
		{
			localStorage.removeItem(this.props.action_opend_key);
		}
		setTimeout(() => {
			this._firstLoad();
           },100);
	}
	_getDistance(lat1, lon1, lat2, lon2) {
		let R = 6371; // km (change this constant to get miles)
		let dLat = (parseFloat(lat2) - parseFloat(lat1)) * Math.PI / 180;

		let dLon = (parseFloat(lon2) - parseFloat(lon1)) * Math.PI / 180;
		let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);

		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = R * c;

		return Math.round(d * 1000);
	}
	_getDateFormat(minmax)
	{

			var dateS = new Date();
			dateS.setDate(dateS.getDate() + parseInt(minmax));
			let dateval=dateS.getDate();
			let montVal=(dateS.getMonth()+1);          

			if (dateval.toString().length === 1) {
				dateval = "0" + dateval.toString();
			}
			if (montVal.toString().length === 1) {
				montVal = "0" + montVal.toString();
			}
			let minVal=dateS.getFullYear()+'-'+montVal+'-'+dateval;
            return minVal;
			
	}
    loadDateField = (field,ind,minVal,maxVal,nme,is_sub_field) => {
		const isFireFox = window.navigator.userAgent.indexOf('Firefox') !== -1;
			return (<Fragment><InputItem
					placeholder={field.data}
					name={field.tag}
					type='date'
					id={field.tag}
					value={this.state.answers[nme]}
					onChange={(val) => {
						let err=this._validateDateValue(field,val);
					
							let state=this.state;
							state.errors[nme]=err;	
							state.answers[nme] = val;
							this.setState(state,()=>{
								this.refreshComponenets(this.state.currentStep);
							});
					}}
					min={minVal}
					max={maxVal}
					autoFocus={is_sub_field ===1 && ind>1 ?false:true}
					inputMode='date'
					required
					autoComplete="nope"
				/>
				{isFireFox?(<FiCalendar	/>):('')}
			</Fragment>);
	}
	_genereateSingleField = (field,ind) => {
		let nme = field.tag;
		let keyName = field.tag + field.type;	
		let is_sub_field=field.is_sub_field;
		let cls="qa-heading";
		if(is_sub_field ===1)
		{
			cls="qa-label";
		}		
		switch (field.type) {
		case 'option':
				let options = field.data.split(',');
				let optList = [];
				let obj = this;
				let checkTimes = [];
				let startInd = 0;
				let isfound = false;
				let isNowFound = false;
				if (field.checktime) {
					checkTimes = field.checktime.split(',');
					let curDate = new Date();
					let curTime = parseInt(curDate.getHours() * 100 + curDate.getMinutes());
					for (let t = 0; t < checkTimes.length; t++) {
						let timeVa = parseInt(checkTimes[t]);
						startInd = t;
						if (options[t].toString().toLowerCase().includes('now')) {
							isNowFound = true;
						}
						else if (timeVa > curTime) {
							isfound = true;
							break;
						}

					}
				}
				else {
					isfound = true;
				}

				if (isfound) {
					if (isNowFound) {
						let optInd = 0;
						let valNow = options[optInd];
						optList.push(
							<div
								key={"now" + optInd}
								className="optselection"
								onClick={() => {   
									obj._saveStateAndMoveNext(nme,valNow);
									
								}}
							>
								{valNow}
							</div>
						);
					}
					for (let optInd = startInd; optInd < options.length; optInd++) {
						let val = options[optInd];
						optList.push(
							<div
								key={val + optInd}
								className="optselection"
								onClick={() => {
									obj._setSystemTag(field,val);	
									obj._saveStateAndMoveNext(nme,val);
								}}
							>
								{val}
							</div>
						);
					}
					return (
						<div key={keyName} >
							<div className={"qaheight90 qa"+field.tag}>
								<div className="qa-heading" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />

								<div className="row optionpadding">
									{optList}
								</div>
							</div>
						</div>
					);

				}
				else {
					let val = field.checktimeerror;
					return (
						<div key={keyName} >
							<div className={"qaheight90 qa"+field.tag}>
								<div className="qa-heading" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(val) }} />
							</div>
							<div
								className="option-list class-btn"
								onClick={() => {

									this._callSaveAndClose();

								}}
							>
								{this.props.cartStore.menuStore.translation.ok}
							</div>
						</div>
					);
				}

			case 'option_day':
				let optionDays = field.data.split(',');
				let daysList = [];
				let objcls = this;
                let maxcnt=field.max>3?3:field.max;

				let min1=(field.min !== undefined ? field.min : 0);
				let max1=(field.max !== undefined ? field.max : 30);
				if(field.startTime)
				{		
				let curDate = new Date();		
				let curTime = parseInt((curDate.getHours()+curDate.getMinutes()/100)*100);
				if(curTime>field.startTime)
				{
				min1=(min1+1);
				max1=(max1+1);
				}
				}
				let minVal2=this._getDateFormat(min1);
				let maxVal2=this._getDateFormat(max1);
			    let selected="";
			
				for (let ind = field.min - 1; ind < maxcnt; ind++) {
					let isvisible = true;

					let val = optionDays[ind];
					let displaystr = val;
					var date = new Date();
					date.setDate(date.getDate() + parseInt(ind));
					if (ind > 1) {

						var day = date.getDay();
						day = day === 0 ? 7 : day;
						val = optionDays[day + 1];
					}
					var dateval = date.getDate();
					var month = date.getMonth();
					var year = date.getFullYear();
					let fullDateString = "";
					if (dateval.toString().length === 1) {
						fullDateString = "0" + dateval.toString();
					}
					else {
						fullDateString = dateval.toString();
					}
					let monthint = month + 1;
					if (monthint.toString().length === 1) {
						fullDateString += "0" + monthint.toString();
					}
					else {
						fullDateString += monthint.toString();
					}
					fullDateString += year.toString();
					let isNotAVialable = "";
					if (field.check_date) {
						let checkDays = field.check_date.split(',');
						if (checkDays.indexOf(fullDateString) > -1) {
							isNotAVialable = "Not Available ";
							if (field.msg) {
								isNotAVialable = field.msg + " ";
							}

						}
					}
					let dstr = (isNotAVialable === "" ? val : isNotAVialable);
					displaystr = dateval + " " + monthNames[month] + " " + year + " (" + dstr + ")";
                    let displayDtTime=displaystr;
					if(this.props.cartStore.menuStore.layout===4)
					{
						displayDtTime=daysWeek[date.getDay()] + "<br/> " + dateval + "<br/> " + monthNames[month];
						if(this.state.answers[nme]===displaystr || this.state.answers[nme]===undefined)
						{
							selected="selectedOpt";
							let aState=this.state; 
							aState.answers[nme]= displaystr;
							this.setState(aState);
						}
					}
					
					if (isvisible) {
						daysList.push(
							<div
								key={val + ind}
								className={isNotAVialable === "" ? "optselection "+selected : "optselection disabledbutton"}
								onClick={() => {
									if (isNotAVialable === "") {
										objcls._saveState(nme, displaystr);
										if(this.props.cartStore.menuStore.layout!==4)
										{
											objcls._next();
										}
										
									}
								}}
								dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(displayDtTime) }}
							/>								
						);
						selected="";
					}
				}
		
				return (
					<div key={keyName} >
						<div className={this.props.cartStore.menuStore.layout===4?"qa"+field.tag: "qaheight90 qa"+field.tag}>
							<div className="qa-heading" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />
                             <div className="extradate"><span>More Dates</span>{this.loadDateField(field,ind,minVal2,maxVal2,nme,is_sub_field)}</div>
							<div className="row optionpadding">
								{daysList}
							</div>
						</div>
					</div>
				);		
			case 'location':
				return (
					<div key={keyName} >
						<div className={"qaheight90 qa"+field.tag}>
							<div className="qa-heading" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />
							<div className="option-list"><div className="locationselection" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.data) }} /></div>
						</div>						
					</div>
				);		
			case 'address':

				let ctry = "sg";
				if (field.defaultCountry) {
					ctry = field.defaultCountry
				}
				let searchOptions = { types: ['geocode'], componentRestrictions: { country: ctry } };
			
				if (field.latitude && field.longitude) {
					let radius = parseInt(field.radius ? field.radius : 30000);
					let lat = parseFloat(field.latitude ? field.latitude : 1.327170);
					let longitude = parseFloat(field.longitude ? field.longitude : 103.859720);
			
					if(window.google) //take care having issue for payment gateway
					{
						let location = new window.google.maps.LatLng(lat, longitude);
						searchOptions.location = location;
					}
					
					searchOptions.radius = radius;
				}

				return (
					<div key={keyName}>
						<div className={"qaheight90 qa"+field.tag}>
							<div className="qa-heading" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />
							<PlacesAutocomplete
								value={this.state.answers[nme] ? this.state.answers[nme] : ''}
								// onChange={(val) => {this.setState({[nme]: val })}}
								onChange={(val) => { this.handleChange(nme, val); }}
								onSelect={(val) => { this.handleSelect(val, field); }}
								searchOptions={searchOptions}
							// onSelect={this.handleSelect}
							>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
									<div>
										<div className="qa-label">{field.search_text}</div>
										<textarea
											{...getInputProps({
												placeholder: field.search_hint,
												className: 'location-search-input',
												//clear:true,
												rows: field.search_line,
												autoComplete:"nope"
											})}
											style={{resize:"none"}}
										/>
										
										<div className="autocomplete-dropdown-container">
											{loading && <div>Loading...</div>}
											{suggestions.map(suggestion => {
												const className = suggestion.active
													? 'suggestion-item--active'
													: 'suggestion-item';
												// inline style for demonstration purpose
												const style = suggestion.active
													? { backgroundColor: '#fafafa', cursor: 'pointer' }
													: { backgroundColor: '#ffffff', cursor: 'pointer' };
												return (
													<div
														{...getSuggestionItemProps(suggestion, {
															className,
															style,
														})}
													>
														<span>{suggestion.description}</span>
													</div>
												);
											})}
										</div>
									</div>
								)}
							</PlacesAutocomplete>
						</div>
						<div>
							<div className="qa-label">{field.address_text}</div>
							<textarea
								placeholder={field.address_hint}
								name={field.address_tag}
								id={field.address_tag}
								value={this.state.answers[field.address_tag]}
								onChange={(el) => {
									this._saveState(field.address_tag,el.target.value);
								}}
								autoFocus={true}
								inputMode="text"
								rows={field.address_line}
								style={{resize:"none"}}

							/>
						<div className={this.state.errors[field.address_tag] && this.state.errors[field.address_tag] !== "" ? "errorqa" : " hideDiv"} >{this.state.errors[field.address_tag]} <FaTimesCircle color="red" /></div>

							<div className="qa-label">{field.note_text}</div>
							<textarea
								placeholder={field.note_hint}
								name={field.note_tag}
								id={field.note_tag}
								value={this.state.answers[field.note_tag]}
								onChange={(el) => this._saveState(field.note_tag,el.target.value)}
								autoFocus={true}
								inputMode="text"
								rows={field.note_line}
								style={{resize:"none"}}
							/>
							<div className={this.state.errors[field.note_tag] && this.state.errors[field.note_tag] !== "" ? "errorqa" : " hideDiv"} >{this.state.errors[field.note_tag]} <FaTimesCircle color="red" /></div>
						</div>
					</div>
				);	
		case 'textarea':
				return (					
						<div key={keyName} className={field.style && (this.state.answers[nme]==="" || !this.state.answers[nme])?field.style+" "+field.tag:field.tag}>
							{field.title && field.title!=="" && (<div className={cls} dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />)}
			    				<textarea
									placeholder={field.data}
									name={field.tag}
									id={field.tag}
									value={this.state.answers[nme]}
									onChange={(val) =>{ 
										this._saveState(nme,val.target.value);
										let error = this._validateField();
										let state=this.state;
										state.errors=error;	
										this.setState(state);
							        }}
									rows={field.lines !== undefined ? field.lines : 5}
									autoFocus={is_sub_field ===1 && ind>1?false:true}
									inputMode="text"
									autoComplete="nope"
									style={{resize:"none"}}
								/>
								<div className={this.state.errors[nme] && this.state.errors[nme] !== "" ? "errorqa" : " hideDiv"} >{this.state.errors[nme]} <FaTimesCircle color="red" /></div>
							</div>
				);
		case 'image':
			return(
			<div key={keyName}>
				<div className="qa-heading" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />
				<div className="option-list"><img src={field.data} alt="promo" className="imageQa" /></div>
			</div>
			);
		case 'logo':
			return(
				<div className="preqalogo" key={keyName}><img src={this.props.cartStore.menuStore.getLogo()} alt="promo" className="imageQa" /></div>
			);	
		case 'select':
			 options = field.data.split(',');
			let optionsVal=options;
			if(field.value)
			{
				optionsVal=field.value.split(',');
			}
			let aState=this.state;   
    		if((!aState.answers[nme] || aState.answers[nme]==='' || optionsVal.indexOf(aState.answers[nme])<0) && options.length>0)
			{			 
			  aState.answers[nme]= optionsVal[0];
			  this.setState(aState);
		   }
				return(
				<div key={keyName}>
					<div className={is_sub_field ===1?"qa-label":"qa-heading"} dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />
					<select 
					name={nme}
					id={nme}
					value={this.state.answers[nme]}
					onChange={(val) =>{ 						
						let state=this.state;
						delete state.msg[field.tag];
						delete state.errors[field.tag];
						let error=this._validateValue(field,val.target.value);
						if(error!=="")
						{
							state.errors[field.tag]=error;
						}
						else
						{
						 state.answers[nme] = val.target.value;	
						}						
						this.setState(state,()=>{
						this.refreshComponenets(this.state.currentStep);
						});
					 }}
					>
					{options.map((fbb, key) =>
					<option key={fbb} value={optionsVal[key]}>{fbb}</option>
					)}                    
					</select>							
					<div className={this.state.errors[nme] && this.state.errors[nme] !== "" ? "errorqa" : " hideDiv"} >{this.state.errors[nme]} <FaTimesCircle color="red" /></div>
				</div>
				);	
		case 'date':
             		
			let min=(field.min !== undefined ? field.min : 0);
			let max=(field.max !== undefined ? field.max : 30);
			if(field.startTime)
			{		
				let curDate = new Date();		
				let curTime = parseInt((curDate.getHours()+curDate.getMinutes()/100)*100);
				if(curTime>field.startTime)
				{
					min=(min+1);
					max=(max+1);
				}
			}
			let minVal=this._getDateFormat(min);
			let maxVal=this._getDateFormat(max);
			let selVal=new Date(this.state.answers[nme]);
			
			let selVal1=new Date(selVal);
			let minVal1=new Date(minVal);
			let maxVal1=new Date(maxVal);

        if(minVal1.toString()==="Invalid Date" || maxVal1.toString()==="Invalid Date" || selVal1.toString()==="Invalid Date" || selVal1<minVal1 || selVal1>maxVal1)
		   {
			 let aState=this.state; 
			 aState.answers[nme]= minVal;
			  this.setState(aState);
		   }
			return (
				<div key={keyName} className="calendersect">				
					<div className={is_sub_field ===1?"qa-label":"qa-heading"} dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />
						{this.loadDateField(field,ind,minVal,maxVal,nme,is_sub_field)}
					<div className={this.state.errors[nme] && this.state.errors[nme] !== "" ? "errorqa" : " hideDiv"} >{this.state.errors[nme]} <FaTimesCircle color="red" /></div>
				</div>
			  );
			case 'message':
			case 'exit':
			case 'continue':
			case 'dump':
				let allData=this.state.answers;
				let tag = this.props.cartStore.menuStore.getRealTagValue();
				if (tag!=="") {
					allData.system_tag=tag;
				}
	
				return (
					<div key={keyName} className="">						
							{field.title?(<div className={is_sub_field ===1?"qa-label":"qa-heading"} dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />):('')}
							{field.type === 'dump' ? (
								<div dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(JSON.stringify(allData)) }} />
							) :
								(
									<div className={field.type==="message" ?"option-list boxshp":"option-list"}><div className="locationselection" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.data) }} /></div>
								)}
					</div>
				);	
        case 'postal':

			let state=this.state;
			if(!state.errors[field.tag] && !state.msg[field.tag])
			{
				this._callZipcodeValidation(field,nme);
			}		   
					
		return (
			<div key={keyName} className={field.style && (this.state.answers[nme]==="" || !this.state.answers[nme])?field.style:"postalfield"}>				
				{field.title && field.title!=="" && (<div className={cls} dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />)}
				{this.props.cartStore.menuStore.layout===4 && (<div className="searchbtn"></div>)}
				<InputItem
					placeholder={field.data}
					name={field.tag}
					type="number"
					id={field.tag}
					value={this.state.answers[nme]}
					onChange={(val) => {
						  this._saveState(nme,val);
						  this._callZipcodeValidation(field,nme);
							  /* this._validatePostalRange(field).then(resObj=> {
							  
								if(resObj.value>-1 && this.props.cartStore.menuStore.layout===4)
								{
									
									 let state=this.state;
									 state.isPostalValid=true;
									 delete state.errors[nme];
								     //state.msg[nme]=resObj.message;
									 // state.answers[nme+'_val']=resObj.value;
									 // state.answers[nme+'_delivery']=resObj.delivery;						 
									
									 this.setState(state,()=>{										
										this.refreshComponenets(this.state.currentStep);
									});
								}
								else if(resObj.value>-1)
								{
									
									 let state=this.state;
									 state.isPostalValid=true;
									 delete state.errors[nme];
								     state.msg[nme]=resObj.message;
									 state.answers[nme+'_val']=resObj.value;
									 state.answers[nme+'_delivery']=resObj.delivery;						 
									
									 this.setState(state,()=>{										
										this.refreshComponenets(this.state.currentStep);
									});
								}
								else
								{															
									 let state=this.state;
									 delete state.msg[nme];
								     state.errors[nme]=resObj.message;
		 
									this.setState(state,()=>{
										this.refreshComponenets(this.state.currentStep);
									});
								}
							});	*/	 
						  						  
					}}
					minLength={field.min !== undefined ? field.min : ''}
					maxLength={field.max !== undefined ? field.max : 100}
					autoFocus={is_sub_field ===1 && ind>1?false:true}
					inputMode="number"
					required
					autoComplete="nope"
				/>
				<div className={this.state.errors[nme] && this.state.errors[nme] !== "" ? "errorqa" : " hideDiv"} >{this.state.errors[nme]} <FaTimesCircle color="red" /></div>
				<div className={this.state.msg[nme] && this.state.msg[nme] !== "" ? "bluemsgqa" : " hideDiv"} >{this.state.msg[nme]} <FaCheckCircle color="green" /></div>
			</div>
		  );				
		default:
		let phonePrefixs=[];
		let tState=this.state;
		let codeName='phone_code';
        if(field.prefix)
		{
			//phonePrefixs=field.prefix.split(',');
			phonePrefixs.push("+65");
			
			if(!tState.answers[codeName] || tState.answers[codeName]==="")
			{
				 tState.answers[codeName]= phonePrefixs[0];
			      this.setState(tState);
			}
		}
		else if(tState.answers[codeName])
		{
			delete tState.answers[codeName];
			this.setState(tState);
		}
		return (
			<div key={keyName} className={field.style && (this.state.answers[nme]==="" || !this.state.answers[nme])?field.style +" "+field.tag:field.tag}>				
				{field.title && field.title!=="" && (<div className={cls} dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />)}
				<div 
				className={field.prefix?'phonesection':''}
				>
				{field.prefix && phonePrefixs.length===1 &&
				  <InputItem
					placeholder={"+65"}
					name={"phone_code"}
					type="text"
					id={"phone_code"}
					value={this.state.answers["phone_code"]}
					disbaled={true}
					className="phoneCode"
				/>
				}
				{field.prefix && phonePrefixs.length>1 &&
				<select 
					name={"phone_code"}
					id={"phone_code"}
					value={this.state.answers["phone_code"]}
					onChange={(val) =>{ 						
						this._saveState("phone_code",val.target.value);
					 }}
					 className="phoneCode"
					>
					{phonePrefixs.map((fbb, key) =>
					<option key={fbb} value={phonePrefixs[key]}>{fbb}</option>
					)}                    
					</select>
				}
				<InputItem
					placeholder={field.data}
					name={field.tag}
					type={field.type}
					id={field.tag}
					value={this.state.answers[nme]}
					onChange={(val) => {						
						this._saveState(nme,val);
											
						let error = this._validateField();
						let state=this.state;
						state.errors=error;	
						this.setState(state);						
					}}
					minLength={field.min !== undefined ? field.min : ''}
					maxLength={field.max !== undefined ? field.max : 100}
					autoFocus={is_sub_field ===1 && ind>1?false:true}
					inputMode={field.type}
					required
					autoComplete="nope"
					autoCorrect="off"
					autoCapitalize="none"
					className={field.prefix?'phonewithprefix':''}
		        />
				</div>
				<div className={this.state.errors[nme] && this.state.errors[nme] !== "" ? "errorqa" : " hideDiv"} >{this.state.errors[nme]} <FaTimesCircle color="red" /></div>
			</div>
		  );
		}
	}
	filedGenerator = (field, index) => {
		//let nme = field.tag;
		let keyName = field.tag + field.type + index;
		switch (field.type) {			
			case 'multi':
				let totalNum=field.total_fields;
				let htmlFields=[];
				for(let k=1;k<=totalNum;k++)
				{
					let fObj=this._setFieldObject(field,k);							
				
					fObj.is_sub_field=1;
					htmlFields.push(this._genereateSingleField(fObj,k));
				}
				return (
					<div key={keyName} >
						<div className={field.classname?"qaheight90 "+field.classname:"qaheight90 qa"+field.type}>
						{field.title && field.title!=="" &&(
						<div className="qa-heading" dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(field.title) }} />
						)}
						{htmlFields}
						</div>
						
					</div>
				);
			default:
				return (
					<div key={keyName} className={field.type==='image'?'imgsec':''}>
						<div className={"qaheight90 qa"+field.tag}>
							{this._genereateSingleField(field,index)}
						</div>						
					</div>
				);
		}
	};
	checkOptionsField = (field) => {
		let totalNum=field.total_fields;
		let isOptionFound=false;
		for(let k=1;k<=totalNum;k++)
		{								
			if(field['type_'+k]==='option')
			{
			   isOptionFound=true;
			}
		}
		return isOptionFound;
	};		
	renderNextButton = (field) => {			
		 let footerSec=[];
			   //field.button_back === true
			   let isOption=false;
			   if(field.type === "multi")
			   {
				   isOption=this.checkOptionsField(field);
			   }
			   let nonBtns= ['option','option_day'];
			  
			   let types= ['message','exit','continue','dump','image'];
				if(this.props.cartStore.menuStore.layout!==4 && (field.button_back === true || (!types.includes(field.type) && (field.button_back===undefined || field.button_back===""))))
				{
					footerSec.push({
						text:(field.button_back_text ? field.button_back_text : this.props.cartStore.menuStore.translation.back),
						onPress: () => {
							if(field.type==="location")
							{
								let result = this.state.answers;
								this.props.cartStore.menuStore.saveQuestion(result);
								this.props.cartStore.menuStore.actionModalVisible = "";
								if (field.min) {
									this.props.cartStore.menuStore.areaLimit = 1;
								}
								this._firstLoad();
							}
							else{							
							this._callSaveAndClose();
							//localStorage.removeItem(this.props.action_opend_key);
							}
							
						}
					  });
				}

				if(!isOption && field.button_next !== false && !nonBtns.includes(field.type))
				{
					let txt=this.props.cartStore.menuStore.translation.next;
					if(field.button_next_text)
					{
						txt=field.button_next_text;
					}
					if(this.props.cartStore.menuStore.layout===4)
					{
						txt=this.props.cartStore.menuStore.translation.proceed;
					}
					if(field.type === 'exit')
					{
						txt=this.props.cartStore.menuStore.translation.exit;
					}
					else if(field.type === 'location')
					{
						txt=this.props.cartStore.menuStore.translation.allow;
					}
					footerSec.push({
						text:txt,
						onPress: () => {
							if (field.type === 'exit') {
								this._callSaveAndClose();
							}
							else if (field.type === 'continue') {
								this._showNextQA(this.props.questionFileds.length);
							}
							else if (field.type === 'location') {
								let nme=field.tag;
								let eArray=[];
								eArray[field.tag]="Loading ....";
								this.setState({ errors: eArray });
									if (navigator.geolocation) {
										navigator.geolocation.getCurrentPosition(
											(position) => {

												let dis = this._getDistance(field.latitude, field.longitude, position.coords.latitude, position.coords.longitude);
												
												if (dis < field.areaLimit) {
													let ljson = '{"latitude":"' + position.coords.latitude + '","longitude":"' + position.coords.longitude + '","accuracy":"' + position.coords.accuracy + '","distance":"' + dis + '"}';
												
													let state=this.state;
													state.answers[nme]=ljson;													
													this.setState(state);
													this._next();
											
												}
												else {
													this._callSaveAndClose();
													this.showErrorMessage(field);
												}

											},
											(error) => {												
												let obj = {};
												obj.title = field.title;
												obj.error = error.message;
												this._callSaveAndClose();
												this.showErrorMessage(obj);
											}
										);
									}
							}
							else if(types.includes(field.type))
							{
								this._next();
							}
						else if(field.type === 'postal' && !this.state.isPostalValid===true)
							{
								return ;
							/*this._validatePostalRange(field).then(resObj=> {
								
								if(resObj.value >-1)
								{
									let state=this.state;
									state.answers[field.tag+'_val']=resObj.value;						state.answers[field.tag+'_delivery']=resObj.delivery;							
									this.setState(state,()=>{
										//console.log(state,'state');
									  this._next();	
									});
																	
								}
								else{
								     let state=this.state;
									 delete state.msg[field.tag];
								     state.errors[field.tag]=resObj.message;
									this.setState(state,()=>{
										this.refreshComponenets(this.state.currentStep);
									});
								}
							});	*/														  
						    }
							else if(field.type === "multi")
							{
								let totalNum=field.total_fields;
								let postalObj={};
								for(let k=1;k<=totalNum;k++)
								{								
									let fObj=this._setFieldObject(field,k);
									//let tag=fObj.tag;
									let type=fObj.type;
									if(type==='postal')
									{
										postalObj=fObj;
									}
								}
								
								if(Object.keys(postalObj).length>0 && !this.state.isPostalValid===true)
								{
									return ;
								}
								else
								{
									this._handleClickAction();
								}

							}
							else 
							{
								this._handleClickAction();
						    }
						}
					  });
				}
				return footerSec;					
	};
	/*renderFields = () => {
		if (!this.props.showModal) {
			// return ;
		}
		const list = [];
		//let cState = JSON.parse(localStorage.getItem(`${this.props.cartStore.menuStore.controller}_postQuestions`));
	   if(this.props.questionFileds.length>0)
	   {
		_.each(this.props.questionFileds, (e, i, l) => {
			if (['remove','set'].indexOf(e.type) < 0) {
				//console.log(e);
				list.push(this.filedGenerator(e, i));
			}

		});
	   }		

		return list;
	};*/
	_defaultClose = closeText => {
		return [
		  {
			text: closeText
			  ? closeText
			  : this.props.cartStore.menuStore.translation.close,
			onPress: () => {     
			  this.setState({
				promptPopup: false
				//promptContent:""
			  });
			}
		  }
		];
	  };
	_renderQuestionPromptPopup = () => {
		return (
		  <PromptPopup
			visibility={this.state.promptPopup}
			title={this.state.promptTitle}
			closePopup={this._defaultClose()}
			content={this.state.promptContent}       
			isLoading={this.state.promptLoading}
		    htmlMsg={true}
			 cartStore={this.props.cartStore}
		  />
		);
	  };
	render = () => {
		//let inputmethocls=this.state.answers.input_method==='Delivery'?'delivery':'selfcollection';
		let inputmethocls='selfcollection';
		//console.log(this.state.showBackButton,this.state.currentStep);
		return (
			<Fragment>
			<Modal
				visible={this.props.showModal}
				transparent={true}
				maskClosable={false}
				//showCloseButton={false}
				//closable={this.props.cartStore.menuStore.layout===4?true:false}
				//animationType="slide-up"				
				className={"App table_question postqa "+this.state.type+" "+inputmethocls }			
				//wrapClassName="qa-wrap"
				footer={this.state.footerBtn}
			    title={this.props.cartStore.menuStore.layout===4?(<div className="headingicons">{this.state.showBackButton ?(<span className="backbtnhead"
			    onClick={(e)=>{ this._callSaveAndClose();}}
				></span>):(<span className="backbtnheadBlank"></span>)}<span className="closebtnhead"
					onClick={(e)=>{this._callSaveAndClose();}}
					><Icon key="1" type="close" style={{ fontSize: '25px', color: '#fff' }}				
					/></span></div>):("")}				
			  >
				{this.state.postqa_content}				
			</Modal>
			<div>{this._renderQuestionPromptPopup()}</div>
			</Fragment>
		);
	};
}

export default ActionQuestions;
