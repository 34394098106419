import React from "react";
import { observer } from "mobx-react";
import { Modal} from "antd-mobile";
import configParam from "../libs/params";

const PromptPopup = observer(
  ({ visibility, title, closePopup, content, isLoading,htmlMsg,cartStore }) => {
    return (
      <Modal
        visible={visibility}
        transparent={true}
        animationType="slide-up"
		className={isLoading?"loadingPrompt customPrompt":"customPrompt"}
        title={cartStore && cartStore.menuStore.layout===4?'':title}
		showCloseButton={true}
        footer={
          Array.isArray(closePopup)
            ? closePopup
            : [
                {
                  text: "Close",
                  onPress: closePopup
                }
              ]
        }
      > 
	  <div className="waitingfullscreen"> 
        {cartStore && cartStore.menuStore.layout===4 && (
		 <img src={cartStore.menuStore.getLogo()} alt="logo" />
		)}	
        <div className="loadingScreen">		
        {htmlMsg && <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
          className="loadingIcon checkstatusPopup"
          dangerouslySetInnerHTML={{
          __html: content.toString()  //remove decodeURIComponent for checklatorder2hours
          }}     
        >         
        </div>}
        {content!=="" && <div
         style={{
           display: "flex",
           flexDirection: "column",
           justifyContent: "center",
           alignItems: "center"
         }}
         className="textMessage"            
       > 
       {content } 
		</div>}
		{isLoading && cartStore && cartStore.menuStore.layout===4 &&
        (<div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
		  className="loadingIcon"
        >         ...
        </div>)}
		</div>
       {isLoading && (!cartStore || cartStore.menuStore.layout!==4 ) &&
        (<div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
		  className="loadingIcon"
        >
          <img src={configParam.fzLoadingImage} alt="loading..." />
        </div>)}	
		</div>
      </Modal>
    );
  }
);

export default PromptPopup;
