import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import { Modal, Button, List, Switch, InputItem } from "antd-mobile";
import { AddonFixed, AddonSummary,RenderAddon,RenderNotes } from "./Addon";
import { FiShoppingCart, FiEdit } from "react-icons/fi";
import CircularProgressbar from "react-circular-progressbar";
import PromptPopup from "./Popup";
import _ from "underscore";
import "../cart.css";
import "react-circular-progressbar/dist/styles.css";
import { FaPaperclip, FaTrashAlt,FaCheckCircle, FaTimesCircle,FaPlus } from "react-icons/fa";
import PaymentOption from "./Payment/PaymentOption";
import FoodStepper from "./FoodStepper";
import PostQuestions from "./ActionQuestions";
import Recomendations from "./Recomendations";
import API from "../libs/api";
import configParam from "../libs/params";
// request from "../libs/request";
import CheckBill from "./CheckBill";
import PowerBy from "./PowerBy";
import { Icon } from "antd";
import { throttle } from "lodash";

const alert = Modal.alert;
const prompt = Modal.prompt;
const Item = List.Item;
const Brief = Item.Brief;

const COUNTDOWN_LIMIT = 3;
let countdownTimer;
let cancelCheckout;
let intervalSeconds = [2, 3, 3, 3, 3, 3, 3, 3, 5];

@inject("cartStore")
@observer
class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Prompt popup
      closePopup: [],
      timeCountdown: COUNTDOWN_LIMIT,
      promptPopup: false,
      promptTitle: "",
      promptContent: this.props.cartStore.menuStore.translation.cart_preparing_order,
      promptLoading: true,
      verificationCode: null,
      checked: this.props.cartStore.menuStore.takeaway === "2" ? true : false,
      payLoad: "",
      promocode: "",
	  promoPopup:false,
      //htmlMsg:false,
      //distance: this.props.cartStore.menuStore.location.areaLimit
    };
    this.props.cartStore.takeAway = this.state.checked;
  }
  componentDidMount() {
    const paymentRes = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_payment`);   
    if (paymentRes) {     
      localStorage.removeItem("is_opened_post_question");
      let paymentDetails = JSON.parse(paymentRes);
     
     /* if(paymentDetails.status === 'cancel' )
      {
        paymentDetails.msg="";
        this._openEmailMessage(paymentDetails);
      }
      else{*/
        this._startOrderProcessing(paymentDetails);
     // }
     /* let partnerTXID = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_partnerTX`);
    //  let tAccessToekn = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_accessToken`);
    //  let is_sandbox = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_sandbox`);
      let userInfo = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_userinfo`);
      let receipt = localStorage.getItem(`${this.props.cartStore.menuStore.controller}_receipt`);
      let cId = this.props.cartStore.menuStore.controller + '--';
      if (userInfo !== "") {
        userInfo = JSON.parse(userInfo);
      }
      if (receipt !== "") {
        receipt = JSON.parse(receipt);
      }
      let cartLength = this.props.cartStore.entries.length;
      if (cartLength > 0 && paymentDetails.method === 'grab_online' && paymentDetails.status === 1) {  // && 
        this._startOrderProcessing(paymentDetails);
      }
      else if (cartLength > 0 && paymentDetails.method === 't05pay' && paymentDetails.status === 'success' ) {
        this._startOrderProcessing(paymentDetails);
      }
      else if (cartLength > 0 && paymentDetails.method === 'eghl' && paymentDetails.status === 'success' ) { 
        this._startOrderProcessing(paymentDetails);
      }
      else if (cartLength > 0 && paymentDetails.method === 'ipay88' && paymentDetails.status === 'success' ) {     
        this._startOrderProcessing(paymentDetails);
      }
      else {
        alert(
          this.props.cartStore.menuStore.translation.payment_status,
          paymentDetails.msg ? paymentDetails.msg : this.props.cartStore.menuStore.translation.payment_msg + '\n Order Id:' + paymentDetails.refno,
          [
            {
              text: this.props.cartStore.menuStore.translation.send_emil,
              onPress: () => {
                let msg = this.props.cartStore.menuStore.translation.email_error;
                this.setState({ tablePopup: false });
                API.sendEmail(paymentRes)
                  .then(res => {

                    if (res.status === 'success') {
                      msg = this.props.cartStore.menuStore.translation.email_sent;
                    }

                    alert(msg, null, [
                      { text: "Close", onPress: () => { } }
                    ]);
                  })
                  .catch(err => {
                    alert(msg, null, [
                      { text: "Close", onPress: () => { } }
                    ]);
                  });
              }
            },
            {
              text: this.props.cartStore.menuStore.translation.close,
              onPress: () => {
                this.setState({
                  tablePopup: false
                });
              }
            }
          ]
        );
      }
      localStorage.removeItem(`${this.props.cartStore.menuStore.controller}_partnerTX`);
     
      localStorage.removeItem(`${this.props.cartStore.menuStore.controller}_userinfo`);
      localStorage.removeItem(`${this.props.cartStore.menuStore.controller}_receipt`);*/
      localStorage.removeItem(`${this.props.cartStore.menuStore.controller}_payment`);

    }
    localStorage.removeItem('hideMenu');
    /* else if(this.props.cartStore.entries.length > 0 && this.props.cartStore.menuStore.location.areaLimit>0) {
       this._getLocation();
     }*/

  }
  checkSinglePaymentGateway(openSinglePaymentGateway) {
    this.callPymanetOptions = openSinglePaymentGateway;
  }
  _openEmailMessage(paymentRes)
  {
	//this.props.cartStore.orderStore.updateOrder(paymentRes,100);
    let msg=(paymentRes.msg && paymentRes.msg!=="" ? paymentRes.msg : this.props.cartStore.menuStore.translation.general_error);// 
    paymentRes.code=(!paymentRes.code || paymentRes.code===""?6000:paymentRes.code);
    msg=msg.replace("#ERROR CODE#",paymentRes.code);
    msg=msg.replace("#Order ID#",paymentRes.refno);

     let btns=[];
     btns.push({
      text: this.props.cartStore.menuStore.translation.close,
      onPress: () => {
        this._callCartStatusCheck(2);
       }
    });
    if(paymentRes.code===5011 || paymentRes.code==="5011")
    {
      btns.push({
        text: this.props.cartStore.menuStore.translation.try_again,
        onPress:() => {
          this._callCartStatusCheck(2);
         // this.props.cartStore.isValidPostQa = "retrypayment";
          localStorage.setItem("is_opened_post_question","1");         
          var evt = document.createEvent('Event');
						evt.initEvent('click', true, true);
        
						let ele = document.querySelector("#checkoutButton");
						ele.dispatchEvent(evt);
						ele.click();
            this.props.cartStore.menuStore.actionModalVisisble = "";
            //this.props.cartStore.menuStore.isValidPostQa = "retrypayment";
  
        }
      });
    }
    /*alert(
      this.props.cartStore.menuStore.translation.payment_error_title,
      msg,
      btns
    );*/
	 this.setState({
              promptPopup: true,
              promptTitle: this.props.cartStore.menuStore.translation.payment_error_title,
              promptLoading: false,
              promptContent: '<div style=text-align:left;>'+msg+'</div>',//"To place order, SCAN the QR Code again.",
              closePopup:btns
	 });
  }
  /*checkPostQAy(postQA) {
        this.callQA = postQA;
    }*/

  _startOrderProcessing(payload) {   
	
	  if(payload.status && payload.status==="failed")
	  {
		let errorMsg=(payload.msg?payload.msg:"");
		payload.msg=this.props.cartStore.menuStore.translation.payment_error;
		if(errorMsg!==""){
		payload.msg+='\n'+errorMsg;
        }
    
    payload.code="5011";
		this._openEmailMessage(payload); 		  
	  }
	  else{
		  this._callCartStatusCheck(1);
		  //this._startGetPingStatus(0,payload,);
		  this._callPaypalPaymentStatus(payload,'redirect');
	  }  
    
   /* this.props.cartStore.addOrderByBackend(payload.orderStatus);
    this.props.cartStore.tabHandler("order");
    this._callCheckOrderStatus(payload);*/ 
  }
  _callPaypalPaymentStatus=(payload,type)=>
  {
     if(type!=='spot')
	 {
		  this.props.cartStore.addOrderByBackend(payload,'');
		  this.props.cartStore.tabHandler("order"); 
	 }
    this._startGetPingStatus(0,payload);
  }
  _startGetPingStatus(ind,payload)
  {
    let timePoll = parseInt(intervalSeconds[ind]);
    if(ind>=intervalSeconds.length || timePoll===0)
    {
      payload.code=5002;         
      this._openEmailMessage(payload);
      return;
    }
   // console.log(77);
     ind++; 
     const { cartStore } = this.props;
     const obj=this;
    setTimeout(function () {      
        API.getPingStaus( payload.pingUrl).then(res => {       
            let orderCode=res.data;      
            if (!res.data || orderCode==="" || orderCode==="undefined" ) {
              obj._startGetPingStatus(ind,payload);
            } 
            else{        
             
            let ordernum = orderCode.split(":"); 
            payload.code=ordernum[1];
			let eMsg="";
		   if(ordernum[2] && ordernum[2]!=="")
		   {
			   eMsg=" "+ordernum[2];
		   }
            if (ordernum[0] && ordernum[0]==="success")
            {
			  let tamount=cartStore.total;	
              ind=intervalSeconds.length+1;
             // cartStore.addOrderByBackend(orderCode);
			  cartStore.addOrderByBackend(payload,ordernum[1]);
              cartStore.tabHandler("order");
              obj._callCheckOrderStatus(payload);
			  cartStore.menuStore.sendGoogleAnalyticsOrder(tamount,ordernum[1]);
            } 
            else if (ind<intervalSeconds.length && (ordernum[0] === "started" || orderCode==="")) {
              obj._startGetPingStatus(ind,payload);
            }
            else if (ordernum[1] && ordernum[0]==="error" && ordernum[1]==="5014")
            {
              payload.msg=cartStore.menuStore.translation.payment_cancel+eMsg;
              obj._openEmailMessage(payload);      
            } 
            else if (ordernum[1] && ordernum[0]==="error" && ordernum[1]==="5011")
            {
              payload.msg=cartStore.menuStore.translation.payment_error+eMsg ;
              obj._openEmailMessage(payload);      
            } 
            else if (ordernum[1] && ordernum[0]==="error" && ordernum[1]==="5012")
            {
              let codenum = ordernum[1].split(":"); 
              payload.code=codenum[0];
              payload.msg=cartStore.menuStore.translation.gateway_error;
              payload.msg=payload.msg.replace("#MESSGE#",codenum[1]);
              obj._openEmailMessage(payload);      
            } 
             else{
              //console.log(66,orderCode);
			  payload.msg=eMsg ;
              obj._openEmailMessage(payload);          
            }
          }
      }).catch(err => {
			console.log(err);
			console.log('--ping error-');
		   obj._startGetPingStatus(ind,payload);

		});
    }, timePoll * 1000);
  }
  _renderCartEntry() {
    const { cartStore,showTabsPage} = this.props;
    if (cartStore.entries.length === 0) {
		if (cartStore.menuStore.layout === 4) {
		  return (
			<div style={{ textAlign: "center", paddingTop: "20%" }}>
			  <div className="cartIconCls"></div>
			  <br />
			  <br />
			  <p><strong>{cartStore.menuStore.translation.cart_hungry}</strong></p>
			  <p>{cartStore.menuStore.translation.coco_empty_cart}</p>
				<Button
				onClick={()=>{showTabsPage('menu');}}
				className="browsebtn"
				>
				{cartStore.menuStore.translation.browse}
				</Button>
			</div>
		  );
		}
		else
		{
		   return (
			<div style={{ textAlign: "center", paddingTop: "20%" }}>
			  <FiShoppingCart size={35} />
			  <br />
			  <br />
			  {cartStore.menuStore.translation.cart_empty}
			</div>
		  );
		}
    } else {
      return _.map(cartStore.entries, function(entry,ind) {
        return (
          <Fragment key={entry.item.id+ind}>
            <div className="cartHeader" />
            <CartItem entry={entry} cartStore={cartStore} ind={ind}/>
          </Fragment>
        );
      });
    }
  }
  _renderConfimPopup() {
    if (this.props.cartStore.entries.length > 0 && this.props.cartStore.menuStore.layout!==4) {
      return (
	  <Fragment>
        <div className="withoutLog clearCartBtn" >
          <Button
            className="bypassbtn greytext"
            onClick={() => {

              alert(
                this.props.cartStore.menuStore.translation.clear_pending_title,
                this.props.cartStore.menuStore.translation.clear_pending_msg,
                [
                  {
                    text: this.props.cartStore.menuStore.translation.cancel
                  },
                  {
                    text: this.props.cartStore.menuStore.translation.confirm,
                    onPress: () => {
                      this.props.cartStore.empty();
                      this.props.cartStore.menuStore.sendGoogleAnalytics('Click', 'Reset Order', this.props.cartStore.menuStore.controller, 1);
                    }
                  }
                ]
              );

            }}
          >
            <FaTrashAlt color={"#666"} size={16} className="trashIcon" />
            <span style={{ marginLeft: "5px" }}>{this.props.cartStore.menuStore.translation.clear_pending_order_all}</span>
          </Button>
        </div>
		{this.props.cartStore.menuStore.isKopikingControler() &&(
			<div className="fkopi">
				<div className="flinks">

				   <a href="/term-conditions.html" target="_blank" rel="noopener noreferrer">{this.props.cartStore.menuStore.translation.term_condition} </a>
				   <a href="/privacy-policy.html" target="_blank" rel="noopener noreferrer">{this.props.cartStore.menuStore.translation.privacy_policy} </a>
				   <a href="/return-policy.html" target="_blank" rel="noopener noreferrer">{this.props.cartStore.menuStore.translation.return_policy} </a>
				   <a href="/faq.html" target="_blank" rel="noopener noreferrer">{this.props.cartStore.menuStore.translation.faq} </a>
				</div>
				<div className="infofooter">
				     <div className="pfotter">
					 <h3>Payment Methods</h3>
					 Credit Cards accepted
					 <img src="/theme/img/pgate.jfif" style={{maxWidth: "200px"}} alt="payment gateway"/>
					 </div>
					 
					  <div className="pfotter">
					  <h3>Contact Info:</h3>
							<p> Email: kopi.ong@kopi-king.com</p>
							<p>Contact Number: 84283728</p>
							<p>Address: Kopi Ong @ MBFC Tower 3: 12 Marina Blvd, #01-04, Singapore 018982</p>
					 </div>
				</div>
		</div>)}
		<PowerBy/>
		</Fragment>
      );
    } else {
      return ("");
    }
  }
  _renderCircleProgress = (countdown, total_limit, closeText) => {
    let total_time = total_limit ? total_limit : COUNTDOWN_LIMIT
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <div style={{ position: "absolute", width: "100%" }}>
          {closeText ? closeText : this.props.cartStore.menuStore.translation.cancel}
        </div>
        <div
          style={{
            width: "35px",
            float: "right",
            paddingRight: 12,
            paddingTop: 4,
            position: "relative"
          }}
        >
          <CircularProgressbar
            percentage={
              (Math.abs(countdown - total_time) / total_time) * 100
            }
            strokeWidth={50}
            textForPercentage={null}
            styles={{
              width: 20,
              path: { strokeLinecap: "butt" },
              text: { fill: "#000" }
            }}
          />
        </div>
      </div>
    );
  }

  _callMerchantCreateOrderApi = (param) => {
    //this._setPopupForMerchantStatus();
    let respR = { status: '0' };
    //let totaltimeCnt=20;
    return new Promise((resolve, reject) => {
      if (param === "") {
        this.props.cartStore.prepareOrderSummary('').then(res => {
          resolve(res);

        })
          .catch(err => {
            console.log(err);
            resolve(respR);
          });
      }
      else {
        //console.log(param, 'param');
        this.props.cartStore.prepareOrderAmount(param).then(resP => {
          resolve(resP);

        })
          .catch(err => {
            resolve(respR);
          });
      }

    });

  }
  _callCartStatusCheck = (res) => {
   // console.log(res);
	if(this.props.cartStore.menuStore.isWebsocketEnabled)
	{
	// this.props.cartStore.webSocketCheckout();		
     //this._callCheckOrderStatus(res);
	    this.setState({ payLoad: res,promptLoading: true,promptPopup: true,promptTitle:"", promptContent: this.props.cartStore.menuStore.translation.cart_processing_order });
	    let params=this.props.cartStore.prepareWebSocketParams();
         API.socketApiJson('order', params,'POST')
			.then(res => {
							
				  //this._callSocketCheckOrderStatus(params.request,params.tempOrderId);
				    params.tempOrderId=res.data.tempOrderId;
					this.props.cartStore.addOrderBySocket(params);
					this.props.cartStore.tabHandler("order"); 
					//this.setState({promptPopup: false});
					this._prepareOnlyCountdown();
				
			})
			.catch(err => {
				//thisObj.setState({ isPaying: false });
				console.log(err);
				// alert(err, null, [
					// { text: "Close", onPress: () => { } }
				// ]);
			});	 
	 return ;	 
	}  
    if (res === 2) {
      this.setState({
		promptContent:"",
        promptTitle:"",		
        promptPopup: false,
        promptLoading: false
      });
      return;
    }
    else if (res.toString().indexOf('"method":"cash"') > 0 || res==='nogateway') {
		
        
		  if(this.props.cartStore.menuStore.layout===4)
		  {
			  this.setState({ payLoad: res,promptLoading: true,promptPopup: true,promptTitle:"", promptContent: this.props.cartStore.menuStore.translation.cart_processing_order, },()=>{
					this.props.cartStore
					.checkout(this.state.verificationCode, this.state.payLoad)
					.then(res => {         
					  this.props.cartStore.tabHandler("order");
					  this._callCheckOrderStatus(res);
				  })
					.catch(err => {
					  console.log("Error Cart :", err);
					   this.setState({
						promptTitle: err.title,
						promptContent: (err.content?err.content:''),
						promptLoading: false,
						closePopup: [
								{
								  text: this.props.cartStore.menuStore.translation.try_again,
								  onPress: () => {
									clearInterval(countdownTimer);
									clearTimeout(cancelCheckout);  
									this._callCartStatusCheck(res);
								  }
								},
								{
								  text: this.props.cartStore.menuStore.translation.close,
								  onPress: () => {
										clearInterval(countdownTimer);
										clearTimeout(cancelCheckout);    
										this.setState({
										promptPopup: false,
										promptContent:""
										});
								  }
								}
							  ]
					     });
					});
				});	
		  }
		  else
		  {
			 this.setState({ payLoad: res});
			 this._prepareCountdown(); 
		  }
	  
      
      return;
    }
    else {
      //console.log(res);
      // console.log(res.method);
      this.setState({
        promptPopup: true,
        promptLoading: true,
        promptTitle: "",
        promptContent: this.props.cartStore.menuStore.translation.cart_processing_order,
        closePopup: []
      });
      if (res !== 1) {
        this._callCheckOrderStatus(res);
      }
    }

  }
  _callCheckOrderStatus = (res) => {

    this.setState({ checked: false });

    const { cartStore } = this.props;

    cartStore.remarks = "";
    cartStore.takeAway = false;

    // window.gtag('event','Checkout', {  'event_category': 'order',  'event_label': cartStore.menuStore.controller,  'value': cartStore.total*100});

    let orders = cartStore.orderStore.lists;
    let lastOrIndex = orders.length - 1;
    let lastOrder = orders[lastOrIndex];
    // let timerId;
    let timerOut;

    timerOut = setTimeout(() => {
      clearInterval(countdownTimer);
      this.setState({ promptPopup: false });
      //if (orders[lastOrIndex].status === null) {
        var ele = document.querySelectorAll(".order_item");
        //ele[0].click();	
		var evt = document.createEvent('Event');
		evt.initEvent('click', true, true);

		let eleN = ele[0];
		eleN.dispatchEvent(evt);
		eleN.click();
				
      //}
    }, COUNTDOWN_LIMIT * 10000);

    this.setState({
      timeCountdown: 30,
      promptTitle: cartStore.menuStore.translation.order_waiting,
      //promptContent: res.content,
      promptContent: cartStore.menuStore.translation.checkout_message,
      promptLoading: cartStore.menuStore.layout===4?true:false,
      //promptPopup: false		
    });
    
    let start = 1;
    countdownTimer = setInterval(() => {
       orders = cartStore.orderStore.lists;
       lastOrIndex = orders.length - 1;
       lastOrder = orders[lastOrIndex];

      if (start>0 && orders[lastOrIndex].status <2) {
        start=0;
        cartStore.orderStore.checkOrder(lastOrder).then(resApi=>{   
          orders = cartStore.orderStore.lists;
          lastOrIndex = orders.length - 1;
          lastOrder = orders[lastOrIndex];      
          if (orders[lastOrIndex].status >1) {
            this.setState({ promptPopup: false });
            //clearInterval(timerId);  
            clearInterval(countdownTimer);
            clearTimeout(timerOut);
             var ele = document.querySelectorAll(".order_item");
            // ele[0].click();
			
				var evt = document.createEvent('Event');
				evt.initEvent('click', true, true);

				//let ele = document.querySelector("#checkoutButton");
				let eleN = ele[0];
				eleN.dispatchEvent(evt);
				eleN.click();
						
						
           }
           else{
            start=1;
           }
        });
      }
    //  start = start + 1;


      if(cartStore.menuStore.layout!==4)
	  {
      this.setState(
        {
          // promptPopup: true,
          timeCountdown: this.state.timeCountdown - 1
        },
        () => {
          this.setState({

            closePopup: [
              {
                //text: cartStore.menuStore.translation.cart_check_order,
                text: this._renderCircleProgress(this.state.timeCountdown, 30, cartStore.menuStore.translation.check_later),
                onPress: () => {
                  //clearInterval(timerId);  					  
                  clearTimeout(countdownTimer);
                  clearTimeout(timerOut);
                  this.setState(
                    {
                      promptPopup: false,
                    }
                  );
                }
              }
            ]
          });
        }
	);}
    }, 1000);
  };
 _prepareOnlyCountdown = () => {
    const { cartStore } = this.props;

    this.setState(
      {
        timeCountdown: COUNTDOWN_LIMIT
      },
      () => {
        this.setState({
          promptPopup: true,
          promptTitle: '',
          promptContent: cartStore.menuStore.translation.order_waiting_confirm,
          promptLoading: false,
          closePopup: this._defaultClose(
            this._renderCircleProgress(this.state.timeCountdown)
          )
        });
      }
    );
    countdownTimer = setInterval(() => {
      this.setState(
        {
          timeCountdown: this.state.timeCountdown - 1
        },
        () => {
          this.setState({
            closePopup: this._defaultClose(
              this._renderCircleProgress(this.state.timeCountdown)
            )
          });
        }
      );
    }, 1000);

    cancelCheckout = setTimeout(() => {
      clearInterval(countdownTimer);
        this.setState({
          promptTitle: cartStore.menuStore.translation.order_waiting_confirm,
          promptContent: cartStore.menuStore.translation.order_check_again,
          promptLoading: false,
          closePopup: this._defaultClose()
        });

    }, COUNTDOWN_LIMIT * 1000);
  };
  _prepareCountdown = () => {
    const { cartStore } = this.props;

    this.setState(
      {
        timeCountdown: COUNTDOWN_LIMIT
      },
      () => {
        this.setState({
          promptPopup: true,
          promptTitle: '',
          promptContent: cartStore.menuStore.translation.cart_preparing_order,
          promptLoading: false,
          closePopup: this._defaultClose(
            this._renderCircleProgress(this.state.timeCountdown)
          )
        });
      }
    );
    countdownTimer = setInterval(() => {
      this.setState(
        {
          timeCountdown: this.state.timeCountdown - 1
        },
        () => {
          this.setState({
            closePopup: this._defaultClose(
              this._renderCircleProgress(this.state.timeCountdown)
            )
          });
        }
      );
    }, 1000);

    cancelCheckout = setTimeout(() => {
      clearInterval(countdownTimer);
      // Handle failed checkout
      countdownTimer = setTimeout(() => {
        this.setState({
          promptTitle: cartStore.menuStore.translation.error,
          promptContent: cartStore.menuStore.translation.error_network,
          promptLoading: false,
          closePopup: this._defaultClose()
        });
      }, 20000);

      this.setState({
        promptLoading: true,
        promptTitle: cartStore.menuStore.translation.cart_processing_order,
        closePopup: []
      });

      cartStore
        .checkout(this.state.verificationCode, this.state.payLoad)
        .then(res => {
          clearTimeout(cancelCheckout);
          clearTimeout(countdownTimer);
          cartStore.tabHandler("order");
          this._callCheckOrderStatus(res);
	  })
        .catch(err => {
          console.log("Error Cart :", err);
          clearTimeout(cancelCheckout);
          clearTimeout(countdownTimer);
          this.setState({
            promptTitle: err.title,
            promptContent: (err.content?err.content:''),
            promptLoading: false,
			closePopup: [
						{
						  text: this.props.cartStore.menuStore.translation.try_again,
						  onPress: () => {
							this._callCartStatusCheck(this.state.payLoad);
						  }
						},
						{
						  text: this.props.cartStore.menuStore.translation.close,
						  onPress: () => {
								this.setState({
								promptPopup: false,
								promptContent:""
								});
						  }
						}
					  ]
          });
        });
    }, COUNTDOWN_LIMIT * 1000);
  };

  _promptVerification = () => {
    prompt(
      this.props.cartStore.menuStore.translation.verification,
      this.props.cartStore.menuStore.translation.verification_message,
      [
        {
          text: this.props.cartStore.menuStore.translation.cancel,
          onPress: () =>
            new Promise(resolve => {
              resolve(null);
            })
        },
        {
          text: this.props.cartStore.menuStore.translation.confirm,
          onPress: value => {
            this._checkoutFlow(value);
          }
        }
      ]
    );
  };

  _checkoutFlow = value => {
    if (
      this.props.cartStore.menuStore.checkVerificationCode(
        value.replace(/ /g, "")
      )
    ) {
      this.setState({
        verificationCode: value
      });

      this._prepareCountdown();
    } else {
      alert(
        this.props.cartStore.menuStore.translation.verification_invalid,
        null,
        [
          {
            text: this.props.cartStore.menuStore.translation.close,
            onPress: () => { }
          }
        ]
      );
    }
  };

  _clickCheckout = e => {
    e.click();
  };
_handleCheckoutAction=(erroMsg)=>{
	 const { cartStore } = this.props;
	 const isStoreOpen = cartStore.checkOperatingHour();
          if (isStoreOpen !== true && cartStore.menuStore.layout!==4) {
            this.setState({
              promptTitle: isStoreOpen.title,
              promptContent: isStoreOpen.content,
              promptLoading: false,
              promptPopup: true,
              closePopup: this._defaultClose()
            });          
            return false;
          }	
          cartStore.checkCartsStatus();
          if (erroMsg!=="" || isStoreOpen !== true) {
			  let msg=isStoreOpen !== true?isStoreOpen.content:this.props.cartStore.menuStore.translation.not_available_msg;
			 if(cartStore.menuStore.layout===4)
			 {
				alert(
						<div className="cartItem"></div>,
						<div dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(msg) }}/>,
						[
						  {
							text: this.props.cartStore.menuStore.translation.close
						  },						  
						]
					  );                  
			 }				 
            return false;
          }
          else if (cartStore.menuStore.areaLimit > 0) {
             localStorage.removeItem("is_opened_post_question");
          }
          if (cartStore.menuStore.socialCheckout === true) {
            if (!("id" in cartStore.menuStore.social) && !("eberId" in cartStore.menuStore.social)) {
				if(cartStore.menuStore.isBWCMethod())
		         {
					 let msg=cartStore.menuStore.translation.invalid_bwc_membership;
					alert(
						<div className="cartItem"></div>,
						<div dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(msg) }}/>,
						[
						  {
							text:cartStore.menuStore.translation.close
						  },						  
						]
					  );
				 }
				else
				{
					 cartStore.menuStore.isLoginModalVisible = true;
				}	
              return;
            }
          }  
          cartStore.isTagExpired();      
          if (cartStore.menuStore.checkPostFields()) {
            return false;
          }         
          if (cartStore.isTagExpired()) {
            localStorage.removeItem(`${this.controller}_noRedirect`);
            this.setState({
              promptPopup: true,
              promptTitle: "",
              promptLoading: false,
              promptContent: cartStore.menuStore.translation.scan_message,//"To place order, SCAN the QR Code again.",
              closePopup: [

                {
                  text: cartStore.menuStore.translation.scan,
                  onPress: () => {
                    window.location.href = configParam.scanUrl;
                  }
                },
                {
                  text: cartStore.menuStore.translation.cancel,
                  onPress: () => {
                    this.setState({
                      promptPopup: false
                    });
                  }
                }
              ]
            });
          } else {       
               cartStore.resetEntries(); 
              if (cartStore.menuStore.verificationCode === null || !cartStore.isCodeExpired()) {
                let tagObj = cartStore.menuStore.checkTagAction(); //'payment', '0' 
                let  paymentAction=""; 
                if(tagObj.payment)
                {
                  paymentAction=tagObj.payment; 
                }
                if(cartStore.menuStore.layout ===4) 
                {
                  //this._prepareCountdown();
				  this.callPymanetOptions.openSinglePaymentGateway({'gateway':'none'});
                } 				
                else if(paymentAction==="0" || cartStore.menuStore.paymentGateways.length === 0 || (paymentAction==="2" && Math.ceil(cartStore.total)===0)) 
                {
                  //this._prepareCountdown();
				  this.callPymanetOptions.openSinglePaymentGateway('nogateway');
                }			
                else if (cartStore.menuStore.paymentGateways.length === 1) {
                  this.callPymanetOptions.openSinglePaymentGateway(cartStore.menuStore.paymentGateways[0]);
                }
                else if (cartStore.menuStore.paymentGateways.length > 1) {
                  cartStore.menuStore.showPaymentOption = true;
                }
                localStorage.removeItem("is_opened_post_question");
              }
              else {
                cartStore.removeCachedCode();
                this._promptVerification();
              }          
          }
	
}
  _renderCheckout() {
    const { cartStore } = this.props;
    let isDisplayPrice=cartStore.checkTotoitoastOutlet();
	let erroMsg=this._getErrorMsg();
	let iconbtn=<FaCheckCircle color="green" />;

	if(erroMsg!=="")
	{
	iconbtn=<FaTimesCircle color="red" />
	} 
    if(cartStore.menuStore.layout===4)
	{
		iconbtn="";
	}
	   
    return (
      <Button
        style={{ cursor: "pointer" }}
        // href="#"
        id="checkoutButton"
        onClick={() => {
          //    console.log(cartStore.menuStore.areaLimit);	
		  let checkValidRedeem=cartStore.checkValidRedeemAmount();
          if (isDisplayPrice === 'error') {
            this.setState({
              promptTitle: this.menuStore.translation.invalid_outlet_title,
              promptContent: this.menuStore.translation.invalid_outlet_message,
              promptLoading: false,
              promptPopup: true,
              closePopup: this._defaultClose()
            });          
            return false;
          }	
		  else if(checkValidRedeem === 'error') {
			  let msg=cartStore.menuStore.translation.less_redeem_amount;
					alert(
						<div className="cartItem"></div>,
						<div dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(msg) }}/>,
						[
							{
							text: cartStore.menuStore.translation.change_voucher,
							onPress: () => {							
							   cartStore.menuStore.openRewardListModal=true;
							}
							},
							{
							text: cartStore.menuStore.translation.add_new_items,
							onPress: () => {
						      this.props.showTabsPage('menu');
							 }
						   }							
						]
					  );
		  }
          else
          {
            this._handleCheckoutAction(erroMsg);
          }           
         }
        }
        disabled={erroMsg!=="" && cartStore.menuStore.layout!==4}
      >
	  {iconbtn}&nbsp;
        {cartStore.menuStore.translation.confirm}{" "}
        {parseFloat(cartStore.total) === 0 || isDisplayPrice==='error' || isDisplayPrice===false ? "" : cartStore.menuStore.currencySymbol + cartStore.total}
      </Button>
    );
  }

  _defaultClose = closeText => {
    return [
      {
        text: closeText
          ? closeText
          : this.props.cartStore.menuStore.translation.close,
        onPress: () => {
          clearInterval(countdownTimer);
          clearTimeout(cancelCheckout);
          //localStorage.removeItem("is_opened_post_question");       
          this.setState({
            promptPopup: false,
            //htmlMsg:false,
            promptContent:""
          });
        }
      }
    ];
  };

  _popupContent = () => {
    //console.log(999,this.state.promptContent);
    return (
      <div>
        {Array.isArray(this.state.promptContent) ? (
          <div>
            <div> {this.state.promptContent[0]} </div>
            <div> {this.state.promptContent[1]} </div>
          </div>
        ) : (
            <div dangerouslySetInnerHTML={{
              __html: this.props.cartStore.menuStore.htmlDecode(this.state.promptContent)
              }} />
          )}
      </div>
    );
  };

  _renderPromptPopup = () => {
    return (
      <PromptPopup
        visibility={this.state.promptPopup}
        title={this.state.promptTitle}
        closePopup={this.state.closePopup}
        content={this._popupContent()}       
        isLoading={this.state.promptLoading}
       // htmlMsg={this.state.htmlMsg}
	   cartStore={this.props.cartStore}
      />
    );
  };
  _getErrorMsg=()=>{
	  let tagObj = this.props.cartStore.menuStore.checkTagAction(); 
	  let qty=this.props.cartStore.size;
	  //console.log(qty,'qty');
	  let freeQty=this.props.cartStore.freeQty;
	  let erroMsg="";
	  if(this.props.cartStore.menuStore.notAvailable.length>0)
	  {
		  erroMsg=this.props.cartStore.menuStore.translation.not_available_msg;
	  }
	  else if(tagObj.min_order && tagObj.min_order>-1 && this.props.cartStore.total< tagObj.min_order){
		 erroMsg=tagObj.min_order_message; 
	  }
	  else if(tagObj.max_order && tagObj.max_order>-1 && this.props.cartStore.total> tagObj.max_order){
		 erroMsg=tagObj.max_order_message; 
	  }
	  else if(tagObj.min_qty && tagObj.min_qty>-1 && qty< tagObj.min_qty){
		 erroMsg=tagObj.min_qty_message; 
	  }
	  else if(tagObj.max_free_qty && tagObj.max_free_qty>-1 && freeQty> tagObj.max_free_qty){
		 erroMsg=tagObj.max_free_qty_message; 
	  }	
	  else if(tagObj.max_qty && tagObj.max_qty>-1 && qty> tagObj.max_qty){
		 erroMsg=tagObj.max_qty_message; 
	  }  
	  return erroMsg;
  };
  
  render() {
    let erroMsg=this._getErrorMsg();
	let title=this.props.cartStore.menuStore.translation.clear_pending_title;
	let alertMsg=this.props.cartStore.menuStore.translation.clear_pending_msg;
	let yesButton=this.props.cartStore.menuStore.translation.confirm;
	if(this.props.cartStore.menuStore.layout===4)
	{
	title=<Icon type="exclamation-circle-o" style={{ fontSize: '50px'}}  />;
	yesButton=this.props.cartStore.menuStore.translation.yes;
	alertMsg=<div dangerouslySetInnerHTML={{
              __html: this.props.cartStore.menuStore.htmlDecode(this.props.cartStore.menuStore.translation.clear_cart_message)
              }} />
	}
	let input_promocode = localStorage.getItem(`input_promocode`);
	if(!input_promocode)
	{
	input_promocode="";	
	}
	let rewardName=this.props.cartStore.appliedRewardName();
    return (
      <Fragment>
        <div>
		{this.props.cartStore.menuStore.layout!==4 && (<div className={this.props.cartStore.size > 0 && erroMsg!=="" ? "notavialableCls" : "hideErrorMessage"}  ><div>{erroMsg}</div></div>)}
          <div className={erroMsg!=="" ? "checkoutBtn checkoutBtnGrey" :"checkoutBtn confirmCheckoutBtn"}>
            {this.props.cartStore.size > 0 && this._renderCheckout()}
          </div>
          <div className="viewPortCart">		     	
            {this.props.cartStore.entries.length > 0 && this.props.cartStore.menuStore.takeaway > 0 && this.props.cartStore.menuStore.layout!==4 ? (
              <Fragment key="takeaway">
                <div className="cartHeader" />
                <div className="takeAway">
                  <List.Item
                    extra={<Switch
                      checked={this.state.checked}
                      color="#108ee9"
                      onChange={() => {
                        this.setState({
                          checked: !this.state.checked,
                        });
                        this.props.cartStore.takeAway = !this.props.cartStore.takeAway;
                      }}
                    />}
                  ><FaPaperclip color={"#666"} /><span>{this.props.cartStore.menuStore.translation.take_away}</span></List.Item>
                </div></Fragment>
            ) : ('')}
            {this.props.cartStore.entries.length > 0 && this.props.cartStore.menuStore.isRemarksVisible && this.props.cartStore.menuStore.layout!==4 ? (
              <Fragment key="remarks">
                <div className="cartHeader" />
                <div className="takeAway">
                  <InputItem
                    style={{ width: "100%" }}
                    placeholder={this.props.cartStore.menuStore.translation.remarks}
                    onChange={value => {
                      this.props.cartStore.remarks = value;
                    }}
                    clear
                  >
                    <FaPaperclip color={"#666"} />
                  </InputItem></div></Fragment>
            ) : ('')}
			
			<div className="cartScreen">		
            {this._renderCartEntry()} 
		    {this.props.cartStore.entries.length > 0 && this.props.cartStore.menuStore.layout===4? (	
			<Fragment>
            <div className="cartlinks"><span className="clearCart" onClick={() => {
              alert(
			    title,
                alertMsg,
                [
                  {
                    text: this.props.cartStore.menuStore.translation.cancel
                  },
                  {
                    text: yesButton,
                    onPress: () => {
                      this.props.cartStore.empty();
                      this.props.cartStore.menuStore.sendGoogleAnalytics('Click', 'Reset Order', this.props.cartStore.menuStore.controller, 1);
                    }
                  }
                ]
              );

            }}
          >
          {this.props.cartStore.menuStore.translation.clear_cart}
			</span><span className="addcartitems"
			onClick={()=>{this.props.showTabsPage('menu');}}
			><FaPlus />{this.props.cartStore.menuStore.translation.add_more_item}</span>
			</div>   
            {rewardName!==""?(<div className="rewardVoucherInfo"> 
				<span className="cartRewardName">{rewardName}</span>				
				<div className="cartRewardIconText"
				onClick={()=>{this.props.cartStore.menuStore.openRewardListModal=true;}}
				>Reward</div></div>):(<div className="voucherInfo"> <Button
				onClick={()=>{
					this.setState({ promoPopup: true });
				}}
				className="addpromolink"
				>
				<span>Promo Code </span>				
				</Button>{ input_promocode && input_promocode!=="" && (<span className="promodelete">({input_promocode}) 
				 <div className="cartItemDelete"
				onClick={() => {
					localStorage.removeItem('input_promocode');
					this.setState({promocode:''});
				}}>
				<FaTrashAlt color={"#666"} size={16} />				
				</div>
				
			</span>)}</div>)} 			
			<Recomendations page="cart" length={this.props.cartStore.entries.length}/>
			
			<PowerBy/></Fragment>) : ('')}	
            </div>
		 
		    {this._renderConfimPopup()} 
		   {this.props.cartStore.entries.length===0?(
             <CheckBill  cartStore={this.props.cartStore} />
            ):('')}
			
          </div>

          <div>{this._renderPromptPopup()}</div>

          <PostQuestions
            showModal={this.props.cartStore.menuStore.actionModalVisisble==="post"}
          //onMounted={postQA => this.checkPostQAy(postQA)}
         //  showErrorMessage={this.showErrorMessage}
           questionFileds={this.props.cartStore.menuStore.postQuestionFileds}
           action_type="post"
           action_opend_key="is_opened_post_question"		   
          />
          <PaymentOption
            visible={this.props.cartStore.menuStore.showPaymentOption}
            close={() => {
              //this.setState({ showPaymentOption: false });
            }}
            _callPaypalPaymentStatus={this._callPaypalPaymentStatus}
            _callCartStatusCheck={this._callCartStatusCheck}
            _callMerchantCreateOrderApi={this._callMerchantCreateOrderApi}
            onMounted={openSinglePaymentGateway => this.checkSinglePaymentGateway(openSinglePaymentGateway)}
            total={`${this.props.cartStore.total}`}
          />
        </div>
		<Modal visible = { this.state.promoPopup }
            transparent = { true }
            maskClosable = { false }
            animationType = "slide-up"
            closable = { false }
            className = "App table_question promopopup"
            wrapClassName = "qa-wrap"
             title = <div><span></span><span className="closeBtn"
			 onClick={()=>{
				 this.setState({promoPopup:false});
			 }}
			 ></span></div>
             footer = {[
              {                
                text: 'Redeem Voucher',
                onPress: () => {
					localStorage.setItem('input_promocode',this.state.promocode);
					this.setState({promoPopup:false});
                }
              }
			 ]}
		    >
			<div className="promoheading">Add Voucher</div>
			<div className="promoInput">			
			<input type="text" value={this.state.promocode} 
			placeholder="Promo Code"
			onChange={(val)=>{				
				this.setState({promocode:val.target.value});
			 }
			}
			/>
			
			</div>
			</Modal>
		
		
		
      </Fragment>
    );
  }
}

const CartItem = observer(({ entry, cartStore,ind }) => {
  let item = entry.item;
  let isDisplayPrice=cartStore.menuStore.checkPriceDisplay(item);
  let extratag = <span
    className="qtytextfield"

    onClick={() => {
      cartStore.menuStore.itemModal.showUpdateModal(
        entry.item,
        entry.addons,
        entry.notes
      );
    }}
  >{cartStore.quantity_map.get(entry.item.id)}</span>;
  let avail = cartStore.menuStore.isAvailable(item);
  if (!avail) {
    
    extratag = <div className="itemNotAvail">
      {cartStore.menuStore.translation.not_available}

    </div>
  }
  
  let itemNotes = [];
if(cartStore.menuStore.layout===4)
{
	itemNotes=cartStore.getAddonsNamesList(entry.addons);
	let total=cartStore.entryTotal(entry);
	let {actualPrice,priceAmt}=cartStore.calculateRewardDiscoutPrice(entry.reward,total);
	// let spRequets=[];
	// if (entry.notes.length > 0) {
	// _.each(entry.notes, (note, index) => {
	   // if (note !== '' && spRequets.length===0) { spRequets.push(note); }
	   // });
	// }
let reward=	entry.reward;
let rewardDiv="";
let isRwardItem=false;
let isDisableCnt=false;
if(!item.isInStock)
{
   isDisableCnt=true;	
}
if(reward && reward.redeem_code && reward.redeem_code!=="")
{
	isRwardItem=true;
	isDisableCnt=true;
	rewardDiv=<div className="disNameBg">{reward.redeem_name}</div>;
}
return (
    <List>
      <Item
        wrap
        className="cart_item"
        thumb={<CartImg entry={entry} cartStore={cartStore} ind={ind} isRwardItem={isRwardItem}/>}
      ><div
        className="cartItemName"
      >
          <div className="innerItemName">
            <span className="cartItem">{item.name}</span><span className="pricecart"> 			
			 <div className="cartItemDelete"
				onClick={() => {
					
			    	alert(
						<div className="cartItem"></div>,
						<div dangerouslySetInnerHTML={{ __html:cartStore.menuStore.htmlDecode(cartStore.menuStore.translation.delete_item) }}/>,
						[
						  {
							text: cartStore.menuStore.translation.close
						  },
						  {
								text: cartStore.menuStore.translation.yes,
								onPress: () => {
									cartStore.menuStore.sendGoogleAnalytics(cartStore.menuStore.googleTrashItem, entry.item.name, cartStore.menuStore.controller, entry.item.price * entry.addons.length*100);
									//console.log(ind);
									cartStore.removeItemIndexWise(entry.item,ind);
								}
						  }						  
						]
					  );	
				
				}}
				>
				<FaTrashAlt color={"#666"} size={16} />
			 </div>
			  <Button
				onClick={()=>{
					if(!isRwardItem)
					{
						cartStore.menuStore.itemModal.showUpdateModal(
						cartStore.entries[ind].item,
						cartStore.entries[ind].addons,
						cartStore.entries[ind].notes
						);
						
						//let {itemModal}=cartStore.menuStore;
						cartStore.menuStore.itemModal.isVisible=2;
						cartStore.menuStore.openSetModal=true;
					}
				}}
				className="editcartitem"
				disabled={isRwardItem}
				>
				edit
				</Button> 
			</span>            
		  </div>
		  {rewardDiv}
		  <Brief>
		     <RenderAddon addonSlected={entry.addons} />
			 <RenderNotes  entry={entry}/>
		  </Brief>
		  <div className="innerStepperRow">
		   <div className="cartSteper">
			<FoodStepper
              value={
                entry.addons.length
              }
              min={0}
              onChange={throttle(
                quantity => {
                     // cartStore.updateQuantity(entry, quantity);
                     // cartStore.resetEntries();
					       if(quantity>0)
						   {
					         if (quantity > entry.addons.length) {
								  cartStore.entries[ind].addons.push(entry.addons[0]);
                                } else {
                                   cartStore.entries[ind].addons.pop();
                                   cartStore.entries[ind].notes.pop();
                                 
                                }
                              cartStore.resetEntries();
						   }
							  
                },
                250,
                { maxWait: 1000 }
              )}
              disabled={isDisableCnt}
			  screen="cart"
			  checkQuanityError={checkQuanityError}
			  cartStore={cartStore}
			  item={item}
            />
			</div>
			{actualPrice!==priceAmt?(<p className="pricep"><strike className="strikeprice">{cartStore.menuStore.currencySymbol+parseFloat(actualPrice).toFixed(2)}</strike>{cartStore.menuStore.currencySymbol+parseFloat(priceAmt).toFixed(2)}</p>):(
				<p className="pricep">{cartStore.menuStore.currencySymbol+parseFloat(priceAmt).toFixed(2)}</p>)}
          </div>
        </div>
      </Item>
    </List>
  );
}
else
{
	let priceTx = parseFloat(item.price) === 0 ? cartStore.menuStore.translation.price_zero : item.priceName;
	  return (
    <List>
      <Item
        wrap
        className="cart_item"
        thumb={<CartImg entry={entry} cartStore={cartStore} ind={ind} isRwardItem={false}/>}
        extra={extratag}
      ><div
        onClick={() => {
          cartStore.menuStore.itemModal.showUpdateModal(
            entry.item,
            entry.addons,
            entry.notes
          );
        }}
        className="cartItemName"
      >
          <div className="innerItemName">
            <p>{item.name}</p>
            <Brief>
             {isDisplayPrice && (
              <div dangerouslySetInnerHTML={{ __html: cartStore.menuStore.htmlDecode(priceTx) }} />
              )}
              {entry.hasNote && <FaPaperclip />}{itemNotes}
            </Brief>
          </div>
        </div>
      </Item>
      <CartItemAddon entry={entry} cartStore={cartStore} />
    </List>
  );
}

});

const CartItemAddon = observer(({ entry, cartStore }) => {
  const { item, addons, notes } = entry;
  //console.log(item.hasAddons,'tt');
  const template = [];
  // If the item has no addons, just skip addon portion
  if (item.hasAddons) {
    // Handle the items defauted in a set menu first (So it appears on the top)
    if (addons && "undefined" in addons) {
      _.each(addons["undefined"], (addon, index) => {
        template.push(<AddonFixed key={`fixed_${index}`} addon={addon} isCustomize={item.isCustomize} screen='cart'/>);
      });
    }

    // Iterate through the cart which stores the items which contains the addons
	 let checkIndex=cartStore.getAddonIndex(addons);
   let headingNum=0;
    _.each(addons, (addonList, addonListIndex) => {
      let sQty=cartStore.getSetMealQty(addonList);
      if(sQty>0)
      {
        headingNum++;
      }
      //console.log(sQty,9);
	  if((item.isCustomize && addonListIndex===checkIndex) || !item.isCustomize)
	  {	
      template.push(
        <div
          id={`addon_${addonListIndex}_${item.id}`}
          key={addonListIndex}
          className={sQty>0?"addonHeader":"hideDiv addonHeader"}
        >
          {
            <span
              style={{ textAlign: "left", paddingRight: 6, fontSize: 12 }}
              onClick={() => {
                 entry.removeAddon(addonListIndex);
                if (entry.addons.length === 0 || entry.item.isCustomize) cartStore.removeItem(entry.item);
				cartStore.resetEntries();
				
              }}
            >
              <FaTrashAlt />
            </span>
          }

          <span
            onClick={() => {
              cartStore.menuStore.itemModal.showUpdateModal(
                item,
                [addons[addonListIndex]],
                [notes[addonListIndex]], // An array of notes array
                value => {
                  notes[addonListIndex] = value;
                }
              );
            }}
			     className="setMealHeading"
           id={`addon_${addonListIndex}_${item.id}_heading`}
          >
            Set {headingNum}
          </span>

          <FiEdit
            style={{ position: "absolute", right: 9 }}
            onClick={() => {
				cartStore.menuStore.isSetMealEdit=true;
              cartStore.menuStore.itemModal.showUpdateModal(
                item,
                [addons[addonListIndex]],
                [notes[addonListIndex]], // An array of notes array
                value => {
                  notes[addonListIndex] = value;
                }
              );
            }}
          />
        </div>
      );

      let keys = Object.keys(addonList);
      _.each(keys, (key, index) => {
        _.each(addonList[key], (addon, addonIndex) => {
          template.push(
            <AddonSummary
              key={`${addonListIndex}${index}_${addon.id}_${addonIndex}`}
              addon={addon}
			  isCustomize={item.isCustomize}
			  screen='cart'
            />
          );
        });
      });
	}
    });
  }
  return template;
});

const CartImg = observer(({ entry, cartStore,ind,isRwardItem }) => {
  let layoutType = cartStore.menuStore.layout;
  let defaultImage = false;
  if (layoutType === 3) {
    defaultImage = entry.item.thumbnail.includes('/dish');
  }

  return (
    <div>
      {cartStore.menuStore.layout!==4 && (<span
        // style={{ marginRight: "10px" }}
        className={defaultImage ? "" : "marginRight10"}
        onClick={() => {
          cartStore.menuStore.sendGoogleAnalytics(cartStore.menuStore.googleTrashItem, entry.item.name, cartStore.menuStore.controller, entry.item.price * entry.addons.length*100);
          cartStore.removeItem(entry.item);
        }}
      >
	  <FaTrashAlt color={"#666"} size={16} />
      </span>)}
      {!defaultImage ? (
        <img
          src={entry.item.thumbnail}
          alt=""
          onClick={() => {
			if(!isRwardItem)
			{
			 if(cartStore.menuStore.layout===4)
			 {
				cartStore.menuStore.itemModal.showUpdateModal(
					cartStore.entries[ind].item,
					cartStore.entries[ind].addons,
					cartStore.entries[ind].notes
					);
					cartStore.menuStore.openSetModal=true;
					let {itemModal}=cartStore.menuStore;
					itemModal.isVisible=2;              
			 }
           
				cartStore.menuStore.itemModal.showUpdateModal(
				  entry.item,
				  entry.addons,
				  entry.notes
				);		
			}				
          }}
        />) : ('')}
    </div>
  );
});
function checkQuanityError(cartStore, item, qty) {
	let qty1=qty;
	let isNextproceed=true;
	if(qty<0)
	{
		let enty=cartStore.getEntry(item);
		qty1=1;
		if(enty)
		{
			 qty=Math.abs(qty); 
			let oldQty=cartStore.quantity_map.get(item.id);
			//console.log(qty,'<',oldQty);
			if(qty<oldQty)
			{
			  qty1=0;
              isNextproceed=false;			  
			}
		}		
	}
	if(!isNextproceed)
	{
		return true;
	}
	let erroMsg=cartStore.checkQuantityValidation(item,qty1);
	if(erroMsg!=="")
	{
	alert(cartStore.menuStore.translation.error, erroMsg, [
	{ text: "Close", onPress: () => { } }
	]);
	   return false;
	}
	return true;
}
export default Cart;
