import React from "react";
import { observer, inject } from "mobx-react";
import configParam from "../libs/params";
@inject("cartStore")
@observer
class PowerBy extends React.Component {
 
    render() {
		return (
            <div className="withoutLog">
              {this.props.cartStore.menuStore.isUnisolnControler()?(
                <div className="poweredByLog">
                <p>{this.props.cartStore.menuStore.translation.powered_by} Unisoln (S) Pte Ltd</p>
                </div>):( 
                <div className="poweredByLog">
                <p>{this.props.cartStore.menuStore.translation.powered_by}</p>
                <a href={configParam.fzdomain} target="_blank" rel="noopener noreferrer">   <img src={configParam.fzlogo} alt="Foodzaps" /></a>
                </div>)}
            </div>
        );
      }
}
export default PowerBy;