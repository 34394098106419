import React ,{ Fragment } from "react";
import { Modal, Button,Checkbox, InputItem } from "antd-mobile";
import { observer, inject } from "mobx-react";
import { SocialIcon } from "react-social-icons";
import SocialButton from "./SocialButton";
import SocialParser from "./SocialParser";
import PromptPopup from "../Popup";
import _ from "underscore";
import { FaUser} from "react-icons/fa";
import request from "../../libs/request";
import configParams from "../../libs/params";
import HeaderPopup from "../../components/HeaderPopup";
const CheckboxItem = Checkbox.CheckboxItem;
const alert = Modal.alert;

@inject("cartStore")
@observer
class Login extends React.Component {
	  
		constructor(props) {
		super(props);
		 this.state = {
            step:"1",
          //registerpopup:false,
			pineapple_card_no:"",
			pineapple_password:"",
			ownerId: this.props.cartStore.menuStore.pineAppleOwnerkey,
			closePopup: [],
			promptPopup: false,
			promptTitle: "",
			promptContent: "",
			promptLoading: true,
			loginEmail:'',
			loginPass:'',
			isCheckingEber:0
		 };
		 
		}
	 componentDidMount () {
        if (this.props.onMounted) {
            this.props.onMounted({
                reloadModal: res => this.reloadModal(res)
            })
		}
		this.setState({ isCheckingEber: 2});
		/*let cc = this.props.cartStore.menuStore.controller;
		let eberToken=localStorage.getItem(`${cc}_eber_token`);
         if(eberToken!=="" &&  isEberExistform )
		 {
			const config = {
			headers: { Authorization: `Bearer ${eberToken}` }
			}
			 this.setState({ isCheckingEber: 1});
     		axios.get(configParams.eberProfileUrl,config).then(res=>{
				
				let pData=res.data;
				this.props.cartStore.menuStore.rewardsList=[];
				let rData=[];
				if(pData.redeemable_list.length>0)
				{
                   rData=pData.redeemable_list;	
				}
               this.props.cartStore.menuStore.rewardsList=rData;				
				this.setState({ isCheckingEber: 0});
				this.props.cartStore.menuStore.isLoginModalVisible = false;
                this.props.cartStore.menuStore.openRewardListModal=true;
				let social= localStorage.getItem(`${this.props.cartStore.menuStore.controller}_social`);
				
				let pRes = { name: pData.display_name, eberId:pData.id ,  provider: 'eber',email: pData.email,username:pData.username,phone_code:pData.phone_code,phone:pData.phone,address:'',points:0,rewards:[]};
				
				if(pData.address && pData.address!=="")
				{
				pRes.address=pData.address;
				}
				if(pData.points && pData.points.length>0)
				{
				pRes.points=pData.points[0].points;
				}
				if(social!=="")
				{
					social=JSON.parse(social);
					if(social && social.eberId===pData.id)
					{
						pRes.rewards=social.rewards;
					}
				}
				this.props.cartStore.menuStore.saveSocial(pRes);
			 }).catch(err => {				  
				 this.setState({ isCheckingEber: 2});
				 console.log('error while eber login',err);
			 });
			 
		 }
         else
		 {
			 this.setState({ isCheckingEber: 2});
		 }*/			 
	 };
	reloadModal=(res)=>
	{
		//just reload the modal
	};	
	eberButton = (appId) => {
		return (<Button
              className=""
			  key="eberbtn"
              onClick={() => {                  
			  
				  window.location.href="https://oauth.connectors.eber.io/o/oauth?client_id="+appId+"&redirect_uri="+configParams.eberRedirectUrl+"?controller="+this.props.cartStore.menuStore.controller;
              }}             
            >  
			  {this.props.cartStore.menuStore.translation.sign_in}</Button>);
	};	
  buttonGenerator = (provider, appId) => {
	 let responsebck=window.location.origin + window.location.pathname;
	 let cController=this.props.cartStore.menuStore.controller;
	 //let cController="demo";
	 
	 //localStorage.setItem('lastController',cController);
    if(provider==="instagram")
	{		
		responsebck=window.location.origin + '/bigbang?c='+cController;
		//responsebck=window.location.origin + '/bigbang?c=demo';
	}
	//console.log(responsebck);
	let btntext="continue_with_"+provider;
    return (
      <SocialButton
        key={provider}
        provider={provider}
		className={provider}
        appId={appId}
		redirect={responsebck}
        onLoginSuccess={(user, err) => {
			 //console.log(user);
          const parsed = SocialParser[provider](user);
          try {
             this.props.cartStore.menuStore.saveSocial(parsed);
			 var p=this.getQueryString('c',window.location.href);			 
			 this.props.cartStore.menuStore.sendGoogleAnalytics('login',provider, cController, 1);
			if(p!=="" && p!==null)
			{
				window.location = window.location.origin + '/'+p;
			}
          } catch (err) {
            console.log(err);
          }
        }}
        onLoginFailure={(user, err) => {
          //alert(err);
         // console.log(user);
          //console.log(err);
        }}
      >
	  {this.props.cartStore.menuStore.layout!==4 && <SocialIcon url="#" network={provider} />}<span>{this.props.cartStore.menuStore.translation[btntext]}</span>
      </SocialButton>
    );
  };
  generatePineAppleButton=(provider, appId)=>
  {
	  if(this.props.cartStore.menuStore.login_option==='1')
	  {		 
		  return ('');
	  }
	  else
	  {
		   return (
	        <span className="socialButton customWap" key="pineapplebtn" ><Button key="pineapplebtn" className="customBtn"
			 onClick={() => {                  
				  //this.pineapple=true;
				  //console.log('yess');
				  this.props.cartStore.menuStore.pineAppleOwnerkey=appId;
				  this.setState({ pineapple: true });
              }} 
			 ><span className="iconz"> <div className="ccle"><img src={configParams.pineAppleRoundImage} alt="Pineapple"/></div></span><span className='txt'>{this.props.cartStore.menuStore.translation.pineapple_btn_text}</span></Button></span>	  
	     );
	  }
  };
  renderButtons = () => {
    const list = [];
	const onlyEber=[];
    _.each(this.props.cartStore.menuStore.socialProviders, (e, i, l) => {
      const keys = Object.keys(e);
	
      //list.push(this.buttonGenerator(keys[0], "560722247670145"));
	  if(keys[0]==='pineapple')
	  {
		  this.props.cartStore.menuStore.pineAppleOwnerkey=e[keys[0]];
		  list.push(this.generatePineAppleButton(keys[0], e[keys[0]]));
	  }
	  else if(e.eber)
	  {	
         let btn=  this.eberButton(e.eber.eberPublicClientId);
		 list.push(btn);
		 onlyEber.push(btn);
	  }
	  else{
      list.push(this.buttonGenerator(keys[0], e[keys[0]]));
	  }
    });
    if(this.props.cartStore.menuStore.layout===4)
	{
		return onlyEber;
	}
    return list;
  };
    _renderLoginPromptPopup = () => {
    return (
      <PromptPopup
        visibility={this.state.promptPopup}
        title={this.state.promptTitle}
        closePopup={this.state.closePopup}
        //content={this._popupContent()}
        isLoading={true}
		 cartStore={this.props.cartStore}
      />
    );
  };

 renderPineAppleModal=()=>{
	return (<Modal
                visible={this.state.pineapple}		
				transparent={true}
				maskClosable={false}
				animationType="slide-up"
				closable={false}
				//title={this.props.cartStore.menuStore.translation.pineapple_login_heading}
				className="App table_question pineappleModal"				
           >
 <div className="row heading"><img src={configParams.pineAppleLogo} alt="Pineapple"/><span>{this.props.cartStore.menuStore.translation.pineapple_login_heading}</span></div>
		   <div className="row borderTop">
					<div>
					<InputItem
					placeholder={this.props.cartStore.menuStore.translation.pineapple_card_no} 
					name="pineapple_card_no"
					type="text"
					id="pineapple_card_no"
					value={this.state.pineapple_card_no}
					onChange={(val) => this.setState({pineapple_card_no: val })}
					required
					/>
					</div>		  
					<div>
					<InputItem
					placeholder={this.props.cartStore.menuStore.translation.pineapple_password} 
					name="pineapple_password"
					type="text"
					id="pineapple_password"
					value={this.state.pineapple_password}
					onChange={(val) => this.setState({pineapple_password: val })}
					required
					/>
					</div>
					
					<div className="btnSetP">
					<Button
					className="class-btn option-list greytext"
					onClick={() => {                  

					this.setState({ pineapple: false });
					}} 
					>{this.props.cartStore.menuStore.translation.cancel}</Button>
					<Button
					className="class-btn option-list"
					onClick={() => { 
					 if(this.state.pineapple_card_no==="" || this.state.pineapple_password==="")
					 {
						 alert(this.props.cartStore.menuStore.translation.pineapple_error, null, [
                            { text: "Close", onPress: () => {} }
                          ]);
						 return;
					 }
					 
					 this.setState({pineapple: false,promptPopup: true,
					                promptTitle: this.props.cartStore.menuStore.translation.pineapple_login_checking});
					 
					 request
						.post(configParams.pineAppleMemberApi,'ownerId='+this.props.cartStore.menuStore.pineAppleOwnerkey+'&cardNo='+this.state.pineapple_card_no+'&password='+this.state.pineapple_password)
                        .then(res => {
							
							let provider='pineapple';
							let cController=this.props.cartStore.menuStore.controller;
							let pRes = { name: res.member.name, id:res.member.cardNo ,  provider: provider,email: res.member.email};
							 if(res.member.phone && res.member.phone!=="")
							 {
								pRes.phone=res.member.phone;
							 }
							 if(res.member.address && res.member.address!=="")
							 {
								pRes.address=res.member.address;
							 }
							 this.setState({ promptPopup: false});
							 this.props.cartStore.menuStore.saveSocial(pRes);
			                 //window.gtag('event',provider, {  'event_category': 'login',  'event_label': cController,  'value': 1});	
							 this.props.cartStore.menuStore.sendGoogleAnalytics('login',provider, cController, 1);  
													 
                          
                        })
                        .catch(err => { 
                            //console.log(err,'7777');						
                             this.setState({ promptPopup: false});
							 err = JSON.parse(err);
							  alert(err.message, null, [
                            { text: "Close", onPress: () => {
								 this.setState({ pineapple:true});
							} }
                          ]);
                        });
					}} 
					>{this.props.cartStore.menuStore.translation.pineapple_submit}</Button>
					
					</div>
			</div>	   
      </Modal>);
 };
 _renderOptionFirstModal=()=>{
	 
	 return (	
      <Modal
        visible={this.props.visible}
        style={{ overflow: "hidden", height: "100%", textAlign: "center" }}
        className="socialModal newModal"
      >
	  
	        <HeaderPopup changeLanguage={this.props.changeLanguage}/>
	        <div className="rewardWrap">	 

                  <div className="formSection">
                  <div>
					<InputItem
					placeholder={this.props.cartStore.menuStore.translation.pineapple_card_no} 
					name="pineapple_card_no"
					type="text"
					id="pineapple_card_no"
					value={this.state.pineapple_card_no}
					onChange={(val) => this.setState({pineapple_card_no: val })}
					required
					/>
					</div>		  
					<div>
					<InputItem
					placeholder={this.props.cartStore.menuStore.translation.pineapple_password} 
					name="pineapple_password"
					type="text"
					id="pineapple_password"
					value={this.state.pineapple_password}
					onChange={(val) => this.setState({pineapple_password: val })}
					required
					/>
					</div>
				
					<Button
					className="class-btn option-list"
					onClick={() => { 
					 if(this.state.pineapple_card_no==="" || this.state.pineapple_password==="")
					 {
						 alert(this.props.cartStore.menuStore.translation.pineapple_error, null, [
                            { text: "Close", onPress: () => {  this.setState({ promptPopup: false});} }
                          ]);
						 return;
					 }
					 
					 this.setState({pineapple: false,promptPopup: true,
					                promptTitle: this.props.cartStore.menuStore.translation.pineapple_login_checking});
					 
					 request
						.post(configParams.pineAppleMemberApi,'ownerId='+this.props.cartStore.menuStore.pineAppleOwnerkey+'&cardNo='+this.state.pineapple_card_no+'&password='+this.state.pineapple_password)
                        .then(res => {
							
							let provider='pineapple';
							let cController=this.props.cartStore.menuStore.controller;
							let pRes = { name: res.member.name, id:res.member.cardNo ,  provider: provider,email: res.member.email};
							if(res.member.phone && res.member.phone!=="")
							 {
								pRes.phone=res.member.phone;
							 }
							 if(res.member.address && res.member.address!=="")
							 {
								pRes.address=res.member.address;
							 }
							 this.setState({ promptPopup: false});
							 this.props.cartStore.menuStore.saveSocial(pRes);
			                 //window.gtag('event',provider, {  'event_category': 'login',  'event_label': cController,  'value': 1});	
							 this.props.cartStore.menuStore.sendGoogleAnalytics('login',provider, cController, 1);  						 
                          
                        })
                        .catch(err => { 
							 err = JSON.parse(err);
							  alert(err.message, null, [
                            { text: "Close", onPress: () => {
								 this.setState({ promptPopup: false});
							} }
                          ]);
                        });
					}} 
					>{this.props.cartStore.menuStore.translation.pineapple_submit}</Button>
				</div>
					 
			<div className="loginSeparator">{this.props.cartStore.menuStore.translation.social_connect}</div>
			<div className="rewBtnSet">  
          {this.renderButtons()}
		   {this.props.cartStore.menuStore.store.hasWaiter?(		  
			<span className="callwaiter">
			 <Button
              className="bypassbtn"
              onClick={() => {                  
				  this.props.callWaiterfunction();
              }}             
            > 
      	    <span className="btnLog1"><FaUser color={"#666"} size={16} /></span></Button>
			<span className="rewText">{this.props.cartStore.menuStore.translation
            .menu_option_call_waiter.toUpperCase()}</span>
			</span>
			 ):('')}
		  </div>		
		  

           <div className="rwcheck">      
			<label>
			<CheckboxItem key="permisison" 
			 onChange={e => {localStorage.setItem(`${this.props.cartStore.menuStore.controller}_enable_membership_account`,e.target.checked);}}			
			defaultChecked>
               <span>{this.props.cartStore.menuStore.translation.checkbox_permission_text}</span>
              </CheckboxItem>			
			</label>
			</div>
			 
		  {this.props.cartStore.menuStore.isLoginWithoutEnabled===2?(
		  
			<div className="skiplogin">
			 <Button
              className="bypassbtn"
              onClick={() => {                  
				  this.props.cartStore.menuStore.byPassSocialLogin();
              }}             
            >  
			{this.props.cartStore.menuStore.translation.continue_without_login} >></Button>
			</div>
			 ):('')}
				      
			     
          </div> 
		  {this._renderLoginPromptPopup()}
      </Modal>
	  
    );
 }
  _renderOptionSecondModal=()=>{
	  return (		
      <Modal
        visible={this.props.visible}
        style={{ overflow: "hidden", height: "100%", textAlign: "center" }}
        className="socialModal oldmodal"
      >
        <div className="withoutLog">
		 {this.props.cartStore.menuStore.store.logo!==""?(
		  <div className="logoWrap">
		<img
            src={this.props.cartStore.menuStore.store.logo}
            alt={this.props.cartStore.menuStore.store.name}           
			  />
			   </div>
			   ):('')}
		 
		  <div className="btnSet">
          {this.renderButtons()}
		  </div>
		   <div dangerouslySetInnerHTML={{ __html: this.props.cartStore.menuStore.htmlDecode(this.props.cartStore.menuStore.translation.login_html) }}  className="loginText"/>
		 
		   {this.props.cartStore.menuStore.store.hasWaiter?(		  
			<div className="btnSet">
			 <Button
              className="bypassbtn"
              onClick={() => {                  
				  this.props.callWaiterfunction();
              }}             
            > 
      	    <FaUser color={"#666"} size={16} />&nbsp;
			{this.props.cartStore.menuStore.translation
            .menu_option_call_waiter}</Button>
			</div>
			 ):('')} 
		  {this.props.cartStore.menuStore.isLoginWithoutEnabled===2?(
		  
			<div className="btnSet">
			 <Button
              className="bypassbtn"
              onClick={() => {                  
				  this.props.cartStore.menuStore.byPassSocialLogin();
              }}             
            >  
			{this.props.cartStore.menuStore.translation.continue_without_login}</Button>
			</div>
			 ):('')}
			<div className="poweredByLog">
			<p>Powered by</p>
			<a href={configParams.fzdomain}  target="_blank" rel="noopener noreferrer">   <img src={configParams.fzlogo}  alt="Foodzaps"  /></a>
			</div>			      
			     
          </div> 
		  {this.renderPineAppleModal()}
		   {this._renderLoginPromptPopup()}
      </Modal>
	  
    );
  }
  _renderLoginHome=()=>{
	  
	  return ( <div className="loginRegister loginhome">         
		 <img src={this.props.cartStore.menuStore.getLogo()} alt="logo" />
	  
	    <div className="buttonsection"> 
		  <div className="earnpoints"><span className="gifticon"></span><p style={{marginTop:"5.5px"}}>Earn points for rewards?</p></div>
			  {this.state.isCheckingEber===2 &&(
			   <Fragment>{this.renderButtons()}</Fragment>
			  )}
			   {this.state.isCheckingEber===1 &&(
			   <p style={{marginTop:"5.5px"}}>{this.props.cartStore.menuStore.translation.eber_checking}</p>
			   )}
			    
			<Button
              className="addpromolink"
              onClick={() => {                  
				  this.props.cartStore.menuStore.byPassSocialLogin();
              }}             
            >  
			{this.props.cartStore.menuStore.translation.continue_guest}</Button>
        </div>	  
	 
		{/*<div className="socialIcons">  
		<span className="facebook"></span><span className="instagram"></span><span className="youtubeicon"></span>
		</div>*/}
           	  
      </div>);
  }

  _renderCoCoSocial=()=>{
	   return (
	   <div className="bottomLine">
	   <div className="signinline"><span>{this.props.cartStore.menuStore.translation.or_sign_with}</span></div>
	   <div className="bottomIcons">  
	   {this.renderButtons()}
       </div></div>);
  }
  _renderCoCoHeader=()=>{
	  return (
	  <div className="info_header">
		<span className="backBtn"
		onClick={()=>{
			this.setState({step: '1' });
		}}
		>
		</span>
		<span className="logoclass">			
		<img src={this.props.cartStore.menuStore.getLogo()} alt="logo" style={{ height: "40px" }}
          //onClick={()=>{console.log(5555);}}
        />
		</span>
		<span className="homeclass">
		</span>
		</div>);
  }
  
  _renderOptionCocoModal=()=>{
	  return (	
      <Fragment>	  
      <Modal
        visible={this.props.visible}
       // style={{ overflow: "hidden", height: "100%", textAlign: "center" }}
        className="socialprompt"
      >	  
	   {this.state.step==='1' && (
	      <Fragment>{this._renderLoginHome()}</Fragment>
	    )}  
  	  
	  </Modal>
      </Fragment>	  
    );
  }
  render = () => {
	 // console.log(this.props.cartStore.menuStore.isLoginWithoutEnabled,'social login');
	 return (
	 <Fragment>
	 {this.props.cartStore.menuStore.layout===4 && this.props.cartStore.menuStore.isEberLoginExist===true && (
	      <Fragment>{this._renderOptionCocoModal()}</Fragment>
	 )}
	 
	  {this.props.cartStore.menuStore.login_option==="1" && this.props.cartStore.menuStore.layout!==4 && this.props.cartStore.menuStore.isEberLoginExist===false && (
	      <Fragment>{this._renderOptionFirstModal()}</Fragment>
	 )}
	 
	  {this.props.cartStore.menuStore.login_option!=="1" && this.props.cartStore.menuStore.layout!==4 && this.props.cartStore.menuStore.isEberLoginExist===false && (
	      <Fragment>{this._renderOptionSecondModal()}</Fragment>
	 )}
	 </Fragment>);
	
  };
	getQueryString = function ( field, url ) {
		var href = url;
		var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
		var string = reg.exec(href);
		return string ? string[1] : null;
	};
}

export default Login;
